<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import { ModelSelect } from "vue-search-select";

export default {
  page: {
    title: "Buat Aktivasi Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    members() {
      return this.$store.getters["user/users"];
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      batchs: "batch/batchs",
    }),
  },

  components: { ModelSelect, Layout, PageHeader },

  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Aktivasi Kelas"
        : "Buat Aktivasi Kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "Aktivasi Kelas",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        courseId: "",
        batchId: "",
        userId: "",
        expiredAt: "",
        typePayment: "FREE",
      },
      userId: "",
      submitted: false,
      errors: {},
      membersOptions: [],
      loading: false,
    };
  },

  watch: {
    "form.courseId"(val) {
      this.fetchBatch({ key: "courseId", value: val });
    },

    members: {
      handler(val) {
        this.membersOptions = [];

        Object.assign([], val).forEach((user) => {
          this.membersOptions.push({
            value: user.id,
            text: `${user.name} - ${user.phoneNumber}`,
          });
        });

        this.membersOptions.push({
          value: this.form.user.id,
          text: `${this.form.user.firstName} ${this.form.user.lastName} - ${this.form.user.phoneNumber}`,
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      fetchCourse: "course/fetch",
      fetchBatch: "batch/changeFilter",
      fetchUsers: "user/changeFilter",

      detail: "activationClass/detail",
      create: "activationClass/create",
      update: "activationClass/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};
      this.form.userId = this.userId;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-activation-class" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },

    uploadBanner(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.banner = e.target.result;
      };
    },

    changeMember(e) {
      const index = this.membersOptions.findIndex((member) => {
        return member.value === this.userId;
      });
      if (index === -1) {
        this.fetchUsers({ key: "search", value: e });
      }
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      this.form.expiredAt = this.form.expiredAt.split("T")[0];
      this.userId = this.form.user.id;
      this.form.courseId = this.form.course.id;

      if (this.form.batch) {
        this.form.batchId = this.form.batch.id;
      }
    }

    this.fetchCourse();
    this.fetchUsers({ key: "roles", value: "MEMBER" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="member">Member</label>
                    <model-select
                      placeholder="Pilih Member"
                      :options="membersOptions"
                      v-model="userId"
                      @searchchange="changeMember"
                    >
                    </model-select>
                    <div v-if="errors['userId']" class="invalid-feedback">
                      <span>{{ errors["userId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="courseId">Kelas</label>
                    <select
                      name="courseId"
                      id="course"
                      class="form-control"
                      v-model="form.courseId"
                    >
                      <option value="">Pilih Kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="batchId">Batch Kelas</label>
                    <select
                      name="batchId"
                      id="batch"
                      class="form-control"
                      v-model="form.batchId"
                    >
                      <option value="">Pilih Batch kelas</option>
                      <option
                        :value="batch.id"
                        v-for="batch in batchs"
                        :key="batch.id"
                        >{{ batch.name }} (
                        {{ batch.startDate | moment("Do MMMM YYYY") }} -
                        {{ batch.finishDate | moment("Do MMMM YYYY") }}
                        )</option
                      >
                    </select>
                    <div v-if="errors['batchId']" class="invalid-feedback">
                      <span>{{ errors["batchId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="typePayment">Jenis Pembayaran Kelas</label>

                    <select
                      v-model="form.typePayment"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['typePayment'],
                      }"
                    >
                      <option value="FREE">Gratis</option>
                      <option value="PAID">Berbayar</option>
                    </select>
                    <div v-if="errors['typePayment']" class="invalid-feedback">
                      <span>{{ errors["typePayment"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="expiredAt">Kadularsa Pada</label>
                    <input
                      name="expiredAt"
                      id="expiredAt"
                      class="form-control"
                      v-model="form.expiredAt"
                      type="date"
                    />
                    <div v-if="errors['expiredAt']" class="invalid-feedback">
                      <span>{{ errors["expiredAt"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
