<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Tambah Badge Member",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Badge Member"
        : "Tambah Badge Member",
      id: this.$route.params.id,
      items: [
        {
          text: "Badge Member",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        description: "",
        image: "",
        iconNonActive: "",
        iconActive: "",
        atLeastTheThreadThatOtherUsersSave: 0,
        atLeastOtherUsersThreadsAreCommentedOn: 0,
        atLeastOtherUsersThreadsAreLiked: 0,
        isPublic: true,
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "badge/create",
      detail: "badge/detail",
      update: "badge/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-badge" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e, type) {
      console.log(e);
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form[type] = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <textarea
                      id="description"
                      v-model="form.description"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                      class="form-control"
                    ></textarea>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"
                      >Apakah Badge Ini Bersifat Publik? (Bisa Dimiliki Semua
                      Orang)</label
                    >
                    <b-form-group>
                      <b-form-checkbox-group
                        id="isPublic"
                        :options="[
                          { value: true, text: 'Iya' },
                          { value: false, text: 'Tidak' },
                        ]"
                        name="isPublic"
                        v-model="form.isPublic"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <div v-if="errors['isPublic']" class="invalid-feedback">
                      <span>{{ errors["isPublic"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="atLeastTheThreadThatOtherUsersSave"
                      >Minimal Obrolan Disimpan Orang Lain</label
                    >
                    <input
                      id="atLeastTheThreadThatOtherUsersSave"
                      v-model="form.atLeastTheThreadThatOtherUsersSave"
                      :class="{
                        'is-invalid':
                          errors['atLeastTheThreadThatOtherUsersSave'],
                      }"
                      class="form-control"
                      type="number"
                    />
                    <div
                      v-if="errors['atLeastTheThreadThatOtherUsersSave']"
                      class="invalid-feedback"
                    >
                      <span>{{
                        errors["atLeastTheThreadThatOtherUsersSave"]
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="atLeastOtherUsersThreadsAreCommentedOn"
                      >Minimal Mengomentari Obrolan Orang Lain</label
                    >
                    <input
                      id="atLeastOtherUsersThreadsAreCommentedOn"
                      v-model="form.atLeastOtherUsersThreadsAreCommentedOn"
                      :class="{
                        'is-invalid':
                          errors['atLeastOtherUsersThreadsAreCommentedOn'],
                      }"
                      class="form-control"
                      type="number"
                    />
                    <div
                      v-if="errors['atLeastOtherUsersThreadsAreCommentedOn']"
                      class="invalid-feedback"
                    >
                      <span>{{
                        errors["atLeastOtherUsersThreadsAreCommentedOn"]
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="atLeastOtherUsersThreadsAreLiked"
                      >Minimal Menyukai Obrolan Orang Lain</label
                    >
                    <input
                      id="atLeastOtherUsersThreadsAreLiked"
                      v-model="form.atLeastOtherUsersThreadsAreLiked"
                      :class="{
                        'is-invalid':
                          errors['atLeastOtherUsersThreadsAreLiked'],
                      }"
                      class="form-control"
                      type="number"
                    />
                    <div
                      v-if="errors['atLeastOtherUsersThreadsAreLiked']"
                      class="invalid-feedback"
                    >
                      <span>{{
                        errors["atLeastOtherUsersThreadsAreLiked"]
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="iconActive">Ikon Aktif</label>
                    <input
                      id="iconActive"
                      type="file"
                      class="form-control"
                      placeholder="iconActive"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['iconActive'],
                      }"
                      @change="uploadImage($event, 'iconActive')"
                    />
                  </div>
                  <img
                    class="mb-3"
                    :src="form.iconActive | media"
                    width="300"
                    alt=""
                  />
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="iconNonActive">Ikon Tidak Aktif</label>
                    <input
                      id="iconNonActive"
                      type="file"
                      class="form-control"
                      placeholder="iconNonActive"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['iconNonActive'],
                      }"
                      @change="uploadImage($event, 'iconNonActive')"
                    />
                  </div>
                  <img
                    class="mb-3"
                    :src="form.iconNonActive | media"
                    width="300"
                    alt=""
                  />
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
