<script>
import { mapGetters } from "vuex";
export default {
  props: ["params"],

  data() {
    return {
      loading: false,
    };
  },

  watch: {
    params: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },

  computed: mapGetters({
    totalMemberNewEnrollment: "dashboard/totalMemberNewEnrollment",
  }),

  methods: {
    async fetchData() {
      this.loading = true;

      await this.$store.dispatch(
        "dashboard/getTotalMemberNewEnrollment",
        this.params
      );

      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="media">
        <div class="media-body overflow-hidden">
          <p class="text-truncate font-size-14 mb-2">
            Total Member Enrollment Baru
          </p>
          <h4 class="mb-0" v-if="!loading">
            {{ totalMemberNewEnrollment | rupiah }}
          </h4>
          <div class="spinner-border text-primary" role="status" v-else>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="text-primary">
          <i class="fa fa-users font-size-24`"></i>
        </div>
      </div>
    </div>
  </div>
</template>
