import orderProofPaymentApi from "../../api/order-proof-payment.api";
import filterDefault from "../../helpers/filter";

export const state = {
  orderProofPayments: [],
  orderProofPayment: {},
  total: 0,
  filter: {
    ...filterDefault,
    status: "",
  },
};

export const getters = {
  orderProofPayments(state) {
    return state.orderProofPayments;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_ORDER_PROOF_PAYMENTS(state, orderProofPayments) {
    state.orderProofPayments = orderProofPayments;
  },

  ADD_ORDER_PROOF_PAYMENT(state, orderProofPayment) {
    state.orderProofPayments.unshift(orderProofPayment);
  },

  MERGE_ORDER_PROOF_PAYMENTS(state, orderProofPayments) {
    state.orderProofPayments = orderProofPayments.concat(
      state.orderProofPayments
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_ORDER_PROOF_PAYMENT(state, id) {
    state.orderProofPayments.splice(
      state.orderProofPayments.findIndex(
        (orderProofPayment) => orderProofPayment.id === id
      ),
      1
    );
  },

  SET_ORDER_PROOF_PAYMENT(state, orderProofPayment) {
    state.orderProofPayment = orderProofPayment;
  },

  CHANGE_ORDER_PROOF_PAYMENT(state, { id, orderProofPayment }) {
    state.orderProofPayments[
      state.orderProofPayments.findIndex(
        (orderProofPayment) => orderProofPayment.id === id
      )
    ] = orderProofPayment;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await orderProofPaymentApi.fetch(state.filter);

      commit("SET_ORDER_PROOF_PAYMENTS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await orderProofPaymentApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_ORDER_PROOF_PAYMENT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await orderProofPaymentApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_ORDER_PROOF_PAYMENT", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await orderProofPaymentApi.detail(id);

    commit("SET_ORDER_PROOF_PAYMENT", data.result);

    return data.result;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data, status } = await orderProofPaymentApi.update(id, payload);

    if (status !== 200) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_ORDER_PROOF_PAYMENT", {
      id,
      orderProofPayment: data.result,
    });

    return { status: true };
  },
};
