<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

/**
 * Master Jawaban Pertanyaaan component
 */
export default {
  page: {
    title: "Jawaban Pertanyaaan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Jawaban Pertanyaaan",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Jawaban Pertanyaaan",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "user", label: "Pengguna" },
        { key: "finishTime", label: "Waktu selesai (menit)" },
        { key: "totalTrueAnswer", label: "Jawaban Benar" },
        { key: "totalFalseAnswer", label: "Jawaban Salah" },
        { key: "score", label: "Nilai" },
        { key: "user", label: "Pengguna" },
        { key: "status", label: "Status" },
        { key: "createdAt", label: "Tanggal Dibuat" },
        { key: "actions", label: "Aksi" },
      ],
      loadingImport: false,
      loading: false,
      processImport: [],
      importForm: {
        file: "",
        csvField: "No Whatsapp",
        databaseField: "phoneNumber",
      },
      errors: {},
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["exam/examAnswers"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["exam/filterExamAnswer"].search;
      },
      set(val) {
        this.changeFilter({
          key: "search",
          value: val,
          id: this.$route.params.id,
        });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["exam/filterExamAnswer"].limit;
      },
      set(val) {
        this.changeFilter({
          key: "limit",
          value: val,
          id: this.$route.params.id,
        });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["exam/filterExamAnswer"].page;
      },
      set(val) {
        this.changeFilter({
          key: "page",
          value: val,
          id: this.$route.params.id,
        });
      },
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetch(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "exam/findAnswerExam",
      changeFilter: "exam/changeFilterAnswerExam",
    }),

    deleteexam(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete({ surveyId: this.$route.params.id, answerId: id });
        } else {
          this.$swal("Data kamu aman!");
        }
      });
    },

    async downloadexam() {
      this.loading = true;
      const response = await this.download(this.$route.params.id);

      window.open(response.url);

      this.loading = false;
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async importAnswer() {
      if (this.processImport.length > 0) {
        this.processImport = [];

        this.$nextTick(() => {
          this.$bvModal.hide("modal-import");
        });
      } else {
        this.loadingImport = true;
        this.errors = {};
        const response = await this.$store.dispatch("exam/importAnswer", {
          surveyId: this.$route.params.id,
          payload: this.importForm,
        });
        this.loadingImport = false;

        if (response.status) {
          this.processImport = response.process;
        } else {
          this.errors = response.errors;

          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat import Jawaban Survey!",
          });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="row">
              <div class="col-lg-4">
                <button
                  class="btn btn-primary btn-sm mr-3"
                  @click="downloadexam"
                >
                  Download Jawaban
                </button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(user)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("Do MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'detail-answer-exam',
                        params: {
                          id: $route.params.id,
                          answerId: data.item.id,
                        },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>

                  <b-button
                    class="mr-3"
                    variant="danger"
                    size="sm"
                    @click="deleteexam(data.item.id)"
                    ><i class="fa fa-trash"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
