import partnershipApi from "../../api/partnership.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnerships: [],
  partnership: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnerships(state) {
    return state.partnerships;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIPS(state, partnerships) {
    state.partnerships = partnerships;
  },

  ADD_PARTNERSHIP(state, partnership) {
    state.partnerships.unshift(partnership);
  },

  MERGE_PARTNERSHIPS(state, partnerships) {
    state.partnerships = partnerships.concat(state.partnerships);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP(state, id) {
    state.partnerships.splice(
      state.partnerships.findIndex((partnership) => partnership.id === id),
      1
    );
  },

  SET_PARTNERSHIP(state, partnership) {
    state.partnership = partnership;
  },

  CHANGE_PARTNERSHIP(state, { id, partnership }) {
    state.partnerships[
      state.partnerships.findIndex((partnership) => partnership.id === id)
    ] = partnership;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnerships.findIndex((number) => number.id === id);

    state.partnerships[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipApi.fetch(state.filter);

      commit("SET_PARTNERSHIPS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP", {
      id,
      partnership: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await partnershipApi.getAudiencesByQuery(query);

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipApi.getQueue(id);

    return data.data;
  },
};
