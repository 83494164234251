<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      roles: "role/roles",
    }),
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Edit Member" : "Create Member",
      id: this.$route.params.id,
      items: [
        {
          text: "Member",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Edit" : "Create",
          active: true,
        },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        phoneNumber: "",
        password: "",
        roleIds: [],
        job: "",
        description: "",
      },
      roleIds: [],
      submitted: false,
      errors: {},
      roleOptions: [],
      isLoading: false,
    };
  },

  watch: {
    roles: {
      handler(val) {
        this.roleOptions = [];

        Object.assign([], val).forEach((role) => {
          this.roleOptions.push({
            id: role.id,
            text: role.name,
          });
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      update: "user/update",
      store: "user/store",
      detail: "user/detail",

      fetchRole: "role/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.isLoading = true;
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.store(this.form);
      }

      this.isLoading = false;

      if (response.status) {
        this.$router.push({ name: "list-admin" });
      } else {
        this.errors = response.errors;
      }
    },
  },

  async mounted() {
    await this.fetchRole();

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
      this.form.roleIds = this.form.roles.map((role) => role.id);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom01">Nama Pertama</label>
                    <input
                      id="validationCustom01"
                      v-model="form.firstName"
                      type="text"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['firstName'],
                      }"
                    />
                    <div v-if="errors['firstName']" class="invalid-feedback">
                      <span>{{ errors["firstName"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Nama Terakhir</label>
                    <input
                      id="validationCustom02"
                      v-model="form.lastName"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': errors['lastName'],
                      }"
                    />
                    <div v-if="errors['lastName']" class="invalid-feedback">
                      <span>{{ errors["lastName"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">E-mail</label>
                    <input
                      id="validationCustom03"
                      v-model="form.email"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['email'],
                      }"
                    />
                    <div v-if="errors['email']" class="invalid-feedback">
                      <span>{{ errors["email"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom04">Username</label>
                    <input
                      id="validationCustom04"
                      v-model="form.username"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['username'],
                      }"
                    />
                    <div v-if="errors['username']" class="invalid-feedback">
                      <span>{{ errors["username"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Nomer Telpon</label>
                    <input
                      id="validationCustom05"
                      v-model="form.phoneNumber"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['phoneNumber'],
                      }"
                    />
                    <div v-if="errors['phoneNumber']" class="invalid-feedback">
                      <span>{{ errors["phoneNumber"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom04">Password</label>
                    <input
                      id="validationCustom06"
                      v-model="form.password"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['password'],
                      }"
                    />
                    <div v-if="errors['password']" class="invalid-feedback">
                      <span>{{ errors["password"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="role">Role</label>
                    <Select2
                      v-model="form.roleIds"
                      :options="roleOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['roleIds']" class="invalid-feedback">
                      <span>{{ errors["roleIds"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Pekerjaan</label>
                    <input
                      id="validationCustom05"
                      v-model="form.job"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['job'],
                      }"
                    />
                    <div v-if="errors['job']" class="invalid-feedback">
                      <span>{{ errors["job"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom03">Tentang</label>
                    <textarea
                      id="validationCustom05"
                      v-model="form.description"
                      type="text"
                      class="form-control"
                      rows="5"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></textarea>
                    <div v-if="errors['job']" class="invalid-feedback">
                      <span>{{ errors["job"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
                :disabled="isLoading"
              >
                <span
                  v-if="isLoading"
                  class="spinner-border spinner-border-sm"
                ></span>

                <span v-else>{{ $route.params.id ? "Update" : "Create" }}</span>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
