<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";
// import { ModelSelect } from "vue-search-select";

export default {
  page: {
    title: "Tambah Group Whatsapp",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      hosts: "whatsappNumberHost/whatsappNumberHosts",
      courses: "course/courses",
      batchs: "batch/batchs",
      members: "user/users",
      partners: "partnershipPartner/partnershipPartners",

      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Group Whatsapp"
        : "Tambah Group Whatsapp",
      id: this.$route.params.id,
      items: [
        {
          text: "Group Whatsapp",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        link: "",
        type: "COMMUNITY",
        description: "",
        hostId: "",
        activityGroupHostId: "",
        broadcastHostId: "",
        courseId: "",
        batchId: "",
        isActive: true,
        userIds: [],
        removeUserIds: [],
        image: "",
        partnerIds: [],
        useExisting: false,
      },
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
      userId: "",
      membersOptions: [],
      partnerOptions: [],
      users: [],
      loadingSync: false,
    };
  },

  watch: {
    "form.courseId"(val) {
      if (val) this.fetchBatch({ key: "courseId", value: val });
    },

    "form.type"(val) {
      if (val === "COMMUNITY") {
        this.form.courseId = "";
        this.form.batchId = "";
      }
    },

    partners: {
      handler(val) {
        this.partnerOptions = [];

        Object.assign([], val).forEach((partner) => {
          this.partnerOptions.push({
            id: partner.id,
            text: `${partner.name}`,
          });
        });
      },
      deep: true,
    },

    members: {
      handler(val) {
        this.membersOptions = [];

        Object.assign([], val).forEach((user) => {
          this.membersOptions.push({
            value: user.id,
            text: `${user.name} - ${user.phoneNumber}`,
          });
        });
      },
      deep: true,
    },

    userId(val) {
      if (val) {
        const index = this.form.userIds.indexOf(val);

        if (index === -1) {
          const memberIndex = this.members.findIndex(
            (member) => member.id === val
          );
          this.form.userIds.push(val);
          this.users.push(this.members[memberIndex]);
        }

        this.userId = "";
      }
    },
  },

  methods: {
    ...mapActions({
      create: "whatsappGroup/create",
      detail: "whatsappGroup/detail",
      update: "whatsappGroup/update",
      sync: "whatsappGroup/syncMember",

      fetchHost: "whatsappNumberHost/fetch",
      fetchCourse: "course/fetch",
      fetchBatch: "batch/changeFilter",
      fetchUsers: "user/changeFilter",
      fetchPartners: "partnershipPartner/changeFilter",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-whatsapp-group" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    changeMember(e) {
      this.fetchUsers({ key: "search", value: e });
    },

    deleteUser(index) {
      if (
        confirm(
          `Apakah kamu yakin ingin menghapus member ${this.users[index].firstName} ${this.users[index].lastName}?`
        )
      ) {
        if (this.form.id)
          this.form.removeUserIds.push(this.form.userIds[index]);
        this.users.splice(index, 1);
        this.form.userIds.splice(index, 1);
      }
    },

    async syncMember() {
      this.loadingSync = true;

      const { data } = await this.sync(this.$route.params.id);
      this.users = data.members;

      this.loadingSync = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },
  },

  async mounted() {
    this.fetchHost();

    if (!this.isPartner()) {
      this.fetchCourse();
      // this.fetchUsers({ key: "roles", value: "MEMBER" });
      this.fetchPartners({ limit: 99999 });
    }

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
      if (data.course) this.form.courseId = data.course.id;
      if (data.courseBatch) this.form.batchId = data.courseBatch.id;
      if (data.host) this.form.hostId = data.host.id;
      if (data.activityGroupHost)
        this.form.activityGroupHostId = data.activityGroupHost.id;
      if (data.broadcastHost) this.form.broadcastHostId = data.broadcastHost.id;

      // this.form.userIds = data.users.map((user) => user.id);
      this.users = data.members;
      this.form.removeUserIds = [];
      this.form.partnerIds = [];

      if (data.partners) {
        data.partners.forEach((partner) => {
          this.form.partnerIds.push(partner.id);
        });
      }
    }

    if (this.isPartner()) {
      this.form.type = "PARTNERSHIP";
      this.form.partnerIds = [this.user.partner.id];
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12" v-if="!isPartner()">
                  <div class="form-group">
                    <label for="hostId">Nomer Host *)</label>
                    <select
                      name="hostId"
                      v-model="form.hostId"
                      class="form-control"
                    >
                      <option value="">Pilih Nomer Host</option>
                      <option
                        :value="host.id"
                        :key="host.id"
                        v-for="host in hosts.filter(
                          (host) => host.type === 'GROUP' && host.isActive
                        )"
                        >{{ host.number }} - {{ host.name }} -
                        {{ host.totalGroup }} Grup</option
                      >
                    </select>
                    <div v-if="errors['hostId']" class="invalid-feedback">
                      <span>{{ errors["hostId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="!isPartner()">
                  <div class="form-group">
                    <label for="activityGroupHostId">Aktivitas Grup Host</label>
                    <select
                      name="activityGroupHostId"
                      v-model="form.activityGroupHostId"
                      class="form-control"
                    >
                      <option value="">Pilih Nomer Host</option>
                      <option
                        :value="host.id"
                        :key="host.id"
                        v-for="host in hosts.filter(
                          (host) =>
                            host.type === 'ACTIVITY_GROUP' && host.isActive
                        )"
                        >{{ host.number }} - {{ host.name }} -
                        {{ host.totalGroup }} Grup</option
                      >
                    </select>
                    <div
                      v-if="errors['activityGroupHostId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["activityGroupHostId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="!isPartner()">
                  <div class="form-group">
                    <label for="broadcastHostId">Broadcast Host</label>
                    <select
                      name="broadcastHostId"
                      v-model="form.broadcastHostId"
                      class="form-control"
                    >
                      <option value="">Pilih Nomer Host</option>
                      <option
                        :value="host.id"
                        :key="host.id"
                        v-for="host in hosts.filter(
                          (host) => host.type === 'BROADCAST' && host.isActive
                        )"
                        >{{ host.number }} - {{ host.name }} -
                        {{ host.totalGroup }} Grup</option
                      >
                    </select>
                    <div
                      v-if="errors['broadcastHostId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["broadcastHostId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama *)</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="link">Link Group</label>
                    <input
                      id="link"
                      v-model="form.link"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['link'],
                      }"
                    />
                    <div v-if="errors['link']" class="invalid-feedback">
                      <span>{{ errors["link"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="useExisting"
                      >Apakah menggunakan grup yang sudah ada?</label
                    >
                    <select v-model="form.useExisting" class="form-control">
                      <option :value="true">Iya</option>
                      <option :value="false">Tidak</option>
                    </select>
                    <div v-if="errors['useExisting']" class="invalid-feedback">
                      <span>{{ errors["useExisting"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="!isPartner()">
                  <div class="form-group">
                    <label for="type">Tipe Grup</label>
                    <select v-model="form.type" class="form-control">
                      <option value="COMMUNITY">Komunitas</option>
                      <option value="EVENT">Event</option>
                      <option value="PARTNERSHIP">Partnership</option>
                      <option value="TESTER">Tester</option>
                      <option value="OTHER">Lainnya</option>
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-12"
                  v-if="form.type === 'PARTNERSHIP' && !isPartner()"
                >
                  <div class="form-group">
                    <label for="batchId">Partner</label>
                    <Select2
                      v-model="form.partnerIds"
                      :options="partnerOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['partnerIds']" class="invalid-feedback">
                      <span>{{ errors["partnerIds"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" v-if="form.type === 'EVENT'">
                  <div class="form-group">
                    <label for="courseId">Kelas</label>
                    <select v-model="form.courseId" class="form-control">
                      <option value="">Pilih Kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" v-if="form.type === 'EVENT'">
                  <div class="form-group">
                    <label for="batchId">Batch Kelas</label>
                    <select v-model="form.batchId" class="form-control">
                      <option value="">Pilih Batch Kelas</option>
                      <option
                        :value="batch.id"
                        v-for="batch in batchs"
                        :key="batch.id"
                        >{{ batch.name }} -
                        {{
                          $options.filters.moment(
                            batch.startDate,
                            "DD MMMM YYYY"
                          )
                        }}
                        s/d
                        {{
                          $options.filters.moment(
                            batch.finishDate,
                            "DD MMMM YYYY"
                          )
                        }}</option
                      >
                    </select>
                    <div v-if="errors['batchId']" class="invalid-feedback">
                      <span>{{ errors["batchId"] }}</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-12">
                  <div>
                    <label for="image">Gambar</label>
                    <input
                      id="image"
                      type="file"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['image'],
                      }"
                      @change="uploadImage"
                    />
                  </div>
                  <div style="width: 300px" v-if="form.image">
                    <img
                      class="mt-3"
                      :src="form.image | media"
                      width="300"
                      alt=""
                      v-img
                    />
                    <button
                      class="form-control btn btn-danger btn-sm mt-3"
                      @click="form.image = ''"
                    >
                      Hapus
                    </button>
                  </div>
                </div> -->

                <div class="col-md-12">
                  <br />
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <textarea
                      id="description"
                      v-model="form.description"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    />
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.id">
                  <br />
                  <div class="form-group">
                    <label for="description">Member</label>
                    <div class="form-group">
                      <button
                        :disabled="loadingSync"
                        class="btn btn-primary btn-sm"
                        @click="syncMember"
                      >
                        <span v-if="!loadingSync">Sync Data Member</span>

                        <div
                          class="spinner-border text-light spinner-border-sm"
                          role="status"
                          v-else
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                    <!-- <model-select
                      placeholder="Pilih Member"
                      :options="membersOptions"
                      v-model="userId"
                      @searchchange="changeMember"
                    >
                    </model-select> -->
                    <table class="table table-hover table-bordered mt-4">
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Whatsapp</th>
                        <th>Role</th>
                        <!-- <th>Aksi</th> -->
                      </tr>
                      <tr v-for="(user, index) in users" :key="user.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <span v-if="user.user">
                            {{ user.user.firstName }}
                            {{ user.user.lastName }}
                          </span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          {{
                            user.user ? user.user.phoneNumber : user.phoneNumber
                          }}
                        </td>
                        <th>{{ user.role }}</th>
                        <!-- <td>
                          <button
                            class="btn btn-danger"
                            @click="deleteUser(index)"
                            type="button"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td> -->
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
