import axios from "axios";
import router from "../router";
import store from "../state/store";

function getToken() {
  return localStorage.getItem("auth.currentUser");
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
});

api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (localStorage.getItem("auth.currentUser")) {
        store.dispatch(
          "notification/error",
          "Sesi telah berakhir, silahkan login kembali"
        );

        store.dispatch("auth/logOut");
      }
    } else if (error.response.status === 403) {
      store.dispatch(
        "notification/error",
        "Kamu tidak memiliki akses pada data ini"
      );

      router.push({ name: "home" });
    } else {
      store.dispatch("notification/error", error.response.data.message);
    }
    return error.response;
  }
);

export default api;
