import tagApi from "../../api/tag.api";
import filterDefault from "../../helpers/filter";

export const state = {
  tags: [],
  tag: {},
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  tags(state) {
    return state.tags;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags;
  },

  ADD_TAG(state, tag) {
    state.tags.unshift(tag);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_TAG(state, id) {
    state.tags.splice(
      state.tags.findIndex((tag) => tag.id === id),
      1
    );
  },

  SET_TAG(state, tag) {
    state.tag = tag;
  },

  CHANGE_TAG(state, { id, tag }) {
    state.tags[state.tags.findIndex((tag) => tag.id === id)] = tag;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await tagApi.fetch(state.filter);

      commit("SET_TAGS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await tagApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_TAG", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await tagApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_TAG", id);

    return { status: true };
  },

  async detail({ dispatch }, id) {
    const { data } = await tagApi.detail(id);

    dispatch("SET_TAG", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await tagApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_TAG", { id, tag: data.data });

    return { status: true };
  },
};
