import partnershipAdvertisementApi from "../../api/partnership-advertisement.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnershipAdvertisements: [],
  partnershipAdvertisement: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnershipAdvertisements(state) {
    return state.partnershipAdvertisements;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIP_ADVERTISEMENTS(state, partnershipAdvertisements) {
    state.partnershipAdvertisements = partnershipAdvertisements;
  },

  ADD_PARTNERSHIP_ADVERTISEMENT(state, partnershipAdvertisement) {
    state.partnershipAdvertisements.unshift(partnershipAdvertisement);
  },

  MERGE_PARTNERSHIP_ADVERTISEMENTS(state, partnershipAdvertisements) {
    state.partnershipAdvertisements = partnershipAdvertisements.concat(
      state.partnershipAdvertisements
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP_ADVERTISEMENT(state, id) {
    state.partnershipAdvertisements.splice(
      state.partnershipAdvertisements.findIndex(
        (partnershipAdvertisement) => partnershipAdvertisement.id === id
      ),
      1
    );
  },

  SET_PARTNERSHIP_ADVERTISEMENT(state, partnershipAdvertisement) {
    state.partnershipAdvertisement = partnershipAdvertisement;
  },

  CHANGE_PARTNERSHIP_ADVERTISEMENT(state, { id, partnershipAdvertisement }) {
    state.partnershipAdvertisements[
      state.partnershipAdvertisements.findIndex(
        (partnershipAdvertisement) => partnershipAdvertisement.id === id
      )
    ] = partnershipAdvertisement;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnershipAdvertisements.findIndex(
      (number) => number.id === id
    );

    state.partnershipAdvertisements[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipAdvertisementApi.fetch(state.filter);

      commit("SET_PARTNERSHIP_ADVERTISEMENTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipAdvertisementApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP_ADVERTISEMENT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipAdvertisementApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP_ADVERTISEMENT", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipAdvertisementApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipAdvertisementApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP_ADVERTISEMENT", {
      id,
      partnershipAdvertisement: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipAdvertisementApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await partnershipAdvertisementApi.getAudiencesByQuery(
      query
    );

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipAdvertisementApi.getQueue(id);

    return data.data;
  },

  async exportExcel() {
    const { data } = await partnershipAdvertisementApi.exportExcel();

    return data;
  },
};
