import ratingApi from "../../api/rating.api";
import filterDefault from "../../helpers/filter";

export const state = {
  ratings: [],
  rating: {},
  filter: {
    ...filterDefault,
    courseId: "",
    moduleId: "",
  },
  total: 0,
};

export const getters = {
  ratings(state) {
    return state.ratings;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_RATINGS(state, ratings) {
    state.ratings = ratings;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  ADD_RATING_OPTION(state, rating) {
    state.ratings.unshift(rating);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_RATING_OPTION(state, id) {
    state.ratings.splice(
      state.ratings.findIndex((rating) => rating.id === id),
      1
    );
  },

  SET_RATING_OPTION(state, rating) {
    state.rating = rating;
  },

  CHANGE_RATING_OPTION(state, { id, rating }) {
    state.ratings[
      state.ratings.findIndex((rating) => rating.id === id)
    ] = rating;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await ratingApi.fetch(state.filter);

      commit("SET_RATINGS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async detail({ dispatch }, id) {
    const { data } = await ratingApi.detail(id);

    dispatch("SET_RATING", data.data);

    return data.data;
  },
};
