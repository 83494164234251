import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
import CKEditor from "ckeditor4-vue";
import VueClipboard from "vue-clipboard2";
const moment = require("moment");
require("moment/locale/id");

import VueQuillEditor from "vue-quill-editor";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);

import vco from "v-click-outside";
import "./utils/filters";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import "@/assets/scss/app.scss";
import "vue-search-select/dist/VueSearchSelect.css";

import BootstrapVueTimeline from "bootstrap-vue-timeline";

Vue.component("b-timeline", BootstrapVueTimeline);

import VueImg from "v-img";

Vue.use(VueImg);

Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(CKEditor);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("apexchart", VueApexCharts);

// Vue Date Range Picker
import VueRangedatePicker from "vue-rangedate-picker";

Vue.use(VueRangedatePicker);

Vue.use(require("vue-pusher"), {
  api_key: process.env.VUE_APP_PUSHER_KEY,
  options: {
    cluster: "ap1",
    encrypted: true,
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
