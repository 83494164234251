var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['name'],
                    },attrs:{"id":"name","type":"text","value":"Mark"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Deskripsi")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['description'],
                    },attrs:{"id":"description"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Apakah Badge Ini Bersifat Publik? (Bisa Dimiliki Semua Orang)")]),_c('b-form-group',[_c('b-form-checkbox-group',{attrs:{"id":"isPublic","options":[
                        { value: true, text: 'Iya' },
                        { value: false, text: 'Tidak' } ],"name":"isPublic"},model:{value:(_vm.form.isPublic),callback:function ($$v) {_vm.$set(_vm.form, "isPublic", $$v)},expression:"form.isPublic"}})],1),(_vm.errors['isPublic'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["isPublic"]))])]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"atLeastTheThreadThatOtherUsersSave"}},[_vm._v("Minimal Obrolan Disimpan Orang Lain")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.atLeastTheThreadThatOtherUsersSave),expression:"form.atLeastTheThreadThatOtherUsersSave"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.errors['atLeastTheThreadThatOtherUsersSave'],
                    },attrs:{"id":"atLeastTheThreadThatOtherUsersSave","type":"number"},domProps:{"value":(_vm.form.atLeastTheThreadThatOtherUsersSave)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "atLeastTheThreadThatOtherUsersSave", $event.target.value)}}}),(_vm.errors['atLeastTheThreadThatOtherUsersSave'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["atLeastTheThreadThatOtherUsersSave"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"atLeastOtherUsersThreadsAreCommentedOn"}},[_vm._v("Minimal Mengomentari Obrolan Orang Lain")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.atLeastOtherUsersThreadsAreCommentedOn),expression:"form.atLeastOtherUsersThreadsAreCommentedOn"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.errors['atLeastOtherUsersThreadsAreCommentedOn'],
                    },attrs:{"id":"atLeastOtherUsersThreadsAreCommentedOn","type":"number"},domProps:{"value":(_vm.form.atLeastOtherUsersThreadsAreCommentedOn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "atLeastOtherUsersThreadsAreCommentedOn", $event.target.value)}}}),(_vm.errors['atLeastOtherUsersThreadsAreCommentedOn'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["atLeastOtherUsersThreadsAreCommentedOn"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"atLeastOtherUsersThreadsAreLiked"}},[_vm._v("Minimal Menyukai Obrolan Orang Lain")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.atLeastOtherUsersThreadsAreLiked),expression:"form.atLeastOtherUsersThreadsAreLiked"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.errors['atLeastOtherUsersThreadsAreLiked'],
                    },attrs:{"id":"atLeastOtherUsersThreadsAreLiked","type":"number"},domProps:{"value":(_vm.form.atLeastOtherUsersThreadsAreLiked)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "atLeastOtherUsersThreadsAreLiked", $event.target.value)}}}),(_vm.errors['atLeastOtherUsersThreadsAreLiked'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["atLeastOtherUsersThreadsAreLiked"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"iconActive"}},[_vm._v("Ikon Aktif")]),_c('input',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['iconActive'],
                    },attrs:{"id":"iconActive","type":"file","placeholder":"iconActive","value":"Mark"},on:{"change":function($event){return _vm.uploadImage($event, 'iconActive')}}})]),_c('img',{staticClass:"mb-3",attrs:{"src":_vm._f("media")(_vm.form.iconActive),"width":"300","alt":""}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"iconNonActive"}},[_vm._v("Ikon Tidak Aktif")]),_c('input',{staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['iconNonActive'],
                    },attrs:{"id":"iconNonActive","type":"file","placeholder":"iconNonActive","value":"Mark"},on:{"change":function($event){return _vm.uploadImage($event, 'iconNonActive')}}})]),_c('img',{staticClass:"mb-3",attrs:{"src":_vm._f("media")(_vm.form.iconNonActive),"width":"300","alt":""}})])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }