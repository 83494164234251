import surveyApi from "../../api/survey.api";
import filterDefault from "../../helpers/filter";

export const state = {
  surveys: [],
  survey: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    batchId: "",
    status: "",
  },
  types: [
    {
      key: "PUBLIC",
      text: "Tidak Harus Masuk",
    },
    {
      key: "PRIVATE",
      text: "Harus Masuk",
    },
    {
      key: "HAVE_TO_FINISH_CLASS",
      text: "Harus Menyelesaikan Kelas",
    },
    {
      key: "JOIN_CLASS",
      text: "Harus Masuk Kedalam Kelas",
    },
  ],
};

export const getters = {
  surveys(state) {
    return state.surveys;
  },

  filter(state) {
    return state.filter;
  },

  types(state) {
    return state.types;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },

  ADD_SURVEY(state, survey) {
    state.surveys.unshift(survey);
  },

  MERGE_SURVEYS(state, surveys) {
    state.surveys = surveys.concat(state.surveys);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_SURVEY(state, id) {
    state.surveys.splice(
      state.surveys.findIndex((survey) => survey.id === id),
      1
    );
  },

  SET_SURVEY(state, survey) {
    state.survey = survey;
  },

  CHANGE_SURVEY(state, { id, survey }) {
    state.surveys[
      state.surveys.findIndex((survey) => survey.id === id)
    ] = survey;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await surveyApi.fetch(state.filter);

      commit("SET_SURVEYS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await surveyApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_SURVEY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await surveyApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_SURVEY", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await surveyApi.detail(id);

    commit("SET_SURVEY", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await surveyApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_SURVEY", { id, survey: data.data });

    return { status: true };
  },
};
