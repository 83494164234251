import whatsappGroupLeaveSurveyQuestionApi from "../../api/whatsapp-group-leave-survey-question.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupLeaveSurveyQuestions: [],
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupLeaveSurveyQuestions(state) {
    return state.whatsappGroupLeaveSurveyQuestions;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS(
    state,
    whatsappGroupLeaveSurveyQuestions
  ) {
    state.whatsappGroupLeaveSurveyQuestions = whatsappGroupLeaveSurveyQuestions;
  },

  ADD_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS(
    state,
    whatsappGroupLeaveSurveyQuestion
  ) {
    state.whatsappGroupLeaveSurveyQuestions.unshift(
      whatsappGroupLeaveSurveyQuestion
    );
  },

  MERGE_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONSS(
    state,
    whatsappGroupLeaveSurveyQuestions
  ) {
    state.whatsappGroupLeaveSurveyQuestions = whatsappGroupLeaveSurveyQuestions.concat(
      state.whatsappGroupLeaveSurveyQuestions
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  CHANGE_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS(
    state,
    { id, whatsappGroupLeaveSurveyQuestion }
  ) {
    state.whatsappGroupLeaveSurveyQuestions[
      state.whatsappGroupLeaveSurveyQuestions.findIndex(
        (whatsappGroupLeaveSurveyQuestion) =>
          whatsappGroupLeaveSurveyQuestion.id === id
      )
    ] = whatsappGroupLeaveSurveyQuestion;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupLeaveSurveyQuestionApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupLeaveSurveyQuestionApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupLeaveSurveyQuestionApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_LEAVE_SURVEY_QUESTIONS", id);

    return { status: true };
  },
};
