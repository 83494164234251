import userOtpApi from "../../api/user-otp.api";
import filterDefault from "../../helpers/filter";

export const state = {
  userOtps: [],
  userOtp: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  userOtps(state) {
    return state.userOtps;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SETUSER_OTPS(state, userOtps) {
    state.userOtps = userOtps;
  },

  ADDUSER_OTP(state, userOtp) {
    state.userOtps.unshift(userOtp);
  },

  MERGEUSER_OTPS(state, userOtps) {
    state.userOtps = userOtps.concat(state.userOtps);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await userOtpApi.fetch(state.filter);

      commit("SETUSER_OTPS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },
};
