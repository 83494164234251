import partnershipPartnerProductsApi from "../../api/partnership-partner-product.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnershipPartnerProducts: [],
  partnershipPartnerProduct: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnershipPartnerProducts(state) {
    return state.partnershipPartnerProducts;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIP_PARTNER_PRODUCTS(state, partnershipPartnerProducts) {
    state.partnershipPartnerProducts = partnershipPartnerProducts;
  },

  ADD_PARTNERSHIP_PARTNER_PRODUCT(state, partnershipPartnerProducts) {
    state.partnershipPartnerProducts.unshift(partnershipPartnerProducts);
  },

  MERGE_PARTNERSHIP_PARTNER_PRODUCTS(state, partnershipPartnerProducts) {
    state.partnershipPartnerProducts = partnershipPartnerProducts.concat(
      state.partnershipPartnerProducts
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP_PARTNER_PRODUCT(state, id) {
    state.partnershipPartnerProducts.splice(
      state.partnershipPartnerProducts.findIndex(
        (partnershipPartnerProducts) => partnershipPartnerProducts.id === id
      ),
      1
    );
  },

  SET_PARTNERSHIP_PARTNER_PRODUCT(state, partnershipPartnerProduct) {
    state.partnershipPartnerProduct = partnershipPartnerProduct;
  },

  CHANGE_PARTNERSHIP_PARTNER_PRODUCT(
    state,
    { id, partnershipPartnerProducts }
  ) {
    state.partnershipPartnerProducts[
      state.partnershipPartnerProducts.findIndex(
        (partnershipPartnerProducts) => partnershipPartnerProducts.id === id
      )
    ] = partnershipPartnerProducts;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnershipPartnerProducts.findIndex(
      (number) => number.id === id
    );

    state.partnershipPartnerProducts[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipPartnerProductsApi.fetch(state.filter);

      commit("SET_PARTNERSHIP_PARTNER_PRODUCTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipPartnerProductsApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP_PARTNER_PRODUCT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipPartnerProductsApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP_PARTNER_PRODUCT", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipPartnerProductsApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipPartnerProductsApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP_PARTNER_PRODUCT", {
      id,
      partnershipPartnerProducts: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipPartnerProductsApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await partnershipPartnerProductsApi.getAudiencesByQuery(
      query
    );

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipPartnerProductsApi.getQueue(id);

    return data.data;
  },
};
