<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master whatsappBroadcast component
 */
export default {
  page: {
    title: "Whatsapp Broadcast",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Whatsapp Broadcast",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Whatsapp Broadcast",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "name", label: "Nama" },
        { key: "text", label: "Teks" },
        { key: "groups", label: "Group" },
        { key: "dateTime", label: "Waktu Blaster" },
        { key: "createdAt", label: "Dibuat Pada" },
        { key: "actions", label: "Aksi", thStyle: "width: 75px" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
      selectTotalReads: [],
      showTotalReads: false,
      repeatSend: [],

      showTeksIndex: 0,
      showTeks: false,
      showResend: false,

      // Resend
      selected: "ALL",
      options: [
        {
          text: "Semua",
          value: "ALL",
        },
        {
          text: "Hanya Gambar",
          value: "IMAGE_ONLY",
        },
        {
          text: "Hanya Teks",
          value: "TEXT_ONLY",
        },
      ],
      resendGroupId: "",
      syncReadIds: [],
      editTotalReadId: "",
      totalViewEdit: 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["whatsappBroadcast/whatsappBroadcasts"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappBroadcast/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    type: {
      get() {
        return this.$store.getters["whatsappBroadcast/filter"].type;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "type", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappBroadcast/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappBroadcast/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappBroadcast/total",
      user: "auth/currentUser",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    if (!this.isPartner) {
      await this.changeFilter({ type: "PARTNER" });
    } else {
      await this.fetch();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "whatsappBroadcast/fetch",
      delete: "whatsappBroadcast/delete",
      changeFilter: "whatsappBroadcast/changeFilter",
      syncRead: "whatsappBroadcast/syncRead",
      updateTotalRead: "whatsappBroadcast/updateTotalRead",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    deleteUserRegion(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showResendForm(id) {
      this.showResend = true;
      this.resendGroupId = id;

      if (!this.tableData[this.showTeksIndex].image) {
        this.options = [
          {
            text: "Semua",
            value: "ALL",
          },
        ];
      } else {
        this.options = [
          {
            text: "Semua",
            value: "ALL",
          },
          {
            text: "Hanya Gambar",
            value: "IMAGE_ONLY",
          },
          {
            text: "Hanya Teks",
            value: "TEXT_ONLY",
          },
        ];
      }
    },

    async syncReadBroadcast(index) {
      const group = this.tableData[this.showTeksIndex];
      const message = group.groups[index];

      this.syncReadIds.push(message.id);
      await this.syncRead({ id: group.id, groupId: message.id });
    },

    resend() {
      this.repeatSend.push(this.resendGroupId);
      this.$store.dispatch("whatsappBroadcast/resend", {
        id: this.tableData[this.showTeksIndex].id,
        groupId: this.resendGroupId,
        type: this.selected,
      });
      this.showResend = false;
      this.resendGroupId = "";
      this.selected = "";
    },

    updateTotalViewBot() {
      this.$swal({
        text:
          "Apakah kamu yakin ingin mengubah data total dibaca pada data ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, ubah !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.updateTotalRead({
            id: this.tableData[this.showTeksIndex].id,
            groupId: this.editTotalReadId,
            payload: { totalViews: this.totalViewEdit },
          });

          const index = this.tableData[this.showTeksIndex].groups.findIndex(
            (group) => group.id === this.editTotalReadId
          );

          if (index > -1)
            this.tableData[this.showTeksIndex].groups[
              index
            ].totalViews = this.totalViewEdit;
        }

        this.editTotalReadId = "";
        this.totalViewEdit = "";
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <b-modal
              centered
              id="modal-import"
              title="Kirim Ulang Broadcast"
              size="md"
              v-model="showResend"
              hide-footer
            >
              <b-form-group label="Pilih Jenis Kirim Ulang">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="selected"
                  :options="options"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>

              <hr />

              <button class="btn-primary form-control" @click="resend">
                Kirim Ulang Broadcast
              </button>
            </b-modal>

            <b-modal
              centered
              id="modal-import"
              title="Detail Broadcast"
              size="xl"
              v-model="showTotalReads"
              hide-footer
            >
              <div class="containter">
                <b-alert variant="info" class="mb-3" dismissible show
                  >Total dibaca diakumulasi H+1 setelah broadcast
                  dikirim</b-alert
                >

                <table class="table table-bordered table-hover">
                  <tr>
                    <th>No</th>
                    <th>Nama Grup</th>
                    <th>Total Dibaca</th>
                    <th>Bot</th>
                    <th>Sudah dikirim</th>
                    <th>Aksi</th>
                  </tr>
                  <tr v-for="(read, index) in selectTotalReads" :key="read.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ read.whatsappGroup.name }}
                      <span style="display: none;">{{
                        read.whatsappGroup.uniqueKey
                      }}</span>
                    </td>
                    <td class="flex items-center">
                      <span v-if="editTotalReadId !== read.id">{{
                        read.totalViews
                      }}</span>
                      <input
                        type="number"
                        class="form-control"
                        v-if="editTotalReadId === read.id"
                        v-model="totalViewEdit"
                        @keyup.enter="updateTotalViewBot"
                      />
                      <i
                        class="ml-2 ri-pencil-line"
                        @click="
                          editTotalReadId = read.id;
                          totalViewEdit = read.totalViews;
                        "
                        v-if="!editTotalReadId"
                      ></i>
                    </td>
                    <td>{{ read.host ? read.host.name : "-" }}</td>
                    <td>
                      {{
                        repeatSend.includes(read.id)
                          ? "Sedang Dikirim Ulang"
                          : read.messageWhatsappId
                          ? "Sudah"
                          : "Belum"
                      }}
                      <span style="display: none;">{{
                        read.messageWhatsappId
                      }}</span>
                    </td>
                    <td>
                      <button
                        class="btn btn-primary btn-sm"
                        v-if="!repeatSend.includes(read.id)"
                        @click="showResendForm(read.id)"
                      >
                        Kirim Ulang
                      </button>

                      <button
                        class="btn btn-warning btn-sm"
                        v-if="read.messageWhatsappId"
                        :disabled="syncReadIds.includes(read.id)"
                        @click="syncReadBroadcast(index)"
                      >
                        Sync Total Baca
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </b-modal>

            <b-modal
              centered
              id="modal-teks"
              title="Detail Teks"
              size="lg"
              v-model="showTeks"
              hide-footer
            >
              <div
                class="containter"
                style="display: flex; flex-flow: column;"
                v-if="tableData[showTeksIndex]"
              >
                <img
                  :src="tableData[showTeksIndex].image"
                  alt=""
                  style="width: 200px; margin: 0 auto;"
                  class="mb-4"
                  v-if="tableData[showTeksIndex].image"
                />
                <div v-html="tableData[showTeksIndex].text"></div>
              </div>
            </b-modal>

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <router-link :to="{ name: 'create-whatsapp-broadcast' }">
                        <b-button class="mr-3" variant="primary" size="sm"
                          ><i class="mdi mdi-plus mr-2"></i> Tambah
                          Broadcast</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </h4>

            <hr v-if="!isPartner()" />

            <div class="col-12 mb-4" v-if="!isPartner()">
              <div class="row">
                <div class="col-lg-3 mr-3" v-if="!isPartner()">
                  <div class="row">
                    <select v-model="type" name="" id="" class="form-control">
                      <option value="">Semua Broadcast</option>
                      <option value="ONLY_BOLU">
                        Hanya Bolu
                      </option>
                      <option value="PARTNER">
                        Partner
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                </template>

                <template #cell(text)="data">
                  <!-- <img
                    v-if="data.item.image"
                    width="150"
                    :src="data.item.image"
                    v-img
                    class="mb-3"
                  />

                  <p>{{ data.item.text }}</p> -->

                  <p
                    style="color: blue; cursor: pointer;"
                    @click="
                      showTeksIndex = data.index;
                      showTeks = true;
                    "
                  >
                    Klik untuk melihat teks
                  </p>
                </template>

                <template #cell(groups)="data">
                  <p
                    style="color: blue; cursor: pointer;"
                    @click="
                      selectTotalReads = data.item.groups;
                      showTotalReads = true;
                    "
                  >
                    {{ data.item.groups.length }} Group
                  </p>
                </template>

                <template #cell(courseBatch)="data">
                  <p v-if="data.item.courseBatch">
                    {{ data.item.courseBatch.name }}
                  </p>
                </template>

                <template #cell(dateTime)="data">
                  <p v-if="data">
                    {{ data.item.dateTime | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1 mb-1"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-whatsapp-broadcast',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteUserRegion(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
