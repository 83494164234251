<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { userMethods } from "../../../state/helpers";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },

  data() {
    return {
      title: this.$route.params.id ? "Ubah Member" : "Buat Member",
      id: this.$route.params.id,
      items: [
        {
          text: "Member",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        utms: [],
        profile: {},
        categories: [],
        onboardingAnswers: [],
        whatsappGroups: [],
      },
      submitted: false,
      errors: {},
    };
  },
  methods: {
    ...userMethods,

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.store(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-member" });
      } else {
        this.errors = response.errors;
      }
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      if (!this.form.profile) this.form.profile = {};
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom01">Nama</label>
                    <p>{{ form.firstName }} {{ form.lastName }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom03">E-mail</label>
                    <p>{{ form.email }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom04">Username</label>
                    <p>{{ form.username }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom03">Nomor Telpon</label>
                    <p>{{ form.phoneNumber }}</p>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="utms">UTM</label>
                    <p v-if="form.utms.length > 0">
                      {{ form.utms.map((utm) => utm.name).join(", ") }}
                    </p>
                    <p v-else>-</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="utms">Tipe</label>
                    <p>{{ form.type }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="utms">LTV</label>
                    <p>{{ form.ltv }}</p>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="utms">Profil</label>
                    <table class="table table-bordered table-hover">
                      <tr>
                        <td>Kategori Disukai</td>
                        <td>
                          <p v-if="form.categories.length > 0">
                            {{
                              form.categories
                                .map((category) => category.name.trim())
                                .join(", ")
                            }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td>
                          <p v-if="form.profile.gender">
                            {{
                              form.profile.gender === "MALE"
                                ? "Laki - Laki"
                                : "Perempuan"
                            }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Akun Instagram</td>
                        <td>
                          <p v-if="form.profile.instagram">
                            {{ form.profile.instagram }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Akun Facebook</td>
                        <td>
                          <p v-if="form.profile.facebook">
                            {{ form.profile.facebook }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Akun Tiktok</td>
                        <td>
                          <p v-if="form.profile.tiktok">
                            {{ form.profile.tiktok }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Regional</td>
                        <td>
                          <p v-if="form.profile.province">
                            {{ form.profile.subdistrict.name }},
                            {{ form.profile.city.name }},
                            {{ form.profile.province.name }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="utms">Kelas Yang Diikuti</label>
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>No</th>
                        <th>Kelas</th>
                        <th>Batch</th>
                        <th>Status</th>
                        <th>UTM</th>
                      </tr>
                      <tr
                        v-for="(course, index) in form.courses"
                        :key="course.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ course.course.title }}</td>
                        <td>
                          <p v-if="course.batch">{{ course.batch.name }}</p>
                          <p v-else>-</p>
                        </td>
                        <td>{{ course.status }}</td>
                        <td>
                          {{ course.utms.map((utm) => utm.name).join(", ") }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="utms">Pertanyaan Onboarding</label>
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>Pertanyaan</th>
                        <th>Kategori</th>
                        <th>Kelas</th>
                        <th>Jawaban</th>
                      </tr>
                      <tbody
                        v-for="onboarding in form.onboardingAnswers"
                        :key="onboarding.id"
                      >
                        <tr v-if="onboarding.onboarding">
                          <td>{{ onboarding.onboarding.question }}</td>
                          <td>{{ onboarding.onboarding.category }}</td>
                          <td>
                            <span v-if="onboarding.onboarding.course">{{
                              onboarding.onboarding.course.title
                            }}</span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <p v-if="onboarding.options.length > 0">
                              {{
                                onboarding.options
                                  .map((option) => option.text)
                                  .join(", ")
                              }}
                            </p>
                            <p v-else>{{ onboarding.text }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="utms">Group Whatsapp</label>
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Tipe</th>
                        <th>Total Member</th>
                        <th>Kelas</th>
                        <th>Batch</th>
                      </tr>
                      <tr v-if="form.whatsappGroupCommunity">
                        <td>1</td>
                        <td>{{ form.whatsappGroupCommunity.name }}</td>
                        <td>{{ form.whatsappGroupCommunity.type }}</td>
                        <td>{{ form.whatsappGroupCommunity.totalMembers }}</td>
                        <td>
                          <p v-if="form.whatsappGroupCommunity.course">
                            {{ form.whatsappGroupCommunity.course.title }}
                          </p>
                          <p v-else>-</p>
                        </td>
                        <td>
                          <p v-if="form.whatsappGroupCommunity.courseBatch">
                            {{ form.whatsappGroupCommunity.courseBatch.name }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                      <tr
                        v-for="(group, index) in form.whatsappGroups"
                        :key="group.id"
                      >
                        <td>
                          {{ index + (form.whatsappGroupCommunity ? 2 : 1) }}
                        </td>
                        <td>{{ group.name }}</td>
                        <td>{{ group.type }}</td>
                        <td>{{ group.totalMembers }}</td>
                        <td>
                          <p v-if="group.course">{{ group.course.title }}</p>
                          <p v-else>-</p>
                        </td>
                        <td>
                          <p v-if="group.courseBatch">
                            {{ group.courseBatch.name }}
                          </p>
                          <p v-else>-</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <button
                class="btn btn-primary form-control"
                type="button"
                @click="
                  $router.push({
                    name: 'edit-member',
                    params: { id: form.id },
                  })
                "
              >
                {{ title }}
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
p {
  margin: 0 !important;
}
</style>
