import api from "./index";

const baseURL = "/certification-events";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const importData = (payload) => api.post(`${baseURL}/import`, payload);

const detail = (id) => api.get(`${baseURL}/${id}`);

export default { fetch, detail, importData };
