import userGroupApi from "../../api/user-group.api";
import filterDefault from "../../helpers/filter";

export const state = {
  userGroups: [],
  userGroup: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  userGroups(state) {
    return state.userGroups;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_USER_GROUPS(state, userGroups) {
    state.userGroups = userGroups;
  },

  ADD_USER_GROUP(state, userGroup) {
    state.userGroups.unshift(userGroup);
  },

  MERGE_USER_GROUPS(state, userGroups) {
    state.userGroups = userGroups.concat(state.userGroups);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_USER_GROUP(state, id) {
    state.userGroups.splice(
      state.userGroups.findIndex((userGroup) => userGroup.id === id),
      1
    );
  },

  SET_USER_GROUP(state, userGroup) {
    state.userGroup = userGroup;
  },

  CHANGE_USER_GROUP(state, { id, userGroup }) {
    state.userGroups[
      state.userGroups.findIndex((userGroup) => userGroup.id === id)
    ] = userGroup;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await userGroupApi.fetch(state.filter);

      commit("SET_USER_GROUPS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await userGroupApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_USER_GROUP", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await userGroupApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_USER_GROUP", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await userGroupApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await userGroupApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_USER_GROUP", { id, userGroup: data.data });

    return { status: true };
  },
};
