<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

/**
 * Master Course component
 */
export default {
  page: {
    title: "Master Rating Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Rating Kelas",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Rating Kelas",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      loading: false,
      fields: [
        { key: "user", label: "Pengguna" },
        { key: "course", label: "Kelas" },
        { key: "module", label: "Module" },
        { key: "rating", label: "Rating" },
        { key: "options", label: "Option" },
        { key: "impression", label: "Kesan" },
        { key: "criticismAndSuggestions", label: "Kritik & Saran" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["rating/ratings"];
    },

    total() {
      return this.$store.getters["rating/total"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["rating/filter"].search;
      },
      set(val) {
        this.changeFilter({ key: "search", value: val });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["rating/filter"].limit;
      },
      set(val) {
        this.changeFilter({ key: "limit", value: val });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["rating/filter"].page;
      },
      set(val) {
        this.changeFilter({ key: "page", value: val });
      },
    },

    courseId: {
      get() {
        return this.$store.getters["rating/filter"].courseId;
      },
      async set(val) {
        this.loading = true;
        await this.fetch({ key: "courseId", value: val });
        await this.fetchModule({ key: "courseId", value: val });
        this.loading = false;
      },
    },

    moduleId: {
      get() {
        return this.$store.getters["rating/filter"].moduleId;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "moduleId", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      modules: "module/modules",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    if (this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId;
    } else {
      await this.fetch({ key: "courseId", value: this.courseId });
    }
    await this.fetchCourse();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "rating/changeFilter",
      fetchCourse: "course/fetch",
      fetchModule: "module/changeFilter",
    }),
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3">
                  <div class="row">
                    <select
                      v-model="courseId"
                      name=""
                      id=""
                      class="form-control"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                      >
                        {{ course.title }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-3">
                  <select v-model="moduleId" name="" id="" class="form-control">
                    <option value="">Pilih module</option>
                    <option
                      :value="module.id"
                      v-for="module in modules"
                      :key="module.id"
                    >
                      {{ module.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(options)="data">
                  <p v-if="data.item.options">
                    <span
                      v-for="(option, index) in data.item.options"
                      :key="option.id"
                      >{{ option.name
                      }}<span v-if="index < data.item.options.length - 1"
                        >,
                      </span>
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.title }}
                  </p>
                </template>

                <template #cell(module)="data">
                  <p v-if="data.item.module">
                    {{ data.item.module.title }}
                  </p>
                </template>

                <template #cell(user)="data">
                  <p>
                    {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                  </p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
