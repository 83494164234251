<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  page: {
    title: "Tambah Pertanyaan Onboarding",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      courses: "course/courses",
      types: "onboarding/types",
      categories: "onboarding/categories",
      questionTypes: "onboarding/questionTypes",
      onboardings: "onboarding/onboardings",
    }),
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Pertanyaan Onboarding"
        : "Tambah Pertanyaan Onboarding",
      id: this.$route.params.id,
      items: [
        {
          text: "Pertanyaan Onboarding",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        type: "",
        question: "",
        order: 0,
        isActive: true,
        filterOnboardingId: "",
        filterOnboardingOptionId: "",
        fieldOnUserProfile: "",
        category: "GENERAL",
        options: [
          {
            text: "",
          },
        ],
      },
      loading: false,
      submitted: false,
      errors: {},
      options: [],

      fields: [
        {
          key: "gender",
          text: "Jenis Kelamin",
        },
        {
          key: "category",
          text: "Kategori produk yang dijual",
        },
        {
          key: "averageProfitPerMonth",
          text: "Rata rata penjualan sebulan",
        },
        {
          key: "typeSelling",
          text: "Tipe Jualan",
        },
        {
          key: "facebook",
          text: "Akun Facebook",
        },
        {
          key: "instagram",
          text: "Akun Instagram",
        },
        {
          key: "tiktok",
          text: "Akun Tiktok",
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      update: "onboarding/update",
      create: "onboarding/create",
      detail: "onboarding/detail",
      fetch: "onboarding/fetch",
      fetchCourse: "course/fetch",
    }),

    changeOnboarding() {
      if (this.form.filterOnboardingId) {
        this.options = this.onboardings.filter(
          (onboarding) => onboarding.id === this.form.filterOnboardingId
        )[0].options;
        this.form.filterOnboardingOptionId = this.options[0].id;
      } else {
        this.options = [];
        this.form.filterOnboardingId = "";
        this.form.filterOnboardingOptionId = "";
      }
    },

    addOption() {
      this.form.options.push({
        text: "",
      });
    },

    haveOption(type) {
      return type === "SELECT" || type === "CHECKBOX" || type === "RADIO";
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-onboarding" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");
    this.fetch();

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);

      if (this.form.filterOnboarding) {
        this.form.filterOnboardingId = this.form.filterOnboarding.id;
      } else {
        this.form.filterOnboardingId = "";
      }
      if (this.form.filterOnboardingOption) {
        this.form.filterOnboardingOptionId = this.form.filterOnboardingOption.id;
      } else {
        this.form.filterOnboardingOptionId = "";
      }

      if (this.form.course) this.form.courses.id;
    }

    this.fetchCourse();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="minimalScore">Pertanyaan</label>
                    <textarea
                      name=""
                      v-model="form.question"
                      id=""
                      cols="30"
                      rows="5"
                      class="form-control"
                    ></textarea>
                    <div v-if="errors['question']" class="invalid-feedback">
                      <span>{{ errors["question"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="minimalScore"
                      >Kondisional Pertanyaan (Opsional)</label
                    >
                    <select
                      type="text"
                      class="form-control"
                      v-model="form.fieldOnUserProfile"
                    >
                      <option value=""
                        >Pilih Data Pada Profile Pengguna Yang Akan Terhubung Ke
                        Pertanyaan Ini</option
                      >
                      <option
                        :value="field.key"
                        v-for="field in fields"
                        :key="field.key"
                        >{{ field.text }}</option
                      >
                    </select>
                    <div
                      v-if="errors['fieldOnUserProfile']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["fieldOnUserProfile"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="minimalScore"
                      >Kondisional Pertanyaan (Opsional)</label
                    >
                    <select
                      type="text"
                      class="form-control"
                      v-model="form.filterOnboardingId"
                      @change="changeOnboarding"
                    >
                      <option value="">Pilih Pertanyaan Onboarding</option>
                      <option
                        :value="onboarding.id"
                        v-for="onboarding in onboardings"
                        :key="onboarding.id"
                        >{{ onboarding.question }}</option
                      >
                    </select>
                    <div
                      v-if="errors['filterOnboardingId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["filterOnboardingId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="minimalScore">Pilih Jawaban Pertanyaan</label>
                    <select
                      type="text"
                      class="form-control"
                      v-model="form.filterOnboardingOptionId"
                    >
                      <option value=""
                        >Pilih Jawaban Pertanyaan Onboarding</option
                      >
                      <option
                        :value="option.id"
                        v-for="option in options"
                        :key="option.id"
                        >{{ option.text }}</option
                      >
                    </select>
                    <div
                      v-if="errors['filterOnboardingId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["filterOnboardingId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Nomer Urut</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.order"
                    />
                    <div v-if="errors['order']" class="invalid-feedback">
                      <span>{{ errors["order"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Apakah Aktif?</label>
                    <select
                      name=""
                      v-model="form.isActive"
                      id=""
                      class="form-control"
                    >
                      <option :value="true">Iya</option>
                      <option :value="false">Tidak</option>
                    </select>
                    <div v-if="errors['isActive']" class="invalid-feedback">
                      <span>{{ errors["isActive"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Tipe</label>
                    <select
                      name="type"
                      id="type"
                      v-model="form.type"
                      class="form-control"
                    >
                      <option value="">Pilih tipe pertanyaan</option>
                      <option
                        :value="type.key"
                        v-for="type in questionTypes"
                        :key="`${index}-${type.key}`"
                        >{{ type.text }}</option
                      >
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Kategori</label>
                    <select
                      name="category"
                      id="category"
                      v-model="form.category"
                      class="form-control"
                    >
                      <option value="">Pilih tipe pertanyaan</option>
                      <option
                        :value="category.key"
                        v-for="category in categories"
                        :key="`${index}-${category.key}`"
                        >{{ category.text }}</option
                      >
                    </select>
                    <div v-if="errors['category']" class="invalid-feedback">
                      <span>{{ errors["category"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.category === 'COURSE'">
                  <div class="form-group">
                    <label for="name">Kelas</label>
                    <select
                      name="course"
                      id="course"
                      v-model="form.courseId"
                      class="form-control"
                    >
                      <option value="">Pilih tipe pertanyaan</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['course']" class="invalid-feedback">
                      <span>{{ errors["course"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="backgroundImage">Opsi Jawaban</label>
                    <draggable v-model="form.options" draggable=".item">
                      <div
                        v-for="(question, index) in form.options"
                        :key="index"
                        class="item"
                      >
                        <div class="row">
                          <div class="col-lg-11">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="label">Jawaban</label>
                                  <input
                                    name=""
                                    v-model="question.text"
                                    id=""
                                    cols="30"
                                    rows="5"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-1">
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click="form.options.splice(index, 1)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary form-control"
                        slot="footer"
                        @click="addOption"
                        type="button"
                      >
                        Tambah Pertanyaan
                      </button>
                    </draggable>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                :disabled="loading"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.item {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
