import orderApi from "../../api/order.api";
import filterDefault from "../../helpers/filter";

export const state = {
  orders: [],
  order: {},
  filter: {
    ...filterDefault,
    status: "",
  },
  total: 0,
  statuses: [
    {
      value: "WAITING_PAYMENT",
      text: "Menunggu Pembayaran",
    },
    {
      value: "WAITING_VERIFICATION",
      text: "Menunggu Verifikasi Otomatis",
    },
    {
      value: "FAILED_AUTO_CHECK_PAYMENT",
      text: "Gagal Verifikasi Otomatis",
    },
    {
      value: "WAITING_MANUAL_CONFIRMATION",
      text: "Menunggu Verifikasi Manual",
    },
    {
      value: "FAILED_MANUAL_CONFIRMATION",
      text: "Gagal Verifikasi Manual",
    },
    {
      value: "DONE",
      text: "Selesai",
    },
    {
      value: "CANCEL",
      text: "Cancel",
    },
    {
      value: "REFUND",
      text: "Refund",
    },
  ],
};

export const getters = {
  orders(state) {
    return state.orders;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },

  statuses(state) {
    return state.statuses;
  },
};

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  ADD_ORDER_OPTION(state, order) {
    state.orders.unshift(order);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_ORDER_OPTION(state, id) {
    state.orders.splice(
      state.orders.findIndex((order) => order.id === id),
      1
    );
  },

  SET_ORDER_OPTION(state, order) {
    state.order = order;
  },

  CHANGE_ORDER_OPTION(state, { id, order }) {
    state.orders[state.orders.findIndex((order) => order.id === id)] = order;
  },

  ADD_DATA_FOLLOWUP(state, { data, index }) {
    state.orders[index].followups.push(data);
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await orderApi.fetch(state.filter);

      commit("SET_ORDERS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async detail(context, id) {
    const { data } = await orderApi.detail(id);

    return data.data;
  },

  async update({ dispatch }, { id, payload }) {
    const { data } = await orderApi.update({ id, payload });

    dispatch("notification/success", data.message, { root: true });

    return data;
  },

  async getResumeOrder(context, params) {
    const { data } = await orderApi.resumeOrder(params);

    return data.data;
  },

  async followup({ dispatch }, payload) {
    const { data } = await orderApi.followup(payload);

    dispatch("notification/success", data.message, { root: true });

    return data.data;
  },
};
