import dashboardApi from "../../api/dashboard.api";

export const state = {
  totalUsers: 0,
  totalUsersAlreadyLogin: 0,
  totalUsersAlreadyReadModule: 0,
  totalNewUsers: 0,
  totalUsersHavingCourse: 0,
  totalUsersHaveCertificate: 0,
  totalUsersLogin: 1,
  totalUsersUsedForum: 0,
  totalUsersTakeQuiz: 0,

  totalNewUsersPeriode: [],
  totalUsersHavingCoursePeriode: [],
  totalUsersHaveCertificatePeriode: [],
  totalUsersLoginPeriode: [],
  totalUsersUsedForumPeriode: [],
  totalUsersTakeQuizPeriode: [],
  totalUsersAlreadyReadModulePeriode: [],

  // Transaction Order
  totalRevenue: 0,
  totalOrdersPaid: 0,
  paidRatio: 0,
  totalMembersOrder: 0,
  totalMembersPaid: 0,

  // Enrollment
  totalNewEnrollment: 0,
  totalNewPaidEnrollment: 0,
  totalNewFreeEnrollment: 0,
  totalMemberNewEnrollment: 0,
  totalMemberNewPaidEnrollment: 0,
  totalMemberNewFreeEnrollment: 0,
};

export const getters = {
  totalUsers(state) {
    return state.totalUsers;
  },

  totalUsersAlreadyLogin(state) {
    return state.totalUsersAlreadyLogin;
  },

  totalUsersAlreadyReadModule(state) {
    return state.totalUsersAlreadyReadModule;
  },

  totalUsersAlreadyReadModulePeriode(state) {
    return state.totalUsersAlreadyReadModulePeriode;
  },

  totalUsersHaveCertificate(state) {
    return state.totalUsersHaveCertificate;
  },

  totalUsersHaveCertificatePeriode(state) {
    return state.totalUsersHaveCertificatePeriode;
  },

  totalNewUsers(state) {
    return state.totalNewUsers;
  },

  totalNewUsersPeriode(state) {
    return state.totalNewUsersPeriode;
  },

  totalUsersHavingCourse(state) {
    return state.totalUsersHavingCourse;
  },

  totalUsersHavingCoursePeriode(state) {
    return state.totalUsersHavingCoursePeriode;
  },

  totalUsersLogin(state) {
    return state.totalUsersLogin;
  },

  totalUsersLoginPeriode(state) {
    return state.totalUsersLoginPeriode;
  },

  totalUsersUsedForumPeriode(state) {
    return state.totalUsersUsedForumPeriode;
  },

  totalUsersUsedForum(state) {
    return state.totalUsersUsedForum;
  },

  totalUsersTakeQuizPeriode(state) {
    return state.totalUsersTakeQuizPeriode;
  },

  totalUsersTakeQuiz(state) {
    return state.totalUsersTakeQuiz;
  },

  // Tramsaction

  totalRevenue(state) {
    return state.totalRevenue;
  },

  totalOrdersPaid(state) {
    return state.totalOrdersPaid;
  },

  paidRatio(state) {
    return state.paidRatio;
  },

  totalMembersOrder(state) {
    return state.totalMembersOrder;
  },

  totalMembersPaid(state) {
    return state.totalMembersPaid;
  },

  // Enrollment
  totalNewEnrollment(state) {
    return state.totalNewEnrollment;
  },

  totalNewPaidEnrollment(state) {
    return state.totalNewPaidEnrollment;
  },

  totalNewFreeEnrollment(state) {
    return state.totalNewFreeEnrollment;
  },

  totalMemberNewEnrollment(state) {
    return state.totalMemberNewEnrollment;
  },

  totalMemberNewPaidEnrollment(state) {
    return state.totalMemberNewPaidEnrollment;
  },

  totalMemberNewFreeEnrollment(state) {
    return state.totalMemberNewFreeEnrollment;
  },
};

export const mutations = {
  SET_TOTAL_USERS(state, totalUsers) {
    state.totalUsers = totalUsers;
  },

  SET_TOTAL_USERS_ALREADY_LOGIN(state, totalUsersAlreadyLogin) {
    state.totalUsersAlreadyLogin = totalUsersAlreadyLogin;
  },

  SET_TOTAL_USERS_ALREADY_READ_MODULE(state, totalUsersAlreadyReadModule) {
    state.totalUsersAlreadyReadModule = totalUsersAlreadyReadModule;
  },

  SET_TOTAL_USERS_ALREADY_READ_MODULE_PERIODE(
    state,
    totalUsersAlreadyReadModulePeriode
  ) {
    state.totalUsersAlreadyReadModulePeriode = totalUsersAlreadyReadModulePeriode;
  },

  SET_TOTAL_USERS_HAVE_CERTIFICATE(state, totalUsersHaveCertificate) {
    state.totalUsersHaveCertificate = totalUsersHaveCertificate;
  },

  SET_TOTAL_USERS_HAVE_CERTIFICATE_PERIODE(
    state,
    totalUsersHaveCertificatePeriode
  ) {
    state.totalUsersHaveCertificatePeriode = totalUsersHaveCertificatePeriode;
  },

  SET_TOTAL_NEW_USERS(state, totalNewUsers) {
    state.totalNewUsers = totalNewUsers;
  },

  SET_TOTAL_NEW_USERS_PERIODE(state, totalNewUsersPeriode) {
    state.totalNewUsersPeriode = totalNewUsersPeriode;
  },

  SET_TOTAL_USERS_HAVING_COURSE(state, totalUsersHavingCourse) {
    state.totalUsersHavingCourse = totalUsersHavingCourse;
  },

  SET_TOTAL_USERS_HAVING_COURSE_PERIODE(state, totalUsersHavingCoursePeriode) {
    state.totalUsersHavingCoursePeriode = totalUsersHavingCoursePeriode;
  },

  SET_TOTAL_USERS_LOGIN(state, totalUsersLogin) {
    state.totalUsersLogin = totalUsersLogin;
  },

  SET_TOTAL_USERS_LOGIN_PERIODE(state, totalUsersLoginPeriode) {
    state.totalUsersLoginPeriode = totalUsersLoginPeriode;
  },

  SET_TOTAL_USERS_USED_FORUM(state, totalUsersUsedForum) {
    state.totalUsersUsedForum = totalUsersUsedForum;
  },

  SET_TOTAL_USERS_USED_FORUM_PERIODE(state, totalUsersUsedForumPeriode) {
    state.totalUsersUsedForumPeriode = totalUsersUsedForumPeriode;
  },

  SET_TOTAL_USERS_TAKE_QUIZ(state, totalUsersTakeQuiz) {
    state.totalUsersTakeQuiz = totalUsersTakeQuiz;
  },

  SET_TOTAL_USERS_TAKE_QUIZ_PERIODE(state, totalUsersTakeQuizPeriode) {
    state.totalUsersTakeQuizPeriode = totalUsersTakeQuizPeriode;
  },

  // Transaction
  SET_TOTAL_REVENUE(state, totalRevenue) {
    state.totalRevenue = totalRevenue;
  },

  SET_TOTAL_ORDERS_PAID(state, totalOrdersPaid) {
    state.totalOrdersPaid = totalOrdersPaid;
  },

  SET_PAID_RATIO(state, paidRatio) {
    state.paidRatio = paidRatio;
  },

  SET_TOTAL_MEMBERS_ORDER(state, totalMembersOrder) {
    state.totalMembersOrder = totalMembersOrder;
  },

  SET_TOTAL_MEMBERS_PAID(state, totalMembersPaid) {
    state.totalMembersPaid = totalMembersPaid;
  },

  // Enrollment
  SET_TOTAL_NEW_ENROLLMENT(state, totalNewEnrollment) {
    state.totalNewEnrollment = totalNewEnrollment;
  },

  SET_TOTAL_NEW_PAID_ENROLLMENT(state, totalNewPaidEnrollment) {
    state.totalNewPaidEnrollment = totalNewPaidEnrollment;
  },

  SET_TOTAL_NEW_FREE_ENROLLMENT(state, totalNewFreeEnrollment) {
    state.totalNewFreeEnrollment = totalNewFreeEnrollment;
  },

  SET_TOTAL_MEMBER_NEW_ENROLLMENT(state, totalMemberNewEnrollment) {
    state.totalMemberNewEnrollment = totalMemberNewEnrollment;
  },

  SET_TOTAL_MEMBER_NEW_PAID_ENROLLMENT(state, totalMemberNewPaidEnrollment) {
    state.totalMemberNewPaidEnrollment = totalMemberNewPaidEnrollment;
  },

  SET_TOTAL_MEMBER_NEW_FREE_ENROLLMENT(state, totalMemberNewFreeEnrollment) {
    state.totalMemberNewFreeEnrollment = totalMemberNewFreeEnrollment;
  },
};

export const actions = {
  async fetchAdmin(context, params) {
    const { data } = await dashboardApi.fetchAdmin(params);

    return data.data;
  },

  async fetchTotalUsers({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsers(params);
    commit("SET_TOTAL_USERS", data.data);
  },

  async getTotalUsersAlreadyLogin({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersAlreadyLogin(params);
    commit("SET_TOTAL_USERS_ALREADY_LOGIN", data.data);
  },

  async getTotalUsersAlreadyReadModule({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersAlreadyReadModule(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_ALREADY_READ_MODULE_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_ALREADY_READ_MODULE", data.data);
    }
  },

  async getTotalUsersHaveCertificate({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersHaveCertificate(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_HAVE_CERTIFICATE_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_HAVE_CERTIFICATE", data.data);
    }
  },

  async getTotalNewUsers({ commit }, params) {
    const { data } = await dashboardApi.getTotalNewUsers(params);

    if (params.periode) {
      commit("SET_TOTAL_NEW_USERS_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_NEW_USERS", data.data);
    }
  },

  async getTotalUsersHavingCourse({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersHavingCourse(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_HAVING_COURSE_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_HAVING_COURSE", data.data);
    }
  },

  async getTotalUsersLogin({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersLogin(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_LOGIN_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_LOGIN", data.data);
    }
  },

  async getTotalUsersUsedForum({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersUsedForum(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_USED_FORUM_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_USED_FORUM", data.data);
    }
  },

  async getTotalUsersTakeQuiz({ commit }, params) {
    const { data } = await dashboardApi.getTotalUsersTakeQuiz(params);

    if (params.periode) {
      commit("SET_TOTAL_USERS_TAKE_QUIZ_PERIODE", data.data);
    } else {
      commit("SET_TOTAL_USERS_TAKE_QUIZ", data.data);
    }
  },

  // Transaction

  async getTotalRevenue({ commit }, params) {
    const { data } = await dashboardApi.getTotalRevenue(params);
    commit("SET_TOTAL_REVENUE", data.data);
  },

  async getTotalOrdersPaid({ commit }, params) {
    const { data } = await dashboardApi.getTotalOrdersPaid(params);
    commit("SET_TOTAL_ORDERS_PAID", data.data);
  },

  async getPaidRatio({ commit }, params) {
    const { data } = await dashboardApi.getPaidRatio(params);
    commit("SET_PAID_RATIO", data.data);
  },

  async getTotalMembersOrder({ commit }, params) {
    const { data } = await dashboardApi.getTotalMembersOrder(params);
    commit("SET_TOTAL_MEMBERS_ORDER", data.data);
  },

  async getTotalMembersPaid({ commit }, params) {
    const { data } = await dashboardApi.getTotalMembersPaid(params);
    commit("SET_TOTAL_MEMBERS_PAID", data.data);
  },

  // Enrollment
  async getTotalNewEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalNewEnrollment(params);
    commit("SET_TOTAL_NEW_ENROLLMENT", data.data);
  },

  async getTotalNewPaidEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalNewPaidEnrollment(params);
    commit("SET_TOTAL_NEW_PAID_ENROLLMENT", data.data);
  },

  async getTotalNewFreeEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalNewFreeEnrollment(params);
    commit("SET_TOTAL_NEW_FREE_ENROLLMENT", data.data);
  },

  async getTotalMemberNewEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalMemberNewEnrollment(params);
    commit("SET_TOTAL_MEMBER_NEW_ENROLLMENT", data.data);
  },

  async getTotalMemberNewPaidEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalMemberNewPaidEnrollment(params);
    commit("SET_TOTAL_MEMBER_NEW_PAID_ENROLLMENT", data.data);
  },

  async getTotalMemberNewFreeEnrollment({ commit }, params) {
    const { data } = await dashboardApi.getTotalMemberNewFreeEnrollment(params);
    commit("SET_TOTAL_MEMBER_NEW_FREE_ENROLLMENT", data.data);
  },
};
