import clinicCategoryApi from "../../api/clinic-category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  clinicCategories: [],
  clinicCategory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  clinicCategories(state) {
    return state.clinicCategories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_CLINIC_CATEGORIES(state, clinicCategories) {
    state.clinicCategories = clinicCategories;
  },

  ADD_CLINIC_CATEGORY(state, clinicCategory) {
    state.clinicCategories.unshift(clinicCategory);
  },

  MERGE_CLINIC_CATEGORIES(state, clinicCategories) {
    state.clinicCategories = clinicCategories.concat(state.clinicCategories);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CLINIC_CATEGORY(state, id) {
    state.clinicCategories.splice(
      state.clinicCategories.findIndex(
        (clinicCategory) => clinicCategory.id === id
      ),
      1
    );
  },

  SET_CLINIC_CATEGORY(state, clinicCategory) {
    state.clinicCategory = clinicCategory;
  },

  CHANGE_CLINIC_CATEGORY(state, { id, clinicCategory }) {
    state.clinicCategories[
      state.clinicCategories.findIndex(
        (clinicCategory) => clinicCategory.id === id
      )
    ] = clinicCategory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.clinicCategories.findIndex(
      (number) => number.id === id
    );

    state.clinicCategories[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await clinicCategoryApi.fetch(state.filter);

      commit("SET_CLINIC_CATEGORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await clinicCategoryApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CLINIC_CATEGORY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await clinicCategoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CLINIC_CATEGORY", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await clinicCategoryApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await clinicCategoryApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CLINIC_CATEGORY", {
      id,
      clinicCategory: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await clinicCategoryApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await clinicCategoryApi.getAudiencesByQuery(query);

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await clinicCategoryApi.getQueue(id);

    return data.data;
  },
};
