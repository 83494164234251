<script>
import QrcodeVue from "qrcode.vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

import { mapActions, mapGetters } from "vuex";

/**
 * Master whatsappNumberHost component
 */
export default {
  page: {
    title: "Nomer Master Whatsapp",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, QrcodeVue },
  data() {
    return {
      title: "Nomer Master Whatsapp",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Nomer Master Whatsapp",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      fields: [
        { key: "name", label: "Nama" },
        { key: "number", label: "Nomer" },
        { key: "type", label: "Tipe" },
        { key: "host", label: "Host" },
        { key: "status", label: "Status" },
        { key: "notes", label: "Catatan" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
      loadingResetSession: false,
      screenshot: "",
      data: [],
      types: [
        { value: "MANAGEMENT_GROUP", text: "Management Grup" },
        { value: "BLASTER", text: "Blaster" },
      ],
      statuses: [
        "CONNECTED",
        "DISCONNECTED",
        "NOT_CONNECT",
        "TIMEOUT",
        "PAIRING",
      ],
      showModalActivity: false,
      activityOpenId: "",
      filterActivity: {
        ordering: "-created_at",
        limit: 20,
        page: 1,
      },
      dataActivities: [],
      dataTotalActivity: 0,
      loadingActivity: false,
      loadingRejoin: false,
      rejoinIndex: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["whatsappNumberHost/whatsappNumberHosts"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    type: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].type;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "type", value: val });
        this.loading = false;
      },
    },

    status: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    partnerId: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].partnerId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "partnerId", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappNumberHost/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappNumberHost/total",
      partners: "partnershipPartner/partnershipPartners",
      user: "auth/currentUser",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) {
        this.index = -1;
        this.screenshot = "";
      }
    },

    tableData: {
      handler(val) {
        this.data = Object.assign(val, []);
      },
      deep: true,
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    if (!this.isPartner()) {
      await this.fetchPartners([
        { key: "limit", value: 9999 },
        { key: "ordering", value: "name" },
      ]);
    }
    this.loading = false;

    const channel = this.$pusher.subscribe("host-number");

    channel.bind("change-status", (data) => {
      const index = this.data.findIndex((d) => d.id === data.id);

      if (index > -1) this.data[index].status = data.status;
    });

    setInterval(() => {
      if (this.index > -1) {
        this.getQrCode();
      }
    }, 10000);
  },
  methods: {
    ...mapActions({
      fetch: "whatsappNumberHost/fetch",
      delete: "whatsappNumberHost/delete",
      changeFilter: "whatsappNumberHost/changeFilter",
      fetchActivities: "whatsappNumberHost/fetchActivities",
      rejoin: "whatsappNumberHost/rejoin",

      fetchPartners: "partnershipPartner/changeFilters",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    deleteNumberHost(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getQrCode(index = "") {
      if (index !== "") this.index = index;

      const dataNumber = this.tableData[this.index];
      this.$nextTick(() => {
        this.$bvModal.show("modal-barcode");
      });

      try {
        if (dataNumber.status !== "CONNECTED" && !this.loadingResetSession) {
          const { data } = await axios.post(
            `${dataNumber.host}/v1/authentications/get-qr`,
            {},
            {
              headers: {
                common: { "Access-Control-Allow-Origin": "*" },
                Authorization: `Bearer ${dataNumber.token}`,
              },
            }
          );
          this.qrcode = data.data.lastQr;
        }
      } catch (error) {
        return;
      }
    },

    async removeSession() {
      this.loadingResetSession = true;
      const dataNumber = this.tableData[this.index];

      await axios.patch(
        `${dataNumber.host}/v1/authentications/remove-session`,
        {},
        {
          headers: {
            common: { "Access-Control-Allow-Origin": "*" },
            Authorization: `Bearer ${dataNumber.token}`,
          },
        }
      );

      this.screenshot = "";
      this.qrcode = "";

      this.tableData[this.index].status = "NOT_CONNECTED";

      this.loadingResetSession = false;
    },

    async restart(index) {
      this.$swal({
        text: "Apakah kamu yakin ingin restart bot ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, Restart !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          this.loadingResetSession = true;
          const dataNumber = this.tableData[index];

          await axios.patch(
            `${dataNumber.host}/v1/authentications/restart`,
            {},
            {
              headers: {
                common: { "Access-Control-Allow-Origin": "*" },
                Authorization: `Bearer ${dataNumber.token}`,
              },
            }
          );

          this.tableData[index].status = "RESTART";

          this.loadingResetSession = false;
        }
      });
    },

    updateBot(index) {
      this.$swal({
        text: "Apakah kamu yakin ingin update bot ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, Update !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          this.loadingResetSession = true;
          const dataNumber = this.tableData[index];

          await axios.patch(
            `${dataNumber.host}/v1/authentications/update`,
            {},
            {
              headers: {
                common: { "Access-Control-Allow-Origin": "*" },
                Authorization: `Bearer ${dataNumber.token}`,
              },
            }
          );

          this.tableData[index].status = "UPDATE";

          this.loadingResetSession = false;
        }
      });
    },

    rejoinBot(index) {
      this.$swal({
        text: "Apakah kamu yakin ingin invite ulang bot ini ke semua grup?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, invite ulang !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          this.loadingRejoin = true;
          const dataNumber = this.tableData[index];

          await this.rejoin(dataNumber.id);

          this.rejoinIndex.push(index);

          this.loadingRejoin = false;
        }
      });
    },

    async takeScreenshot() {
      this.loadingResetSession = true;
      const dataNumber = this.tableData[this.index];

      const { data } = await axios.get(
        `${dataNumber.host}/v1/authentications/screenshot`,
        {},
        {
          headers: {
            common: { "Access-Control-Allow-Origin": "*" },
            Authorization: `Bearer ${dataNumber.token}`,
          },
        }
      );

      this.screenshot = data.data.image;
      this.loadingResetSession = false;
    },

    async openModalActivity(id) {
      this.filterActivity = {
        ordering: "-created_at",
        limit: 20,
        page: 1,
      };

      this.showModalActivity = true;
      this.activityOpenId = id;

      this.loadingActivity = true;

      const { data, total } = await this.fetchActivities({
        id,
        params: this.filterActivity,
      });

      this.dataActivities = data;
      this.dataTotalActivity = total;

      this.loadingActivity = false;
    },

    async showMoreActivity() {
      this.filterActivity.page++;

      this.loadingActivity = true;

      const { data, total } = await this.fetchActivities({
        id: this.activityOpenId,
        params: this.filterActivity,
      });

      this.dataActivities.push(...data);
      this.dataTotalActivity = total;

      this.loadingActivity = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-modal
              centered
              id="modal-activity"
              title="Aktivitas Bot"
              size="xl"
              hide-footer
              v-model="showModalActivity"
            >
              <table class="table table-hover table-bordered">
                <tr>
                  <th>Tanggal</th>
                  <th>Jenis Aktifitas</th>
                  <th>Status</th>
                  <th>Endpoint</th>
                  <th>Catatan</th>
                </tr>
                <tr v-for="activity in dataActivities" :key="activity.id">
                  <td>
                    {{ activity.createdAt | moment("Do MMMM YYYY HH:mm") }}
                  </td>
                  <td>{{ activity.type }}</td>
                  <td>{{ activity.status }}</td>
                  <td>{{ activity.url }}</td>
                  <td>{{ activity.notes }}</td>
                </tr>
                <tfoot>
                  <tr>
                    <td colspan="5">
                      <button
                        class="btn btn-primary form-control"
                        @click="showMoreActivity"
                        :disabled="loadingActivity"
                      >
                        Tampilkan Lebih Banyak
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </b-modal>

            <b-modal
              centered
              id="modal-barcode"
              title="Scan Barcode Whatsapp"
              :size="!screenshot ? 'md' : 'xl'"
              hide-footer
              v-model="showModal"
            >
              <div
                class="text-center"
                v-if="index > -1 && !loadingResetSession"
              >
                <div
                  v-if="
                    tableData[index].status === 'NOT_CONNECTED' ||
                      tableData[index].status === 'NOT_CONNECT' ||
                      tableData[index].status === 'AUTH_FAILURE' ||
                      tableData[index].status === 'RESTART' ||
                      tableData[index].status === 'RESET_SESSION' ||
                      tableData[index].status === 'DISCONNECTED'
                  "
                >
                  <QrcodeVue
                    :value="qrcode"
                    :size="300"
                    level="H"
                    v-if="!screenshot"
                  />

                  <div v-else>
                    <img :src="screenshot" v-img alt="" width="100%" />
                  </div>

                  <button
                    class="btn btn-danger form-control mt-3"
                    @click="removeSession"
                  >
                    Reset Session
                  </button>

                  <button
                    class="btn btn-primary form-control mt-3"
                    @click="!screenshot ? takeScreenshot() : (screenshot = '')"
                  >
                    {{ !screenshot ? "Ambil Screenshot" : "Hapus Screenshot" }}
                  </button>
                </div>
                <h4 v-else>
                  <div v-if="!screenshot">
                    WhatsApp telah tersambung ke server
                  </div>
                  <div v-else>
                    <img :src="screenshot" v-img alt="" width="100%" />
                  </div>

                  <button
                    class="btn btn-danger form-control mt-3"
                    @click="removeSession"
                  >
                    Reset Session
                  </button>

                  <button
                    class="btn btn-primary form-control mt-3"
                    @click="!screenshot ? takeScreenshot() : (screenshot = '')"
                  >
                    {{ !screenshot ? "Ambil Screenshot" : "Hapus Screenshot" }}
                  </button>
                </h4>
              </div>
              <div v-if="loadingResetSession" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </b-modal>

            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title" v-if="!isPartner()">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <router-link
                        :to="{ name: 'create-whatsapp-number-host' }"
                      >
                        <b-button class="mr-3" variant="primary" size="sm"
                          ><i class="mdi mdi-plus mr-2"></i> Tambah Nomer
                          Whatsapp</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </h4>

            <hr class="my-4" v-if="!isPartner()" />

            <div class="row">
              <div class="col-lg-3 ml-3">
                <div class="row">
                  <select v-model="type" name="" id="" class="form-control">
                    <option value="">Pilih Tipe Host</option>
                    <option
                      :value="type.value"
                      v-for="type in types"
                      :key="type.value"
                    >
                      {{ type.text }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-3">
                <select v-model="status" name="" id="" class="form-control">
                  <option value="">Pilih status</option>
                  <option
                    :value="status"
                    v-for="status in statuses"
                    :key="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="data"
                :fields="fields"
                responsive="xl"
                :per-page.sync="perPage"
                :busy="loading"
                ref="table"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(name)="data">
                  <p>
                    <span v-if="data.item.isBanned">[Banned]</span>
                    {{ data.item.name }}
                    <span v-if="data.item.partner"
                      >[{{ data.item.partner.name }}]</span
                    >
                  </p>
                </template>

                <template #cell(type)="data">
                  <p>
                    {{ data.item.type | hostType }}
                  </p>
                </template>

                <template #cell(notes)="data">
                  <p>Aktif : {{ data.item.isActive | convertBoolean }}</p>
                  <p>
                    Device : {{ data.item.device ? data.item.device : "-" }}
                  </p>
                  <p>
                    Lokasi : {{ data.item.location ? data.item.location : "-" }}
                  </p>
                  <p>
                    Expired Kartu :
                    {{
                      data.item.expiredAt
                        ? $moment(data.item.expiredAt).format("D MMMM YYYY")
                        : "-"
                    }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1 mb-1"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-whatsapp-number-host',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1 mb-1"
                    variant="info"
                    size="sm"
                    @click="openModalActivity(data.item.id)"
                    ><i class="ri-history-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1 mb-1"
                    variant="warning"
                    size="sm"
                    @click="getQrCode(data.index)"
                    ><i class="ri-eye-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1"
                    variant="primary"
                    size="sm"
                    @click="restart(data.index)"
                    ><i class="ri-refresh-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1"
                    variant="info"
                    size="sm"
                    @click="updateBot(data.index)"
                    ><i class="ri-upload-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1"
                    variant="warning"
                    size="sm"
                    @click="rejoinBot(data.index)"
                    :disabled="
                      loadingRejoin || rejoinIndex.indexOf(data.index) > -1
                    "
                    ><i class="ri-repeat-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteNumberHost(data.item.id)"
                    class="mt-1"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
