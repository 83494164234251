import whatsappGroupWordSearchSummaryWordApi from "../../api/whatsapp-group-word-search-summary-word.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupWordSearchSummaryWords: [],
  whatsappGroupWordSearchSummaryWord: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupWordSearchSummaryWords(state) {
    return state.whatsappGroupWordSearchSummaryWords;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(
    state,
    whatsappGroupWordSearchSummaryWords
  ) {
    state.whatsappGroupWordSearchSummaryWords = whatsappGroupWordSearchSummaryWords;
  },

  ADD_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD(
    state,
    whatsappGroupWordSearchSummaryWord
  ) {
    state.whatsappGroupWordSearchSummaryWords.unshift(
      whatsappGroupWordSearchSummaryWord
    );
  },

  MERGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(
    state,
    whatsappGroupWordSearchSummaryWords
  ) {
    state.whatsappGroupWordSearchSummaryWords = whatsappGroupWordSearchSummaryWords.concat(
      state.whatsappGroupWordSearchSummaryWords
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD(state, id) {
    state.whatsappGroupWordSearchSummaryWords.splice(
      state.whatsappGroupWordSearchSummaryWords.findIndex(
        (whatsappGroupWordSearchSummaryWord) =>
          whatsappGroupWordSearchSummaryWord.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD(
    state,
    whatsappGroupWordSearchSummaryWord
  ) {
    state.whatsappGroupWordSearchSummaryWord = whatsappGroupWordSearchSummaryWord;
  },

  CHANGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD(
    state,
    { id, whatsappGroupWordSearchSummaryWord }
  ) {
    state.whatsappGroupWordSearchSummaryWords[
      state.whatsappGroupWordSearchSummaryWords.findIndex(
        (whatsappGroupWordSearchSummaryWord) =>
          whatsappGroupWordSearchSummaryWord.id === id
      )
    ] = whatsappGroupWordSearchSummaryWord;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupWordSearchSummaryWordApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupWordSearchSummaryWordApi.create(
      payload
    );
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupWordSearchSummaryWordApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupWordSearchSummaryWordApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupWordSearchSummaryWordApi.update(
      id,
      payload
    );

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD", {
      id,
      whatsappGroupWordSearchSummaryWord: data.data,
    });

    return { status: true };
  },
};
