import categoryApi from "../../api/category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  categories: [],
  category: {},
  filter: {
    ...filterDefault,
    ordering: "name",
  },
};

export const getters = {
  categories(state) {
    return state.categories;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },

  ADD_CATEGORY(state, category) {
    state.categories.unshift(category);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CATEGORY(state, id) {
    state.categories.splice(
      state.categories.findIndex((category) => category.id === id),
      1
    );
  },

  SET_CATEGORY(state, category) {
    state.category = category;
  },

  CHANGE_CATEGORY(state, { id, category }) {
    state.categories[
      state.categories.findIndex((category) => category.id === id)
    ] = category;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetchCategories");
  },

  async fetchCategories({ commit, state }) {
    try {
      const { data } = await categoryApi.fetch(state.filter);

      commit("SET_CATEGORIES", data.data);

      return data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await categoryApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CATEGORY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await categoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CATEGORY", id);

    return { status: true };
  },

  async detail({ dispatch }, id) {
    const { data } = await categoryApi.detail(id);

    dispatch("SET_CATEGORY", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await categoryApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CATEGORY", { id, category: data.data });

    return { status: true };
  },
};
