<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Detail Pesanan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detail Pesanan",
      id: this.$route.params.id,
      items: [
        {
          text: "Pesanan",
          href: "/",
        },
      ],
      form: {
        user: {},
        order: {},
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "order/detail",
      update: "order/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async updateStatus(status) {
      this.loading = true;

      const response = await this.update({
        id: this.$route.params.id,
        payload: { status },
      });

      this.form = response.result;

      this.loading = false;
    },

    getTotalSubtotal() {
      try {
        return this.form.items.reduce((prev, next) => {
          return (prev += next.price - next.discount);
        }, 0);
      } catch (error) {
        return 0;
      }
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      console.log(this.form);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Nomer Pesanan</b></label>
                    <p>{{ form.orderNumber }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Nama</b></label>
                    <p>{{ form.user.firstName }} {{ form.user.lastName }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Whatsapp</b></label>
                    <p>{{ form.user.phoneNumber }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Status</b></label>
                    <p>{{ form.status | statusOrder }}</p>
                  </div>
                </div>
              </div>

              <hr />

              <p><b>Rincian Pesanan</b></p>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Kelas</th>
                    <th>Batch</th>
                    <th>Harga</th>
                    <th>Diskon</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in form.items" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.course.title }}</td>
                    <td>{{ item.batch.name }}</td>
                    <td>{{ item.price | rupiah("Rp.") }}</td>
                    <td>{{ item.discount | rupiah("Rp.") }}</td>
                    <td>{{ (item.price - item.discount) | rupiah("Rp.") }}</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <th colspan="5" class="text-right">Total Subtotal</th>
                    <th>{{ getTotalSubtotal() | rupiah("Rp.") }}</th>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-right">Diskon Voucher</th>
                    <th>{{ form.discount | rupiah("Rp.") }}</th>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-right">Kode Unik</th>
                    <th>{{ form.uniqueCode | rupiah("Rp.") }}</th>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-right">Total Harus Dibayar</th>
                    <th>{{ form.totalPrice | rupiah("Rp.") }}</th>
                  </tr>
                </tfoot>
              </table>

              <hr class="my-5" />

              <p><b>Riwayat Pesanan</b></p>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(history, index) in form.historyStatuses"
                    :key="history.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ history.name | statusOrder }}</td>
                    <td>
                      {{ history.createdAt | moment("D MMMM YYYY, HH:mm") }} WIB
                    </td>
                  </tr>
                </tbody>
              </table>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
                v-if="form.status !== 'DONE' && form.status !== 'CANCEL'"
                @click="updateStatus('DONE')"
              >
                <span v-if="!loading">Selesaikan Pesanan</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                :disabled="loading"
                class="btn btn-danger mt-4 form-control"
                type="submit"
                v-if="form.status !== 'DONE' && form.status !== 'CANCEL'"
                @click="updateStatus('CANCEL')"
              >
                <span v-if="!loading">Batalkan Pesanan</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
