<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";
import VueRangedatePicker from "vue-rangedate-picker";
import Select2 from "v-select2-component";

/**
 * Master whatsappGroupWordSearchSummaries component
 */
export default {
  page: {
    title: "Rangkuman",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueRangedatePicker, Select2 },
  data() {
    return {
      title: "Rangkuman",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Rangkuman",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "group", label: "Group" },
        { key: "word", label: "Kata kata" },
        { key: "category", label: "Kategori" },
        { key: "total", label: "Total", sortable: true },
        { key: "createdAt", label: "Tanggal", sortable: true },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
      groupsOptions: [],
      categoriesOptions: [],
      wordsOptions: [],
      filterGroupOptions: [],
      filterCategoryOptions: [],
      filterWordOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      groups: "whatsappGroup/whatsappGroups",
      categories:
        "whatsappGroupWordSearchSummaryWordCategory/whatsappGroupWordSearchSummaryWordCategories",
      words:
        "whatsappGroupWordSearchSummaryWord/whatsappGroupWordSearchSummaryWords",
      user: "auth/currentUser",
    }),

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters[
        "whatsappGroupWordSearchSummary/whatsappGroupWordSearchSummaries"
      ];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    groupIds: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .groupIds;
      },
      async set(val) {
        console.log(val);
        this.loading = true;
        await this.changeFilter({ key: "groupIds", value: val.join(",") });
        this.loading = false;
      },
    },

    wordIds: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .wordIds;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "wordIds", value: val.join(",") });
        this.loading = false;
      },
    },

    categoryIds: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .categoryIds;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "categoryIds", value: val.join(",") });
        this.loading = false;
      },
    },

    startAt: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .startAt;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "startAt", value: val });
        this.loading = false;
      },
    },

    endAt: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .endAt;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "endAt", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappGroupWordSearchSummary/filter"]
          .page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappGroupWordSearchSummary/total",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },

    groups: {
      handler() {
        this.groupsOptions = [];
        this.groups.forEach((group) => {
          this.groupsOptions.push({ id: group.id, text: group.name });
        });
      },
      deep: true,
    },

    words: {
      handler() {
        this.wordsOptions = [];
        this.words.forEach((word) => {
          this.wordsOptions.push({ id: word.id, text: word.word });
        });
      },
      deep: true,
    },

    categories: {
      handler() {
        this.categoriesOptions = [];
        this.categories.forEach((category) => {
          this.categoriesOptions.push({ id: category.id, text: category.name });
        });
      },
      deep: true,
    },

    filterGroupOptions: {
      handler(val) {
        this.groupIds = val;
      },
      deep: true,
    },

    filterCategoryOptions: {
      handler(val) {
        this.categoryIds = val;
      },
      deep: true,
    },

    filterWordOptions: {
      handler(val) {
        this.wordIds = val;
      },
      deep: true,
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();

    if (!this.isPartner()) {
      await this.fetchGroup([{ key: "limit", value: 9999 }]);
    } else {
      await this.fetchGroup([
        { key: "limit", value: 9999 },
        { key: "partnerId", value: this.user.partner.id },
      ]);
    }

    await this.fetchCategory();
    await this.fetchWord();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "whatsappGroupWordSearchSummary/fetch",
      delete: "whatsappGroupWordSearchSummary/delete",
      changeFilter: "whatsappGroupWordSearchSummary/changeFilter",
      changeFilters: "whatsappGroupWordSearchSummary/changeFilters",

      fetchGroup: "whatsappGroup/changeFilters",
      fetchCategory: "whatsappGroupWordSearchSummaryWordCategory/fetch",
      fetchWord: "whatsappGroupWordSearchSummaryWord/fetch",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async onDateSelected(daterange) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let startAt = new Date(daterange.start);
      let endAt = new Date(daterange.end);
      const startCreatedAt = `${startAt.getUTCFullYear()}-${startAt.getUTCMonth() +
        1}-${startAt.getUTCDate()}`;
      const endCreatedAt = `${endAt.getUTCFullYear()}-${endAt.getUTCMonth() +
        1}-${endAt.getUTCDate()}`;

      this.changeFilters([
        { key: "startAt", value: startCreatedAt },
        { key: "endAt", value: endCreatedAt },
      ]);

      document.getElementsByClassName(
        "input-date"
      )[0].innerHTML = `${startAt.getUTCDate()} ${
        months[startAt.getUTCMonth()]
      } ${startAt.getUTCFullYear()} - ${endAt.getUTCDate()} ${
        months[endAt.getUTCMonth()]
      } ${endAt.getUTCFullYear()}`;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Pilih Tanggal</label>
                    <vue-rangedate-picker @selected="onDateSelected" i18n="ID">
                    </vue-rangedate-picker>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="">Pilih Grup</label>
                    <Select2
                      v-model="filterGroupOptions"
                      :options="groupsOptions"
                      :settings="{ multiple: true }"
                      style="width: 100%"
                    />
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="">Pilih Kategori</label>
                    <Select2
                      v-model="filterCategoryOptions"
                      :options="categoriesOptions"
                      :settings="{ multiple: true }"
                      style="width: 100%"
                    />
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="">Pilih Kata Kata</label>
                    <Select2
                      v-model="filterWordOptions"
                      :options="wordsOptions"
                      :settings="{ multiple: true }"
                      style="width: 100%"
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY") }}
                  </p>
                </template>

                <template #cell(group)="data">
                  <p>
                    {{
                      data.item.whatsappGroup
                        ? data.item.whatsappGroup.name
                        : "-"
                    }}
                  </p>
                </template>

                <template #cell(category)="data">
                  <p>
                    {{ data.item.category ? data.item.category.name : "-" }}
                  </p>
                </template>

                <template #cell(word)="data">
                  <p>
                    {{ data.item.word ? data.item.word.word : "-" }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name:
                          'edit-whatsapp-group-word-search-summary-word-category',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteUserRegion(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.select2-container {
  width: 100% !important;
}
</style>
