<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Buat Giveaway",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    fasilitators() {
      return this.$store.getters["user/users"];
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      categories: "category/categories",
      batchs: "batch/batchs",
      modules: "module/modules",
      user: "auth/currentUser",
      mentors: "user/users",
      surveys: "survey/surveys",
      winnerTypes: "giveaway/winnerTypes",
      users: "user/users",
    }),
  },

  components: { Layout, PageHeader, Select2 },

  watch: {
    "form.courseIds": {
      async handler(val) {
        this.batchOptions = [];

        val.forEach((id) => {
          const index = this.courses.findIndex((course) => course.id === id);

          this.courses[index].batchs.forEach((batch) => {
            this.batchOptions.push({
              id: batch.id,
              text: `${batch.name} ${this.courses[index].title} -
                        ${this.$options.filters.moment(
                          batch.startDate,
                          "DD MMMM YYYY"
                        )}
                        s/d
                        ${this.$options.filters.moment(
                          batch.finishDate,
                          "DD MMMM YYYY"
                        )}`,
            });
          });
        });

        await this.fetchUser([
          { key: "courseIds", value: this.form.courseIds.join(",") },
          { key: "surveyIds", value: this.form.surveyIds.join(",") },
          { key: "batchIds", value: this.form.batchIds.join(",") },
          { key: "limit", value: 99999999 },
        ]);
      },
      deep: true,
    },

    "form.batchIds": {
      async handler() {
        await this.fetchUser([
          { key: "courseIds", value: this.form.courseIds.join(",") },
          { key: "surveyIds", value: this.form.surveyIds.join(",") },
          { key: "batchIds", value: this.form.batchIds.join(",") },
          { key: "limit", value: 99999999 },
        ]);
      },
      deep: true,
    },

    "form.surveyIds": {
      async handler() {
        await this.fetchUser([
          { key: "courseIds", value: this.form.courseIds.join(",") },
          { key: "surveyIds", value: this.form.surveyIds.join(",") },
          { key: "batchIds", value: this.form.batchIds.join(",") },
          { key: "limit", value: 99999999 },
        ]);
      },
      deep: true,
    },

    users: {
      handler(val) {
        this.userOptions = [];

        val.forEach((user) => {
          this.userOptions.push({
            id: user.id,
            text: `${user.firstName} ${user.lastName}`,
          });
        });
      },
      deep: true,
    },
  },

  data() {
    return {
      title: this.$route.params.id ? "Ubah Giveaway" : "Buat Giveaway",
      id: this.$route.params.id,
      categoriesOptions: [],
      items: [
        {
          text: "Giveaway",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        batchIds: [],
        courseIds: [],
        surveyIds: [],
        name: "",
        descriptionWaiting: "",
        descriptionFinish: "",
        finishedAt: "",
        banner: "",
        typeWinner: "",
        gifts: [
          {
            name: "",
            totalWinner: 0,
            winnerIds: [],
          },
        ],
      },
      submitted: false,
      errors: {},
      batchOptions: [],
      courseOptions: [],
      surveyOptions: [],
      userOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchBatch: "batch/fetch",
      fetchCourse: "course/fetch",
      fetchSurvey: "survey/fetch",
      detail: "giveaway/detail",
      create: "giveaway/create",
      update: "giveaway/update",

      fetchUser: "user/changeFilters",
    }),

    successCopy() {
      alert("Berhasil menyalin jawaban");
    },

    getRole() {
      return this.user.role;
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit() {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        if (this.form.typeWinner === "MANUAL") {
          this.form.gifts.forEach((gift) => {
            gift.totalWinner = gift.winnerIds.length;
          });
        }

        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-giveaway" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadBanner(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.banner = e.target.result;
      };
    },
  },

  async mounted() {
    await this.fetchCourse();
    await this.fetchSurvey();
    await this.fetchBatch();

    this.courseOptions = this.courses.map((course) => {
      return { id: course.id, text: course.title };
    });

    this.surveyOptions = this.surveys.map((survey) => {
      return { id: survey.id, text: survey.name };
    });

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);

      if (this.form.surveys) {
        this.form.surveyIds = this.form.surveys.map((survey) => survey.id);
      }

      if (this.form.courses) {
        this.form.courseIds = this.form.courses.map((course) => course.id);
      }
      if (this.form.batchs) {
        this.form.batchIds = this.form.batchs.map((batch) => batch.id);
      }

      this.form.finishedAt = this.$options.filters.moment(
        this.form.finishedAt,
        "YYYY-MM-DDTHH:mm"
      );

      this.form.gifts.forEach((gift) => {
        gift.winnerIds = gift.winners.map((winner) => winner.id);
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="name"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="finishedAt">Selesai Pada Tanggal</label>
                    <input
                      id="finishedAt"
                      v-model="form.finishedAt"
                      type="datetime-local"
                      class="form-control"
                      placeholder="finishedAt"
                      :class="{
                        'is-invalid': errors['finishedAt'],
                      }"
                    />
                    <div v-if="errors['finishedAt']" class="invalid-feedback">
                      <span>{{ errors["finishedAt"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="winnerType">Syarat Pemanang Giveaway</label>
                    <select
                      name="winnerType"
                      v-model="form.typeWinner"
                      id=""
                      class="form-control"
                    >
                      <option value="">Pilih Syarat Pemenang Giveaway</option>
                      <option
                        :value="winnerType.key"
                        :key="winnerType.key"
                        v-for="winnerType in winnerTypes"
                        >{{ winnerType.text }}</option
                      >
                    </select>
                    <div v-if="errors['winnerType']" class="invalid-feedback">
                      <span>{{ errors["winnerType"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="course">Kelas</label>
                    <Select2
                      v-model="form.courseIds"
                      :options="courseOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="batch">Batch Kelas</label>
                    <Select2
                      v-model="form.batchIds"
                      :options="batchOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['batchId']" class="invalid-feedback">
                      <span>{{ errors["batchId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="survey">Survey</label>
                    <Select2
                      v-model="form.surveyIds"
                      :options="surveyOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['surveyIds']" class="invalid-feedback">
                      <span>{{ errors["surveyIds"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>No</th>
                        <th>Kode</th>
                        <th>Deskripsi</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>{kelas}</td>
                        <td>Menampilkan nama kelas</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>{countdown}</td>
                        <td>Menampilkan countdown giveaway</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>{prizetable}</td>
                        <td>Menampilkan table daftar hadiah dan pemenang</td>
                      </tr>
                    </table>

                    <label for="descriptionWaiting"
                      ><b>Teks Ketika Giveaway Masih Berjalan</b></label
                    >
                    <quill-editor
                      v-model="form.descriptionWaiting"
                      style="height: 200px"
                    />
                    <div
                      v-if="errors['descriptionWaiting']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["descriptionWaiting"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <label for="descriptionFinish"
                      ><b>Teks Ketika Giveaway Sudah Selesai</b></label
                    >
                    <quill-editor
                      v-model="form.descriptionFinish"
                      style="height: 200px"
                    />
                    <div
                      v-if="errors['descriptionFinish']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["descriptionFinish"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="banner">Banner</label>
                    <input
                      id="banner"
                      type="file"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['banner'],
                      }"
                      @change="uploadBanner"
                    />
                  </div>
                  <img
                    class="mb-3"
                    :src="form.banner | media"
                    width="300"
                    alt=""
                  />
                </div>

                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <label for="descriptionFinish"><b>Hadiah</b></label>
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>No</th>
                        <th>Hadiah</th>
                        <th v-if="form.typeWinner !== 'MANUAL'">
                          Jumlah Pemenang
                        </th>
                        <th v-if="form.typeWinner === 'MANUAL'">Pemenang</th>
                        <th>Action</th>
                      </tr>
                      <tr v-for="(gift, index) in form.gifts" :key="index">
                        <th>{{ index + 1 }}</th>
                        <th>
                          <input
                            v-model="gift.name"
                            type="text"
                            class="form-control"
                            placeholder="Nama Hadiah"
                            :class="{
                              'is-invalid': errors['giftName'],
                            }"
                          />
                        </th>
                        <th v-if="form.typeWinner !== 'MANUAL'">
                          <input
                            v-model="gift.totalWinner"
                            type="number"
                            class="form-control"
                            placeholder="Total Pemenang"
                            :class="{
                              'is-invalid': errors['totalWinner'],
                            }"
                          />
                        </th>
                        <th v-if="form.typeWinner === 'MANUAL'" width="50%">
                          <Select2
                            v-model="gift.winnerIds"
                            :options="userOptions"
                            :settings="{ multiple: true }"
                          />
                        </th>
                        <th>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="form.gifts.splice(index, 1)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </th>
                      </tr>
                      <tr>
                        <th colspan="3">
                          <button
                            class="btn btn-primary form-control btn-sm"
                            type="button"
                            @click="
                              form.gifts.push({ name: '', totalWinner: 1 })
                            "
                          >
                            Tambah Hadiah
                          </button>
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary form-control"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.answered {
  width: 100%;
  border: 1px solid #efefef;
  padding: 15px;
}

.select2-container {
  width: 100%;
}
</style>
