import ratingOptionApi from "../../api/rating-option.api";
import filterDefault from "../../helpers/filter";

export const state = {
  ratingOptions: [],
  ratingOption: {},
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  ratingOptions(state) {
    return state.ratingOptions;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_RATING_OPTIONS(state, ratingOptions) {
    state.ratingOptions = ratingOptions;
  },

  ADD_RATING_OPTION(state, ratingOption) {
    state.ratingOptions.unshift(ratingOption);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_RATING_OPTION(state, id) {
    state.ratingOptions.splice(
      state.ratingOptions.findIndex((ratingOption) => ratingOption.id === id),
      1
    );
  },

  SET_RATING_OPTION(state, ratingOption) {
    state.ratingOption = ratingOption;
  },

  CHANGE_RATING_OPTION(state, { id, ratingOption }) {
    state.ratingOptions[
      state.ratingOptions.findIndex((ratingOption) => ratingOption.id === id)
    ] = ratingOption;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await ratingOptionApi.fetch(state.filter);

      commit("SET_RATING_OPTIONS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await ratingOptionApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_RATING_OPTION", data.data);
    dispatch("ratingOption/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await ratingOptionApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("ratingOption/success", data.message, { root: true });
    commit("REMOVE_RATING_OPTION", id);

    return { status: true };
  },

  async detail({ dispatch }, id) {
    const { data } = await ratingOptionApi.detail(id);

    dispatch("SET_RATING_OPTION", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await ratingOptionApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("ratingOption/success", data.message, { root: true });
    commit("CHANGE_RATING_OPTION", { id, ratingOption: data.data });

    return { status: true };
  },
};
