<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Log component
 */
export default {
  page: {
    title: "Master Log",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Log",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Log",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "user", label: "Pengguna" },
        { key: "description", label: "Deskripsi" },
        { key: "ip", label: "IP" },
        { key: "browser", label: "Browser" },
        { key: "platform", label: "Platform" },
        { key: "version", label: "Versi" },
        { key: "type", label: "Tipe" },
        { key: "url", label: "URL" },
        { key: "course", label: "Kelas" },
        { key: "courseModule", label: "Modul" },
        { key: "courseModuleItem", label: "Submodul" },
        { key: "giveaway", label: "Giveaway" },
        { key: "survey", label: "Survey" },
        { key: "createdAt", label: "Dibuat" },
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["log/logs"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["log/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["log/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["log/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    courseId: {
      get() {
        return this.$store.getters["log/filter"].courseId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "courseId", value: val });
        this.loading = false;
      },
    },

    mentorId: {
      get() {
        return this.$store.getters["log/filter"].mentorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "mentorId", value: val });
        this.loading = false;
      },
    },

    fasilitatorId: {
      get() {
        return this.$store.getters["log/filter"].fasilitatorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "fasilitatorId", value: val });
        this.loading = false;
      },
    },

    status: {
      get() {
        return this.$store.getters["log/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      users: "user/users",
      statuses: "log/statuses",
      modules: "module/modules",
      total: "log/total",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "log/fetch",
      changeFilter: "log/changeFilter",
    }),

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <!-- <div class="form-group">
                        <label for=""></label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(url)="data">
                  <a
                    v-if="data.item.url"
                    :href="`https://kelas.bolu.id${data.item.url}`"
                  >
                    https://kelas.bolu.id{{ data.item.url }}
                  </a>
                  <p v-else>-</p>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(user)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(survey)="data">
                  <p v-if="data.item.survey">
                    {{ data.item.survey.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(giveaway)="data">
                  <p v-if="data.item.giveaway">
                    {{ data.item.giveaway.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(courseModuleItem)="data">
                  <p v-if="data.item.courseModuleItem">
                    {{ data.item.courseModuleItem.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(courseModule)="data">
                  <p v-if="data.item.courseModule">
                    {{ data.item.courseModule.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(type)="data">
                  {{ data.item.type | titleCase }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
