<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Template Absensi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Template Absensi"
        : "Tambah Template Absensi",
      id: this.$route.params.id,
      items: [
        {
          text: "Template Absensi",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        text: "",
        category: "MORNING",
        isActive: true,
        partnerId: "",
      },
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "whatsappGroupGreetingTemplate/create",
      detail: "whatsappGroupGreetingTemplate/detail",
      update: "whatsappGroupGreetingTemplate/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-whatsapp-group-greeting-template" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
    }

    if (this.isPartner()) this.form.partnerId = this.user.partner.id;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama *)</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="category">Kategori *)</label>
                    <select
                      id="category"
                      v-model="form.category"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['category'],
                      }"
                    >
                      <option value="MORNING">Pagi</option>
                      <option value="NIGHT">Malam</option>
                    </select>
                    <div v-if="errors['category']" class="invalid-feedback">
                      <span>{{ errors["category"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="isActive">Apakah template ini aktif? *)</label>
                    <select
                      id="isActive"
                      v-model="form.isActive"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['isActive'],
                      }"
                    >
                      <option :value="true">Iya Aktif</option>
                      <option :value="false">TIdak Aktif</option>
                    </select>
                    <div v-if="errors['isActive']" class="invalid-feedback">
                      <span>{{ errors["isActive"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="text">Teks</label>
                    <textarea
                      id="text"
                      v-model="form.text"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['text'],
                      }"
                    />
                    <div v-if="errors['text']" class="invalid-feedback">
                      <span>{{ errors["text"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
