import api from "./index";

const baseURL = "/regions";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const fetchProvinces = (params) => api.get(`${baseURL}/provinces`, { params });

const fetchCities = (params) => api.get(`${baseURL}/cities`, { params });

export default { fetch, fetchProvinces, fetchCities };
