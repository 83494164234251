<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Giveaway component
 */
export default {
  page: {
    title: "Master Giveaway",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Giveaway",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Giveaway",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nama" },
        { key: "slug", label: "Link" },
        { key: "courses", label: "Kelas" },
        { key: "batchs", label: "Batch" },
        { key: "surveys", label: "Survey" },
        { key: "partnershipPartner", label: "Partner" },
        { key: "finishedAt", label: "Selesai Pada" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["giveaway/giveaways"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courseId: {
      get() {
        return this.$store.getters["giveaway/filter"].courseId;
      },
      set(val) {
        this.fetchBatch({ key: "courseId", value: val });
        this.changeFilter({ key: "courseId", value: val });
      },
    },

    batchId: {
      get() {
        return this.$store.getters["giveaway/filter"].batchId;
      },
      set(val) {
        this.changeFilter({ key: "batchId", value: val });
      },
    },

    surveyId: {
      get() {
        return this.$store.getters["giveaway/filter"].surveyId;
      },
      set(val) {
        this.changeFilter({ key: "surveyId", value: val });
      },
    },

    search: {
      get() {
        return this.$store.getters["giveaway/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["giveaway/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["giveaway/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    status: {
      get() {
        return this.$store.getters["giveaway/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      users: "user/users",
      statuses: "giveaway/statuses",
      total: "giveaway/total",
      batchs: "batch/batchs",
      surveys: "survey/surveys",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;

    await this.fetchCourse();
    await this.fetchSurvey();
  },
  methods: {
    ...mapActions({
      fetch: "giveaway/fetch",
      changeFilter: "giveaway/changeFilter",
      delete: "giveaway/delete",

      fetchBatch: "batch/changeFilter",
      fetchCourse: "course/fetch",
      fetchSurvey: "survey/fetch",
    }),

    deleteGiveaway(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <router-link :to="{ name: 'create-giveaway' }">
                        <b-button class="mr-3" variant="primary" size="sm"
                          ><i class="mdi mdi-plus mr-2"></i> Tambah
                          Giveaway</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </h4>

            <hr />

            <!-- <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3">
                  <div class="row">
                    <label for="courseId">Kelas</label>
                    <select
                      name="courseId"
                      id="courseId"
                      class="form-control"
                      v-model="courseId"
                    >
                      <option value="">Semua Kelas</option>
                      <option
                        :value="course.id"
                        :key="course.id"
                        v-for="course in courses"
                        >{{ course.title }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="col-lg-3 ml-4">
                  <div class="row">
                    <label for="batchId">Batch Kelas</label>
                    <select
                      name="batchId"
                      id="batchId"
                      class="form-control"
                      v-model="batchId"
                    >
                      <option value="">Semua Batch Kelas</option>
                      <option
                        :value="batch.id"
                        :key="batch.id"
                        v-for="batch in batchs"
                        >{{ batch.name }} -
                        {{ batch.startDate | moment("DD MMMM YYYY") }}
                        s/d
                        {{ batch.finishDate | moment("DD MMMM YYYY") }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="col-lg-3 ml-4">
                  <div class="row">
                    <label for="surveyId">Survey</label>
                    <select
                      name="surveyId"
                      id="surveyId"
                      class="form-control"
                      v-model="surveyId"
                    >
                      <option value="">Semua Survey</option>
                      <option
                        :value="survey.id"
                        :key="survey.id"
                        v-for="survey in surveys"
                        >{{ survey.name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                @filtered="onFiltered"
              >
                <template #cell(courses)="data">
                  <p v-if="data.item.courses">
                    <span v-for="course in data.item.courses" :key="course.id"
                      >{{ course.title }},
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(partnershipPartner)="data">
                  <p v-if="data.item.partnershipPartner">
                    {{ data.item.partnershipPartner.name }}
                  </p>
                </template>

                <template #cell(surveys)="data">
                  <p v-if="data.item.surveys">
                    <span v-for="survey in data.item.surveys" :key="survey.id"
                      >{{ survey.name }},
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(batchs)="data">
                  <p v-if="data.item.batchs">
                    <span v-for="batch in data.item.batchs" :key="batch.id"
                      >{{ batch.name }}
                      {{ batch.startDate | moment("DD MMMM YYYY") }} s/d
                      {{ batch.finishDate | moment("DD MMMM YYYY") }},
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(slug)="data">
                  <a
                    v-if="data.item.slug"
                    :href="`https://kelas.bolu.id/giveaway/${data.item.slug}`"
                  >
                    https://kelas.bolu.id/giveaway/{{ data.item.slug }}
                  </a>
                  <p v-else>-</p>
                </template>

                <template #cell(finishedAt)="data">
                  <p v-if="data.item.finishedAt">
                    {{
                      data.item.finishedAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(survey)="data">
                  <p v-if="data.item.survey">
                    {{ data.item.survey.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(batch)="data">
                  <p v-if="data.item.batch">
                    {{ data.item.batch.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(questioner)="data">
                  <p v-if="data.item.questioner">
                    {{ data.item.questioner }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(module)="data">
                  <p v-if="data.item.module">
                    {{ data.item.module.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(isStar)="data">
                  <p v-if="data.item.isStar">
                    1
                  </p>
                  <p v-else>0</p>
                </template>

                <template #cell(status)="data">
                  {{ data.item.status | filterGiveawayState }}
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="warning"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'winner-giveaway',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-giveaway',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteGiveaway(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
