<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("auth", {
      setCurrentUser: "setCurrentUser",
    }),
  },
  async mounted() {
    const token =
      this.$route.query.access_token ||
      localStorage.getItem("auth.currentUser");

    if (!token) {
      return this.$router.push({ path: "/" });
    }

    const response = await this.setCurrentUser(token);
    localStorage.setItem("auth.currentUser", token);

    if (!response.success) {
      console.log("Login failed");
      return this.$router.push({ path: "/login" });
    } else if (
      response.roles.findIndex((role) => role.name === "ADMIN") == -1
    ) {
      return this.$router.push({ path: "/login" });
    }

    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  },
};
</script>

<template>
  <div>
    <p>Please wait a moment while we complete your login process</p>
  </div>
</template>
