import forumRegionApi from "../../api/forum-region.api";
import filterDefault from "../../helpers/filter";

export const state = {
  forumRegions: [],
  forumRegion: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  forumRegions(state) {
    return state.forumRegions;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_FORUM_REGIONS(state, forumRegions) {
    state.forumRegions = forumRegions;
  },

  ADD_FORUM_REGION(state, forumRegion) {
    state.forumRegions.unshift(forumRegion);
  },

  MERGE_FORUM_REGIONS(state, forumRegions) {
    state.forumRegions = forumRegions.concat(state.forumRegions);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_FORUM_REGION(state, id) {
    state.forumRegions.splice(
      state.forumRegions.findIndex((forumRegion) => forumRegion.id === id),
      1
    );
  },

  SET_FORUM_REGION(state, forumRegion) {
    state.forumRegion = forumRegion;
  },

  CHANGE_FORUM_REGION(state, { id, forumRegion }) {
    state.forumRegions[
      state.forumRegions.findIndex((forumRegion) => forumRegion.id === id)
    ] = forumRegion;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await forumRegionApi.fetch(state.filter);

      commit("SET_FORUM_REGIONS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const payloadData = Object.assign(payload);
    payloadData.cityIds = payloadData.cityIds.map((city) => city.id);

    const { data } = await forumRegionApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_FORUM_REGION", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await forumRegionApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_FORUM_REGION", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await forumRegionApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const payloadData = Object.assign(payload);
    payloadData.cityIds = payloadData.cityIds.map((city) => city.id);

    const { data } = await forumRegionApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_FORUM_REGION", { id, forumRegion: data.data });

    return { status: true };
  },
};
