<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueRangedatePicker from "vue-rangedate-picker";

import { mapActions } from "vuex";

/**
 * Master whatsappGroup component
 */
export default {
  page: {
    title: "Whatsapp Group",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueRangedatePicker },
  data() {
    return {
      title: "Whatsapp Group",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Whatsapp Group",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "date", label: "Tanggal", sortable: true },
        { key: "groupName", label: "Grup" },
        { key: "totalChat", label: "Chat" },
        { key: "totalChatMember", label: "Chat Member" },
        { key: "totalActiveUser", label: "Pengguna Aktif" },
        { key: "totalJoin", label: "Join" },
        { key: "totalLeave", label: "Leave" },
        { key: "totalReadMorning", label: "Dilihat Pagi" },
        { key: "totalReadNight", label: "Dilihat Malam" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
      tableData: [],
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const date = new Date();
    let startDateData = new Date(date.getFullYear(), date.getMonth(), 2);
    let endDateData = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const startDate = `${startDateData.getUTCFullYear()}-${startDateData.getUTCMonth() +
      1}-${startDateData.getUTCDate()}`;
    const endDate = `${endDateData.getUTCFullYear()}-${endDateData.getUTCMonth() +
      1}-${endDateData.getUTCDate()}`;

    document.getElementsByClassName("input-date")[0].style.width = "100%";
    document.getElementsByClassName(
      "input-date"
    )[0].innerHTML = `${startDateData.getUTCDate()} ${
      months[startDateData.getUTCMonth()]
    } ${startDateData.getUTCFullYear()} - ${endDateData.getUTCDate()} ${
      months[endDateData.getUTCMonth()]
    } ${endDateData.getUTCFullYear()}`;

    this.tableData = await this.getResumeActivity({ startDate, endDate });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getResumeActivity: "whatsappGroup/getResumeActivity",
    }),

    async onDateSelected(daterange) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let startDate = new Date(daterange.start);
      let endDate = new Date(daterange.end);
      startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() +
        1}-${startDate.getUTCDate()}`;
      endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() +
        1}-${endDate.getUTCDate()}`;
      this.loading = true;
      this.tableData = await this.getResumeActivity({ startDate, endDate });
      this.loading = false;

      document.getElementsByClassName(
        "input-date"
      )[0].innerHTML = `${startDate.getUTCDate()} ${
        months[startDate.getUTCMonth()]
      } ${startDate.getUTCFullYear()} - ${endDate.getUTCDate()} ${
        months[endDate.getUTCMonth()]
      } ${endDate.getUTCFullYear()}`;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="col-6">
              <div class="form-group">
                <label for="">Pilih Tanggal</label>
                <vue-rangedate-picker @selected="onDateSelected" i18n="ID">
                </vue-rangedate-picker>
              </div>
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY") }}
                  </p>
                </template>

                <template #cell(host)="data">
                  <p v-if="data.item.host">
                    {{ data.item.host.number }}
                  </p>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.title }}
                  </p>
                </template>

                <template #cell(link)="data">
                  <a :href="data.item.link" v-if="data.item.link">Buka Link</a>
                  <p v-else>-</p>
                </template>

                <template #cell(isActive)="data">
                  <p>{{ data.item.isActive | convertBoolean }}</p>
                </template>

                <template #cell(courseBatch)="data">
                  <p v-if="data.item.courseBatch">
                    {{ data.item.courseBatch.name }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-whatsapp-group',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteUserRegion(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
