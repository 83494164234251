<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Font from "./font.vue";
import { mapActions, mapGetters } from "vuex";

import { fabric } from "fabric";

export default {
  page: {
    title: "Buat Sertifikat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      courses: "course/courses",
      certifications: "certification/certifications",
    }),
  },
  components: { Layout, PageHeader, Font },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Sertifikat" : "Buat Sertifikat",
      id: this.$route.params.id,
      items: [
        {
          text: "Sertifikat",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        courseId: "",
        canvas: "",
      },
      submitted: false,
      errors: {},
      canvas: null,
      oldData: null,
      objectSelect: null,
      typeSelect: "",
      option: {
        fontSize: "",
        textAlign: "",
        fontFamily: "",
      },

      font: "",
      fonts: [
        "Montserrat-Black",
        "Montserrat-Bold",
        "Montserrat-Regular",
        "Montserrat-Thin",
        "Montserrat-Light",
        "OpenSans-Light",
      ],
      loading: false,
    };
  },

  watch: {
    font(val) {
      this.objectSelect.fontFamily = val;
      this.canvas.renderAll();
      this.saveCanvas();
    },

    // "option.fontSize"(val) {
    //   this.objectSelect.fontSize = val;
    //   this.canvas.renderAll();
    //   this.saveCanvas();
    // },
  },

  methods: {
    ...mapActions({
      create: "certification/create",
      update: "certification/update",
      detail: "certification/detail",

      fetchCourse: "course/fetch",
      fetch: "certification/fetch",
    }),

    changeOption() {
      try {
        Object.keys(this.option).forEach((opt) => {
          this.objectSelect[opt] = this.option[opt];
        });
      } catch (error) {
        console.log(error);
      }
      this.canvas.renderAll();
      this.saveCanvas();
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-certification" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },

    async addText() {
      this.canvas.add(
        new fabric.Textbox("Ketik untuk merubah...", {
          left: 0,
          top: 0,
          fill: "black",
        })
      );

      this.saveCanvas();
    },

    addImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      const self = this;
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        setTimeout(() => {
          fabric.Image.fromURL(e.target.result, function(img) {
            img.scaleToWidth(200);
            img.scaleToHeight(200);
            img.set({ left: 0, top: 0 });
            self.canvas.add(img);
          });
        }, 100);
      };
    },

    reset() {
      this.canvas.clear();
    },

    restore() {
      try {
        const self = this;
        this.canvas.loadFromJSON(JSON.parse(this.form.canvas), function() {
          self.canvas.renderAll();
        });
      } catch (error) {
        console.log(error);
      }
    },

    saveCanvas() {
      this.form.canvas = JSON.stringify(this.canvas.toJSON());
    },

    async onObjectSelected(e) {
      try {
        await Object.keys(e.target).forEach((opt) => {
          this.option[opt] = e.target[opt];
        });
      } catch (error) {
        console.log(error);
      }
      console.log(e.target.type);

      if (e.target) {
        if (e.target.type === "i-text") {
          e.target.type = "textbox";
        }

        this.objectSelect = e.target;
        this.typeSelect = e.target.type;
      }

      this.saveCanvas();
    },

    removeObject() {
      this.canvas.remove(this.objectSelect);
    },

    async copyCertificate(e) {
      const data = await this.detail(e.target.value);

      this.form.canvas = data.canvas;
      this.restore();
    },
  },

  async mounted() {
    await this.fetchCourse();

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
      this.form.courseId = this.form.course.id;
    }

    const ref = this.$refs.can;
    this.canvas = new fabric.Canvas(ref);
    this.canvas.on("selection:updated", this.onObjectSelected);
    this.canvas.on("selection:created", this.onObjectSelected);
    this.canvas.on("selection:cleared", this.onObjectSelected);
    this.canvas.on("object:moving", this.onObjectSelected);
    this.canvas.on("object:modified", this.onObjectSelected);

    if (this.$route.params.id) {
      this.restore();
    }

    window.addEventListener("click", (e) => {
      if (!["CANVAS", "SELECT", "BUTTON"].includes(e.target.tagName)) {
        this.canvas.discardActiveObject().renderAll();
      }
    });

    this.fetch();
  },
};
</script>

<template>
  <Layout>
    <Font></Font>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="courseId">Kelas</label>
                    <select
                      name="courseId"
                      id="courseId"
                      class="form-control"
                      v-model="form.courseId"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="certificationId"
                      >Salin Sertifikat Dari Kelas</label
                    >
                    <select
                      name="certificationId"
                      id="certificationId"
                      class="form-control"
                      @change="copyCertificate"
                    >
                      <option value=""
                        >Pilih kelas untuk salin sertifikat</option
                      >
                      <option
                        :value="certification.id"
                        v-for="certification in certifications"
                        :key="certification.id"
                        >{{ certification.course.title }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="certification">Sertifikat</label>

                    <div class="col-lg-12 mt-3">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <button
                              class="btn btn-primary btn-sm mr-2"
                              type="button"
                              @click="addText"
                            >
                              Tambah Tulisan
                            </button>

                            <label
                              for="image"
                              class="m-0 btn btn-warning btn-sm"
                            >
                              Tambah Gambar
                            </label>
                            <input
                              type="file"
                              name="image"
                              id="image"
                              @change="addImage"
                              style="display: none;"
                            />

                            <button
                              class="btn btn-danger btn-sm ml-2"
                              type="button"
                              @click="removeObject"
                              v-if="objectSelect"
                            >
                              Hapus
                            </button>
                          </div>
                        </div>

                        <div class="col-lg-12" v-if="typeSelect !== 'image'">
                          <div class="row mt-3">
                            <div class="col-lg-2">
                              <label for="">Jenis Font</label>
                              <select
                                class="form-control"
                                v-model="option.fontFamily"
                                @change="changeOption"
                              >
                                <option value="">Pilih Font</option>
                                <option
                                  :value="font"
                                  v-for="font in fonts"
                                  :key="font"
                                  >{{ font }}</option
                                >
                              </select>
                            </div>

                            <div class="col-lg-2">
                              <label for="">Ukuran Font</label>
                              <select
                                class="form-control"
                                v-model="option.fontSize"
                                @change="changeOption"
                              >
                                <option value="">Pilih Ukuran Font</option>
                                <option
                                  :value="font"
                                  v-for="font in 64"
                                  :key="font"
                                  >{{ font }}</option
                                >
                              </select>
                            </div>

                            <div class="col-lg-2">
                              <label for="">Text Align</label>
                              <select
                                class="form-control"
                                v-model="option.textAlign"
                                @change="changeOption"
                              >
                                <option value="">Pilih Text Align</option>
                                <option
                                  :value="font"
                                  v-for="font in [
                                    'left',
                                    'center',
                                    'right',
                                    'justify',
                                  ]"
                                  :key="font"
                                  >{{ font }}</option
                                >
                              </select>
                            </div>
                          </div>
                        </div>

                        <div
                          class="form-group"
                          style="width: 100%; display: flex; flex-flow: column;"
                        >
                          <div
                            style="border: 1px solid #777777; width: 850px"
                            class="mt-3"
                          >
                            <canvas ref="can" width="850" height="600"></canvas>
                          </div>

                          <table
                            class="ml-3 mt-3 table table-bordered tabler-hover"
                          >
                            <thead>
                              <tr>
                                <th>Kode</th>
                                <th>Deskripsi</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>{name}</td>
                                <td>Untuk menampilkan nama peserta</td>
                              </tr>
                              <tr>
                                <td>{number}</td>
                                <td>Untuk menampilkan nomer sertifikat</td>
                              </tr>
                              <tr>
                                <td>{course}</td>
                                <td>Untuk menampilkan nama kelas</td>
                              </tr>
                              <tr>
                                <td>{finishDate}</td>
                                <td>
                                  Untuk menampilkan tanggal selesai mengikuti
                                  kelas
                                </td>
                              </tr>
                              <tr>
                                <td>{batchDate}</td>
                                <td>
                                  Untuk menampilkan tanggal batch kelas
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                :disabled="loading"
                class="btn btn-primary form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
