import api from "./index";

const baseURL = "/notification-blasts";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

export default { fetch, detail, create };
