import userApi from "../../api/user.api";
import filterDefault from "../../helpers/filter";

export const state = {
  users: [],
  user: {},
  total: 0,
  filter: {
    ...filterDefault,
    roles: "MEMBER",
    groupId: "",
    utms: "",
    courseIds: [],
  },
};

export const getters = {
  users(state) {
    return state.users;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  MERGE_USER(state, users) {
    state.users = users.concat(state.users);
  },

  ADD_USER(state, user) {
    state.users.unshift(user);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_USER(state, id) {
    state.users.splice(
      state.users.findIndex((user) => user.id === id),
      1
    );
  },

  SET_USER(state, user) {
    state.user = user;
  },

  CHANGE_USER(state, { id, user }) {
    state.users[state.users.findIndex((user) => user.id === id)] = user;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => {
      const { key, value } = filter;

      commit("SET_FILTER", { key, value });
    });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      commit("SET_USERS", []);
      const { data } = await userApi.fetch(state.filter);

      data.data.data.map(
        (user) => (user.name = `${user.firstName} ${user.lastName}`)
      );

      commit("SET_USERS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async store({ commit, dispatch }, payload) {
    const { data } = await userApi.store(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_USER", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await userApi.deleteUser(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_USER", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await userApi.getUser(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await userApi.updateUser(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_USER", { id, user: data.data });

    return { status: true };
  },

  async importUser({ dispatch, commit }, payload) {
    const { data } = await userApi.importUser(payload);

    data.data.data.map(
      (user) => (user.name = `${user.firstName} ${user.lastName}`)
    );

    dispatch("notification/success", data.message, { root: true });
    commit("MERGE_USER", data.data.data);

    return { status: true, process: data.data.process };
  },

  async exportUsers({ state }) {
    const filter = Object.assign({}, state.filter);
    filter.limit = 9999999999;

    const { data } = await userApi.exportUsers(filter);

    return data;
  },
};
