<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Buat Event",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      categories: "partnershipItemCategory/partnershipItemCategories",
      partners: "partnershipPartner/partnershipPartners",
      courses: "course/courses",
      surveys: "survey/surveys",
      giveaways: "giveaway/giveaways",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Event" : "Buat Event",
      id: this.$route.params.id,
      items: [
        {
          text: "Event",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        linkDocument: "",
        categoryId: "",
        totalParticipantZoom: 0,
        totalParticipantInstagramLive: 0,
        totalViewYoutube: 0,
        courseId: "",
        giveawayId: "",
        surveyId: "",
        startDate: "",
        finishDate: "",
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "partnershipItem/create",
      update: "partnershipItem/update",
      detail: "partnershipItem/detail",

      fetchCategory: "partnershipItemCategory/changeFilter",
      fetchPartner: "partnershipPartner/changeFilter",
      fetchCourse: "course/changeFilter",
      fetchSurvey: "survey/changeFilter",
      fetchGiveaway: "giveaway/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-partnership-item" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    addItem() {
      this.form.items.push({
        name: "",
        revenue: 0,
        linkDocument: "",
        partnerId: "",
        categoryId: "",
        totalParticipantZoom: 0,
        totalParticipantInstagramLive: 0,
        totalViewYoutube: 0,
        courseId: "",
        giveawayId: "",
        surveyId: "",
        startDate: "",
        finishDate: "",
        linkEvent: "",
        linkAffiliate: "",
        linkContent: "",
        linkGroupWhatsapp: "",
        linkSurvey: "",
        linkZoom: "",
      });
    },
  },

  async mounted() {
    this.fetchCategory({ key: "ordering", value: "name" });
    this.fetchPartner({ key: "ordering", value: "name" });
    this.fetchCourse([
      { key: "ordering", value: "title" },
      { key: "limit", value: 99999999 },
    ]);
    this.fetchSurvey({ key: "ordering", value: "-created_at" });
    this.fetchGiveaway({ key: "ordering", value: "-created_at" });

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
      if (this.form.category) this.form.categoryId = this.form.category.id;
      if (this.form.course) this.form.courseId = this.form.course.id;
      if (this.form.giveaway) this.form.giveawayId = this.form.giveaway.id;
      if (this.form.survey) this.form.surveyId = this.form.survey.id;

      this.form.startDate = this.$options.filters.moment(
        this.form.startDate,
        "YYYY-MM-DD"
      );

      this.form.finishDate = this.$options.filters.moment(
        this.form.finishDate,
        "YYYY-MM-DD"
      );
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">Nama</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.name"
                        />
                        <div v-if="errors['name']" class="invalid-feedback">
                          <span>{{ errors["name"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="categoryId">Kategori</label>
                        <select class="form-control" v-model="form.categoryId">
                          <option value="">Pilih Kategori</option>
                          <option
                            v-for="category of categories"
                            :key="category.id"
                            :value="category.id"
                            >{{ category.name }}</option
                          >
                        </select>
                        <div
                          v-if="errors['categoryId']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["categoryId"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="startDate">Tanggal Mulai</label>
                        <input
                          id="startDate"
                          v-model="form.startDate"
                          type="date"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['startDate'],
                          }"
                        />
                        <div
                          v-if="errors['startDate']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["startDate"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="finishDate">Tanggal Selesai</label>
                        <input
                          id="finishDate"
                          v-model="form.finishDate"
                          type="date"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['finishDate'],
                          }"
                        />
                        <div
                          v-if="errors['finishDate']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["finishDate"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="totalParticipantZoom"
                          >Total Partisipasi Zoom</label
                        >
                        <input
                          id="totalParticipantZoom"
                          v-model="form.totalParticipantZoom"
                          type="number"
                          class="form-control"
                          placeholder="Revenue"
                          :class="{
                            'is-invalid': errors['totalParticipantZoom'],
                          }"
                        />
                        <div
                          v-if="errors['totalParticipantZoom']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["totalParticipantZoom"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="totalParticipantInstagramLive"
                          >Total Partisipasi IG Live</label
                        >
                        <input
                          id="totalParticipantInstagramLive"
                          v-model="form.totalParticipantInstagramLive"
                          type="number"
                          class="form-control"
                          placeholder="Revenue"
                          :class="{
                            'is-invalid':
                              errors['totalParticipantInstagramLive'],
                          }"
                        />
                        <div
                          v-if="errors['totalParticipantInstagramLive']"
                          class="invalid-feedback"
                        >
                          <span>{{
                            errors["totalParticipantInstagramLive"]
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="totalViewYoutube">Total View Youtube</label>
                        <input
                          id="totalViewYoutube"
                          v-model="form.totalViewYoutube"
                          type="number"
                          class="form-control"
                          placeholder="Revenue"
                          :class="{
                            'is-invalid': errors['totalViewYoutube'],
                          }"
                        />
                        <div
                          v-if="errors['totalViewYoutube']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["totalViewYoutube"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkEvent">Link Event</label>
                        <input
                          id="linkEvent"
                          v-model="form.linkEvent"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkEvent'],
                          }"
                        />
                        <div
                          v-if="errors['linkEvent']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkEvent"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkAffiliate">Link Affiliasi</label>
                        <input
                          id="linkAffiliate"
                          v-model="form.linkAffiliate"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkAffiliate'],
                          }"
                        />
                        <div
                          v-if="errors['linkAffiliate']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkAffiliate"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkContent">Link Konten</label>
                        <input
                          id="linkContent"
                          v-model="form.linkContent"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkContent'],
                          }"
                        />
                        <div
                          v-if="errors['linkContent']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkContent"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkGroupWhatsapp"
                          >Link Grup Whatsapp</label
                        >
                        <input
                          id="linkGroupWhatsapp"
                          v-model="form.linkGroupWhatsapp"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkGroupWhatsapp'],
                          }"
                        />
                        <div
                          v-if="errors['linkGroupWhatsapp']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkGroupWhatsapp"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkSurvey">Link Survey</label>
                        <input
                          id="linkSurvey"
                          v-model="form.linkSurvey"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkSurvey'],
                          }"
                        />
                        <div
                          v-if="errors['linkSurvey']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkSurvey"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mt-2">
                        <label for="linkZoom">Link Zoom</label>
                        <input
                          id="linkZoom"
                          v-model="form.linkZoom"
                          type="text"
                          class="form-control"
                          placeholder="Link Event"
                          :class="{
                            'is-invalid': errors['linkZoom'],
                          }"
                        />
                        <div v-if="errors['linkZoom']" class="invalid-feedback">
                          <span>{{ errors["linkZoom"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mt-2">
                        <label for="linkDocument"
                          >Link Dokumen Google Drive</label
                        >
                        <input
                          id="linkDocument"
                          v-model="form.linkDocument"
                          type="text"
                          class="form-control"
                          placeholder="Link Dokumen Google Drive"
                          :class="{
                            'is-invalid': errors['linkDocument'],
                          }"
                        />
                        <div
                          v-if="errors['linkDocument']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["linkDocument"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <hr />
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="courseId">Kelas Terkait</label>
                        <select v-model="form.courseId" class="form-control">
                          <option value="">Pilih Kelas</option>
                          <option
                            v-for="course in courses"
                            :key="course.id"
                            :value="course.id"
                            >{{ course.title }}</option
                          >
                        </select>
                        <div v-if="errors['courseId']" class="invalid-feedback">
                          <span>{{ errors["courseId"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="giveawayId">Giveaway Terkait</label>
                        <select v-model="form.giveawayId" class="form-control">
                          <option value="">Pilih Giveaway</option>
                          <option
                            v-for="giveaway in giveaways"
                            :key="giveaway.id"
                            :value="giveaway.id"
                            >{{ giveaway.name }}</option
                          >
                        </select>
                        <div
                          v-if="errors['giveawayId']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["giveawayId"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="surveyId">Survey Terkait</label>
                        <select v-model="form.surveyId" class="form-control">
                          <option value="">Pilih Survey</option>
                          <option
                            v-for="survey in surveys"
                            :key="survey.id"
                            :value="survey.id"
                            >{{ survey.name }}</option
                          >
                        </select>
                        <div v-if="errors['surveyId']" class="invalid-feedback">
                          <span>{{ errors["surveyId"] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary form-control mt-4"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
