import batchApi from "../../api/batch.api";
import filterDefault from "../../helpers/filter";

export const state = {
  batchs: [],
  batch: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  batchs(state) {
    return state.batchs;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_BATCHS(state, batchs) {
    state.batchs = batchs;
  },

  ADD_BATCH(state, batch) {
    state.batchs.unshift(batch);
  },

  MERGE_BATCHS(state, batchs) {
    state.batchs = batchs.concat(state.batchs);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_BATCH(state, id) {
    state.batchs.splice(
      state.batchs.findIndex((batch) => batch.id === id),
      1
    );
  },

  SET_BATCH(state, batch) {
    state.batch = batch;
  },

  CHANGE_BATCH(state, { id, batch }) {
    state.batchs[state.batchs.findIndex((batch) => batch.id === id)] = batch;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await batchApi.fetch(state.filter);

      commit("SET_BATCHS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await batchApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_BATCH", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await batchApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_BATCH", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await batchApi.detail(id);

    commit("SET_BATCH", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await batchApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_BATCH", { id, batch: data.data });

    return { status: true };
  },
};
