import badgeApi from "../../api/badge.api";
import filterDefault from "../../helpers/filter";

export const state = {
  badges: [],
  badge: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  badges(state) {
    return state.badges;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_BADGES(state, badges) {
    state.badges = badges;
  },

  ADD_BADGE(state, badge) {
    state.badges.unshift(badge);
  },

  MERGE_BADGES(state, badges) {
    state.badges = badges.concat(state.badges);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_BADGE(state, id) {
    state.badges.splice(
      state.badges.findIndex((badge) => badge.id === id),
      1
    );
  },

  SET_BADGE(state, badge) {
    state.badge = badge;
  },

  CHANGE_BADGE(state, { id, badge }) {
    state.badges[state.badges.findIndex((badge) => badge.id === id)] = badge;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await badgeApi.fetch(state.filter);

      commit("SET_BADGES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await badgeApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_BADGE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await badgeApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_BADGE", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await badgeApi.detail(id);

    commit("SET_BADGE", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await badgeApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_BADGE", { id, badge: data.data });

    return { status: true };
  },
};
