<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Kata",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      categories:
        "whatsappGroupWordSearchSummaryWordCategory/whatsappGroupWordSearchSummaryWordCategories",
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Kata" : "Tambah Kata",
      id: this.$route.params.id,
      items: [
        {
          text: "Kata",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        word: "",
        categoryId: "",
        partnerId: "",
      },
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "whatsappGroupWordSearchSummaryWord/create",
      detail: "whatsappGroupWordSearchSummaryWord/detail",
      update: "whatsappGroupWordSearchSummaryWord/update",

      fetchCategory: "whatsappGroupWordSearchSummaryWordCategory/changeFilters",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({
          name: "list-whatsapp-group-word-search-summary-word",
        });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
    }

    if (this.isPartner()) {
      this.form.partnerId = this.user.partner.id;
      this.fetchCategory([
        { key: "limit", value: 999 },
        { key: "partnerId", value: this.user.partner.id },
      ]);
    } else {
      this.fetchCategory([{ key: "limit", value: 999 }]);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="word"
                      >Kata kata (pisahkan dengan koma untuk kata kata yang
                      mirip) *)</label
                    >
                    <input
                      id="word"
                      v-model="form.word"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['word'],
                      }"
                    />
                    <div v-if="errors['word']" class="invalid-feedback">
                      <span>{{ errors["word"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="categoryId">Kategori *)</label>
                    <select v-model="form.categoryId" class="form-control">
                      <option value="">Pilih Kategori</option>
                      <option
                        :value="category.id"
                        :key="category.id"
                        v-for="category in categories"
                        >{{ category.name }}</option
                      >
                    </select>
                    <div v-if="errors['categoryId']" class="invalid-feedback">
                      <span>{{ errors["categoryId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
