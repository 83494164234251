<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Pengaturan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pengaturan",
      id: this.$route.params.id,
      items: [
        {
          text: "Pengaturan",
          href: "/setting",
        },
      ],
      forms: [],
      groups: [],
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      update: "setting/update",
      fetch: "setting/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      response = await this.update(this.forms);

      if (response.status) {
        window.scrollTo({ top: 0, behaviour: "smooth" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    getForms(group) {
      return this.forms.filter((form) => form.group == group);
    },
  },

  async mounted() {
    this.forms = Object.assign([], await this.fetch());

    this.forms.forEach((form) => {
      if (this.groups.indexOf(form.group) === -1) this.groups.push(form.group);
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row" v-for="group in groups" :key="group">
                <div class="col-md-12">
                  <div class="form-group">
                    <h5>{{ group }}</h5>
                  </div>

                  <div
                    class="col-md-12"
                    v-for="form in getForms(group)"
                    :key="form.id"
                  >
                    <div class="form-group">
                      <label for="courseId"
                        >{{ form.key }} ({{ form.description }})</label
                      >
                      <textarea
                        type="text"
                        class="form-control"
                        v-model="form.value"
                        :placeholder="`Masukkan ${form.key}`"
                        rows="3"
                      />
                    </div>
                  </div>

                  <hr />
                </div>
              </div>

              <button
                class="btn btn-primary form-control mt-4"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">Ubah Pengaturan</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style>
.ql-container {
  height: 400px !important;
}
</style>
