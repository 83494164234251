import api from "./index";

const baseURL = "/surveys";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

// API For Survey Answer
const fetchAnswer = (id, params) =>
  api.get(`${baseURL}/${id}/answers`, { params });

const detailAnswer = (surveyId, answerId) =>
  api.get(`${baseURL}/${surveyId}/answers/${answerId}`);

const deleteAnswer = (surveyId, answerId) =>
  api.delete(`${baseURL}/${surveyId}/answers/${answerId}`);

const updateAnswer = (surveyId, answerId, payload) =>
  api.patch(`${baseURL}/${surveyId}/answers/${answerId}`, payload);

const downloadAnswer = (surveyId) =>
  api.get(`${baseURL}/${surveyId}/answers/download`);

const importAnswer = (surveyId, payload) =>
  api.post(`${baseURL}/${surveyId}/answers/import`, payload);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  fetchAnswer,
  detailAnswer,
  deleteAnswer,
  updateAnswer,
  downloadAnswer,
  importAnswer,
};
