import areaApi from "../../api/area.api";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async fetchProvinces() {
    const { data } = await areaApi.fetchProvinces({ ordering: "name" });

    return data.result.data;
  },

  async fetchCities(context, id) {
    const { data } = await areaApi.fetchCities({
      ordering: "name",
      provinceId: id,
    });

    console.log(data.result.data);

    return data.result.data;
  },
};
