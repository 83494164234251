import whatsappGroupApi from "../../api/whatsapp-group.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroups: [],
  whatsappGroup: {},
  total: 0,
  filter: {
    ...filterDefault,
    type: "",
  },
};

export const getters = {
  whatsappGroups(state) {
    return state.whatsappGroups;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUPS(state, whatsappGroups) {
    state.whatsappGroups = whatsappGroups;
  },

  ADD_WHATSAPP_GROUP(state, whatsappGroup) {
    state.whatsappGroups.unshift(whatsappGroup);
  },

  MERGE_WHATSAPP_GROUPS(state, whatsappGroups) {
    state.whatsappGroups = whatsappGroups.concat(state.whatsappGroups);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP(state, id) {
    state.whatsappGroups.splice(
      state.whatsappGroups.findIndex(
        (whatsappGroup) => whatsappGroup.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP(state, whatsappGroup) {
    state.whatsappGroup = whatsappGroup;
  },

  CHANGE_WHATSAPP_GROUP(state, { id, whatsappGroup }) {
    state.whatsappGroups[
      state.whatsappGroups.findIndex((whatsappGroup) => whatsappGroup.id === id)
    ] = whatsappGroup;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.whatsappGroups.findIndex((number) => number.id === id);

    state.whatsappGroups[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupApi.fetch(state.filter);

      commit("SET_WHATSAPP_GROUPS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP", {
      id,
      whatsappGroup: data.data,
    });

    return { status: true };
  },

  async setStatus({ commit }, { id, payload }) {
    const { status } = payload;
    const { data } = await whatsappGroupApi.setStatus(id, payload);

    commit("SET_STATUS", { id, status });

    return data;
  },

  async syncMember({ commit }, id) {
    const { data } = await whatsappGroupApi.syncMember(id);

    commit("CHANGE_WHATSAPP_GROUP", {
      id,
      whatsappGroup: data.data,
    });

    return data;
  },

  async getResumeActivity(context, params) {
    const { data } = await whatsappGroupApi.resume(params);

    return data.data;
  },

  async invite(context, payload) {
    const { data } = await whatsappGroupApi.invite(payload);

    return data.data;
  },

  async syncChat(context, { id, payload }) {
    const { data } = await whatsappGroupApi.syncChat(id, payload);

    return data.data;
  },

  async syncGroupToSoby(context, id) {
    const { data } = await whatsappGroupApi.syncGroupToSoby(id);

    return data.data;
  },
};
