import api from "./index";

const baseURL = "/users";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const getUser = (id) => api.get(`${baseURL}/${id}/detail`);

const store = (payload) => api.post(`${baseURL}`, payload);

const updateUser = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const deleteUser = (id) => api.delete(`${baseURL}/${id}`);

const importUser = (payload) => api.post(`${baseURL}/import`, payload);

const exportUsers = (params) => api.get(`${baseURL}/download`, { params });

export default {
  fetch,
  getUser,
  store,
  updateUser,
  deleteUser,
  importUser,
  exportUsers,
};
