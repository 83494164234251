<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueRangedatePicker from "vue-rangedate-picker";
import Select2 from "v-select2-component";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Member component
 */
export default {
  page: {
    title: "Master Member",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueRangedatePicker, Select2 },

  data() {
    return {
      importForm: {
        file: "",
      },
      title: "Master Member",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Member",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "firstName", sortable: true, label: "Nama" },
        { key: "phoneNumber", sortable: true, label: "Nomer Telpon" },
        { key: "email", sortable: true, label: "E-mail" },
        { key: "utms", sortable: true, label: "UTM" },
        {
          key: "whatsappGroupCommunity",
          sortable: true,
          label: "Whatsapp Group",
        },
        { key: "lastLoginAt", sortable: true, label: "Terakhir Login" },
        { key: "createdAt", sortable: true, label: "Dibuat Pada" },
        { key: "actions", label: "Aksi" },
      ],
      loadingImport: false,
      loading: false,
      processImport: { data: [], resume: {} },
      loadingExport: false,
      parentUtm: "",
      childUtm: "",
      coursesOptions: [],
      filterCourseIds: [],
    };
  },

  watch: {
    sortBy() {
      this.fetch({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.fetch({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    parentUtm() {
      const utms = [this.parentUtm, this.childUtm];
      this.utms = utms.join(",");
    },

    childUtm() {
      const utms = [this.parentUtm, this.childUtm];
      this.utms = utms.join(",");
    },

    courses: {
      handler() {
        this.coursesOptions = [];
        this.courses.forEach((course) => {
          this.coursesOptions.push({ id: course.id, text: course.title });
        });
      },
      deep: true,
    },

    filterCourseIds: {
      handler(val) {
        this.courseIds = val;
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      total: "user/total",
      groups: "whatsappGroup/whatsappGroups",
      utmsData: "utm/utms",
      courses: "course/courses",
    }),

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["user/users"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["user/filter"].search;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "search", value: val });
        this.loading = false;
      },
    },

    utms: {
      get() {
        return this.$store.getters["user/filter"].utms;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "utms", value: val });
        this.loading = false;
      },
    },

    groupId: {
      get() {
        return this.$store.getters["user/filter"].groupId;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "groupId", value: val });
        this.loading = false;
      },
    },

    courseIds: {
      get() {
        return this.$store.getters["user/filter"].courseIds;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "courseIds", value: val.join(",") });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["user/filter"].limit;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["user/filter"].page;
      },
      set(val) {
        this.loading = true;
        this.fetch({ key: "page", value: val });
        this.loading = false;
      },
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetch({ key: "roles", value: "MEMBER" });
    this.loading = false;

    this.fetchUserGroup();
    this.fetchUtm();
    this.fetchCourse({ key: "ordering", value: "title" });
  },
  methods: {
    ...mapActions({
      delete: "user/delete",
      fetch: "user/changeFilter",
      changeFilters: "user/changeFilters",

      fetchUserGroup: "whatsappGroup/fetch",
      fetchUtm: "utm/fetch",
      fetchCourse: "course/changeFilter",
    }),

    async importUser() {
      if (this.processImport.length > 0) {
        this.processImport = [];

        this.$nextTick(() => {
          this.$bvModal.hide("modal-import");
        });
      } else {
        this.loadingImport = true;
        const response = await this.$store.dispatch(
          "user/importUser",
          this.importForm
        );
        this.loadingImport = false;
        this.processImport = [];

        if (response.status) {
          this.processImport = response.process;
        } else {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat import member!",
          });
        }
      }
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    deleteMember(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    async exportUsers() {
      this.loadingExport = true;
      const data = await this.$store.dispatch("user/exportUsers");

      window.open(data.data, "_blank");
      this.loadingExport = false;
    },

    async onDateSelected(daterange) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let startAt = new Date(daterange.start);
      let endAt = new Date(daterange.end);
      const startCreatedAt = `${startAt.getUTCFullYear()}-${startAt.getUTCMonth() +
        1}-${startAt.getUTCDate()}`;
      const endCreatedAt = `${endAt.getUTCFullYear()}-${endAt.getUTCMonth() +
        1}-${endAt.getUTCDate()}`;

      this.changeFilters([
        { key: "startCreatedAt", value: startCreatedAt },
        { key: "endCreatedAt", value: endCreatedAt },
      ]);

      document.getElementsByClassName(
        "input-date"
      )[0].innerHTML = `${startAt.getUTCDate()} ${
        months[startAt.getUTCMonth()]
      } ${startAt.getUTCFullYear()} - ${endAt.getUTCDate()} ${
        months[endAt.getUTCMonth()]
      } ${endAt.getUTCFullYear()}`;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <router-link to="/member/create">
                <b-button class="mr-3" variant="primary" size="sm"
                  ><i class="mdi mdi-plus mr-2"></i> Tambah Member</b-button
                >
              </router-link>

              <b-button
                variant="danger"
                size="sm"
                class="mr-3"
                @click="exportUsers"
                ><div
                  class="spinner-border spinner-border-sm text-center text-primary"
                  role="status"
                  v-if="loadingExport"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>Download data user</span></b-button
              >

              <b-button
                variant="warning"
                size="sm"
                class="mr-3"
                v-b-modal.modal-import
                >Import Member</b-button
              >

              <b-button variant="info" size="sm" v-b-modal.modal-filter
                >Filter Member</b-button
              >

              <b-modal
                centered
                id="modal-filter"
                title="Filter Member"
                size="xl"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-3">
                      <label for="">Pilih Parent UTM</label>
                      <select
                        v-model="parentUtm"
                        name=""
                        id=""
                        class="form-control"
                      >
                        <option value="">Semua UTM</option>
                        <option
                          :value="utm.id"
                          v-for="utm in utmsData.filter(
                            (utm) => utm.parent === null
                          )"
                          :key="utm.id"
                          >{{ utm.name }}</option
                        >
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="">Pilih Child UTM</label>
                      <select
                        v-model="childUtm"
                        name=""
                        id=""
                        class="form-control"
                      >
                        <option value="">Semua UTM</option>
                        <option
                          :value="utm.id"
                          v-for="utm in utmsData.filter((utm) => {
                            if (parentUtm) {
                              return utm.parent && utm.parent.id === parentUtm;
                            } else {
                              return utm.parent;
                            }
                          })"
                          :key="utm.id"
                          >{{ utm.name }}</option
                        >
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="">Pilih Grup Whatsapp Komunitas</label>
                      <select
                        v-model="groupId"
                        name=""
                        id=""
                        class="form-control"
                      >
                        <option value="">Semua Whatsapp Group</option>
                        <option
                          :value="group.id"
                          v-for="group in groups.filter(
                            (group) => group.type === 'COMMUNITY'
                          )"
                          :key="group.id"
                          >{{ group.name }}</option
                        >
                      </select>
                    </div>

                    <div class="col-3">
                      <div class="form-group">
                        <label for="">Pilih Tanggal</label>
                        <vue-rangedate-picker
                          @selected="onDateSelected"
                          i18n="ID"
                          righttoleft="true"
                        >
                        </vue-rangedate-picker>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="">Pernah Mengikuti Kelas</label>
                        <Select2
                          v-model="filterCourseIds"
                          :options="coursesOptions"
                          :settings="{ multiple: true }"
                          style="width: 100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>

              <b-modal
                centered
                id="modal-import"
                title="Import Member"
                @ok.prevent="importUser"
                size="xl"
              >
                <div v-if="processImport.data.length > 0">
                  <p><b>Rangkuman Hasil Import</b></p>
                  <table class="table table-bordered table-hover">
                    <tr>
                      <th>Data dibuat</th>
                      <th>Data diupdate</th>
                      <th>Data gagal</th>
                    </tr>
                    <tr>
                      <td>{{ processImport.resume.create }}</td>
                      <td>{{ processImport.resume.update }}</td>
                      <td>{{ processImport.resume.failed }}</td>
                    </tr>
                  </table>

                  <br />
                  <hr />
                  <br />

                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>
                          No
                        </th>
                        <th>
                          Nama
                        </th>
                        <th>
                          E-mail
                        </th>
                        <th>
                          Whatsapp
                        </th>
                        <th>
                          Status
                        </th>
                        <th>
                          Pesan
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(process, index) in processImport.data"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ process.name }}</td>
                        <td>{{ process.email }}</td>
                        <td>{{ process.whatsapp }}</td>
                        <td>{{ process.status }}</td>
                        <td>{{ process.message }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <input
                    @change="uploadFileImport"
                    type="file"
                    name=""
                    id=""
                    accept=".csv"
                    v-if="!loadingImport"
                  />
                  <div class="text-center" v-else>
                    <div
                      class="spinner-border text-center text-primary"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </b-modal>
            </h4>
            <div class="row mt-4">
              <div class="col-lg-12">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="alert alert-info" style="width: 100%">
                          <p class="m-0 text-center">
                            Total Member <b>{{ total }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 mt-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(firstName)="data">
                  <p>{{ data.item.name }}</p>
                </template>

                <template #cell(lastLoginAt)="data">
                  <p>
                    {{
                      data.item.lastLoginAt | moment("Do MMMM YYYY HH:mm:SS")
                    }}
                  </p>
                </template>

                <template #cell(utms)="data">
                  <p>
                    {{ data.item.utms.map((utm) => utm.name).join(",") }}
                  </p>
                </template>

                <template #cell(whatsappGroupCommunity)="data">
                  <div v-if="data.item.whatsappGroupCommunity">
                    <a
                      :href="data.item.whatsappGroupCommunity.link"
                      target="_blank"
                      >{{ data.item.whatsappGroupCommunity.name }}</a
                    >
                  </div>
                </template>

                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY HH:mm:SS") }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-member',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    class="mr-3"
                    variant="warning"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'detail-member',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteMember(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.select2-container {
  width: 100% !important;
}
</style>
