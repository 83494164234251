import submoduleApi from "../../api/submodule.api";
import filterDefault from "../../helpers/filter";

export const state = {
  submodules: [],
  submodule: {},
  filter: {
    ...filterDefault,
  },
  total: 0,
};

export const getters = {
  submodules(state) {
    return state.submodules;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_SUBMODULES(state, submodules) {
    state.submodules = submodules;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  ADD_SUBMODULE(state, submodule) {
    state.submodules.unshift(submodule);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_SUBMODULE(state, id) {
    state.submodules.splice(
      state.submodules.findIndex((submodule) => submodule.id === id),
      1
    );
  },

  SET_SUBMODULE(state, submodule) {
    state.submodule = submodule;
  },

  CHANGE_SUBMODULE(state, { id, submodule }) {
    state.submodules[
      state.submodules.findIndex((submodule) => submodule.id === id)
    ] = submodule;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await submoduleApi.fetch(state.filter);

      commit("SET_TOTAL", data.data.total);
      commit("SET_SUBMODULES", data.data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await submoduleApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_SUBMODULE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true, data: data.data };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await submoduleApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_SUBMODULE", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await submoduleApi.detail(id);

    commit("SET_SUBMODULE", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await submoduleApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_SUBMODULE", { id, submodule: data.data });

    return { status: true };
  },
};
