<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Daftar Bukti Pembayaran component
 */
export default {
  page: {
    title: "Daftar Bukti Pembayaran",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Daftar Bukti Pembayaran",
      items: [
        {
          text: "Daftar",
          href: "/",
        },
        {
          text: "Bukti Pembayaran",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "orderNumber", label: "Nomer Pesanan", sortable: true },
        { key: "user", label: "Pengguna", sortable: true },
        { key: "whatsapp", label: "Nomer Whatsapp", sortable: true },
        { key: "image", label: "Bukti Pembayaran", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "createdAt", label: "Diunggah Pada", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["orderProofPayment/orderProofPayments"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["orderProofPayment/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    status: {
      get() {
        return this.$store.getters["orderProofPayment/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["orderProofPayment/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["orderProofPayment/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "orderProofPayment/total",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "orderProofPayment/fetch",
      delete: "orderProofPayment/delete",
      changeFilter: "orderProofPayment/changeFilter",
    }),

    deleteorderProofPayment(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4">
                  <div class="row">
                    <select v-model="status" class="form-control">
                      <option value="">Pilih Status</option>
                      <option value="WAITING">Menunggu</option>
                      <option value="PROCESS">Sedang Diproses</option>
                      <option value="ACCEPT">Telah Disetujui</option>
                      <option value="REJECT">Ditolak</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(status)="data">
                  <p>{{ data.item.status | statusOrderProofPayment }}</p>
                </template>

                <template #cell(orderNumber)="data">
                  <p>{{ data.item.order.orderNumber }}</p>
                </template>

                <template #cell(user)="data">
                  <p>
                    {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                  </p>
                </template>

                <template #cell(whatsapp)="data">
                  <p>
                    {{ data.item.user.phoneNumber }}
                  </p>
                </template>

                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY, HH:mm") }}
                    WIB
                  </p>
                </template>

                <template #cell(image)="data">
                  <img v-img :src="data.item.image" alt="" width="100px" />
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'detail-order-proof-payment',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
