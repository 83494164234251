import api from "./index";

const baseURL = "/orders";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const update = ({ id, payload }) => api.patch(`${baseURL}/${id}`, payload);

const resumeOrder = (params) =>
  api.get(`${baseURL}/dashboards/resume`, { params });

const followup = (payload) => api.post(`${baseURL}/followup`, payload);

export default { fetch, detail, resumeOrder, update, followup };
