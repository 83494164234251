import api from "./index";

const baseURL = "/course-module-ratings";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

export default { fetch, detail };
