import whatsappGroupGreetingTemplateApi from "../../api/whatsapp-group-greeting-template.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupGreetingTemplates: [],
  whatsappGroupGreetingTemplate: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupGreetingTemplates(state) {
    return state.whatsappGroupGreetingTemplates;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_GREETING_TEMPLATES(state, whatsappGroupGreetingTemplates) {
    state.whatsappGroupGreetingTemplates = whatsappGroupGreetingTemplates;
  },

  ADD_WHATSAPP_GROUP_GREETING_TEMPLATE(state, whatsappGroupGreetingTemplate) {
    state.whatsappGroupGreetingTemplates.unshift(whatsappGroupGreetingTemplate);
  },

  MERGE_WHATSAPP_GROUP_GREETING_TEMPLATES(
    state,
    whatsappGroupGreetingTemplates
  ) {
    state.whatsappGroupGreetingTemplates = whatsappGroupGreetingTemplates.concat(
      state.whatsappGroupGreetingTemplates
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_GREETING_TEMPLATE(state, id) {
    state.whatsappGroupGreetingTemplates.splice(
      state.whatsappGroupGreetingTemplates.findIndex(
        (whatsappGroupGreetingTemplate) =>
          whatsappGroupGreetingTemplate.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_GREETING_TEMPLATE(state, whatsappGroupGreetingTemplate) {
    state.whatsappGroupGreetingTemplate = whatsappGroupGreetingTemplate;
  },

  CHANGE_WHATSAPP_GROUP_GREETING_TEMPLATE(
    state,
    { id, whatsappGroupGreetingTemplate }
  ) {
    state.whatsappGroupGreetingTemplates[
      state.whatsappGroupGreetingTemplates.findIndex(
        (whatsappGroupGreetingTemplate) =>
          whatsappGroupGreetingTemplate.id === id
      )
    ] = whatsappGroupGreetingTemplate;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.whatsappGroupGreetingTemplates.findIndex(
      (number) => number.id === id
    );

    state.whatsappGroupGreetingTemplates[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupGreetingTemplateApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_GREETING_TEMPLATES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupGreetingTemplateApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_GREETING_TEMPLATE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupGreetingTemplateApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_GREETING_TEMPLATE", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupGreetingTemplateApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupGreetingTemplateApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_GREETING_TEMPLATE", {
      id,
      whatsappGroupGreetingTemplate: data.data,
    });

    return { status: true };
  },

  async setStatus({ commit }, { id, payload }) {
    const { status } = payload;
    const { data } = await whatsappGroupGreetingTemplateApi.setStatus(
      id,
      payload
    );

    commit("SET_STATUS", { id, status });

    return data;
  },

  async syncMember({ commit }, id) {
    const { data } = await whatsappGroupGreetingTemplateApi.syncMember(id);

    commit("CHANGE_WHATSAPP_GROUP_GREETING_TEMPLATE", {
      id,
      whatsappGroupGreetingTemplate: data.data,
    });

    return data;
  },
};
