import api from "./index";

const baseURL = "/settings";

const fetch = (params) => api.get(`${baseURL}`, { params });
const update = (payload) => api.patch(`${baseURL}`, payload);

export default {
  fetch,
  update,
};
