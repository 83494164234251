import api from "./index";

const baseURL = "/clinics";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const takeQuestion = (id) => api.patch(`${baseURL}/${id}/take-question`);

const finishQuestion = ({ id, payload }) =>
  api.patch(`${baseURL}/${id}/finish`, payload);

const addCategory = ({ id, payload }) =>
  api.patch(`${baseURL}/${id}/add-category`, payload);

const sendMessage = (id, payload) =>
  api.post(`${baseURL}/${id}/send-message`, payload);

const updateMessage = (clinicId, id, payload) =>
  api.patch(`${baseURL}/${clinicId}/messages/${id}`, payload);

const rejectQuestion = (id) => api.patch(`${baseURL}/${id}/reject`);

const pinQuestion = (id) => api.patch(`${baseURL}/${id}/pin`);

const setPublicQuestion = (id) => api.patch(`${baseURL}/${id}/set-public`);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  takeQuestion,
  sendMessage,
  finishQuestion,
  addCategory,
  rejectQuestion,
  pinQuestion,
  setPublicQuestion,
  updateMessage,
};
