<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import VueRangedatePicker from "vue-rangedate-picker";
import { mapActions, mapGetters } from "vuex";
// import Select2 from "v-select2-component";
import TotalNewFreeEnrollment from "@/components/dashboard/enrollment/TotalNewFreeEnrollment";
import TotalNewPaidEnrollment from "@/components/dashboard/enrollment/TotalNewPaidEnrollment";
import TotalNewEnrollment from "@/components/dashboard/enrollment/TotalNewEnrollment";
import TotalMemberNewFreeEnrollment from "@/components/dashboard/enrollment/TotalMemberNewFreeEnrollment";
import TotalMemberNewPaidEnrollment from "@/components/dashboard/enrollment/TotalMemberNewPaidEnrollment";
import TotalMemberNewEnrollment from "@/components/dashboard/enrollment/TotalMemberNewEnrollment";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    VueRangedatePicker,
    // Select2,

    TotalNewFreeEnrollment,
    TotalNewPaidEnrollment,
    TotalNewEnrollment,
    TotalMemberNewFreeEnrollment,
    TotalMemberNewPaidEnrollment,
    TotalMemberNewEnrollment,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Bolu",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      filter: {
        startDate: "",
        endDate: "",
        courseIds: "",
        moduleIds: "",
        batchIds: "",
        courseArrayIds: [],
        batchArrayIds: [],
      },
      data: {},
      courseOptions: [],
      batchOptions: [],
    };
  },

  computed: mapGetters({
    courses: "course/courses",
  }),

  watch: {
    "filter.courseArrayIds"(val) {
      this.batchOptions = [];

      val.forEach((id) => {
        const index = this.courses.findIndex((course) => course.id === id);

        this.courses[index].batchs.forEach((batch) => {
          this.batchOptions.push({
            id: batch.id,
            text: `${batch.name} ${this.courses[index].title} -
                        ${this.$options.filters.moment(
                          batch.startDate,
                          "DD MMMM YYYY"
                        )}
                        s/d
                        ${this.$options.filters.moment(
                          batch.finishDate,
                          "DD MMMM YYYY"
                        )}`,
          });
        });
      });

      this.filter.courseIds = val.join(",");
      this.getData();
    },

    "filter.batchArrayIds"(val) {
      this.filter.batchIds = val.join(",");
      this.getData();
    },
  },

  methods: {
    ...mapActions({
      fetchAdmin: "dashboard/fetchAdmin",
      fetchCourse: "course/fetch",
    }),

    onDateSelected(daterange) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const startDate = new Date(daterange.start);
      const endDate = new Date(daterange.end);
      this.filter.startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() +
        1}-${startDate.getUTCDate()}`;
      this.filter.endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() +
        1}-${endDate.getUTCDate()}`;

      document.getElementsByClassName(
        "input-date"
      )[0].innerHTML = `${startDate.getUTCDate()} ${
        months[startDate.getUTCMonth()]
      } ${startDate.getUTCFullYear()} - ${endDate.getUTCDate()} ${
        months[endDate.getUTCMonth()]
      } ${endDate.getUTCFullYear()}`;
    },

    async getData() {
      const data = await this.fetchAdmin(this.filter);
      this.data = Object.assign({}, data);
    },
  },

  async mounted() {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 2);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.filter.startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() +
      1}-${startDate.getUTCDate()}`;
    this.filter.endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() +
      1}-${endDate.getUTCDate()}`;

    document.getElementsByClassName("input-date")[0].style.width = "100%";
    document.getElementsByClassName(
      "input-date"
    )[0].innerHTML = `${startDate.getUTCDate()} ${
      months[startDate.getUTCMonth()]
    } ${startDate.getUTCFullYear()} - ${endDate.getUTCDate()} ${
      months[endDate.getUTCMonth()]
    } ${endDate.getUTCFullYear()}`;

    await this.fetchCourse();

    this.courseOptions = this.courses.map((course) => {
      return { id: course.id, text: course.title };
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 mb-3">
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label for="">Pilih Tanggal</label>
              <vue-rangedate-picker @selected="onDateSelected" i18n="ID">
              </vue-rangedate-picker>
            </div>
          </div>
          <!-- <div class="col-xl-4">
            <label for="course">Kelas</label>
            <Select2
              v-model="filter.courseArrayIds"
              :options="courseOptions"
              :settings="{ multiple: true }"
            />
          </div>
          <div class="col-xl-4">
            <label for="batch">Batch</label>
            <Select2
              v-model="filter.batchArrayIds"
              :options="batchOptions"
              :settings="{ multiple: true }"
            />
          </div> -->
        </div>
      </div>

      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <TotalNewEnrollment :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <TotalNewFreeEnrollment :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <TotalNewPaidEnrollment :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <TotalMemberNewEnrollment :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <TotalMemberNewFreeEnrollment :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <TotalMemberNewPaidEnrollment :params.sync="filter" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.input-date {
  width: 100%;
}
</style>
