import whatsappGroupInvitationHistoryApi from "../../api/whatsapp-group-invitation-history.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupInvitationHistories: [],
  whatsappGroupInvitationHistory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupInvitationHistories(state) {
    return state.whatsappGroupInvitationHistories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_INVITATION_HISTORIES(
    state,
    whatsappGroupInvitationHistories
  ) {
    state.whatsappGroupInvitationHistories = whatsappGroupInvitationHistories;
  },

  ADD_WHATSAPP_GROUP_INVITATION_HISTORIES(
    state,
    whatsappGroupInvitationHistory
  ) {
    state.whatsappGroupInvitationHistories.unshift(
      whatsappGroupInvitationHistory
    );
  },

  MERGE_WHATSAPP_GROUP_INVITATION_HISTORIESS(
    state,
    whatsappGroupInvitationHistories
  ) {
    state.whatsappGroupInvitationHistories = whatsappGroupInvitationHistories.concat(
      state.whatsappGroupInvitationHistories
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_INVITATION_HISTORIES(state, id) {
    state.whatsappGroupInvitationHistories.splice(
      state.whatsappGroupInvitationHistories.findIndex(
        (whatsappGroupInvitationHistory) =>
          whatsappGroupInvitationHistory.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_INVITATION_HISTORY(state, whatsappGroupInvitationHistory) {
    state.whatsappGroupInvitationHistory = whatsappGroupInvitationHistory;
  },

  CHANGE_WHATSAPP_GROUP_INVITATION_HISTORIES(
    state,
    { id, whatsappGroupInvitationHistory }
  ) {
    state.whatsappGroupInvitationHistories[
      state.whatsappGroupInvitationHistories.findIndex(
        (whatsappGroupInvitationHistory) =>
          whatsappGroupInvitationHistory.id === id
      )
    ] = whatsappGroupInvitationHistory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupInvitationHistoryApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_INVITATION_HISTORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async reinvite({ dispatch }, payload) {
    const { data } = await whatsappGroupInvitationHistoryApi.reinvite(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },
};
