<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Detail Bukti Pembayaran",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detail Bukti Pembayaran",
      id: this.$route.params.id,
      items: [
        {
          text: "Bukti Pembayaran",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        user: {},
        order: {},
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "orderProofPayment/create",
      detail: "orderProofPayment/detail",
      update: "orderProofPayment/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async updateStatus(status) {
      this.loading = true;

      const response = await this.update({
        id: this.$route.params.id,
        payload: { status },
      });

      console.log(response);

      if (response.status) {
        this.$router.push({ name: "list-order-proof-payment" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Nomer Pesanan</b></label>
                    <p>{{ form.order.orderNumber }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Nama</b></label>
                    <p>{{ form.user.firstName }} {{ form.user.lastName }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Whatsapp</b></label>
                    <p>{{ form.user.phoneNumber }}</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name"><b>Total yang harus dibayar</b></label>
                    <p>{{ form.order.totalPrice | rupiah("Rp.") }}</p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name"><b>Status</b></label>
                    <p>{{ form.status | statusOrderProofPayment }}</p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name"><b>Bukti pembayaran</b></label>
                    <div class="col-lg-12">
                      <img v-img :src="form.image" width="500px" />
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
                @click="updateStatus('PROCESS')"
                v-if="form.status !== 'ACCEPT' && form.status !== 'REJECT'"
              >
                <span v-if="!loading">Proses Bukti Pembayaran</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
                v-if="form.status === 'PROCESS'"
                @click="updateStatus('ACCEPT')"
              >
                <span v-if="!loading">Konfirmasi Bukti Pembayaran</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                :disabled="loading"
                class="btn btn-danger mt-4 form-control"
                type="submit"
                v-if="form.status === 'PROCESS'"
                @click="updateStatus('REJECT')"
              >
                <span v-if="!loading">Tolak Bukti Pembayaran</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
