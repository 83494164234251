import partnershipPartnerProductCategoryApi from "../../api/partnership-partner-product-category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnershipPartnerProductCategories: [],
  partnershipPartnerProductCategory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnershipPartnerProductCategories(state) {
    return state.partnershipPartnerProductCategories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIP_PARTNER_PRODUCT_CATEGORIES(
    state,
    partnershipPartnerProductCategories
  ) {
    state.partnershipPartnerProductCategories = partnershipPartnerProductCategories;
  },

  ADD_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY(
    state,
    partnershipPartnerProductCategory
  ) {
    state.partnershipPartnerProductCategories.unshift(
      partnershipPartnerProductCategory
    );
  },

  MERGE_PARTNERSHIP_PARTNER_PRODUCT_CATEGORIES(
    state,
    partnershipPartnerProductCategories
  ) {
    state.partnershipPartnerProductCategories = partnershipPartnerProductCategories.concat(
      state.partnershipPartnerProductCategories
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY(state, id) {
    state.partnershipPartnerProductCategories.splice(
      state.partnershipPartnerProductCategories.findIndex(
        (partnershipPartnerProductCategory) =>
          partnershipPartnerProductCategory.id === id
      ),
      1
    );
  },

  SET_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY(
    state,
    partnershipPartnerProductCategory
  ) {
    state.partnershipPartnerProductCategory = partnershipPartnerProductCategory;
  },

  CHANGE_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY(
    state,
    { id, partnershipPartnerProductCategory }
  ) {
    state.partnershipPartnerProductCategories[
      state.partnershipPartnerProductCategories.findIndex(
        (partnershipPartnerProductCategory) =>
          partnershipPartnerProductCategory.id === id
      )
    ] = partnershipPartnerProductCategory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnershipPartnerProductCategories.findIndex(
      (number) => number.id === id
    );

    state.partnershipPartnerProductCategories[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipPartnerProductCategoryApi.fetch(
        state.filter
      );

      commit("SET_PARTNERSHIP_PARTNER_PRODUCT_CATEGORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipPartnerProductCategoryApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipPartnerProductCategoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipPartnerProductCategoryApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipPartnerProductCategoryApi.update(
      id,
      payload
    );

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP_PARTNER_PRODUCT_CATEGORY", {
      id,
      partnershipPartnerProductCategory: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipPartnerProductCategoryApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const {
      data,
    } = await partnershipPartnerProductCategoryApi.getAudiencesByQuery(query);

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipPartnerProductCategoryApi.getQueue(id);

    return data.data;
  },
};
