import onboardingApi from "../../api/onboarding.api";
import filterDefault from "../../helpers/filter";

export const state = {
  onboardings: [],
  onboarding: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    category: "",
  },
  categories: [
    {
      key: "GENERAL",
      text: "General",
    },
    {
      key: "COURSE",
      text: "Kelas",
    },
  ],
  questionTypes: [
    {
      key: "MULTIPLE_CHOICE",
      text: "Multiple Choice",
    },
    {
      key: "SINGLE_CHOICE",
      text: "Single Choice",
    },
    {
      key: "TEXT",
      text: "Teks",
    },
  ],
  onboardingAnswers: [],
  totalExamAnswers: 0,
  filterExamAnswer: {
    ...filterDefault,
  },
};

export const getters = {
  onboardings(state) {
    return state.onboardings;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },

  types(state) {
    return state.types;
  },

  categories(state) {
    return state.categories;
  },

  questionTypes(state) {
    return state.questionTypes;
  },

  onboardingAnswers(state) {
    return state.onboardingAnswers;
  },

  totalExamAnswers(state) {
    return state.totalExamAnswers;
  },

  filterExamAnswer(state) {
    return state.filterExamAnswer;
  },
};

export const mutations = {
  SET_ONBOARDINGS(state, onboardings) {
    state.onboardings = onboardings;
  },

  ADD_ONBOARDING(state, onboarding) {
    state.onboardings.unshift(onboarding);
  },

  MERGE_ONBOARDINGS(state, onboardings) {
    state.onboardings = onboardings.concat(state.onboardings);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_ONBOARDING(state, id) {
    state.onboardings.splice(
      state.onboardings.findIndex((onboarding) => onboarding.id === id),
      1
    );
  },

  SET_ONBOARDING(state, onboarding) {
    state.onboarding = onboarding;
  },

  CHANGE_ONBOARDING(state, { id, onboarding }) {
    state.onboardings[
      state.onboardings.findIndex((onboarding) => onboarding.id === id)
    ] = onboarding;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_TOTAL_ANSWER_ONBOARDING(state, total) {
    state.totalExamAnswers = total;
  },

  SET_ANSWER_ONBOARDING(state, answerExams) {
    state.onboardingAnswers = answerExams;
  },

  SET_FILTER_ONBOARDING_ANSWER(state, { key, value }) {
    state.filterExamAnswer[key] = value;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await onboardingApi.fetch(state.filter);

      commit("SET_ONBOARDINGS", data.data);
      commit("SET_TOTAL", data.data.length);

      return data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await onboardingApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_ONBOARDING", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await onboardingApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_ONBOARDING", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await onboardingApi.detail(id);

    commit("SET_ONBOARDING", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await onboardingApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_ONBOARDING", { id, onboarding: data.data });

    return { status: true };
  },

  async findAnswerExam({ state, commit }, id) {
    const { data } = await onboardingApi.findAnswerExam({
      id,
      params: state.filterExamAnswer,
    });

    commit("SET_ANSWER_ONBOARDING", data.data.data);
    commit("SET_TOTAL_ANSWER_ONBOARDING", data.data.total);

    return data;
  },

  async changeFilterAnswerExam({ dispatch, commit }, { id, key, value }) {
    commit("SET_FILTER_ANSWER_ONBOARDING", { key, value });

    dispatch("findAnswerExam", id);
  },

  async detailExamAnswer(context, { onboardingId, answerId }) {
    const { data } = await onboardingApi.detailAnswerExam({
      onboardingId,
      answerId,
    });

    return data.data;
  },
};
