import certificationEventApi from "../../api/certification-event.api";
import filterDefault from "../../helpers/filter";

export const state = {
  certificationEvents: [],
  certificationEvent: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    batchId: "",
  },
};

export const getters = {
  certificationEvents(state) {
    return state.certificationEvents;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_CERTIFICATION_EVENTS(state, certificationEvents) {
    state.certificationEvents = certificationEvents;
  },

  ADD_CERTIFICATION_EVENT(state, certificationEvent) {
    state.certificationEvents.unshift(certificationEvent);
  },

  MERGE_CERTIFICATION_EVENTS(state, certificationEvents) {
    state.certificationEvents = certificationEvents.concat(
      state.certificationEvents
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CERTIFICATION_EVENT(state, id) {
    state.certificationEvents.splice(
      state.certificationEvents.findIndex(
        (certificationEvent) => certificationEvent.id === id
      ),
      1
    );
  },

  SET_CERTIFICATION_EVENT(state, certificationEvent) {
    state.certificationEvent = certificationEvent;
  },

  CHANGE_CERTIFICATION_EVENT(state, { id, certificationEvent }) {
    state.certificationEvents[
      state.certificationEvents.findIndex(
        (certificationEvent) => certificationEvent.id === id
      )
    ] = certificationEvent;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await certificationEventApi.fetch(state.filter);

      commit("SET_CERTIFICATION_EVENTS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async download({ state, dispatch }) {
    const filter = Object.assign({}, state.filter);
    filter.limit = 999999999;
    const { data } = await certificationEventApi.download(filter);

    if (!data.success) {
      return { success: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });

    return { status: true, url: data.data };
  },

  async importData({ dispatch, commit }, payload) {
    const { data } = await certificationEventApi.importData(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("MERGE_CERTIFICATION_EVENTS", data.data.data);

    return { status: true, process: data.data.process };
  },
};
