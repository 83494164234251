<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master whatsappGroupKulwaTemplate component
 */
export default {
  page: {
    title: "Template Kulwa",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Template Kulwa",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Template Kulwa",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "name", label: "Nama", sortable: true },
        {
          key: "delay",
          label: "Delay antar bubble chat (dalam detik)",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters[
        "whatsappGroupKulwaTemplate/whatsappGroupKulwaTemplates"
      ];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappGroupKulwaTemplate/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappGroupKulwaTemplate/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappGroupKulwaTemplate/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappGroupKulwaTemplate/total",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "whatsappGroupKulwaTemplate/fetch",
      delete: "whatsappGroupKulwaTemplate/delete",
      changeFilter: "whatsappGroupKulwaTemplate/changeFilter",
    }),

    deleteUserRegion(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <router-link
                        :to="{
                          name: 'create-whatsapp-group-kulwa-template',
                        }"
                      >
                        <b-button class="mr-3" variant="primary" size="sm"
                          ><i class="mdi mdi-plus mr-2"></i> Tambah
                          Template</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(category)="data">
                  <p>{{ data.item.category.name }}</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-whatsapp-group-kulwa-template',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteUserRegion(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
