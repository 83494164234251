<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions } from "vuex";

/**
 * Master Produk component
 */
export default {
  page: {
    title: "Master Produk",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Produk",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Mitra",
          href: "/partnership",
        },
        {
          text: "Produk",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nama" },
        { key: "shortDescription", label: "Deskripsi Singkat" },
        { key: "totalClickLink", label: "Total Klik" },
        { key: "totalClickLinkAction", label: "Total Klik Link" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters[
        "partnershipPartnerProduct/partnershipPartnerProducts"
      ];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["partnershipPartnerProduct/filter"].search;
      },
      set(val) {
        this.changeFilter({ key: "search", value: val });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["partnershipPartnerProduct/filter"].limit;
      },
      set(val) {
        this.changeFilter({ key: "limit", value: val });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["partnershipPartnerProduct/filter"].page;
      },
      set(val) {
        this.changeFilter({ key: "page", value: val });
      },
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.loading = true;

    await this.fetch();

    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "partnershipPartnerProduct/fetch",
      delete: "partnershipPartnerProduct/delete",
    }),

    deleteMember(id) {
      console.log(id);
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Yes, delete it !",
        cancelButtonText: "No, cancel !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Your data is safe!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <router-link :to="{ name: 'create-partnership-partner-product' }">
                <b-button class="mr-3" variant="primary" size="sm"
                  ><i class="mdi mdi-plus mr-2"></i> Tambah Produk</b-button
                >
              </router-link>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(shortDescription)="data">
                  <div v-html="data.item.shortDescription"></div>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-partnership-partner-product',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteMember(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
