<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";
import { ModelSelect } from "vue-search-select";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Tambah Forum Group",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    users() {
      return this.$store.getters["user/users"];
    },
  },
  components: { Layout, PageHeader, ModelSelect, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Forum Group" : "Tambah Forum Group",
      id: this.$route.params.id,
      items: [
        {
          text: "Forum Group",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        title: "",
        description: "",
        image: "",
        access: "PUBLIC",
        type: "DISCUSSION",
        securityCode: "",
        regionId: "",
        partnerId: "",
        adminIds: [],
        recommendations: [],
        recommendationProvinceIds: [],
      },
      provinceId: "",
      submitted: false,
      errors: {},
      partners: [],
      regionals: [],
      loading: false,
      usersOptions: [],
      adminId: "",
      userSelect: {},
      onboardings: [],
      provinces: [],
    };
  },
  methods: {
    ...mapActions({
      create: "forumGroup/create",
      detail: "forumGroup/detail",
      update: "forumGroup/update",

      fetchPartners: "forumGroupPartner/fetch",
      fetchRegionals: "forumRegion/fetch",
      fetchUsers: "user/changeFilter",
      detailUser: "user/detail",

      fetchOnboardings: "onboarding/fetch",
      fetchProvinces: "area/fetchProvinces",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-forum-group" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    alreadySelect(id) {
      return this.getIndexCity(id) > -1;
    },

    getIndexCity(id) {
      try {
        return this.form.cityIds.findIndex((city) => city.id === id);
      } catch (error) {
        return -1;
      }
    },

    deleteSelect(id) {
      this.form.cityIds.splice(this.getIndexCity(id), 1);
    },

    changeUser(e) {
      const index = this.usersOptions.findIndex((member) => {
        return member.value === this.userId;
      });

      if (index === -1) {
        this.$store.commit("user/SET_FILTER", {
          key: "roles",
          value: "MEMBER,ADMIN,MENTOR,FASILITATOR",
        });

        this.fetchUsers({ key: "search", value: e });
      }
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },

    getOptions(onboarding) {
      return onboarding.options.map((option) => {
        return { value: option.id, text: option.text };
      });
    },
  },

  watch: {
    async provinceId(val) {
      this.cities = await this.fetchCities(val);
    },

    users: {
      handler(val) {
        this.usersOptions = [];

        Object.assign([], val).forEach((user) => {
          this.usersOptions.push({
            value: user.id,
            text: `${user.name} - ${user.phoneNumber}`,
          });
        });

        if (this.userSelect.id) {
          this.usersOptions.push({
            value: this.userSelect.id,
            text: `${this.userSelect.firstName} ${this.userSelect.lastName} - ${this.userSelect.phoneNumber}`,
          });
        }
      },
      deep: true,
    },

    async adminId(val) {
      this.userSelect = await this.detailUser(val);
      this.form.adminIds = [val];
    },
  },

  async mounted() {
    this.partners = await this.fetchPartners();
    this.regionals = await this.fetchRegionals();
    this.onboardings = await this.fetchOnboardings();
    this.provinces = await this.fetchProvinces();
    this.provinces = this.provinces.map((province) => {
      return { id: province.id, text: province.name };
    });

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      const recommendations = Object.assign([], data.recommendations);

      this.form = data;

      if (data.admins.length > 0) {
        this.adminId = data.admins[0].user.id;
        this.userSelect = await this.detailUser(this.adminId);

        if (this.userSelect.id) {
          this.usersOptions.push({
            value: this.userSelect.id,
            text: `${this.userSelect.firstName} ${this.userSelect.lastName} - ${this.userSelect.phoneNumber}`,
          });
        }
      }

      this.form.recommendations = recommendations
        .filter((onboarding) => onboarding.type !== "PROVINCE")
        .map((onboarding) => {
          return {
            onboardingId: onboarding.question.id,
            question: onboarding.question.question,
            options: onboarding.question.options,
            type: onboarding.question.type,
            selectedIds: onboarding.options.map((option) => option.id),
          };
        });

      try {
        this.form.recommendationProvinceIds = recommendations
          .filter((onboarding) => onboarding.type === "PROVINCE")[0]
          .provinces.map((province) => province.id);
      } catch (error) {
        console.log(error);
      }
    } else {
      this.form.recommendations = this.onboardings.map((onboarding) => {
        return {
          onboardingId: onboarding.id,
          question: onboarding.question,
          options: onboarding.options,
          type: onboarding.type,
          selectedIds: [],
        };
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Judul *)</label>
                    <input
                      id="title"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['title'],
                      }"
                    />
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="description">Deskripsi *)</label>
                    <input
                      id="description"
                      v-model="form.description"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    />
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="access">Jenis Akses *)</label>
                    <select
                      name=""
                      id=""
                      v-model="form.access"
                      class="form-control"
                    >
                      <option value="PUBLIC">Publik</option>
                      <option value="PRIVATE">Private</option>
                    </select>
                    <div v-if="errors['access']" class="invalid-feedback">
                      <span>{{ errors["access"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.access === 'PRIVATE'">
                  <div class="form-group">
                    <label for="securityCode"
                      >Kode Keamanan (Maksimal 6 Angka) *)</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.securityCode"
                    />
                    <div v-if="errors['securityCode']" class="invalid-feedback">
                      <span>{{ errors["securityCode"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="type">Jenis Group *)</label>
                    <select
                      name=""
                      id=""
                      v-model="form.type"
                      class="form-control"
                    >
                      <option value="DISCUSSION">Diskusi</option>
                      <option value="REGIONAL">Daerah</option>
                      <option value="PARTNER">Partner</option>
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Admin *)</label>
                    <model-select
                      placeholder="Pilih Pengguna"
                      :options="usersOptions"
                      v-model="adminId"
                      @searchchange="changeUser"
                    >
                    </model-select>
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.type === 'PARTNER'">
                  <div class="form-group">
                    <label for="partnerId">Partner</label>
                    <select
                      name=""
                      id=""
                      v-model="form.partnerId"
                      class="form-control"
                    >
                      <option value="">Pilih Partner</option>
                      <option
                        :value="partner.id"
                        v-for="partner in partners"
                        :key="partner.id"
                        >{{ partner.name }}</option
                      >
                    </select>
                    <div v-if="errors['partnerId']" class="invalid-feedback">
                      <span>{{ errors["partnerId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.type === 'REGIONAL'">
                  <div class="form-group">
                    <label for="regionId">Wilayah</label>
                    <select
                      name=""
                      id=""
                      v-model="form.regionId"
                      class="form-control"
                    >
                      <option value="">Pilih Wilayah</option>
                      <option
                        :value="regional.id"
                        v-for="regional in regionals"
                        :key="regional.id"
                        >{{ regional.name }}</option
                      >
                    </select>
                    <div v-if="errors['regionId']" class="invalid-feedback">
                      <span>{{ errors["regionId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group">
                <label for="#">Provinsi Rekomendasi</label>
                <Select2
                  v-model="form.recommendationProvinceIds"
                  :options="provinces"
                  :settings="{ multiple: true }"
                />
              </div>

              <br />

              <label for="#">Pengaturan Rekomendasi</label>
              <table class="table table-bordered table-hover table-fixed">
                <tr>
                  <th>Pertanyaan Onboarding</th>
                  <th>Jawaban Onboarding</th>
                </tr>
                <tbody
                  v-for="onboarding in form.recommendations"
                  :key="onboarding.onboardingId"
                >
                  <tr v-if="onboarding.type !== 'TEXT'">
                    <td>{{ onboarding.question }}</td>
                    <td>
                      <b-form-group label="Pilih Jawaban">
                        <b-form-checkbox-group
                          :id="onboarding.onboardingId"
                          :options="getOptions(onboarding)"
                          :name="onboarding.onboardingId"
                          stacked
                          v-model="onboarding.selectedIds"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="image">Gambar *)</label>
                    <input
                      type="file"
                      class="form-control"
                      @change="uploadImage"
                      accept="image/png, image/jpg, image/jpeg"
                    />

                    <div
                      v-if="form.image"
                      style="text-align: center; width: 300px"
                      class="mr-2"
                    >
                      <img
                        :src="form.image"
                        alt=""
                        style="width: 100%; height: 300px; object-fit: contain"
                      />

                      <button class="btn btn-danger" @click="form.image = ''">
                        <i class="fa fa-trash mr-2"></i>
                        <span>Hapus Gambar</span>
                      </button>
                    </div>

                    <div v-if="errors['image']" class="invalid-feedback">
                      <span>{{ errors["image"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
