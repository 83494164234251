import certificationApi from "../../api/certification.api";
import filterDefault from "../../helpers/filter";

export const state = {
  certifications: [],
  certification: {},
  filter: {
    ...filterDefault,
    fields: "id,image,course.title,course.id",
  },
};

export const getters = {
  certifications(state) {
    return state.certifications;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_CERTIFICATIONS(state, certifications) {
    state.certifications = certifications;
  },

  ADD_CERTIFICATION(state, certification) {
    state.certifications.unshift(certification);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CERTIFICATION(state, id) {
    state.certifications.splice(
      state.certifications.findIndex(
        (certification) => certification.id === id
      ),
      1
    );
  },

  SET_CERTIFICATION(state, certification) {
    state.certification = certification;
  },

  CHANGE_CERTIFICATION(state, { id, certification }) {
    state.certifications[
      state.certifications.findIndex((certification) => certification.id === id)
    ] = certification;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await certificationApi.fetch(state.filter);

      commit("SET_CERTIFICATIONS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await certificationApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CERTIFICATION", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true, data: data.data };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await certificationApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CERTIFICATION", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await certificationApi.detail(id);

    // commit("SET_CERTIFICATION", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await certificationApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CERTIFICATION", { id, certification: data.data });

    return { status: true };
  },
};
