import giveawayApi from "../../api/giveaway.api";
import filterDefault from "../../helpers/filter";

export const state = {
  giveaways: [],
  giveaway: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    surveyId: "",
    batchId: "",
    status: "",
    ordering: "-created_at",
  },
  statuses: [
    {
      key: "PROGRESS",
      text: "Sedang Proses",
    },
    {
      key: "SHUFFLE",
      text: "Sedang Dipilih Acak",
    },
    {
      key: "DONE",
      text: "Sudah Selesai",
    },
  ],
  winnerTypes: [
    {
      key: "MANUAL",
      text: "Pilih Secara Manual",
    },
    {
      key: "SURVEY",
      text: "Menyelesaikan Survey",
    },
    {
      key: "CERTIFICATE",
      text: "Memiliki Sertifikat",
    },
    {
      key: "SURVEY_AND_CERTIFICATE",
      text: "Menyelesaikan Survey dan Memiliki Sertifikat",
    },
    {
      key: "SURVEY_OR_CERTIFICATE",
      text: "Menyelesaikan Survey atau Memiliki Sertifikat",
    },
  ],
};

export const getters = {
  giveaways(state) {
    return state.giveaways;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },

  winnerTypes(state) {
    return state.winnerTypes;
  },
};

export const mutations = {
  SET_GIVEAWAYS(state, giveaways) {
    state.giveaways = giveaways;
  },

  ADD_GIVEAWAY(state, giveaway) {
    state.giveaways.unshift(giveaway);
  },

  MERGE_GIVEAWAYS(state, giveaways) {
    state.giveaways = giveaways.concat(state.giveaways);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_GIVEAWAY(state, id) {
    state.giveaways.splice(
      state.giveaways.findIndex((giveaway) => giveaway.id === id),
      1
    );
  },

  SET_GIVEAWAY(state, giveaway) {
    state.giveaway = giveaway;
  },

  CHANGE_GIVEAWAY(state, { id, giveaway }) {
    state.giveaways[
      state.giveaways.findIndex((giveaway) => giveaway.id === id)
    ] = giveaway;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await giveawayApi.fetch(state.filter);

      commit("SET_GIVEAWAYS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await giveawayApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_GIVEAWAY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await giveawayApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_GIVEAWAY", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await giveawayApi.detail(id);

    commit("SET_GIVEAWAY", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await giveawayApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_GIVEAWAY", { id, giveaway: data.data });

    return { status: true };
  },
};
