<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import VueRangedatePicker from "vue-rangedate-picker";
import { mapActions, mapGetters } from "vuex";
// import Select2 from "v-select2-component";

import Revenue from "@/components/dashboard/transaction/Revenue";
import OrderPaid from "@/components/dashboard/transaction/OrderPaid";
import PercentagePaidRatio from "@/components/dashboard/transaction/PercentagePaidRatio";
import MemberPaid from "@/components/dashboard/transaction/MemberPaid";
import MemberOrder from "@/components/dashboard/transaction/MemberOrder";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    VueRangedatePicker,
    // Select2,
    Revenue,
    OrderPaid,
    PercentagePaidRatio,
    MemberPaid,
    MemberOrder,
  },
  data() {
    return {
      title: "Dashboard Pesanan",
      items: [
        {
          text: "Bolu",
        },
        {
          text: "Pesanan",
          active: true,
        },
      ],
      filter: {
        startDate: "",
        endDate: "",
      },
      resumeData: {},
    };
  },

  computed: mapGetters({
    statuses: "order/statuses",
  }),

  watch: {
    "filter.courseArrayIds"(val) {
      this.batchOptions = [];

      val.forEach((id) => {
        const index = this.courses.findIndex((course) => course.id === id);

        this.courses[index].batchs.forEach((batch) => {
          this.batchOptions.push({
            id: batch.id,
            text: `${batch.name} ${this.courses[index].title} -
                        ${this.$options.filters.moment(
                          batch.startDate,
                          "DD MMMM YYYY"
                        )}
                        s/d
                        ${this.$options.filters.moment(
                          batch.finishDate,
                          "DD MMMM YYYY"
                        )}`,
          });
        });
      });

      this.filter.courseIds = val.join(",");
      this.getData();
    },

    "filter.batchArrayIds"(val) {
      this.filter.batchIds = val.join(",");
      this.getData();
    },
  },

  methods: {
    ...mapActions({
      getResumeOrder: "order/getResumeOrder",
    }),

    onDateSelected(daterange) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const startDate = new Date(daterange.start);
      const endDate = new Date(daterange.end);
      this.filter.startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() +
        1}-${startDate.getUTCDate()}`;
      this.filter.endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() +
        1}-${endDate.getUTCDate()}`;

      document.getElementsByClassName(
        "input-date"
      )[0].innerHTML = `${startDate.getUTCDate()} ${
        months[startDate.getUTCMonth()]
      } ${startDate.getUTCFullYear()} - ${endDate.getUTCDate()} ${
        months[endDate.getUTCMonth()]
      } ${endDate.getUTCFullYear()}`;
    },

    async getData() {
      const data = await this.getResumeOrder(this.filter);
      this.resumeData = Object.assign({}, data);
    },
  },

  mounted() {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 2);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.filter.startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() +
      1}-${startDate.getUTCDate()}`;
    this.filter.endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() +
      1}-${endDate.getUTCDate()}`;

    document.getElementsByClassName("input-date")[0].style.width = "100%";
    document.getElementsByClassName(
      "input-date"
    )[0].innerHTML = `${startDate.getUTCDate()} ${
      months[startDate.getUTCMonth()]
    } ${startDate.getUTCFullYear()} - ${endDate.getUTCDate()} ${
      months[endDate.getUTCMonth()]
    } ${endDate.getUTCFullYear()}`;

    this.getData();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 mb-3">
        <div class="row">
          <div class="col-xl-12">
            <div class="form-group">
              <label for="">Pilih Tanggal</label>
              <vue-rangedate-picker @selected="onDateSelected" i18n="ID">
              </vue-rangedate-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4" v-for="status in statuses" :key="status.key">
            <div
              class="card"
              @click="
                $router.push({
                  name: 'list-order',
                  query: { status: status.value },
                })
              "
            >
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">
                      Pesanan {{ status.text }}
                    </p>
                    <h4 class="mb-0">
                      {{ resumeData[status.value.toLowerCase()] }}
                    </h4>
                  </div>
                  <div class="text-primary">
                    <i class="fa fa-users font-size-24`"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <Revenue :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <OrderPaid :params.sync="filter" />
          </div>
          <div class="col-md-4">
            <PercentagePaidRatio :params.sync="filter" />
          </div>
          <div class="col-md-6">
            <MemberPaid :params.sync="filter" />
          </div>
          <div class="col-md-6">
            <MemberOrder :params.sync="filter" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
