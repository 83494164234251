import whatsappGroupWordSearchSummaryWordCategoryApi from "../../api/whatsapp-group-word-search-summary-word-category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupWordSearchSummaryWordCategories: [],
  whatsappGroupWordSearchSummaryWordCategory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupWordSearchSummaryWordCategories(state) {
    return state.whatsappGroupWordSearchSummaryWordCategories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(
    state,
    whatsappGroupWordSearchSummaryWordCategories
  ) {
    state.whatsappGroupWordSearchSummaryWordCategories = whatsappGroupWordSearchSummaryWordCategories;
  },

  ADD_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(
    state,
    whatsappGroupWordSearchSummaryWordCategory
  ) {
    state.whatsappGroupWordSearchSummaryWordCategories.unshift(
      whatsappGroupWordSearchSummaryWordCategory
    );
  },

  MERGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDSS(
    state,
    whatsappGroupWordSearchSummaryWordCategories
  ) {
    state.whatsappGroupWordSearchSummaryWordCategories = whatsappGroupWordSearchSummaryWordCategories.concat(
      state.whatsappGroupWordSearchSummaryWordCategories
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(state, id) {
    state.whatsappGroupWordSearchSummaryWordCategories.splice(
      state.whatsappGroupWordSearchSummaryWordCategories.findIndex(
        (whatsappGroupWordSearchSummaryWordCategory) =>
          whatsappGroupWordSearchSummaryWordCategory.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD(
    state,
    whatsappGroupWordSearchSummaryWordCategory
  ) {
    state.whatsappGroupWordSearchSummaryWordCategory = whatsappGroupWordSearchSummaryWordCategory;
  },

  CHANGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS(
    state,
    { id, whatsappGroupWordSearchSummaryWordCategory }
  ) {
    state.whatsappGroupWordSearchSummaryWordCategories[
      state.whatsappGroupWordSearchSummaryWordCategories.findIndex(
        (whatsappGroupWordSearchSummaryWordCategory) =>
          whatsappGroupWordSearchSummaryWordCategory.id === id
      )
    ] = whatsappGroupWordSearchSummaryWordCategory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const {
        data,
      } = await whatsappGroupWordSearchSummaryWordCategoryApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupWordSearchSummaryWordCategoryApi.create(
      payload
    );
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const {
      data,
    } = await whatsappGroupWordSearchSummaryWordCategoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORDS", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupWordSearchSummaryWordCategoryApi.detail(
      id
    );

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupWordSearchSummaryWordCategoryApi.update(
      id,
      payload
    );

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_WORD_SEARCH_SUMMARY_WORD", {
      id,
      whatsappGroupWordSearchSummaryWordCategory: data.data,
    });

    return { status: true };
  },
};
