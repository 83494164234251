var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['name'],
                    },attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"number"}},[_vm._v("Nomer *)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.number),expression:"form.number"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['number'],
                    },attrs:{"id":"number","type":"text"},domProps:{"value":(_vm.form.number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "number", $event.target.value)}}}),(_vm.errors['number'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["number"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"token"}},[_vm._v("Token *)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.token),expression:"form.token"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['token'],
                    },attrs:{"id":"token","type":"text"},domProps:{"value":(_vm.form.token)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "token", $event.target.value)}}}),(_vm.errors['token'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["token"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipe *)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['type'],
                    },attrs:{"id":"type","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"MANAGEMENT_GROUP"}},[_vm._v("Management Grup")]),_c('option',{attrs:{"value":"BLASTER"}},[_vm._v("Blaster")])]),(_vm.errors['type'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["type"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"host"}},[_vm._v("Host *)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.host),expression:"form.host"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['host'],
                    },attrs:{"id":"host","type":"text"},domProps:{"value":(_vm.form.host)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "host", $event.target.value)}}}),(_vm.errors['host'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["host"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"isBanned"}},[_vm._v("Apakah bot ini terbanned? *)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isBanned),expression:"form.isBanned"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['isBanned'],
                    },attrs:{"id":"isBanned","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "isBanned", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":true}},[_vm._v("Iya")]),_c('option',{domProps:{"value":false}},[_vm._v("TIdak")])]),(_vm.errors['isBanned'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["isBanned"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"isActive"}},[_vm._v("Apakah bot ini aktif? *)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isActive),expression:"form.isActive"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['isActive'],
                    },attrs:{"id":"isActive","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "isActive", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":true}},[_vm._v("Iya Aktif")]),_c('option',{domProps:{"value":false}},[_vm._v("TIdak Aktif")])]),(_vm.errors['isActive'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["isActive"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"device"}},[_vm._v("Device")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.device),expression:"form.device"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['device'],
                    },attrs:{"id":"device","type":"text"},domProps:{"value":(_vm.form.device)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "device", $event.target.value)}}}),(_vm.errors['device'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["device"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"location"}},[_vm._v("Lokasi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.location),expression:"form.location"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['location'],
                    },attrs:{"id":"location","type":"text"},domProps:{"value":(_vm.form.location)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "location", $event.target.value)}}}),(_vm.errors['location'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["location"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"expiredAt"}},[_vm._v("Expired Kartu")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.expiredAt),expression:"form.expiredAt"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['expiredAt'],
                    },attrs:{"id":"expiredAt","type":"date"},domProps:{"value":(_vm.form.expiredAt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "expiredAt", $event.target.value)}}}),(_vm.errors['expiredAt'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["expiredAt"]))])]):_vm._e()])])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }