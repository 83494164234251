<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Tambah Laporan Forum",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Proses Laporan Forum"
        : "Tambah Laporan Forum",
      id: this.$route.params.id,
      items: [
        {
          text: "Laporan Forum",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        description: "",
        image: "",
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "forumReport/detail",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(type) {
      await this.$store.dispatch("forumReport/changeStatus", {
        id: this.form.id,
        status: type,
      });

      this.$router.push({ name: "list-forum-report" });

      this.loading = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name"><b>Nama Pelapor</b></label>
                    <p v-if="form.user">
                      {{ form.user.firstName }} {{ form.user.lastName }}
                    </p>
                    <p v-else>-</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"><b>Pembuat Forum</b></label>
                    <p v-if="form.forum.author">
                      {{ form.forum.author.firstName }}
                      {{ form.forum.author.lastName }}
                    </p>
                    <p v-else>-</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"><b>Isi Laporan</b></label>
                    <p v-if="form.option">
                      {{ form.option.name }}
                    </p>
                    <p v-if="form.message">
                      Pesan : <b>{{ form.message }}</b>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"><b>Detail Forum</b></label>
                    <p v-html="form.forum.content"></p>

                    <div style="display: flex; flex-flow: wrap;">
                      <div
                        v-for="media in form.forum.medias"
                        :key="media.key"
                        class="mr-3"
                      >
                        <img
                          width="200"
                          height="200"
                          v-img
                          :src="media.url"
                          alt=""
                          v-if="media.type === 'IMAGE'"
                        />

                        <video
                          width="320"
                          height="240"
                          controls
                          class="mt-3"
                          v-if="media.type === 'VIDEO'"
                        >
                          <source :src="form.video" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="form.status === 'PROCESS'">
                <button
                  :disabled="loading"
                  class="btn btn-primary mt-4 form-control"
                  type="submit"
                  @click="formSubmit('ACCEPT')"
                >
                  <span v-if="!loading">Setujui Laporan</span>

                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                    v-else
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>

                <button
                  :disabled="loading"
                  class="btn btn-danger mt-3 form-control"
                  type="submit"
                  @click="formSubmit('REJECT')"
                >
                  <span v-if="!loading">Tolak Laporan</span>

                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                    v-else
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
