<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Detail Jawaban Pertanyaan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detail Jawaban Pertanyaan",
      id: this.$route.params.id,
      items: [
        {
          text: "Detail Jawaban Pertanyaan",
          href: "/",
        },
      ],
      form: {
        note: "",
      },
      submitted: false,
      errors: {},
      answers: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "surveyAnswer/detail",
      update: "surveyAnswer/update",
    }),

    async submit() {
      this.loading = true;

      let response = await this.update({
        surveyId: this.$route.params.id,
        answerId: this.$route.params.answerId,
        payload: this.form,
      });

      if (response.status) {
        this.$router.push({ name: "answer-survey" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    const data = await this.detail({
      surveyId: this.$route.params.d,
      answerId: this.$route.params.answerId,
    });
    this.form = Object.assign({}, data);

    this.form.options.forEach((option) => {
      const index = this.answers.findIndex((answer) => {
        return option.option.id === answer.id;
      });

      if (index !== -1) {
        this.answers[index].answer += `, ${option.text}`;
      } else {
        if (option.option) {
          this.answers.push({
            question: option.option.label,
            id: option.option.id,
            answer: option.text,
          });
        }
      }
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <p class="mb-0">
              Survey <b>{{ form.survey.name }}</b>
            </p>
            <p class="mb-0" v-if="form.user">
              Jawaban dari
              <b>{{ form.user.firstName }} {{ form.user.lastName }}</b>
            </p>
            <p>
              Jawaban diterima pada
              <b>{{ form.createdAt | moment("Do MMMM YYYY - HH:mm:SS") }}</b>
            </p>

            <table class="mt-3 table table-bordered table-hovered">
              <tr>
                <th>No.</th>
                <th>Pertanyaan</th>
                <th>Jawaban</th>
              </tr>

              <tr v-for="(answer, index) in answers" :key="answer.id">
                <th>{{ index + 1 }}</th>
                <th>{{ answer.question }}</th>
                <th>{{ answer.answer }}</th>
              </tr>
            </table>

            <hr />

            <div class="form-group">
              <label for="note">Catatan</label>
              <textarea
                v-model="form.note"
                name=""
                id=""
                cols="30"
                rows="10"
                class="form-control"
              ></textarea>

              <button
                :disabled="loading"
                class="mt-4 btn btn-primary form-control"
                @click="submit"
              >
                <span v-if="!loading">Ubah Catatan</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
