<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Modul Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Modul Kelas" : "Buat Modul Kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "Course Module",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Edit" : "Create",
          active: true,
        },
      ],
      form: {
        title: "",
        description: "",
        isActive: true,
        courseId: "",
        sequence: 0,
        point: 0,
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      update: "module/update",
      create: "module/create",
      detail: "module/detail",

      fetchCourse: "course/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-module" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadBanner(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.banner = e.target.result;
      };
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);
      this.form.courseId = this.form.course.id;
    }

    this.fetchCourse();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="courseId">Kelas</label>
                    <select
                      id="courseId"
                      v-model="form.courseId"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                    >
                      <option value="">Pilih Kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="title">Judul</label>
                    <input
                      id="title"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      placeholder="Judul"
                      :class="{
                        'is-invalid': errors['title'],
                      }"
                    />
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="point">Point</label>
                    <input
                      id="point"
                      v-model="form.point"
                      type="number"
                      class="form-control"
                      placeholder="point"
                      :class="{
                        'is-invalid': errors['point'],
                      }"
                    />
                    <div v-if="errors['point']" class="invalid-feedback">
                      <span>{{ errors["point"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="sequence">Urutan</label>
                    <input
                      id="sequence"
                      v-model="form.sequence"
                      type="text"
                      class="form-control"
                      placeholder="sequence"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['sequence'],
                      }"
                    />
                    <div v-if="errors['sequence']" class="invalid-feedback">
                      <span>{{ errors["sequence"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <ckeditor
                      id="description"
                      v-model="form.description"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></ckeditor>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="type">Apakah aktif?</label>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.isActive"
                      name="checkbox-1"
                    >
                      Centang jika modul ini aktif
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
