<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Voucher component
 */
export default {
  page: {
    title: "Master Voucher",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Voucher",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Voucher",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "name", label: "Nama", sortable: true },
        { key: "code", label: "Kode", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        {
          key: "totalAmount",
          label: "Total Diskon / Cashback",
          sortable: true,
        },
        {
          key: "maxAmount",
          label: "Maksimal Diskon / Cashback",
          sortable: true,
        },
        {
          key: "minimumTotalSpending",
          label: "Minimal Total Belanja",
          sortable: true,
        },
        {
          key: "limit",
          label: "Limit",
          sortable: true,
        },
        {
          key: "used",
          label: "Sudah Digunakan",
          sortable: true,
        },
        {
          key: "expiredAt",
          label: "Kadaluarsa",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,

      payloadSendVoucher: {
        type: "",
        expiredAt: "",
        voucherId: "",
      },

      loadingSend: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["voucher/vouchers"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["voucher/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["voucher/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["voucher/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "voucher/total",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "voucher/fetch",
      delete: "voucher/delete",
      changeFilter: "voucher/changeFilter",
      gift: "voucher/gift",
    }),

    deletevoucher(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async sendVoucher() {
      this.loadingSend = true;

      const response = await this.gift(this.payloadSendVoucher);

      this.loadingSend = false;

      if (response.status) {
        this.processImport = response.process;
      } else {
        this.errors = response.errors;

        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat import Jawaban Survey!",
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <router-link :to="{ name: 'create-voucher' }">
                    <b-button class="mr-3" variant="primary" size="sm"
                      ><i class="mdi mdi-plus mr-2"></i> Tambah Voucher
                      Topik</b-button
                    >
                  </router-link>

                  <b-button variant="warning" size="sm" v-b-modal.modal-import
                    >Kirim Voucher Ke Member</b-button
                  >

                  <b-modal
                    centered
                    id="modal-import"
                    title="Kirim Voucher Ke Member"
                    @ok.prevent="sendVoucher"
                    size="xl"
                  >
                    <div class="text-center" v-if="loadingSend">
                      <div
                        class="spinner-border text-center text-primary"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div v-else>
                      <div class="form-group">
                        <label for="#">Voucher</label>
                        <select
                          class="form-control"
                          v-model="payloadSendVoucher.voucherId"
                        >
                          <option value="">Pilih Voucher</option>
                          <option
                            :value="voucher.id"
                            v-for="voucher in tableData"
                            :key="voucher.id"
                            >{{ voucher.name }}</option
                          >
                        </select>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="#">Filter jenis member</label>
                            <select
                              class="form-control"
                              v-model="payloadSendVoucher.type"
                            >
                              <option value="">Pilih jenis filter</option>
                              <option value="all">Semua Member</option>
                              <option value="alreadyLogin"
                                >Member pernah login</option
                              >
                              <option value="manual"
                                >Pilih member manual</option
                              >
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="#">Tanggal Kadaluarsa</label>
                            <input
                              type="date"
                              v-model="payloadSendVoucher.expiredAt"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(type)="data">
                  <p>{{ data.item.type | titleCase }}</p>
                </template>

                <template #cell(maxAmount)="data">
                  <p>{{ data.item.maxAmount | rupiah("Rp. ") }}</p>
                </template>

                <template #cell(totalAmount)="data">
                  <p v-if="!data.item.amountPercentage">
                    {{ data.item.totalAmount | rupiah("Rp. ") }}
                  </p>
                  <p v-else>{{ data.item.totalAmount }}%</p>
                </template>

                <template #cell(minimumTotalSpending)="data">
                  <p>
                    {{ data.item.minimumTotalSpending | rupiah("Rp. ") }}
                  </p>
                </template>

                <template #cell(expiredAt)="data">
                  <p>
                    {{ data.item.expiredAt | moment("D MMMM YYYY") }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-voucher',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deletevoucher(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
