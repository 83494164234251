<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";
import slugify from "slugify";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Tambah Forum",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Forum" : "Tambah Forum",
      id: this.$route.params.id,
      items: [
        {
          text: "Forum",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        title: "",
        content: "",
        slug: "",
        medias: [],
        isAnonymous: false,
        regionId: "",
        forumGroupId: "",
      },
      topics: [],
      typeMedia: "image",
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
      regions: [],
      groups: [],
    };
  },
  methods: {
    ...mapActions({
      create: "forum/create",
      detail: "forum/detail",
      update: "forum/update",

      fetchCategories: "category/fetchCategories",
      fecthRegions: "forumRegion/fetch",
      fecthGroups: "forumGroup/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-forum" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    upload(e) {
      const images = e.target.files;

      images.forEach((image) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          if (this.form.medias.length < 5 && this.typeMedia === "image") {
            this.form.medias.push(e.target.result);
          } else if (this.typeMedia === "video") {
            this.form.medias = [e.target.result];
          }
        };
      });
    },

    updateSlug() {
      this.form.slug = slugify(this.form.title.toLowerCase());
    },
  },

  async mounted() {
    const categories = await this.fetchCategories();
    this.topics = categories.map((category) => {
      return { id: category.id, text: category.name };
    });

    if (this.$route.params.id) {
      this.form = Object.assign({}, await this.detail(this.$route.params.id));
      this.form.topicIds = this.form.categories.map((category) => category.id);
      this.form.medias = this.form.medias.map((media) => media.url);
      this.form.regionId = this.form.region.id;
      this.form.forumGroupId = this.form.forumGroup.id;
    }

    this.regions = await this.fecthRegions();
    this.groups = await this.fecthGroups();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="title">Judul *)</label>
                    <input
                      id="title"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['title'],
                      }"
                      @keyup="updateSlug()"
                    />
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="slug">Slug</label>
                    <input
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['slug'],
                      }"
                    />
                    <div v-if="errors['slug']" class="invalid-feedback">
                      <span>{{ errors["slug"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="slug">Topik *)</label>
                    <Select2
                      v-model="form.topicIds"
                      :options="topics"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['topicIds']" class="invalid-feedback">
                      <span>{{ errors["topicIds"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="content">Konten *)</label>
                    <textarea
                      id="content"
                      v-model="form.content"
                      :class="{
                        'is-invalid': errors['content'],
                      }"
                      class="form-control"
                    ></textarea>
                    <div v-if="errors['content']" class="invalid-feedback">
                      <span>{{ errors["content"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="typeMedia">Tipe Media *)</label>
                    <select v-model="typeMedia" class="form-control">
                      <option value="image">Gambar</option>
                      <option value="video">Video</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="typeMedia">Wilayah</label>
                    <select v-model="form.regionId" class="form-control">
                      <option value="">Pilih Wilayah</option>
                      <option
                        :value="region.id"
                        v-for="region in regions"
                        :key="region.id"
                        >{{ region.name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="form.forumGroupId">Group</label>
                    <select v-model="form.forumGroupId" class="form-control">
                      <option value="">Pilih Group</option>
                      <option
                        :value="group.id"
                        v-for="group in groups"
                        :key="group.id"
                        >{{ group.title }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="typeMedia">Media</label>
                    <input
                      type="file"
                      class="form-control"
                      v-if="typeMedia === 'image'"
                      multiple
                      accept="image/*"
                      @change="upload"
                    />

                    <input
                      type="file"
                      class="form-control"
                      v-if="typeMedia === 'video'"
                      accept="video/*"
                      @change="upload"
                    />

                    <div class="d-flex">
                      <div
                        v-for="(media, index) in form.medias"
                        :key="`media-${index}`"
                        class="mt-3 mr-3"
                        style="width: 150px; height: 150px; text-align: center;"
                      >
                        <img
                          style="width: 100%; height: 100%; object-fit: cover"
                          :src="media"
                          alt=""
                          v-if="typeMedia === 'image'"
                          v-img
                        />

                        <video
                          style="width: 100%; height: 100%; object-fit: cover"
                          :src="media"
                          alt=""
                          v-if="typeMedia === 'video'"
                        />

                        <button
                          class="btn btn-danger mt-3"
                          @click="form.medias.splice(index)"
                        >
                          Hapus Media <i class="ml-1 fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-5 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
