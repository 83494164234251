import notificationTempalteApi from "../../api/notification-template.api";
import filterDefault from "../../helpers/filter";

export const state = {
  notificationTemplates: [],
  forumTopic: {},
  total: 0,
  filter: {
    ...filterDefault,
  },

  orderTemplateHelper: [
    {
      code: "courseTitle",
      description: "Judul dari kelas yang dibeli",
    },
    {
      code: "classStartDate",
      description: "Tanggal mulai kelas (Jika memiliki batch)",
    },
    {
      code: "classFinishDate",
      description: "Tanggal selesai kelas (Jika memiliki batch)",
    },
    {
      code: "courseLink",
      description: "Link untuk menuju ke halaman kelas",
    },
    {
      code: "courseGroupLink",
      description: "Link untuk menuju ke halaman group kelas",
    },
    {
      code: "orderNumber",
      description: "Nomer Pesanan",
    },
    {
      code: "originalPrice",
      description: "Harga asli sebelum dikurangi diskin",
    },
    {
      code: "uniqueCode",
      description: "Kode unik pembayaran",
    },
    {
      code: "totalPrice",
      description: "Total harga",
    },
    {
      code: "discountPromo",
      description: "Diskon Promo",
    },
    {
      code: "discountVoucher",
      description: "Diskon Voucher",
    },
    {
      code: "paymentMethodFull",
      description: "Metode pembayaran",
    },
    {
      code: "paymentMethodBankName",
      description: "Metode pembayaran (Nama Bank)",
    },
    {
      code: "paymentMethodName",
      description: "Metode pembayaran (Nama Pemilik)",
    },
    {
      code: "paymentMethodNumber",
      description: "Metode pembayaran (Nomer Rekening)",
    },
    {
      code: "paymentDeadline",
      description: "Batas waktu pembayaran",
    },
    {
      code: "orderLink",
      description: "Link untuk halaman pesanan",
    },
    {
      code: "orderPaymentLink",
      description: "Link untuk halaman pembayaran pesanan",
    },
    {
      code: "orderUploadEvidenceLink",
      description: "Link untuk halaman upload bukti pembayaran pesanan",
    },
    {
      code: "orderLink",
      description: "Link untuk menuju ke halaman pesanan",
    },
    {
      code: "userName",
      description: "Nama lengkap pengguna",
    },
    {
      code: "userFirstName",
      description: "Nama pertama pengguna",
    },
    {
      code: "userLastName",
      description: "Nama terakhir pengguna",
    },
    {
      code: "userEmail",
      description: "Email pengguna",
    },
    {
      code: "userPhoneNumber",
      description: "Nomer telpon pengguna",
    },
    {
      code: "userUsername",
      description: "Username pengguna",
    },
    {
      code: "userDefaultPassword",
      description: "Password default",
    },
  ],
};

export const getters = {
  notificationTemplates(state) {
    return state.notificationTemplates;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },

  orderTemplateHelper(state) {
    return state.orderTemplateHelper;
  },
};

export const mutations = {
  SET_NOTIFICATION_TEMPLATES(state, notificationTemplates) {
    state.notificationTemplates = notificationTemplates;
  },

  ADD_NOTIFICATION_TEMPLATE(state, forumTopic) {
    state.notificationTemplates.unshift(forumTopic);
  },

  MERGE_NOTIFICATION_TEMPLATES(state, notificationTemplates) {
    state.notificationTemplates = notificationTemplates.concat(
      state.notificationTemplates
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_NOTIFICATION_TEMPLATE(state, id) {
    state.notificationTemplates.splice(
      state.notificationTemplates.findIndex(
        (forumTopic) => forumTopic.id === id
      ),
      1
    );
  },

  SET_NOTIFICATION_TEMPLATE(state, forumTopic) {
    state.forumTopic = forumTopic;
  },

  CHANGE_NOTIFICATION_TEMPLATE(state, { id, forumTopic }) {
    state.notificationTemplates[
      state.notificationTemplates.findIndex(
        (forumTopic) => forumTopic.id === id
      )
    ] = forumTopic;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await notificationTempalteApi.fetch(state.filter);

      commit("SET_NOTIFICATION_TEMPLATES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await notificationTempalteApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_NOTIFICATION_TEMPLATE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await notificationTempalteApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_NOTIFICATION_TEMPLATE", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await notificationTempalteApi.detail(id);

    commit("SET_NOTIFICATION_TEMPLATE", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await notificationTempalteApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_NOTIFICATION_TEMPLATE", { id, forumTopic: data.data });

    return { status: true };
  },
};
