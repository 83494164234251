import api from "./index";

const baseURL = "/user-course-certificates";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const download = (params) => api.get(`${baseURL}/download`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const refresh = (id) => api.patch(`${baseURL}/${id}/refresh`);

export default { fetch, detail, download, refresh };
