<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Detail Whatsapp Blaster",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detail Whatsapp Blaster",
      items: [
        {
          text: "Whatsapp Blaster",
          href: "/whatsapp-blaster",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      perPage: 10,
      search: "",
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "updatedAt",
      sortDesc: true,
      fields: [
        { key: "user", label: "Member", sortable: false },
        { key: "phoneNumber", label: "Nomer Telpon", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "host", label: "Nama BOT", sortable: false },
        { key: "seenAt", label: "Dilihat Pada", sortable: false },
        { key: "updatedAt", label: "Update Terakhir", sortable: false },
      ],
      data: [],
      loading: false,
      index: -1,
      showModal: false,
      indexSelect: -1,
      timelineItems: [],

      total: 0,
      params: {
        ordering: "created_at",
        search: "",
        limit: 25,
        page: 1,
        status: "",
        alreadyRead: "",
      },
    };
  },

  watch: {
    params: {
      async handler() {
        await this.fetchQueue();
      },
      deep: true,
    },
  },

  methods: {
    clickShowHistories(index) {
      this.showModal = true;
      this.indexSelect = index;

      this.timelineItems = [];

      this.timelineItems.push({
        title: "PENDING",
        timestamp: new Date(this.data[index].createdAt),
      });

      this.data[index].historyStatuses.forEach((status) => {
        this.timelineItems.push({
          title: status.name,
          timestamp: new Date(status.createdAt),
        });
      });
    },

    async fetchQueue() {
      this.loading = true;

      const { data, total } = await this.$store.dispatch(
        "whatsappBlaster/getQueue",
        {
          id: this.$route.params.id,
          params: this.params,
        }
      );

      this.data = data;
      this.total = total;

      // title, timestamp

      this.loading = false;
    },
  },

  async mounted() {
    await this.fetchQueue();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-modal
              centered
              id="modal-whatsapp-blaster-history"
              title="Histori Whatsapp Blaster"
              size="md"
              hide-footer
              v-model="showModal"
            >
              <div class="row">
                <div class="col-lg-12">
                  <b-timeline
                    :items="timelineItems"
                    :reverse="false"
                    date-format="dd MMMM yyyy HH:mm:ss"
                    variant="primary"
                    :human-friendly-time="false"
                  />
                </div>
              </div>
            </b-modal>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="params.limit"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="params.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="data"
                :fields="fields"
                responsive="sm"
                :busy="loading"
                :per-page.sync="params.limit"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(user)="data">
                  <p v-if="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(status)="data">
                  <p v-if="data">
                    {{ data.item.status }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(host)="data">
                  <div v-if="data.item.host">
                    <p class="m-0">{{ data.item.host.name }}</p>
                    <p>{{ data.item.host.number }}</p>
                  </div>
                </template>

                <template #cell(seenAt)="data">
                  <p v-if="data.item.seenAt">
                    {{ data.item.seenAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                  <p v-else>Belum dilihat</p>
                </template>

                <template #cell(updatedAt)="data">
                  <span v-if="data">
                    {{ data.item.updatedAt | moment("Do MMMM YYYY, HH:mm:ss") }}
                  </span>
                  <span v-else>-</span>
                  <b-button
                    class="ml-1"
                    variant="warning"
                    size="sm"
                    @click="clickShowHistories(data.index)"
                  >
                    <i class="ri-eye-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="params.page"
                      :total-rows="total"
                      :per-page="params.limit"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
