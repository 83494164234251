import api from "./index";

const baseURL = "/whatsapp-group-greeting-histories";

const fetch = (params) => api.get(`${baseURL}`, { params });

const resend = (id) => api.patch(`${baseURL}/${id}/resend`);

const syncTotalRead = (id) => api.patch(`${baseURL}/${id}/sync-total-read`);

const updateTotalRead = (id) => api.patch(`${baseURL}/${id}/update-total-read`);

export default { fetch, resend, syncTotalRead, updateTotalRead };
