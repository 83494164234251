<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Sertifikat Pengguna component
 */
export default {
  page: {
    title: "Master Sertifikat Pengguna",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Sertifikat Pengguna",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Sertifikat Pengguna",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "number", label: "Nomor" },
        { key: "user", label: "Member" },
        { key: "course", label: "Kelas" },
        { key: "batch", label: "Batch" },
        { key: "image", label: "Sertifikat" },
        { key: "createdAt", label: "Dibuat Pada" },
        { key: "action", label: "Aksi" },
      ],
      loading: false,
      loadingRefresh: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["userCourseCertificate/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["userCourseCertificate/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["userCourseCertificate/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    courseId: {
      get() {
        return this.$store.getters["userCourseCertificate/filter"].courseId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "courseId", value: val });
        await this.changeFilter({ key: "batchId", value: null });
        await this.fetchBatch({ key: "courseId", value: val });
        this.loading = false;
      },
    },

    batchId: {
      get() {
        return this.$store.getters["userCourseCertificate/filter"].batchId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "batchId", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      users: "user/users",
      total: "userCourseCertificate/total",
      batchs: "batch/batchs",
      tableData: "userCourseCertificate/userCourseCertificates",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    await this.fetchCourse();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "userCourseCertificate/fetch",
      download: "userCourseCertificate/download",
      changeFilter: "userCourseCertificate/changeFilter",
      fetchCourse: "course/fetch",
      fetchBatch: "batch/changeFilter",
    }),

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async downloadUserCourseCertificate() {
      this.loading = true;
      const response = await this.download(this.$route.params.id);

      window.open(response.url);

      this.loading = false;
    },

    async refresh(id) {
      this.loadingRefresh = true;
      await this.$store.dispatch("userCourseCertificate/refresh", id);
      this.$refs.userTable.refresh();
      this.loadingRefresh = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="row mb-3">
              <div class="col-lg-4">
                <div class="row">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="downloadUserCourseCertificate"
                  >
                    Download Daftar Sertifikat
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="row">
                  <select v-model="courseId" name="" id="" class="form-control">
                    <option value="">Pilih kelas</option>
                    <option
                      :value="course.id"
                      v-for="course in courses"
                      :key="course.id"
                    >
                      {{ course.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4">
                <select v-model="batchId" name="" id="" class="form-control">
                  <option value="">Pilih Batch</option>
                  <option
                    :value="batch.id"
                    v-for="batch in batchs"
                    :key="batch.id"
                  >
                    {{ batch.name }} -
                    {{ batch.startDate | moment("DD MMMM YYYY") }}
                    s/d
                    {{ batch.finishDate | moment("DD MMMM YYYY") }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                ref="userTable"
              >
                <template #cell(course)="data">
                  <p v-if="data.item.userCourse">
                    {{ data.item.userCourse.course.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(user)="data">
                  <div v-if="data.item.user">
                    <p class="m-0">
                      {{ data.item.user.firstName }}
                      {{ data.item.user.lastName }}
                    </p>
                    <p class="m-0">
                      <a :href="`mailto:${data.item.user.email}`">{{
                        data.item.user.email
                      }}</a>
                    </p>
                    <p class="m-0">
                      <a
                        :href="`https://wa.me/${data.item.user.phoneNumber}`"
                        >{{ data.item.user.phoneNumber }}</a
                      >
                    </p>
                  </div>
                  <p v-else>-</p>
                </template>

                <template #cell(batch)="data">
                  <p v-if="data.item.userCourse">
                    {{ data.item.userCourse.batch.name }} -
                    {{
                      data.item.userCourse.batch.startDate
                        | moment("DD MMMM YYYY")
                    }}
                    s/d
                    {{
                      data.item.userCourse.batch.finishDate
                        | moment("DD MMMM YYYY")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(image)="data">
                  <a
                    v-if="data.item.image"
                    :href="data.item.image"
                    target="_blank"
                  >
                    Download Sertifikat
                  </a>
                  <p v-else>-</p>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(action)="data">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="refresh(data.item.id)"
                    v-if="!data.item.refresh"
                  >
                    <div
                      class="spinner-border spinner-border-sm text-center text-primary"
                      role="status"
                      v-if="loadingRefresh"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span>Refresh</span>
                  </button>

                  <p v-else>Menunggu Pengguna</p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
