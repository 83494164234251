<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master whatsappGroupGreetingHistory component
 */
export default {
  page: {
    title: "History Absensi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "History Absensi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "History Absensi",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      fields: [
        { key: "group", label: "Group" },
        { key: "template", label: "Template" },
        { key: "host", label: "Bot" },
        { key: "category", label: "Kategori" },
        { key: "totalViews", label: "Total Dilihat" },
        { key: "messageWhatsappId", label: "Sudah dikirim" },
        { key: "createdAt", label: "Tanggal" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,

      resendIds: [],
      syncTotalReadIds: [],

      payloadUpdateTotalViews: {
        totalViews: 0,
      },
      idSelected: -1,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters[
        "whatsappGroupGreetingHistory/whatsappGroupGreetingHistories"
      ];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappGroupGreetingHistory/filter"]
          .search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappGroupGreetingHistory/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappGroupGreetingHistory/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappGroupGreetingHistory/total",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;

    setInterval(() => {
      if (this.index > -1) {
        this.getQrCode();
      }
    }, 1000);
  },
  methods: {
    ...mapActions({
      fetch: "whatsappGroupGreetingHistory/fetch",
      delete: "whatsappGroupGreetingHistory/delete",
      changeFilter: "whatsappGroupGreetingHistory/changeFilter",

      resend: "whatsappGroupGreetingHistory/resend",
      syncTotalRead: "whatsappGroupGreetingHistory/syncTotalRead",
      updateTotalRead: "whatsappGroupGreetingHistory/updateTotalRead",
    }),

    deleteUserRegion(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, ubah !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    async actionResend(id) {
      this.resendIds.push(id);

      await this.resend(id);
    },

    async actionSyncTotalRead(id) {
      this.syncTotalReadIds.push(id);

      await this.syncTotalRead(id);
    },

    async updateTotalReadBot() {
      this.$swal({
        text:
          "Apakah kamu yakin untuk mengubah data total read pada absen ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          this.updateTotalRead({
            id: this.idSelected,
            payload: this.payloadUpdateTotalViews,
          });
          this.idSelected = -1;
        } else {
          this.idSelected = -1;
        }
      });
    },

    async editTotalReadBot(id) {
      this.idSelected = id;
      const index = this.tableData.findIndex((data) => data.id === id);

      if (index > -1)
        this.payloadUpdateTotalViews.totalViews = this.tableData[
          index
        ].totalViews;
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>
                <template #cell(isActive)="data">
                  <p>
                    {{ data.item.isActive | convertBoolean }}
                  </p>
                </template>

                <template #cell(text)="data">
                  <p v-html="data.item.text"></p>
                </template>

                <template #cell(host)="data">
                  <p>{{ data.item.host ? data.item.host.name : "-" }}</p>
                </template>

                <template #cell(group)="data">
                  <p>{{ data.item.group.name }}</p>
                </template>

                <template #cell(totalViews)="data">
                  <p v-if="data.item.id !== idSelected">
                    <span>{{ data.item.totalViews }}</span
                    ><i
                      class="ml-2 ri-pencil-line"
                      @click="editTotalReadBot(data.item.id)"
                    ></i>
                  </p>
                  <input
                    type="number"
                    v-model="payloadUpdateTotalViews.totalViews"
                    class="form-control"
                    v-else
                    @keyup.enter="updateTotalReadBot"
                  />
                </template>

                <template #cell(template)="data">
                  <p>{{ data.item.template.name }}</p>
                </template>

                <template #cell(createdAt)="data">
                  <p>{{ data.item.createdAt | moment("D MMMM yyyy") }}</p>
                </template>

                <template #cell(messageWhatsappId)="data">
                  <p>{{ data.item.messageWhatsappId ? "Sudah" : "Belum" }}</p>
                </template>

                <template #cell(category)="data">
                  <p>
                    {{ data.item.category === "MORNING" ? "Pagi" : "Malam" }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3 mb-2"
                    variant="primary"
                    size="sm"
                    @click="actionResend(data.item.id)"
                    :disabled="resendIds.includes(data.item.id)"
                    >Kirim Ulang</b-button
                  >

                  <b-button
                    variant="warning"
                    size="sm"
                    @click="actionSyncTotalRead(data.item.id)"
                    :disabled="syncTotalReadIds.includes(data.item.id)"
                    >Sync Total Dilihat</b-button
                  >
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
