<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master whatsappGroupImport component
 */
export default {
  page: {
    title: "Whatsapp Group Import",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Whatsapp Group Import",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Whatsapp Group Import",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "group", label: "Grup", sortable: true },
        { key: "file", label: "File", sortable: true },
        {
          key: "whatsappGroupHistories",
          label: "Total Keluar Masuk",
          sortable: true,
        },
        { key: "whatsappGroupChats", label: "Total Chat", sortable: true },
        { key: "pendings", label: "Total Pending", sortable: true },
        { key: "createdAt", label: "Tanggal Import" },
      ],
      loading: false,
      qrcode: "",
      index: -1,
      showModal: false,
      importForm: {
        groupId: "",
        file: "",
      },
      loadingImport: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["whatsappGroupImport/whatsappGroupImports"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappGroupImport/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappGroupImport/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappGroupImport/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappGroupImport/total",
      groups: "whatsappGroup/whatsappGroups",
    }),
  },
  watch: {
    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    showModal(val) {
      if (!val) this.index = -1;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();

    if (this.isPartner()) {
      await this.fechGroups([
        { key: "ordering", value: "name" },
        { key: "limit", value: 999 },
        { key: "partnerId", value: this.user.partner.id },
      ]);
    } else {
      await this.fechGroups([
        { key: "ordering", value: "name" },
        { key: "limit", value: 999 },
      ]);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "whatsappGroupImport/fetch",
      create: "whatsappGroupImport/create",
      delete: "whatsappGroupImport/delete",
      changeFilter: "whatsappGroupImport/changeFilter",

      fechGroups: "whatsappGroup/changeFilters",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    deleteUserRegion(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    async importData() {
      this.loadingImport = true;
      await this.create(this.importForm);
      await this.fetch();
      this.loadingImport = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <b-button
                        class="mr-3"
                        variant="primary"
                        size="sm"
                        v-b-modal.modal-import
                        >Import Data Grup</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </h4>

            <b-modal
              centered
              id="modal-import"
              title="Import Data"
              @ok.prevent="importData"
              size="lg"
            >
              <b-alert
                :variant="notification.type"
                class="mb-3"
                dismissible
                @dismissed="$store.dispatch('notification/clear')"
                v-if="notification.message"
                show
                >{{ notification.message }}</b-alert
              >
              <div v-if="!loadingImport">
                <br />
                <div class="form-group">
                  <label for="">Group</label>
                  <select v-model="importForm.groupId" class="form-control">
                    <option value="Pilih Grup"></option>
                    <option
                      :value="group.id"
                      v-for="group in groups"
                      :key="group.id"
                      >{{ group.name }}</option
                    >
                  </select>
                </div>

                <div class="form-group">
                  <label for="">File .txt</label>
                  <input
                    @change="uploadFileImport"
                    type="file"
                    name=""
                    id=""
                    accept=".txt"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="text-center" v-else>
                <div
                  class="spinner-border text-center text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </b-modal>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(group)="data">
                  <p v-if="data.item.group">
                    {{ data.item.group.name }}
                  </p>
                </template>

                <template #cell(file)="data">
                  <a
                    :href="data.item.file"
                    target="_blank"
                    class="btn btn-primary btn-sm"
                    v-if="data.item.file"
                  >
                    Download File
                  </a>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
