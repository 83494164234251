var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['name'],
                    },attrs:{"id":"validationCustom01","type":"text","placeholder":"Nama","value":"Mark"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v("Nomer Telpon")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phoneNumber),expression:"form.phoneNumber"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['phoneNumber'],
                    },attrs:{"id":"phoneNumber","type":"text","placeholder":"Nomer Telpon","value":"Mark"},domProps:{"value":(_vm.form.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phoneNumber", $event.target.value)}}}),(_vm.errors['phoneNumber'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["phoneNumber"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['email'],
                    },attrs:{"id":"email","type":"text","placeholder":"E-mail","value":"Mark"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.errors['email'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["email"]))])]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('br')]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"typeId"}},[_vm._v("Tipe")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.typeId),expression:"form.typeId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['typeId'],
                    },attrs:{"id":"typeId"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "typeId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Tipe Partner")]),_vm._l((_vm.types),function(type){return _c('option',{key:type.id,domProps:{"value":type.id}},[_vm._v(_vm._s(type.name))])})],2),(_vm.errors['typeId'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["typeId"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"categoryId"}},[_vm._v("Kategori")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.categoryId),expression:"form.categoryId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['categoryId'],
                    },attrs:{"id":"categoryId"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "categoryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Kategori Partner")]),_vm._l((_vm.categories),function(category){return _c('option',{key:category.id,domProps:{"value":category.id}},[_vm._v(_vm._s(category.name))])})],2),(_vm.errors['categoryId'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["categoryId"]))])]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('br')]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pic"}},[_vm._v("PIC")]),_c('table',{staticClass:"table table-hover table-bordered"},[_c('tr',[_c('th',[_vm._v("Nama")]),_c('th',[_vm._v("Nomer Telpon")]),_c('th',[_vm._v("E-mail")]),_c('th',[_vm._v("Posisi")]),_c('th',[_vm._v("Aksi")])]),_vm._l((_vm.form.pics),function(pic,index){return _c('tr',{key:("index-pic-" + index)},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(pic.name),expression:"pic.name"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['name'],
                          },attrs:{"id":"name","type":"text","placeholder":"Nama PIC","value":"Mark"},domProps:{"value":(pic.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(pic, "name", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(pic.phoneNumber),expression:"pic.phoneNumber"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['phoneNumber'],
                          },attrs:{"id":"phoneNumber","type":"text","placeholder":"Nomer Telpon PIC","value":"Mark"},domProps:{"value":(pic.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(pic, "phoneNumber", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(pic.email),expression:"pic.email"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['email'],
                          },attrs:{"id":"email","type":"text","placeholder":"E-mail PIC","value":"Mark"},domProps:{"value":(pic.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(pic, "email", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(pic.position),expression:"pic.position"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.errors['position'],
                          },attrs:{"id":"position","type":"text","placeholder":"Posisi PIC","value":"Mark"},domProps:{"value":(pic.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(pic, "position", $event.target.value)}}})]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.pics.splice(index, 1)}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('button',{staticClass:"btn btn-warning form-control btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.form.pics.push({
                              name: '',
                              phoneNumber: '',
                              email: '',
                              position: '',
                            })}}},[_vm._v(" Tambah PIC ")])])])],2)])]),_c('div',{staticClass:"col-12"},[_c('br')]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"website"}},[_vm._v("Website")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.website),expression:"form.website"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['website'],
                    },attrs:{"id":"website","type":"text","placeholder":"Website","value":"Mark"},domProps:{"value":(_vm.form.website)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "website", $event.target.value)}}}),(_vm.errors['website'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["website"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"instagram"}},[_vm._v("Instagram")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.instagram),expression:"form.instagram"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['instagram'],
                    },attrs:{"id":"instagram","type":"text","placeholder":"Instagram","value":"Mark"},domProps:{"value":(_vm.form.instagram)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "instagram", $event.target.value)}}}),(_vm.errors['instagram'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["instagram"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tiktok"}},[_vm._v("Tiktok")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tiktok),expression:"form.tiktok"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['tiktok'],
                    },attrs:{"id":"tiktok","type":"text","placeholder":"Tiktok","value":"Mark"},domProps:{"value":(_vm.form.tiktok)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "tiktok", $event.target.value)}}}),(_vm.errors['tiktok'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["tiktok"]))])]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('br')]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"additionalNotes"}},[_vm._v("Catatan Tambahan")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.additionalNotes),expression:"form.additionalNotes"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['additionalNotes'],
                    },attrs:{"id":"additionalNotes","type":"text","placeholder":"Catatan Tambahan","value":"Mark"},domProps:{"value":(_vm.form.additionalNotes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "additionalNotes", $event.target.value)}}}),_vm._v(" "),(_vm.errors['additionalNotes'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["additionalNotes"]))])]):_vm._e()])])]),_c('button',{staticClass:"btn btn-primary form-control mt-4",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }