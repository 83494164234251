<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Detail Jawaban Pertanyaan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detail Jawaban Pertanyaan",
      id: this.$route.params.id,
      items: [
        {
          text: "Detail Jawaban Pertanyaan",
          href: "/",
        },
      ],
      form: {
        note: "",
      },
      submitted: false,
      errors: {},
      answers: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "exam/detailExamAnswer",
      update: "exam/update",
    }),

    async submit() {
      this.loading = true;

      let response = await this.update({
        surveyId: this.$route.params.id,
        answerId: this.$route.params.answerId,
        payload: this.form,
      });

      if (response.status) {
        this.$router.push({ name: "answer-survey" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    isCorrect(questionId) {
      try {
        return this.form.questions.filter(
          (question) => question.question.id === questionId
        )[0].correct
          ? "Benar"
          : "Salah";
      } catch (error) {
        return "Salahh";
      }
    },

    isAnswer(questionId, optionId) {
      try {
        const questionIndex = this.form.questions.findIndex(
          (question) => question.question.id === questionId
        );
        const optionIndex = this.form.questions[
          questionIndex
        ].options.findIndex((option) => option.id === optionId);

        return optionIndex > -1;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    const data = await this.detail({
      examIdId: this.$route.params.d,
      answerId: this.$route.params.answerId,
    });

    this.form = Object.assign({}, data);

    // this.form.options.forEach((option) => {
    //   const index = this.answers.findIndex((answer) => {
    //     return option.option.id === answer.id;
    //   });

    //   if (index !== -1) {
    //     this.answers[index].answer += `, ${option.text}`;
    //   } else {
    //     if (option.option) {
    //       this.answers.push({
    //         question: option.option.label,
    //         id: option.option.id,
    //         answer: option.text,
    //       });
    //     }
    //   }
    // });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <p class="mb-0" v-if="form.user">
              Jawaban dari
              <b>{{ form.user.firstName }} {{ form.user.lastName }}</b>
            </p>
            <p>
              Jawaban diterima pada
              <b>{{ form.createdAt | moment("Do MMMM YYYY - HH:mm:SS") }}</b>
            </p>

            <p class="mb-0">
              Selesai dalam :
              <b>{{ form.finishTime }} menit</b>
            </p>
            <p class="mb-0">
              Jawaban Benar :
              <b>{{ form.totalTrueAnswer }}</b>
            </p>

            <p class="mb-0">
              Jawaban Salah :
              <b>{{ form.totalFalseAnswer }}</b>
            </p>

            <p>
              Skor :
              <b>{{ form.score }}</b>
            </p>

            <div
              class="card"
              v-for="question in form.exam.questions"
              :key="question.id"
            >
              <div class="p-3">
                <p class="font-weight-bold">
                  Status Jawaban : {{ isCorrect(question.id) }}
                </p>

                <p class="font-weight-bold mb-0">Pertanyaan :</p>
                <div v-html="question.question"></div>

                <hr />

                <p class="font-weight-bold mb-0">Jawaban :</p>
                <ul>
                  <li
                    class="mt-3"
                    v-for="option in question.options"
                    :key="option.id"
                  >
                    {{ option.text }}

                    <span class="ml-2 badge badge-primary" v-if="option.correct"
                      >Jawaban Benar</span
                    >

                    <span
                      class="ml-2 badge badge-danger"
                      v-if="isAnswer(question.id, option.id)"
                      >Jawaban Member</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
