<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  page: {
    title: "Tambah Kuis",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      courses: "course/courses",
      courseModules: "module/modules",
      courseModuleItems: "submodule/submodules",
      types: "exam/types",
      questionTypes: "exam/questionTypes",
    }),
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: this.$route.params.id ? "Ubah kuis" : "Tambah kuis",
      id: this.$route.params.id,
      items: [
        {
          text: "kuis",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        type: "",
        courseId: "",
        courseModuleId: "",
        courseModuleItemId: "",
        minimalScore: "",
        questions: [
          {
            question: "",
            type: "",
            time: 1,
            options: [
              {
                text: "",
                correct: false,
              },
            ],
          },
        ],
      },
      loading: false,
      submitted: false,
      errors: {},
    };
  },

  methods: {
    ...mapActions({
      update: "exam/update",
      create: "exam/create",
      detail: "exam/detail",

      fetchCourse: "course/fetch",
      fetchCourseModule: "module/changeFilter",
      fetchCourseModuleItem: "submodule/changeFilter",
    }),

    addOption() {
      this.form.questions.push({
        question: "",
        type: "",
        time: 1,
        options: [
          {
            text: "",
            correct: false,
          },
        ],
      });
    },

    addOptionText(index) {
      this.form.questions[index].options.push({ text: "", correct: false });
    },

    haveOption(type) {
      return type === "SELECT" || type === "CHECKBOX" || type === "RADIO";
    },

    uploadBackgroundImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.backgroundImage = e.target.result;
      };
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-exam" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },

    changeCourseId() {
      this.fetchCourseModule({ key: "courseId", value: this.form.courseId });

      this.form.courseModuleId = "";
    },

    changeCourseModuleId() {
      this.fetchCourseModuleItem({
        key: "moduleId",
        value: this.form.courseModuleId,
      });

      this.form.courseModuleItemId = "";
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);

      if (this.form.course) {
        this.form.courseId = this.form.course.id;
        this.changeCourseId();
      }

      if (this.form.courseModule) {
        this.form.courseModuleId = this.form.courseModule.id;
        this.changeCourseModuleId();
      }

      if (this.form.courseModuleItem) {
        this.form.courseModuleItemId = this.form.courseModuleItem.id;
      }
    }

    await this.fetchCourse();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Tipe</label>
                    <select
                      name="type"
                      id="type"
                      v-model="form.type"
                      class="form-control"
                    >
                      <option value="">Pilih tipe kuis</option>
                      <option
                        :value="type.key"
                        v-for="type in types"
                        :key="type.key"
                        >{{ type.text }}</option
                      >
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="minimalScore">Minimal Skor</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="form.minimalScore"
                    />
                    <div v-if="errors['minimalScore']" class="invalid-feedback">
                      <span>{{ errors["minimalScore"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4" v-if="form.type !== ''">
                  <div class="form-group">
                    <label for="examId">Kelas</label>
                    <select
                      v-model="form.courseId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                      @change="changeCourseId()"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-4"
                  v-if="form.type === 'MODULE' || form.type === 'SUBMODULE'"
                >
                  <div class="form-group">
                    <label for="courseModuleId">Modul</label>
                    <select
                      v-model="form.courseModuleId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseModuleId'],
                      }"
                      @change="changeCourseModuleId()"
                    >
                      <option value="">Pilih Modul</option>
                      <option
                        :value="courseModule.id"
                        v-for="courseModule in courseModules"
                        :key="courseModule.id"
                        >{{ courseModule.title }}</option
                      >
                    </select>
                    <div
                      v-if="errors['courseModuleId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["courseModuleId"] }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="form.type === 'SUBMODULE'" class="col-md-4">
                  <div class="form-group">
                    <label for="courseModuleItemId">Submodul</label>
                    <select
                      v-model="form.courseModuleItemId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseModuleItemId'],
                      }"
                    >
                      <option value="">Pilih Submodul</option>
                      <option
                        :value="courseModuleItem.id"
                        v-for="courseModuleItem in courseModuleItems"
                        :key="courseModuleItem.id"
                        >{{ courseModuleItem.title }}</option
                      >
                    </select>
                    <div
                      v-if="errors['courseModuleItemId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["courseModuleItemId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="backgroundImage">Pertanyaan</label>
                    <draggable v-model="form.questions" draggable=".item">
                      <div
                        v-for="(question, index) in form.questions"
                        :key="index"
                        class="item"
                      >
                        <div class="row">
                          <div class="col-lg-11">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="questionType"
                                    >Tipe pertanyaan</label
                                  >
                                  <select
                                    name="type"
                                    id="questionType"
                                    class="form-control"
                                    v-model="question.type"
                                  >
                                    <option value=""
                                      >Pilih tipe pertanyaan</option
                                    >
                                    <option
                                      :value="type.key"
                                      v-for="type in questionTypes"
                                      :key="`${index}-${type.key}`"
                                      >{{ type.text }}</option
                                    >
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="questionTime"
                                    >Waktu pertanyaan (Menit)</label
                                  >
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="question.time"
                                  />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="label">Pertanyaan</label>
                                  <quill-editor
                                    v-model="question.question"
                                    height="100"
                                  />
                                </div>
                              </div>

                              <div class="col-lg-12">
                                <label>Options</label>
                                <draggable
                                  v-model="question.options"
                                  draggable=".item"
                                >
                                  <div
                                    v-for="(item,
                                    indexItem) in question.options"
                                    :key="`${indexItem}-${index}`"
                                    class="item"
                                  >
                                    <div class="row">
                                      <div class="col-lg-11">
                                        <div class="form-group">
                                          <label for="name">Teks Option</label>
                                          <input
                                            v-model="item.text"
                                            type="text"
                                            class="form-control"
                                          />
                                        </div>

                                        <div
                                          class="form-check"
                                          v-if="
                                            question.type === 'MULTIPLE_CHOICE'
                                          "
                                        >
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :value="true"
                                            v-model="item.correct"
                                            :id="
                                              `checkbox-${indexItem}-${index}`
                                            "
                                          />
                                          <label
                                            class="form-check-label"
                                            :for="
                                              `checkbox-${indexItem}-${index}`
                                            "
                                          >
                                            Apakah ini adalah jawaban
                                          </label>
                                        </div>

                                        <div
                                          class="form-check"
                                          v-if="
                                            question.type === 'SINGLE_CHOICE'
                                          "
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            :name="`answer-${index}`"
                                            :id="`answer-${indexItem}-${index}`"
                                            :value="true"
                                            v-model="item.correct"
                                          />
                                          <label
                                            class="form-check-label"
                                            :for="
                                              `answer-${indexItem}-${index}`
                                            "
                                          >
                                            Apakah ini adalah jawaban
                                          </label>
                                        </div>
                                      </div>

                                      <div class="col-lg-1">
                                        <button
                                          class="btn btn-danger btn-sm"
                                          @click="
                                            question.options.splice(
                                              indexItem,
                                              1
                                            )
                                          "
                                          type="button"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    type="button"
                                    class="btn btn-primary form-control"
                                    slot="footer"
                                    @click="addOptionText(index)"
                                    v-if="question.type !== 'TEXT'"
                                  >
                                    Tambah Option Pertanyaan
                                  </button>
                                </draggable>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-1">
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click="form.questions.splice(index, 1)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary form-control"
                        slot="footer"
                        @click="addOption"
                        type="button"
                      >
                        Tambah Pertanyaan
                      </button>
                    </draggable>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                :disabled="loading"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.item {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
