<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Aktivasi Kelas component
 */
export default {
  page: {
    title: "Aktivasi Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Aktivasi Kelas",
      items: [
        {
          text: "Aktivasi Kelas",
          href: "/activation-class",
        },
        {
          text: "Aktivasi Kelas",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "course", label: "Kelas" },
        { key: "batch", label: "Batch Kelas" },
        { key: "user", label: "Member" },
        { key: "alreadyJoinGroup", label: "Sudah Join Group" },
        { key: "status" },
        { key: "typePayment", label: "Tipe Pembayaran Kelas" },
        { key: "typeEnroll", label: "Tipe Enroll Kelas" },
        { key: "expiredAt", label: "Tanggal Kadaluarsa" },
        { key: "finishedAt", label: "Tanggal Selesai" },
        { key: "actions", label: "Aksi" },
      ],

      importForm: {
        file: "",
        courseId: "",
        batchId: "",
        typePayment: "FREE",
      },
      errors: {},
      loadingImport: false,
      loading: false,
      processImport: { data: [], resume: {} },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["activationClass/activationClasses"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["activationClass/filter"].search;
      },
      set(val) {
        this.loading = true;
        this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["activationClass/filter"].limit;
      },
      set(val) {
        this.loading = true;
        this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["activationClass/filter"].page;
      },
      set(val) {
        this.loading = true;
        this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      batchs: "batch/batchs",
      total: "activationClass/total",
    }),
  },

  watch: {
    "importForm.courseId"(value) {
      this.fetchBatch({ key: "courseId", value });
    },
  },

  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.loading = true;

    await this.fetch();
    await this.fetchCourse();

    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "activationClass/fetch",
      changeFilter: "activationClass/changeFilter",
      delete: "activationClass/delete",
      fetchCourse: "course/fetch",
      fetchBatch: "batch/changeFilter",
    }),

    async importUserCourse() {
      if (this.processImport.length > 0) {
        this.processImport = [];

        this.$nextTick(() => {
          this.$bvModal.hide("modal-import");
        });
      } else {
        this.loadingImport = true;
        this.errors = {};
        const response = await this.$store.dispatch(
          "activationClass/importActivationClass",
          this.importForm
        );
        this.loadingImport = false;

        if (response.status) {
          this.processImport = response.process;
        } else {
          this.errors = response.errors;

          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat import aktivasi kelas!",
          });
        }
      }
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    deleteactivationClass(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <router-link :to="{ name: 'create-activation-class' }">
                <b-button class="mr-3" variant="primary" size="sm"
                  ><i class="mdi mdi-plus mr-2"></i> Tambah Aktivasi
                  Kelas</b-button
                >
              </router-link>

              <b-button variant="warning" size="sm" v-b-modal.modal-import
                >Import Aktivasi Kelas</b-button
              >

              <b-modal
                centered
                id="modal-import"
                title="Import Member"
                @ok.prevent="importUserCourse"
                size="xl"
              >
                <div v-if="processImport.data.length > 0">
                  <p><b>Rangkuman Hasil Import</b></p>
                  <table class="table table-bordered table-hover">
                    <tr>
                      <th>Data dibuat</th>
                      <th>Data diupdate</th>
                      <th>Data gagal</th>
                    </tr>
                    <tr>
                      <td>{{ processImport.resume.create }}</td>
                      <td>{{ processImport.resume.update }}</td>
                      <td>{{ processImport.resume.failed }}</td>
                    </tr>
                  </table>

                  <br />
                  <hr />
                  <br />

                  <p><b>Daftar Data Hasil Import</b></p>
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>
                          No.
                        </th>
                        <th>
                          Whatsapp
                        </th>
                        <th>
                          Tanggal Kadaluarsa
                        </th>
                        <th>
                          Status
                        </th>
                        <th>
                          Pesan
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(process, index) in processImport.data"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ process.whatsapp }}</td>
                        <td>{{ process.expiredAt }} {{ process.expiredat }}</td>
                        <td>{{ process.status }}</td>
                        <td>{{ process.message }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="text-center" v-if="loadingImport">
                    <div
                      class="spinner-border text-center text-primary"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="batchId">Kelas</label>

                        <select
                          v-model="importForm.courseId"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['courseId'],
                          }"
                        >
                          <option value="">Pilih Kelas</option>
                          <option
                            :value="course.id"
                            v-for="course in courses"
                            :key="course.id"
                            >{{ course.title }}</option
                          >
                        </select>
                        <div v-if="errors['courseId']" class="invalid-feedback">
                          <span>{{ errors["courseId"] }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="batchId">Batch Kelas</label>

                        <select
                          v-model="importForm.batchId"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['batchId'],
                          }"
                        >
                          <option value="">Pilih Batch Kelas</option>
                          <option
                            :value="batch.id"
                            v-for="batch in batchs"
                            :key="batch.id"
                          >
                            {{ batch.name }} ({{
                              batch.startDate | moment("MMMM Do YYYY")
                            }}
                            - {{ batch.finishDate | moment("MMMM Do YYYY") }})
                          </option>
                        </select>
                        <div v-if="errors['batchId']" class="invalid-feedback">
                          <span>{{ errors["batchId"] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="typePayment">Jenis Pembayaran Kelas</label>

                        <select
                          v-model="importForm.typePayment"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['typePayment'],
                          }"
                        >
                          <option value="FREE">Gratis</option>
                          <option value="PAID">Berbayar</option>
                        </select>
                        <div
                          v-if="errors['typePayment']"
                          class="invalid-feedback"
                        >
                          <span>{{ errors["typePayment"] }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="batchId">File</label>

                        <input
                          @change="uploadFileImport"
                          type="file"
                          name=""
                          id=""
                          accept=".csv"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['file'],
                          }"
                        />

                        <div v-if="errors['file']" class="invalid-feedback">
                          <span>{{ errors["file"] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(user)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.firstName }}
                    {{ data.item.user.lastName }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(alreadyJoinGroup)="data">
                  <p>
                    {{
                      data.item.alreadyJoinGroup
                        | convertBoolean("Sudah", "Belum")
                    }}
                  </p>
                </template>

                <template #cell(batch)="data">
                  <p v-if="data.item.batch">
                    {{ data.item.batch.name }} ({{
                      data.item.batch.startDate | moment("Do MMMM YYYY")
                    }}
                    - {{ data.item.batch.finishDate | moment("Do MMMM YYYY") }})
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(status)="data">
                  <p v-if="data.item.status">
                    {{ data.item.status | titleCase }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(typeEnroll)="data">
                  <p v-if="data.item.typeEnroll">
                    {{ data.item.typeEnroll | titleCase }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(typePayment)="data">
                  <p v-if="data.item.typePayment">
                    {{ data.item.typePayment | titleCase }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(expiredAt)="data">
                  <p v-if="data.item.expiredAt">
                    {{ data.item.expiredAt | moment("Do MMMM YYYY") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(finishedAt)="data">
                  <p v-if="data.item.finishedAt">
                    {{ data.item.finishedAt | moment("Do MMMM YYYY") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-activation-class',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteactivationClass(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
