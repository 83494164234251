<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
// import { ModelSelect } from "vue-search-select";

export default {
  page: {
    title: "Tambah Whatsapp Broadcast",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      groups: "whatsappGroup/whatsappGroups",
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Whatsapp Broadcast"
        : "Tambah Whatsapp Broadcast",
      id: this.$route.params.id,
      items: [
        {
          text: "Whatsapp Broadcast",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        text: "",
        image: "",
        groupIds: [],
        dateTime: "",
        partnerId: "",
      },
      loadingFetchGroup: false,
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
      userId: "",
      membersOptions: [],
      users: [],
      loadingSync: false,
      groupFilter: {
        type: "",
        search: "",
      },
    };
  },

  watch: {
    groupFilter: {
      handler(val) {
        this.loadingFetchGroup = true;

        this.fetchGroups([
          { key: "type", value: val.type },
          { key: "search", value: val.search },
        ]);

        this.loadingFetchGroup = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      create: "whatsappBroadcast/create",
      detail: "whatsappBroadcast/detail",
      update: "whatsappBroadcast/update",

      fetchGroups: "whatsappGroup/changeFilters",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-whatsapp-broadcast" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },

    selectGroup(id) {
      const index = this.form.groupIds.indexOf(id);

      if (index > -1) {
        this.form.groupIds.splice(index, 1);
      } else {
        this.form.groupIds.push(id);
      }
    },

    selectAll() {
      this.groups.forEach((group) => {
        const index = this.form.groupIds.indexOf(group.id);

        if (index === -1) this.form.groupIds.push(group.id);
      });
    },

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },
  },

  async mounted() {
    const filters = [
      { key: "limit", value: 9999 },
      { key: "ordering", value: "name" },
      { key: "alreadySyncWithSoby", value: true },
    ];

    if (this.user.roles.findIndex((role) => role.name === "PARTNER") > -1) {
      filters.push(
        { key: "type", value: "PARTNERSHIP" },
        { key: "partnerId", value: this.user.partner.id }
      );
    }

    this.loadingFetchGroup = true;
    this.fetchGroups(filters);
    this.loadingFetchGroup = false;

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;

      if (this.form.partner) this.form.partnerId = this.form.partner.id;
      if (this.form.dateTime)
        this.form.dateTime = this.$options.filters.moment(
          this.form.dateTime,
          "YYYY-MM-DDTHH:mm"
        );

      if (this.form.groups)
        this.form.groupIds = this.form.groups.map(
          (group) => group.whatsappGroup.id
        );
    }

    if (this.isPartner()) this.form.partnerId = this.user.partner.id;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama Broadcast</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="name"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="text">Teks Whatsapp Broadcast</label>
                    <textarea
                      id="text"
                      v-model="form.text"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['text'],
                      }"
                      rows="10"
                    />
                    <div v-if="errors['text']" class="invalid-feedback">
                      <span>{{ errors["text"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <div>
                      <label for="image">Gambar</label>
                      <input
                        id="image"
                        type="file"
                        class="form-control"
                        placeholder="image"
                        value="Mark"
                        :class="{
                          'is-invalid': errors['image'],
                        }"
                        @change="uploadImage"
                      />
                    </div>
                    <div style="width: 300px" v-if="form.image">
                      <img
                        class="mt-3"
                        :src="form.image | media"
                        width="300"
                        alt=""
                        v-img
                      />
                      <button
                        class="form-control btn btn-danger btn-sm mt-3"
                        @click="form.image = ''"
                      >
                        Hapus
                      </button>
                    </div>
                  </div>
                </div>

                <br />
                <br />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dateTime"
                      >Tanggal dan waktu broadcast (Optional)</label
                    >
                    <input
                      id="dateTime"
                      v-model="form.dateTime"
                      type="datetime-local"
                      class="form-control"
                      placeholder="Link dateTime"
                      :class="{
                        'is-invalid': errors['dateTime'],
                      }"
                    />
                    <div v-if="errors['dateTime']" class="invalid-feedback">
                      <span>{{ errors["dateTime"] }}</span>
                    </div>
                  </div>
                </div>

                <br />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="image">Grup</label>

                    <div class="col-12 mb-4">
                      <div class="row">
                        <div class="col-lg-3 mr-3" v-if="!isPartner()">
                          <div class="row">
                            <select
                              v-model="groupFilter.type"
                              name=""
                              id=""
                              class="form-control"
                            >
                              <option value="">Semua Tipe Grup</option>
                              <option value="COMMUNITY">
                                Komunitas
                              </option>
                              <option value="EVENT">
                                Event
                              </option>
                              <option value="PARTNERSHIP">
                                Partnership
                              </option>
                              <option value="TESTER">
                                Tester
                              </option>
                              <option value="OTHER">
                                Other
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-3 mr-3">
                          <div class="row">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Cari group..."
                              v-model="groupFilter.search"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th @click="selectAll">Pilih Semua</th>
                          <th>Nama</th>
                          <th>Tipe</th>
                          <th>Aktif</th>
                          <th>Total Member</th>
                          <th v-if="!isPartner()">Kelas</th>
                          <th v-if="!isPartner()">Batch</th>
                        </tr>
                      </thead>

                      <tbody v-if="loadingFetchGroup">
                        <tr>
                          <td
                            :colspan="!isPartner() ? 7 : 5"
                            class="text-center"
                          >
                            Sedang mengambil data...
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="!loadingFetchGroup">
                        <tr
                          v-for="group in groups"
                          :key="group.id"
                          @click="selectGroup(group.id)"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :value="group.id"
                              v-model="form.groupIds"
                              :id="`checkbox-group`"
                            />
                          </td>
                          <td>{{ group.name }}</td>
                          <td>{{ group.type }}</td>
                          <td>{{ group.isActive }}</td>
                          <td>{{ group.totalMembers }}</td>
                          <td v-if="!isPartner()">
                            {{ group.course ? group.course.title : "-" }}
                          </td>
                          <td v-if="!isPartner()">
                            {{
                              group.courseBatch ? group.courseBatch.name : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
