<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Whatsapp Blaster",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Whatsapp Blaster",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Whatsapp Blaster",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "createdAt", label: "Tanggal", sortable: false },
        { key: "name", label: "Nama", sortable: false },
        { key: "startAt", label: "Tanggal Mulai", sortable: false },
        { key: "endAt", label: "Tanggal Selesai", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "totalQueue", label: "Total Antrian", sortable: false },
        {
          key: "totalQueueDone",
          label: "Total Antrian Terkirim",
          sortable: false,
        },
        {
          key: "totalRead",
          label: "Total Pesan Dibaca",
          sortable: false,
        },
        { key: "actions", label: "Aksi", sortable: false },
      ],
      loading: false,
      index: -1,
      showModal: false,
      selectedTemplate: {},
      loadingDownload: false,
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["whatsappBlaster/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["whatsappBlaster/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["whatsappBlaster/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "whatsappBlaster/total",
      tableData: "whatsappBlaster/whatsappBlasters",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "whatsappBlaster/fetch",
      destroy: "whatsappBlaster/destroy",
      delete: "whatsappBlaster/delete",
    }),

    deleteBlaster(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    downloadCsv(csv) {
      window.open(csv, "_blank");
    },

    async updatePause(id, isPaused) {
      await this.$store.dispatch("whatsappBlaster/updatePause", {
        isPaused,
        id,
      });

      this.loading = true;
      await this.fetch();
      this.loading = false;
    },

    async downloadQueue(id) {
      this.loadingDownload = true;
      const data = await this.$store.dispatch(
        "whatsappBlaster/downloadQueue",
        id
      );

      window.open(data.data, "_blank");
      this.loadingDownload = false;
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <router-link :to="{ name: 'create-whatsapp-blaster' }">
                    <b-button class="mr-3" variant="primary" size="sm"
                      ><i class="mdi mdi-plus mr-2"></i> Tambah
                      Blaster</b-button
                    >
                  </router-link>
                </div>
              </div>
            </h4>

            <b-modal
              centered
              id="modal-import"
              :title="`Template blaster ${selectedTemplate.name}`"
              size="xl"
            >
              <div class="col-12" v-if="selectedTemplate.templates">
                <div class="row">
                  <div
                    class="col-12"
                    v-for="template in selectedTemplate.templates"
                    :key="template.id"
                  >
                    <h5>{{ template.name }}</h5>
                    <br />
                    <div class="col-12">
                      <div class="row">
                        <div
                          class="col-4 mb-2"
                          v-for="option in template.options"
                          :key="`head-${option.id}`"
                        >
                          <label for="">{{
                            option.key | filterTemplateType
                          }}</label>
                          <p>{{ option.text }}</p>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                </div>
              </div>
            </b-modal>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                fixed
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                :busy="loading"
                ref="table"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  <p v-if="data">
                    {{ data.item.createdAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                  <p v-else>-</p>
                </template>
                <template #cell(startAt)="data">
                  <p v-if="data">
                    {{ data.item.startAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(endAt)="data">
                  <p v-if="data">
                    {{ data.item.endAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(name)="data">
                  <p v-if="data">{{ data.item.name }}</p>
                  <p v-else>-</p>
                </template>

                <template #cell(templates)="data">
                  <b-button
                    variant="warning"
                    size="sm"
                    v-b-modal.modal-import
                    @click="selectedTemplate = data.item"
                    v-if="!data.item.csv"
                    >Lihat Template</b-button
                  >

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="downloadCsv(data.item.csv)"
                    v-if="data.item.csv"
                    >Download CSV</b-button
                  >
                </template>

                <template #cell(status)="data">
                  <p v-if="data">{{ data.item.status }}</p>
                  <p v-else>-</p>
                </template>

                <template #cell(totalAudiences)="data">
                  <p v-if="data">{{ data.item.totalAudiences }}</p>
                  <p v-else>0</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-1 mb-1"
                    variant="primary"
                    size="sm"
                    @click="updatePause(data.item.id, !data.item.isPaused)"
                    >{{
                      data.item.isPaused ? "Lanjutkan" : "Berhentikan"
                    }}</b-button
                  >

                  <b-button
                    v-if="data.item.status === 'PUBLISHED'"
                    class="mr-1  mb-1"
                    variant="warning"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'queue-whatsapp-blaster',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>

                  <b-button
                    class="mr-1  mb-1"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-whatsapp-blaster',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteBlaster(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
