<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { portfolioMethods, clientMethods } from "../../../state/helpers";

export default {
  page: {
    title: "Tambah Client",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Client" : "Tambah Client",
      id: this.$route.params.id,
      items: [
        {
          text: "Client",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        clientId: "",
        programName: "",
        type: "",
        banner: "",
        description: "",
        activity: "",
        eventDate: "",
        location: "",
        video: "",
        galleries: [
          {
            imageUrl: "",
            description: "",
          },
        ],
      },
      types: ["UMKM", "Komunitas", "CSR"],
      submitted: false,
      errors: {},
      loading: false,
      clients: [],
    };
  },
  methods: {
    ...clientMethods,
    ...portfolioMethods,

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      try {
        this.submitted = true;
        this.loading = true;
        let response = {};

        if (this.$route.params.id) {
          response = await this.update({
            id: this.$route.params.id,
            payload: this.form,
          });
        } else {
          response = await this.create(this.form);
        }

        if (response.status) {
          this.loading = false;

          this.$router.push({ name: "list-portfolio" });
        } else {
          this.loading = false;

          this.errors = response.errors;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    uploadImage(e, index) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.form.galleries[index].imageUrl = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },

  async mounted() {
    this.clients = (await this.fetchClients()).data;
    this.clients = this.clients.map((client) => {
      return {
        value: client.id,
        text: client.name,
      };
    });

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = data;
      this.form.clientId = data.client.id;
      this.form.eventDate = data.eventDate.split("T")[0];
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="clientId">Client</label>
                    <b-form-select
                      v-model="form.clientId"
                      :options="clients"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['clientId'],
                      }"
                    >
                      <template #first>
                        <option :value="''" disabled>Pilih Client</option>
                      </template>

                      <template #option="{ option }">
                        <span>{{ option.name }}</span>
                      </template>
                    </b-form-select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="programName">Nama</label>
                    <input
                      id="programName"
                      v-model="form.programName"
                      type="text"
                      class="form-control"
                      placeholder="Nama"
                      :class="{
                        'is-invalid': errors['programName'],
                      }"
                    />
                    <div v-if="errors['programName']" class="invalid-feedback">
                      <span>{{ errors["programName"] }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="name">Type</label>
                    <b-form-select
                      v-model="form.type"
                      :options="types"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['type'],
                      }"
                    >
                      <template #first>
                        <option :value="''" disabled>Pilih Type</option>
                      </template>

                      <template #option="{ option }">
                        <span>{{ option }}</span>
                      </template>
                    </b-form-select>

                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="banner">Banner</label>
                    <input
                      id="banner"
                      type="file"
                      class="form-control"
                      placeholder="Banner"
                      :class="{
                        'is-invalid': errors['banner'],
                      }"
                      @change="uploadImage"
                    />
                    <img
                      class="mt-3"
                      :src="form.banner | media"
                      width="300"
                      alt=""
                    />
                    <div v-if="errors['banner']" class="invalid-feedback">
                      <span>{{ errors["banner"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="description">Deskripsi</label>
                    <textarea
                      id="description"
                      v-model="form.description"
                      class="form-control"
                      placeholder="Deskripsi"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></textarea>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="activity">Daftar Aktivitas</label>
                    <textarea
                      id="activity"
                      v-model="form.activity"
                      class="form-control"
                      placeholder="List of Activities"
                      :class="{
                        'is-invalid': errors['activity'],
                      }"
                    ></textarea>
                    <div
                      v-if="errors['activity']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["activity"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="eventDate">Tanggal Event</label>
                    <input
                      id="eventDate"
                      v-model="form.eventDate"
                      type="date"
                      class="form-control"
                      placeholder="Event Date"
                      :class="{
                        'is-invalid': errors['eventDate'],
                      }"
                    />
                    <div v-if="errors['eventDate']" class="invalid-feedback">
                      <span>{{ errors["eventDate"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="location">Lokasi</label>
                    <input
                      id="location"
                      v-model="form.location"
                      type="text"
                      class="form-control"
                      placeholder="Location"
                      :class="{
                        'is-invalid': errors['location'],
                      }"
                    />
                    <div v-if="errors['location']" class="invalid-feedback">
                      <span>{{ errors["location"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mt-3">
                    <label for="video">Video</label>
                    <input
                      id="video"
                      v-model="form.video"
                      type="text"
                      class="form-control"
                      placeholder="Video"
                      :class="{
                        'is-invalid': errors['video'],
                      }"
                    />
                    <div v-if="errors['video']" class="invalid-feedback">
                      <span>{{ errors["video"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <label for="galleries">Galeri</label>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Gambar</th>
                        <th>Deskripsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(highlight, index) in form.galleries"
                        :key="index"
                      >
                        <td>
                          <input
                            type="file"
                            class="form-control"
                            placeholder="image"
                            :class="{
                              'is-invalid': errors['image'],
                            }"
                            @change="uploadImage($event, index)"
                          />
                          <img
                            class="mb-3"
                            :src="highlight.imageUrl | media"
                            width="300"
                            alt=""
                          />
                        </td>
                        <td>
                          <input
                            v-model="highlight.description"
                            type="text"
                            class="form-control"
                            placeholder="Deskripsi"
                            :class="{
                              'is-invalid': errors['description'],
                            }"
                          />
                          <div
                            v-if="errors['description']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["description"] }}</span>
                          </div>
                        </td>
                        <td class="text-center">
                          <button
                            class="btn btn-danger"
                            @click="form.galleries.splice(index, 1)"
                          >
                            Hapus
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <button
                            class="btn btn-primary"
                            type="button"
                            @click="
                              form.galleries.push({
                                imageUrl: '',
                                description: '',
                              })
                            "
                          >
                            Tambah
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
