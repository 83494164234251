import bannerApi from "../../api/banner.api";
import filterDefault from "../../helpers/filter";

export const state = {
  banners: [],
  banner: {},
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  banners(state) {
    return state.banners;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },

  ADD_BANNER(state, banner) {
    state.banners.unshift(banner);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_BANNER(state, id) {
    state.banners.splice(
      state.banners.findIndex((banner) => banner.id === id),
      1
    );
  },

  SET_BANNER(state, banner) {
    state.banner = banner;
  },

  CHANGE_BANNER(state, { id, banner }) {
    state.banners[
      state.banners.findIndex((banner) => banner.id === id)
    ] = banner;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetchbanners");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await bannerApi.fetch(state.filter);

      commit("SET_BANNERS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await bannerApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_BANNER", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await bannerApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_BANNER", id);

    return { status: true };
  },

  async detail({ dispatch }, id) {
    const { data } = await bannerApi.detail(id);

    dispatch("SET_BANNER", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await bannerApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_BANNER", { id, banner: data.data });

    return { status: true };
  },
};
