import api from "./index";

const baseURL = "/forums/reports";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const changeStatus = (id, data) =>
  api.patch(`${baseURL}/${id}/change-status`, data);

export default { fetch, detail, create, update, destroy, changeStatus };
