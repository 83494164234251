<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Kulwa",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      templates: "whatsappGroupKulwaTemplate/whatsappGroupKulwaTemplates",
      groups: "whatsappGroup/whatsappGroups",
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Kulwa" : "Tambah Kulwa",
      id: this.$route.params.id,
      items: [
        {
          text: "Kulwa",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        templateId: "",
        startAt: "",
        groupIds: [],
      },
      submitted: false,
      errors: {},
      loading: false,
      groupFilter: {
        type: "",
        search: "",
      },
    };
  },

  watch: {
    groupFilter: {
      handler(val) {
        this.loadingFetchGroup = true;

        this.fetchGroups([
          { key: "type", value: val.type },
          { key: "search", value: val.search },
        ]);

        this.loadingFetchGroup = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      create: "whatsappGroupKulwa/create",
      detail: "whatsappGroupKulwa/detail",
      update: "whatsappGroupKulwa/update",

      fetchTemplate: "whatsappGroupKulwaTemplate/fetch",
      fetchGroups: "whatsappGroup/changeFilters",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({
          name: "list-whatsapp-group-kulwa",
        });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    selectGroup(id) {
      const index = this.form.groupIds.indexOf(id);

      if (index > -1) {
        this.form.groupIds.splice(index, 1);
      } else {
        this.form.groupIds.push(id);
      }
    },

    selectAll() {
      this.groups.forEach((group) => {
        const index = this.form.groupIds.indexOf(group.id);

        if (index === -1) this.form.groupIds.push(group.id);
      });
    },

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },
  },

  async mounted() {
    const filters = [
      { key: "limit", value: 9999 },
      { key: "ordering", value: "name" },
    ];

    if (this.user.roles.findIndex((role) => role.name === "PARTNER") > -1) {
      filters.push(
        { key: "type", value: "PARTNERSHIP" },
        { key: "partnerId", value: this.user.partner.id }
      );
    }

    this.loadingFetchGroup = true;
    this.fetchGroups(filters);
    this.loadingFetchGroup = false;

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;

      if (this.form.template) this.form.templateId = this.form.template.id;
      if (this.form.groups)
        this.form.groupIds = this.form.groups.map((group) => group.id);
      if (this.form.startAt)
        this.form.startAt = this.$options.filters.moment(
          this.form.startAt,
          "YYYY-MM-DDTHH:mm"
        );
    }

    this.fetchTemplate();
    if (this.isPartner()) this.form.partnerId = this.user.partner.id;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama *)</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="startAt"
                      >Tanggal dan waktu mulai kulwa *)</label
                    >
                    <input
                      id="startAt"
                      v-model="form.startAt"
                      type="datetime-local"
                      class="form-control"
                      placeholder="Link startAt"
                      :class="{
                        'is-invalid': errors['startAt'],
                      }"
                    />
                    <div v-if="errors['startAt']" class="invalid-feedback">
                      <span>{{ errors["startAt"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="templateId">Template *)</label>
                    <select v-model="form.templateId" class="form-control">
                      <option value="">Pilih Template</option>
                      <option
                        :value="template.id"
                        :key="template.id"
                        v-for="template in templates"
                        >{{ template.name }}</option
                      >
                    </select>
                    <div v-if="errors['templateId']" class="invalid-feedback">
                      <span>{{ errors["templateId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="image">Grup</label>

                    <div class="col-12 mb-4">
                      <div class="row">
                        <div class="col-lg-3 mr-3" v-if="!isPartner()">
                          <div class="row">
                            <select
                              v-model="groupFilter.type"
                              name=""
                              id=""
                              class="form-control"
                            >
                              <option value="">Semua Tipe Grup</option>
                              <option value="COMMUNITY">
                                Komunitas
                              </option>
                              <option value="EVENT">
                                Event
                              </option>
                              <option value="PARTNERSHIP">
                                Partnership
                              </option>
                              <option value="TESTER">
                                Tester
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-3 mr-3">
                          <div class="row">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Cari group..."
                              v-model="groupFilter.search"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th @click="selectAll">Pilih Semua</th>
                          <th>Nama</th>
                          <th>Tipe</th>
                          <th>Aktif</th>
                          <th>Total Member</th>
                          <th>Kelas</th>
                          <th>Batch</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="group in groups"
                          :key="group.id"
                          @click="selectGroup(group.id)"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :value="group.id"
                              v-model="form.groupIds"
                              :id="`checkbox-group`"
                            />
                          </td>
                          <td>{{ group.name }}</td>
                          <td>{{ group.type }}</td>
                          <td>
                            {{
                              group.isActive | convertBoolean("Iya", "Tidak")
                            }}
                          </td>
                          <td>{{ group.totalMembers }}</td>
                          <td>{{ group.course ? group.course.title : "-" }}</td>
                          <td>
                            {{
                              group.courseBatch ? group.courseBatch.name : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
