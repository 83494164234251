import api from "./index";

const baseURL = "/whatsapp-group-leave-survey-questions";

const fetch = (params) => api.get(`${baseURL}`, { params });

const create = (payload) => api.post(`${baseURL}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

export default { fetch, create, destroy };
