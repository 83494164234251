<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Tambah Forum Regional",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Forum Regional"
        : "Tambah Forum Regional",
      id: this.$route.params.id,
      items: [
        {
          text: "Forum Regional",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        cityIds: [],
      },
      provinceId: "",
      submitted: false,
      errors: {},
      provinces: [],
      cities: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "forumRegion/create",
      detail: "forumRegion/detail",
      update: "forumRegion/update",

      fetchProvinces: "area/fetchProvinces",
      fetchCities: "area/fetchCities",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-forum-regional" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    alreadySelect(id) {
      return this.getIndexCity(id) > -1;
    },

    getIndexCity(id) {
      try {
        return this.form.cityIds.findIndex((city) => city.id === id);
      } catch (error) {
        return -1;
      }
    },

    deleteSelect(id) {
      this.form.cityIds.splice(this.getIndexCity(id), 1);
    },

    selectAllCity() {
      this.cities.forEach((city) => {
        if (!this.alreadySelect(city.id)) {
          this.form.cityIds.push(city);
        }
      });
    },
  },

  watch: {
    async provinceId(val) {
      this.cities = await this.fetchCities(val);
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form.name = data.name;
      this.form.cityIds = data.cities;
    }

    this.provinces = await this.fetchProvinces();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="#">Pilih Kota</label>

                    <select v-model="provinceId" class="form-control">
                      <option value="">-- Pilih Provinsi --</option>
                      <option
                        :value="province.id"
                        v-for="province in provinces"
                        :key="province.id"
                        >{{ province.name }}</option
                      >
                    </select>

                    <button
                      @click="selectAllCity"
                      class="mt-3 btn btn-primary btn-sm form-control"
                      v-if="cities.length > 0"
                      type="button"
                    >
                      Pilih Semua Kota
                    </button>
                    <table class="mt-3 table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kota</th>
                          <th>
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(city, index) in cities" :key="city.id">
                          <td>{{ index + 1 }}</td>
                          <td>{{ city.type }} {{ city.name }}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              v-if="!alreadySelect(city.id)"
                              @click="form.cityIds.push(city)"
                            >
                              Pilih Kota
                            </button>
                            <button
                              type="button"
                              @click="
                                form.cityIds.splice(getIndexCity(city.id), 1)
                              "
                              class="btn btn-danger btn-sm"
                              v-else
                            >
                              Hapus Kota
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="#">Kota Yang Dipilih</label>

                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kota</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(city, index) in form.cityIds"
                          :key="city.id"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ city.type }} {{ city.name }}</td>
                          <td>
                            <button
                              type="button"
                              @click="deleteSelect(city.id)"
                              class="btn btn-danger btn-sm"
                            >
                              Hapus Kota
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
