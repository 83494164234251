import api from "./index";

const baseURL = "/whatsapp-group-filter-onboarding-templates";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
};
