import userCourseCertificateApi from "../../api/user-course-certificate.api";
import filterDefault from "../../helpers/filter";

export const state = {
  userCourseCertificates: [],
  userCourseCertificate: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    batchId: "",
  },
};

export const getters = {
  userCourseCertificates(state) {
    return state.userCourseCertificates;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_USER_COURSE_CERTIFICATES(state, userCourseCertificates) {
    state.userCourseCertificates = userCourseCertificates;
  },

  ADD_USER_COURSE_CERTIFICATE(state, userCourseCertificate) {
    state.userCourseCertificates.unshift(userCourseCertificate);
  },

  MERGE_USER_COURSE_CERTIFICATES(state, userCourseCertificates) {
    state.userCourseCertificates = userCourseCertificates.concat(
      state.userCourseCertificates
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_USER_COURSE_CERTIFICATE(state, id) {
    state.userCourseCertificates.splice(
      state.userCourseCertificates.findIndex(
        (userCourseCertificate) => userCourseCertificate.id === id
      ),
      1
    );
  },

  SET_USER_COURSE_CERTIFICATE(state, userCourseCertificate) {
    state.userCourseCertificate = userCourseCertificate;
  },

  CHANGE_USER_COURSE_CERTIFICATE(state, { id, userCourseCertificate }) {
    state.userCourseCertificates[
      state.userCourseCertificates.findIndex(
        (userCourseCertificate) => userCourseCertificate.id === id
      )
    ] = userCourseCertificate;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await userCourseCertificateApi.fetch(state.filter);

      commit("SET_USER_COURSE_CERTIFICATES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async download({ state, dispatch }) {
    const filter = Object.assign({}, state.filter);
    filter.limit = 999999999;
    const { data } = await userCourseCertificateApi.download(filter);

    if (!data.success) {
      return { success: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });

    return { status: true, url: data.data };
  },

  async refresh({ dispatch, commit }, id) {
    const { data } = await userCourseCertificateApi.refresh(id);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_USER_COURSE_CERTIFICATE", {
      id,
      userCourseCertificate: data.data,
    });

    return { status: true };
  },
};
