import whatsappNumberHostApi from "../../api/whatsapp-number-host.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappNumberHosts: [],
  whatsappNumberHost: {},
  total: 0,
  filter: {
    ...filterDefault,
    type: "",
    status: "",
    partnerId: "",
  },
};

export const getters = {
  whatsappNumberHosts(state) {
    return state.whatsappNumberHosts;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_NUMBER_HOSTS(state, whatsappNumberHosts) {
    state.whatsappNumberHosts = whatsappNumberHosts;
  },

  ADD_WHATSAPP_NUMBER_HOST(state, whatsappNumberHost) {
    state.whatsappNumberHosts.unshift(whatsappNumberHost);
  },

  MERGE_WHATSAPP_NUMBER_HOSTS(state, whatsappNumberHosts) {
    state.whatsappNumberHosts = whatsappNumberHosts.concat(
      state.whatsappNumberHosts
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_NUMBER_HOST(state, id) {
    state.whatsappNumberHosts.splice(
      state.whatsappNumberHosts.findIndex(
        (whatsappNumberHost) => whatsappNumberHost.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_NUMBER_HOST(state, whatsappNumberHost) {
    state.whatsappNumberHost = whatsappNumberHost;
  },

  CHANGE_WHATSAPP_NUMBER_HOST(state, { id, whatsappNumberHost }) {
    state.whatsappNumberHosts[
      state.whatsappNumberHosts.findIndex(
        (whatsappNumberHost) => whatsappNumberHost.id === id
      )
    ] = whatsappNumberHost;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.whatsappNumberHosts.findIndex(
      (number) => number.id === id
    );

    state.whatsappNumberHosts[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappNumberHostApi.fetch(state.filter);

      commit("SET_WHATSAPP_NUMBER_HOSTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappNumberHostApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_NUMBER_HOST", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappNumberHostApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_NUMBER_HOST", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappNumberHostApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappNumberHostApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_NUMBER_HOST", {
      id,
      whatsappNumberHost: data.data,
    });

    return { status: true };
  },

  async setStatus({ commit }, { id, payload }) {
    const { status } = payload;
    // const { data } = await whatsappNumberHostApi.setStatus(id, payload);

    commit("SET_STATUS", { id, status });

    return status;
  },

  async fetchActivities(context, { id, params }) {
    const { data } = await whatsappNumberHostApi.fetchActivities(id, params);

    return { data: data.data.data, total: data.data.total };
  },

  async rejoin(context, id) {
    const { data } = await whatsappNumberHostApi.rejoin(id);

    return data.data;
  },
};
