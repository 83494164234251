import examApi from "../../api/exam.api";
import filterDefault from "../../helpers/filter";

export const state = {
  exams: [],
  exam: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseIds: "",
    courseModuleIds: "",
    courseModuleItemIds: "",
  },
  types: [
    {
      key: "COURSE",
      text: "Kelas",
    },
    {
      key: "MODULE",
      text: "Modul",
    },
    {
      key: "SUBMODULE",
      text: "Submodul",
    },
  ],
  questionTypes: [
    {
      key: "MULTIPLE_CHOICE",
      text: "Multiple Choice",
    },
    {
      key: "SINGLE_CHOICE",
      text: "Single Choice",
    },
    {
      key: "TEXT",
      text: "Teks",
    },
  ],
  examAnswers: [],
  totalExamAnswers: 0,
  filterExamAnswer: {
    ...filterDefault,
  },
};

export const getters = {
  exams(state) {
    return state.exams;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },

  types(state) {
    return state.types;
  },

  questionTypes(state) {
    return state.questionTypes;
  },

  examAnswers(state) {
    return state.examAnswers;
  },

  totalExamAnswers(state) {
    return state.totalExamAnswers;
  },

  filterExamAnswer(state) {
    return state.filterExamAnswer;
  },
};

export const mutations = {
  SET_EXAMS(state, exams) {
    state.exams = exams;
  },

  ADD_EXAM(state, exam) {
    state.exams.unshift(exam);
  },

  MERGE_EXAMS(state, exams) {
    state.exams = exams.concat(state.exams);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_EXAM(state, id) {
    state.exams.splice(
      state.exams.findIndex((exam) => exam.id === id),
      1
    );
  },

  SET_EXAM(state, exam) {
    state.exam = exam;
  },

  CHANGE_EXAM(state, { id, exam }) {
    state.exams[state.exams.findIndex((exam) => exam.id === id)] = exam;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_TOTAL_ANSWER_EXAM(state, total) {
    state.totalExamAnswers = total;
  },

  SET_ANSWER_EXAM(state, answerExams) {
    state.examAnswers = answerExams;
  },

  SET_FILTER_EXAM_ANSWER(state, { key, value }) {
    state.filterExamAnswer[key] = value;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await examApi.fetch(state.filter);

      commit("SET_EXAMS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await examApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_EXAM", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await examApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_EXAM", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await examApi.detail(id);

    commit("SET_EXAM", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await examApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_EXAM", { id, exam: data.data });

    return { status: true };
  },

  async findAnswerExam({ state, commit }, id) {
    const { data } = await examApi.findAnswerExam({
      id,
      params: state.filterExamAnswer,
    });

    commit("SET_ANSWER_EXAM", data.data.data);
    commit("SET_TOTAL_ANSWER_EXAM", data.data.total);

    return data;
  },

  async changeFilterAnswerExam({ dispatch, commit }, { id, key, value }) {
    commit("SET_FILTER_ANSWER_EXAM", { key, value });

    dispatch("findAnswerExam", id);
  },

  async detailExamAnswer(context, { examId, answerId }) {
    const { data } = await examApi.detailAnswerExam({ examId, answerId });

    return data.data;
  },
};
