<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

/**
 * Chat component
 */
export default {
  page: {
    title: "Pertanyaan Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { simplebar, Layout },
  data() {
    return {
      submitted: false,
      chat: {
        message: "",
      },
      ChatData: [
        {
          id: 1,
          name: "Frank Vickery",
          message: "Hey! I am available",
          image: require("@/assets/images/users/avatar-2.jpg"),
          time: "12:09",
        },
        {
          id: 2,
          align: "right",
          name: "Ricky Clark",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:02",
        },
        {
          text: "Today",
        },
        {
          id: 3,
          name: "Frank Vickery",
          message: "Hello!",
          image: require("@/assets/images/users/avatar-2.jpg"),
          time: "10:06",
        },
        {
          id: 4,
          name: "Frank Vickery",
          message: "& Next meeting tomorrow 10.00AM",
          image: require("@/assets/images/users/avatar-2.jpg"),
          time: "10:06",
        },
        {
          id: 5,
          align: "right",
          name: "Ricky Clark",
          message: "Wow that's great",
          time: "10:07",
        },
      ],
      forum: {
        author: {},
        course: {},
        responses: [],
      },
    };
  },
  validations: {
    chat: {
      message: { required },
    },
  },
  async mounted() {
    var container2 = document.querySelector(
      "#containerElement .simplebar-content-wrapper"
    );
    container2.scrollTo({ top: 500, behavior: "smooth" });

    if (this.$route.params.id) {
      this.forum = await this.detail(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      detail: "forum/detail",
      sendResponse: "forum/sendResponse",
    }),

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const data = this.sendResponse({
          id: this.forum.id,
          payload: this.chat,
        });
        console.log(data);

        this.handleScroll();
      }
      this.submitted = false;
      this.chat = {};
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-body border-bottom">
        <div class="user-chat-border">
          <div class="row">
            <div class="col-md-12 col-12">
              <h5 class="font-size-15 mb-1">
                {{ forum.author.firstName }} {{ forum.author.lastName }}
                <span v-if="forum.isAnonymous"> - Post Sebagai Anonim</span>
              </h5>
              <p class="text-muted mb-0">
                <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                Pertanyaan pada kelas <b>{{ forum.course.title }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="chat-widget">
          <div class="chat-conversation">
            <simplebar style="height: 70vh" ref="current" id="containerElement">
              <ul class="list-unstyled mb-0 pr-3">
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img v-img :src="`${forum.author.profilePictrue}`" alt />
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ forum.author.firstName }} {{ forum.author.lastName }}
                      </div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">{{ forum.content }}</p>
                        <div class="images">
                          <div
                            class="image"
                            v-for="media in forum.medias"
                            :key="media.url"
                          >
                            <img v-img :src="media.url" alt="" />
                          </div>
                        </div>
                      </div>
                      <p class="chat-time mb-0">
                        {{ forum.createdAt | moment("D MMMM YYYY, HH:mm") }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>

              <ul
                class="list-unstyled mb-0 pr-3"
                v-for="(data, index) in forum.responses"
                :key="index"
                :class="{
                  right: data.author.id !== forum.author.id,
                }"
              >
                <li v-if="!data.text">
                  <div class="conversation-list">
                    <div v-if="data.author" class="chat-avatar">
                      <img :src="`${data.author.profilePictrue}`" alt />
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ data.author.firstName }} {{ data.author.lastName }}
                      </div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">{{ data.message }}</p>
                      </div>
                      <p class="chat-time mb-0">
                        {{ data.createdAt | moment("D MMMM YYYY, HH:mm") }}
                      </p>
                    </div>
                  </div>
                </li>

                <li v-if="data.text">
                  <div class="chat-day-title">
                    <span class="title">Today</span>
                  </div>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
      </div>
      <div class="p-3 chat-input-section border-top">
        <form @submit.prevent="formSubmit">
          <div class="col-12">
            <!-- <input
                type="text"
                class="form-control rounded chat-input pl-3"
                placeholder="Enter Message..."
                v-model="chat.message"
                :class="{ 'is-invalid': submitted && $v.chat.message.$error }"
              /> -->
            <quill-editor v-model="chat.message" height="100px" />
            <div
              v-if="submitted && $v.chat.message.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.chat.message.required"
                >This value is required.</span
              >
            </div>
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary form-control mt-3">
              <span class="d-none d-sm-inline-block mr-2">Send</span>
              <i class="mdi mdi-send"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.images {
  display: flex;
  margin-top: 10px;
}

.image {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.image:last-child {
  margin-right: 0;
}

.image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
