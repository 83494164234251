import clinicApi from "../../api/clinic.api";
import filterDefault from "../../helpers/filter";

export const state = {
  clinics: [],
  clinic: {},
  total: 0,
  filter: {
    ...filterDefault,
    isNullableFasilitator: false,
  },
};

export const getters = {
  clinics(state) {
    return state.clinics;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_CLINICS(state, clinics) {
    state.clinics = clinics;
  },

  ADD_CLINIC(state, clinic) {
    state.clinics.unshift(clinic);
  },

  MERGE_CLINICS(state, clinics) {
    state.clinics = clinics.concat(state.clinics);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CLINIC(state, id) {
    state.clinics.splice(
      state.clinics.findIndex((clinic) => clinic.id === id),
      1
    );
  },

  SET_CLINIC(state, clinic) {
    state.clinic = clinic;
  },

  CHANGE_CLINIC(state, { id, clinic }) {
    state.clinics[
      state.clinics.findIndex((clinic) => clinic.id === id)
    ] = clinic;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.clinics.findIndex((number) => number.id === id);

    state.clinics[index].state = status;
  },

  ADD_MESSAGE(state, { id, message }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === id);

    state.clinics[index].messages.push(message);
  },

  UPDATE_MESSAGE(state, { clinicId, id, message }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === clinicId);
    const messageIndex = state.clinics[index].messages.findIndex(
      (message) => message.id === id
    );

    state.clinics[index].messages[messageIndex] = message;
  },

  CHANGE_STATUS(state, { id, status }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === id);

    state.clinics[index].status = status;
  },

  CHANGE_PINNED(state, { id, isPinned }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === id);

    if (index > -1) state.clinics[index].isPinned = isPinned;
  },

  CHANGE_PUBLIC(state, { id, isPublicAccess }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === id);

    if (index > -1) state.clinics[index].isPublicAccess = isPublicAccess;
  },

  ADD_MESSAGE_TO_CLINIC(state, { id, message }) {
    const index = state.clinics.findIndex((clinic) => clinic.id === id);

    if (index > -1) state.clinics[index].messages.push(message);
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await clinicApi.fetch(state.filter);

      commit("SET_CLINICS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await clinicApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CLINIC", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await clinicApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CLINIC", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await clinicApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await clinicApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CLINIC", {
      id,
      clinic: data.data,
    });

    return { status: true };
  },

  async takeQuestion(context, id) {
    const { data } = await clinicApi.takeQuestion(id);

    return data.data;
  },

  async sendMessage({ commit }, { id, payload }) {
    const { data } = await clinicApi.sendMessage(id, payload);

    commit("ADD_MESSAGE", { id, message: data.data });

    return data.data;
  },

  async updateMessage({ commit }, { clinicId, id, payload }) {
    const { data } = await clinicApi.updateMessage(clinicId, id, payload);

    commit("UPDATE_MESSAGE", { clinicId, id, message: data.data });

    return data.data;
  },

  async finishQuestion({ commit, dispatch }, { id, payload }) {
    const { data } = await clinicApi.finishQuestion({ id, payload });

    commit("CHANGE_STATUS", { id, status: "DONE" });
    dispatch("notification/success", data.message, { root: true });

    return data.data;
  },

  async rejectQuestion({ commit, dispatch }, id) {
    const { data } = await clinicApi.rejectQuestion(id);

    commit("CHANGE_STATUS", { id, status: "REJECT" });
    dispatch("notification/success", data.message, { root: true });

    return data.data;
  },

  async addCategory({ dispatch }, { id, payload }) {
    const { data } = await clinicApi.addCategory({ id, payload });

    dispatch("notification/success", data.message, { root: true });

    return data.data;
  },

  async pinQuestion({ dispatch, commit }, id) {
    const { data } = await clinicApi.pinQuestion(id);

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PINNED", { id, isPinned: data.data.isPinned });

    return data.data;
  },

  async setPublicQuestion({ dispatch, commit }, id) {
    const { data } = await clinicApi.setPublicQuestion(id);

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PUBLIC", { id, isPublicAccess: data.data.isPublicAccess });

    return data.data;
  },

  async addMessageToClinic({ commit }, { id, message }) {
    commit("ADD_MESSAGE_TO_CLINIC", { id, message });
  },
};
