<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  page: {
    title: "Tambah Template Kulwa",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Template Kulwa"
        : "Tambah Template Kulwa",
      id: this.$route.params.id,
      items: [
        {
          text: "Template Kulwa",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        delay: 10,
        items: [{ text: "", image: "", order: 0 }],
      },
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      create: "whatsappGroupKulwaTemplate/create",
      detail: "whatsappGroupKulwaTemplate/detail",
      update: "whatsappGroupKulwaTemplate/update",
    }),

    onChangeDraggable() {
      this.form.items = this.form.items.map((item, index) => {
        item.order = index;

        return item;
      });
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({
          name: "list-whatsapp-group-kulwa-template",
        });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e, index) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.items[index].image = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama Template *)</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name"
                      >Delay antar bubble chat (dalam detik) *)</label
                    >
                    <input
                      id="delay"
                      v-model="form.delay"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['delay'],
                      }"
                    />
                    <div v-if="errors['delay']" class="invalid-feedback">
                      <span>{{ errors["delay"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="word"
                      >Teks Kulwa (Drag & Drop untuk merubah urutan)</label
                    >

                    <draggable
                      v-model="form.items"
                      draggable=".item"
                      @change="onChangeDraggable"
                    >
                      <table
                        class="table table-bordered table-hover item"
                        v-for="(item, index) in form.items"
                        :key="`item-${index}`"
                      >
                        <tr>
                          <td width="10%">{{ index + 1 }}</td>
                          <td width="40%">
                            <label for="">Teks Kulwa</label>
                            <textarea
                              v-model="item.text"
                              type="text"
                              class="form-control"
                              rows="10"
                            />
                          </td>
                          <td width="40%">
                            <label for="">Gambar</label>
                            <input
                              type="file"
                              class="form-control"
                              @change="uploadImage($event, index)"
                            />
                            <div style="width: 300px" v-if="item.image">
                              <img
                                class="mt-3"
                                :src="item.image | media"
                                width="300"
                                alt=""
                                v-img
                              />
                              <button
                                class="form-control btn btn-danger btn-sm mt-3"
                                @click="item.image = ''"
                              >
                                Hapus
                              </button>
                            </div>
                          </td>
                          <td width="10%">
                            <b-button
                              variant="danger"
                              size="sm"
                              @click="form.items.splice(index, 1)"
                              ><i class="ri-delete-bin-line"></i
                            ></b-button>
                          </td>
                        </tr>
                      </table>
                    </draggable>
                    <table class="table table-bordered table-hover">
                      <tr>
                        <td>
                          <button
                            class="btn btn-primary form-control"
                            @click="
                              form.items.push({
                                text: '',
                                image: '',
                                order: form.items.length,
                              })
                            "
                            type="button"
                          >
                            Tambah Teks Kulwa
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
