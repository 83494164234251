import whatsappGroupOnboardingFilterApi from "../../api/whatsapp-group-onboarding-filter.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupOnboardingFilters: [],
  whatsappGroupOnboardingFilter: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupOnboardingFilters(state) {
    return state.whatsappGroupOnboardingFilters;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_ONBOARDING_FILTERS(state, whatsappGroupOnboardingFilters) {
    state.whatsappGroupOnboardingFilters = whatsappGroupOnboardingFilters;
  },

  ADD_WHATSAPP_GROUP_ONBOARDING_FILTER(state, whatsappGroupOnboardingFilter) {
    state.whatsappGroupOnboardingFilters.unshift(whatsappGroupOnboardingFilter);
  },

  MERGE_WHATSAPP_GROUP_ONBOARDING_FILTERS(
    state,
    whatsappGroupOnboardingFilters
  ) {
    state.whatsappGroupOnboardingFilters = whatsappGroupOnboardingFilters.concat(
      state.whatsappGroupOnboardingFilters
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_ONBOARDING_FILTER(state, id) {
    state.whatsappGroupOnboardingFilters.splice(
      state.whatsappGroupOnboardingFilters.findIndex(
        (whatsappGroupOnboardingFilter) =>
          whatsappGroupOnboardingFilter.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_ONBOARDING_FILTER(state, whatsappGroupOnboardingFilter) {
    state.whatsappGroupOnboardingFilter = whatsappGroupOnboardingFilter;
  },

  CHANGE_WHATSAPP_GROUP_ONBOARDING_FILTER(
    state,
    { id, whatsappGroupOnboardingFilter }
  ) {
    state.whatsappGroupOnboardingFilters[
      state.whatsappGroupOnboardingFilters.findIndex(
        (whatsappGroupOnboardingFilter) =>
          whatsappGroupOnboardingFilter.id === id
      )
    ] = whatsappGroupOnboardingFilter;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => commit("SET_FILTER", filter));

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupOnboardingFilterApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_ONBOARDING_FILTERS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupOnboardingFilterApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_ONBOARDING_FILTER", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupOnboardingFilterApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_ONBOARDING_FILTER", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupOnboardingFilterApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupOnboardingFilterApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_ONBOARDING_FILTER", {
      id,
      whatsappGroupOnboardingFilter: data.data,
    });

    return { status: true };
  },
};
