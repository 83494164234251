import forumReportApi from "../../api/forum-report.api";
import filterDefault from "../../helpers/filter";

export const state = {
  forumReports: [],
  forumReport: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  forumReports(state) {
    return state.forumReports;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_FORUM_REPORTS(state, forumReports) {
    state.forumReports = forumReports;
  },

  ADD_FORUM_REPORT(state, forumReport) {
    state.forumReports.unshift(forumReport);
  },

  MERGE_FORUM_REPORTS(state, forumReports) {
    state.forumReports = forumReports.concat(state.forumReports);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_FORUM_REPORT(state, id) {
    state.forumReports.splice(
      state.forumReports.findIndex((forumReport) => forumReport.id === id),
      1
    );
  },

  SET_FORUM_REPORT(state, forumReport) {
    state.forumReport = forumReport;
  },

  CHANGE_FORUM_REPORT(state, { id, forumReport }) {
    state.forumReports[
      state.forumReports.findIndex((forumReport) => forumReport.id === id)
    ] = forumReport;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await forumReportApi.fetch(state.filter);

      commit("SET_FORUM_REPORTS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await forumReportApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_FORUM_REPORT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await forumReportApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_FORUM_REPORT", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await forumReportApi.detail(id);

    commit("SET_FORUM_REPORT", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await forumReportApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_FORUM_REPORT", { id, forumReport: data.data });

    return { status: true };
  },

  async changeStatus({ dispatch }, { id, status }) {
    const { data } = await forumReportApi.changeStatus(id, { status });

    if (status === "ACCEPT" || status === "REJECT") {
      await dispatch("notification/success", data.message, { root: true });
    }

    return data;
  },
};
