import forumTopicApi from "../../api/forum-topic.api";
import filterDefault from "../../helpers/filter";

export const state = {
  forumTopics: [],
  forumTopic: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  forumTopics(state) {
    return state.forumTopics;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_forumTopicS(state, forumTopics) {
    state.forumTopics = forumTopics;
  },

  ADD_forumTopic(state, forumTopic) {
    state.forumTopics.unshift(forumTopic);
  },

  MERGE_forumTopicS(state, forumTopics) {
    state.forumTopics = forumTopics.concat(state.forumTopics);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_forumTopic(state, id) {
    state.forumTopics.splice(
      state.forumTopics.findIndex((forumTopic) => forumTopic.id === id),
      1
    );
  },

  SET_forumTopic(state, forumTopic) {
    state.forumTopic = forumTopic;
  },

  CHANGE_forumTopic(state, { id, forumTopic }) {
    state.forumTopics[
      state.forumTopics.findIndex((forumTopic) => forumTopic.id === id)
    ] = forumTopic;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await forumTopicApi.fetch(state.filter);

      commit("SET_forumTopicS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await forumTopicApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_forumTopic", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await forumTopicApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_forumTopic", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await forumTopicApi.detail(id);

    commit("SET_forumTopic", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await forumTopicApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_forumTopic", { id, forumTopic: data.data });

    return { status: true };
  },
};
