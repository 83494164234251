import voucherApi from "../../api/voucher.api";
import filterDefault from "../../helpers/filter";

export const state = {
  vouchers: [],
  voucher: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
  types: [
    {
      value: "DISCOUNT",
      text: "Discount",
    },
    {
      value: "CASHBACK",
      text: "Cashback",
    },
  ],
};

export const getters = {
  vouchers(state) {
    return state.vouchers;
  },

  types(state) {
    return state.types;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers;
  },

  ADD_VOUCHER(state, voucher) {
    state.vouchers.unshift(voucher);
  },

  MERGE_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers.concat(state.vouchers);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_VOUCHER(state, id) {
    state.vouchers.splice(
      state.vouchers.findIndex((voucher) => voucher.id === id),
      1
    );
  },

  SET_VOUCHER(state, voucher) {
    state.voucher = voucher;
  },

  CHANGE_VOUCHER(state, { id, voucher }) {
    state.vouchers[
      state.vouchers.findIndex((voucher) => voucher.id === id)
    ] = voucher;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await voucherApi.fetch(state.filter);

      commit("SET_VOUCHERS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await voucherApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_VOUCHER", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await voucherApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_VOUCHER", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await voucherApi.detail(id);

    commit("SET_VOUCHER", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await voucherApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_VOUCHER", { id, voucher: data.data });

    return { status: true };
  },

  async importvoucher({ dispatch, commit }, payload) {
    const { data } = await voucherApi.importvoucher(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("MERGE_VOUCHERS", data.data.data);

    return { status: true, process: data.data.process };
  },

  async gift(context, payload) {
    const { data } = await voucherApi.gift(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    return { status: true, process: data.data.process };
  },
};
