<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Template Pemberitahuan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      orderTemplateHelper: "notificationTemplate/orderTemplateHelper",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Template Pemberitahuan"
        : "Tambah Template Pemberitahuan",
      id: this.$route.params.id,
      items: [
        {
          text: "Template Pemberitahuan",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        titleNotification: "",
        templateWhatsapp: "",
        templateNotification: "",
        imageWhatsapp: "",
      },
      submitted: false,
      errors: {},
      loading: false,

      helpers: [],
    };
  },
  methods: {
    ...mapActions({
      create: "notificationTemplate/create",
      detail: "notificationTemplate/detail",
      update: "notificationTemplate/update",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-notification-template" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.imageWhatsapp = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = Object.assign(await this.detail(this.$route.params.id), {});

      if (this.form.type === "ORDER") {
        this.helpers = this.orderTemplateHelper;
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <b-alert variant="info" class="mb-3" show>{{
              form.description
            }}</b-alert>

            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kode</th>
                  <th>Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(helper, index) in helpers" :key="helper.code">
                  <td>{{ index + 1 }}</td>
                  <td>{{ helper.code }}</td>
                  <td>{{ helper.description }}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Judul Pemberitahuan</label>
                    <input
                      v-model="form.titleNotification"
                      cols="30"
                      rows="10"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Teks Pemberitahuan</label>
                    <textarea
                      v-model="form.templateNotification"
                      cols="30"
                      rows="10"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <hr />

                  <div class="form-group">
                    <label for="checkbox-show-forum"
                      >Apakah pemberitahuan ini juga dikirim lewat
                      whatsapp?</label
                    >
                    <b-form-checkbox
                      id="checkbox-show-forum"
                      v-model="form.isSendWhatsapp"
                      name="checkbox-show-forum"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="col-md-12">
                  <hr />

                  <b-alert variant="info" class="mb-3" show>
                    Untuk teks whatsapp bisa pake format whatsapp seperti bold
                    (*) dan lainnya
                  </b-alert>
                  <div class="form-group">
                    <label for="name">Teks Whatsapp</label>
                    <textarea
                      v-model="form.templateWhatsapp"
                      cols="30"
                      rows="10"
                      class="form-control"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="name">Image Whatsapp</label>
                    <input
                      @change="uploadImage"
                      type="file"
                      class="form-control"
                    />
                    <img
                      :src="form.imageWhatsapp | media"
                      width="300"
                      alt=""
                      class="mt-3"
                    />
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
