<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Buat Sub Modul Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    modules() {
      return this.$store.getters["module/modules"];
    },
  },
  watch: {
    "form.courseId"(val) {
      console.log(val);
      this.fetchModule({ key: "courseId", value: val });
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Sub Modul Kelas"
        : "Buat Sub Modul Kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "Sub Modul Kelas",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Edit" : "Create",
          active: true,
        },
      ],
      form: {
        courseId: "",
        moduleId: "",
        title: "",
        duration: 1,
        sequence: 0,
        content: "",
        resume: "",
        video: "",
        isActive: true,
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      update: "submodule/update",
      create: "submodule/create",
      detail: "submodule/detail",

      fetchCourse: "course/fetch",
      fetchModule: "module/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-submodule" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadBanner(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.banner = e.target.result;
      };
    },
  },

  async mounted() {
    this.fetchCourse();

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      this.form.courseId = this.form.module.course.id;
      this.form.moduleId = this.form.module.id;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="courseId">Kelas</label>
                    <select
                      id="courseId"
                      v-model="form.courseId"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                    >
                      <option value="">Pilih Kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="moduleId">Modul</label>
                    <select
                      id="moduleId"
                      v-model="form.moduleId"
                      type="text"
                      class="form-control"
                      placeholder="Module"
                      :class="{
                        'is-invalid': errors['moduleId'],
                      }"
                    >
                      <option value="">Pilih Modul</option>
                      <option
                        :value="module.id"
                        v-for="module in modules"
                        :key="module.id"
                        >{{ module.title }}</option
                      >
                    </select>
                    <div v-if="errors['moduleId']" class="invalid-feedback">
                      <span>{{ errors["moduleId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="title">Judul</label>
                    <input
                      id="title"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      placeholder="Judul"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['title'],
                      }"
                    />
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="duration">Durasi (Menit)</label>
                    <input
                      id="duration"
                      v-model="form.duration"
                      type="number"
                      class="form-control"
                      placeholder="Durasi"
                      :class="{
                        'is-invalid': errors['duration'],
                      }"
                    />
                    <div v-if="errors['duration']" class="invalid-feedback">
                      <span>{{ errors["duration"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="sequence">Urutan</label>
                    <input
                      id="sequence"
                      v-model="form.sequence"
                      type="number"
                      class="form-control"
                      placeholder="Urutan"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['sequence'],
                      }"
                    />
                    <div v-if="errors['sequence']" class="invalid-feedback">
                      <span>{{ errors["sequence"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="content">Konten</label>
                    <quill-editor v-model="form.content" height="100" />
                    <div v-if="errors['content']" class="invalid-feedback">
                      <span>{{ errors["content"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="resume">Ringkasan</label>
                    <quill-editor v-model="form.resume" height="100" />
                    <div v-if="errors['resume']" class="invalid-feedback">
                      <span>{{ errors["resume"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="video">ID Video Youtube</label>
                    <input
                      id="video"
                      v-model="form.video"
                      :class="{
                        'is-invalid': errors['video'],
                      }"
                      class="form-control"
                      type="textr"
                    />
                    <div v-if="errors['video']" class="invalid-feedback">
                      <span>{{ errors["video"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="type">Apakah sub modul ini aktif?</label>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.isActive"
                      name="checkbox-1"
                    >
                      Centang jika sub modul ini aktif
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary form-control mt-4"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style>
.ql-container {
  height: 400px !important;
}
</style>
