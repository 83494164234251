import jwt_decode from "jwt-decode";

import authenticationApi from "../../api/authentication.api";
import { getFirebaseBackend } from "../../helpers/firebase/authUtils";

import router from "../../router";

export const state = {
  currentUser: decodeToken(),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    try {
      state.currentUser = jwt_decode(newValue);
      state.currentUser.isPartner =
        state.currentUser.roles.findIndex((role) => role.name === "PARTNER") >
        -1;
      saveState("auth.currentUser", newValue);
    } catch (error) {
      state.currentUser = "";
    }
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },

  currentUser(state) {
    return state.currentUser;
  },
};

export const actions = {
  // Logs in the current user.
  async logIn({ dispatch }, payload) {
    const { data } = await authenticationApi.login(payload);

    if (data.statusCode === 401) {
      dispatch("notification/error", "Username atau password salah", {
        root: true,
      });

      return { success: false, errors: {} };
    }

    if (data.statusCode === 422) {
      return { success: false, errors: data.errors };
    }


    return { success: data.success};
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);
    localStorage.removeItem("auth.currentUser");

    router.push({ name: "login" });
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response) => {
        const user = response;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  async verificationOtp({ commit, dispatch, getters }, { username, password, code } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    const {data} =  await authenticationApi.verificationOtp({ username, password, code })

    commit("SET_CURRENT_USER", data.data.accessToken);

    return { success: true, roles: jwt_decode(data.data.accessToken).roles };
  },

  resendOtp({ dispatch, getters }, { username, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return authenticationApi.resendOtp({ username, password })
  },

  async setCurrentUser({ commit }, token) {
    commit("SET_CURRENT_USER", token);

    return { success: true, roles: jwt_decode(token).roles };
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data;
        return message;
      });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit("SET_CURRENT_USER", user);
    return user;
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, state);
}

function decodeToken() {
  try {
    return jwt_decode(localStorage.getItem("auth.currentUser"));
  } catch (error) {
    console.log(error)
    return "";
  }
}
