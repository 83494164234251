<script>
import simplebar from "simplebar-vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { chatData, chatMessagesData } from "./data";

import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Select2 from "v-select2-component";

/**
 * Chat Component
 */
export default {
  page: {
    title: "Chat",
    meta: [{ name: "description" }],
  },
  components: { Layout, PageHeader, simplebar, Select2 },
  data() {
    return {
      chatData: chatData,
      chatMessagesData: chatMessagesData,
      title: "Chat",
      items: [
        {
          text: "Skote",
          href: "/",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "Frank Vickery",
      indexSelect: -1,
      indexMessage: -1,
      messageEdit: "",
      loadingFinishing: false,
      formFinishing: {
        categoryIds: [],
      },
      showModalFinish: false,
      showModalAddCategory: false,
      clinicCategoryOptions: [],
      showModalEditMessage: false,
    };
  },
  validations: {
    form: {
      message: { required },
    },
  },

  watch: {
    indexSelect(val) {
      this.formFinishing.categoryIds = this.clinics[val].categories.map(
        (category) => category.id
      );

      setTimeout(() => {
        document
          .querySelector("#containerElement .simplebar-content-wrapper")
          .scrollTo({ top: 99999, behavior: "smooth" });
      }, 100);
    },

    clinicCategories: {
      handler(val) {
        this.clinicCategoryOptions = [];

        Object.assign([], val).forEach((category) => {
          this.clinicCategoryOptions.push({
            id: category.id,
            text: category.name,
          });
        });
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Get the name of user
     */
    chatUsername(name, image) {
      this.image = image;
      this.username = name;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        image: this.image,
      });
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      await this.sendMessage({
        id: this.clinics[this.indexSelect].id,
        payload: this.form,
      });

      this.form.message = "";

      document
        .querySelector("#containerElement .simplebar-content-wrapper")
        .scrollTo({ top: 99999, behavior: "smooth" });
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },

    async finishClinic() {
      await this.finishQuestion({
        id: this.clinics[this.indexSelect].id,
        payload: this.formFinishing,
      });

      this.showModalFinish = false;
    },

    async addCategoryClinic() {
      await this.addCategory({
        id: this.clinics[this.indexSelect].id,
        payload: this.formFinishing,
      });

      this.showModalAddCategory = false;
    },

    editMessage(indexMessage) {
      this.indexMessage = indexMessage;
      this.messageEdit = this.clinics[this.indexSelect].messages[
        this.indexMessage
      ].message;
      this.showModalEditMessage = true;
    },

    async updateMessage() {
      this.loadingFinishing = true;
      const payload = {
        clinicId: this.clinics[this.indexSelect].id,
        id: this.clinics[this.indexSelect].messages[this.indexMessage].id,
        payload: { message: this.messageEdit },
      };

      await this.updateClinicMessage(payload);
      this.loadingFinishing = false;
      this.showModalEditMessage = false;
    },

    ...mapActions({
      fetch: "clinic/changeFilters",
      sendMessage: "clinic/sendMessage",
      fetchClinicCategories: "clinicCategory/changeFilter",
      finishQuestion: "clinic/finishQuestion",
      detail: "clinic/detail",
      takeQuestion: "clinic/takeQuestion",
      addCategory: "clinic/addCategory",
      addMessageToClinic: "clinic/addMessageToClinic",
      updateClinicMessage: "clinic/updateMessage",
    }),
  },

  computed: {
    ...mapGetters({
      user: "auth/currentUser",
      clinics: "clinic/clinics",
      clinicCategories: "clinicCategory/clinicCategories",
    }),

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  async mounted() {
    await this.fetch([
      { key: "fasilitatorId", value: this.user.id },
      { key: "limit", value: 9999 },
    ]);
    await this.fetchClinicCategories({ key: "limit", value: 9999 });

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      if (!data.fasilitator) {
        return this.$swal({
          text: "Apakah kamu yakin ingin menjawab pertanyaan ini?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          confirmButtonText: "Iya",
          cancelButtonText: "Tidak",
          closeOnConfirm: false,
          closeOnCancel: false,
          showCancelButton: true,
        }).then(async (willDelete) => {
          if (willDelete.isConfirmed) {
            await this.takeQuestion(this.$route.params.id);
            await this.fetch([
              { key: "fasilitatorId", value: this.user.id },
              { key: "status", value: "PROGRESS" },
              { key: "limit", value: 9999 },
            ]);

            setTimeout(() => {
              try {
                this.indexSelect = this.clinics.findIndex(
                  (clinic) => clinic.id === this.$route.params.id
                );
              } catch (error) {
                console.log(error);
              }
            }, 1000);
          } else {
            return this.$router.push({ name: "list-clinic" });
          }
        });
      }

      if (data.fasilitator.id !== this.user.id)
        return this.$router.push({ name: "list-clinic" });
    }

    if (this.$route.params.id) {
      setTimeout(() => {
        try {
          this.indexSelect = this.clinics.findIndex(
            (clinic) => clinic.id === this.$route.params.id
          );
        } catch (error) {
          console.log(error);
        }
      }, 1000);
    }

    const channel = this.$pusher.subscribe("clinic-send-message");

    channel.bind(`clinic`, async (data) => {
      const index = this.clinics.findIndex((c) => c.id === data.id);

      if (
        this.clinics[index].messages.findIndex(
          (message) => message.id === data.message.id
        ) === -1
      ) {
        await this.addMessageToClinic({ id: data.id, message: data.message });

        if (data.id === this.clinics[this.indexSelect].id) {
          setTimeout(() => {
            document
              .querySelector("#containerElement .simplebar-content-wrapper")
              .scrollTo({ top: 99999, behavior: "smooth" });
          }, 100);
        }
      }
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal
      centered
      id="modal-clinic"
      title="Selesaikan Pertanyaan Klinik"
      @ok.prevent="finishClinic"
      size="lg"
      v-model="showModalFinish"
    >
      <div v-if="!loadingFinishing">
        <div class="form-group">
          <label for="">Pilih kategori untuk pertanyaan ini</label>
          <Select2
            v-model="formFinishing.categoryIds"
            :options="clinicCategoryOptions"
            :settings="{ multiple: true }"
          />
        </div>
      </div>
      <div class="text-center" v-else>
        <div class="spinner-border text-center text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </b-modal>

    <b-modal
      centered
      id="modal-update-message"
      title="Ubah Pesan"
      @ok.prevent="updateMessage"
      size="lg"
      v-model="showModalEditMessage"
    >
      <div v-if="!loadingFinishing">
        <div class="form-group">
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            class="form-control"
            v-model="messageEdit"
          >
          </textarea>
        </div>
      </div>
      <div class="text-center" v-else>
        <div class="spinner-border text-center text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </b-modal>

    <b-modal
      centered
      id="modal-add-category"
      title="Tambah Kategori Pada Pertanyaan Klinik"
      @ok.prevent="addCategoryClinic"
      size="lg"
      v-model="showModalAddCategory"
    >
      <div v-if="!loadingFinishing">
        <div class="form-group">
          <label for="">Pilih kategori untuk pertanyaan ini</label>
          <Select2
            v-model="formFinishing.categoryIds"
            :options="clinicCategoryOptions"
            :settings="{ multiple: true }"
          />
        </div>
      </div>
      <div class="text-center" v-else>
        <div class="spinner-border text-center text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </b-modal>

    <b-alert
      :variant="notification.type"
      class="mb-3"
      dismissible
      @dismissed="$store.dispatch('notification/clear')"
      v-if="notification.message"
      show
      >{{ notification.message }}</b-alert
    >

    <div class="d-lg-flex mb-4">
      <div class="chat-leftsidebar">
        <div class="p-3 border-bottom">
          <div class="media">
            <div class="align-self-center mr-3">
              <img
                src="@/assets/images/users/avatar-2.jpg"
                class="avatar-xs rounded-circle"
                alt
              />
            </div>
            <div class="media-body">
              <h5 class="font-size-15 mt-0 mb-1">
                {{ user.firstName }} {{ user.lastName }}
              </h5>
              <p class="text-muted mb-0">
                <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                Active
              </p>
            </div>
            <div>
              <b-dropdown class="chat-noti-dropdown" right variant="white">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal font-size-20"></i>
                </template>
                <b-dropdown-item>Action</b-dropdown-item>
                <b-dropdown-item>Another action</b-dropdown-item>
                <b-dropdown-item>Something else here</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="card-body border-bottom py-2">
          <div class="search-box chat-search-box">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search..." />
              <i class="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>

        <div class="chat-leftsidebar-nav">
          <b-card-text>
            <div>
              <simplebar style="max-height: 500px" id="scrollElement">
                <ul class="list-unstyled chat-list">
                  <li
                    class
                    v-for="(data, index) of clinics.filter(
                      (clinic) => clinic.state !== 'REJECT'
                    )"
                    :key="data.id"
                    @click="indexSelect = index"
                    :class="{ active: index === indexSelect }"
                  >
                    <a href="javascript: void(0);">
                      <div class="media">
                        <div
                          class="user-img align-self-center mr-3 online"
                          v-if="data.user.profilePicture"
                        >
                          <img
                            :src="`${data.user.profilePicture}`"
                            class="rounded-circle avatar-xs"
                            alt
                          />
                          <span class="user-status"></span>
                        </div>
                        <div
                          class="user-img mr-3"
                          v-if="!data.user.profilePicture"
                          :class="{
                            online: `${data.status}` === 'online',
                            away: `${data.status}` === 'away',
                          }"
                        >
                          <div class="avatar-xs align-self-center">
                            <span
                              class="avatar-title rounded-circle bg-light text-body"
                              >{{ data.user.firstName.charAt(0) }}</span
                            >
                          </div>
                          <span class="user-status"></span>
                        </div>
                        <div class="media-body overflow-hidden">
                          <h5 class="text-truncate font-size-14 mb-1">
                            {{ data.user.firstName }}
                          </h5>
                          <p class="text-truncate mb-0">
                            {{ data.question }}
                          </p>
                        </div>
                        <div class="font-size-11">
                          {{ data.createdAt | moment("Do MMMM YYYY HH:mm") }}
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </simplebar>
            </div>
          </b-card-text>
        </div>
      </div>
      <div class="w-100 user-chat mt-4 mt-sm-0" v-if="indexSelect > -1">
        <div class="p-3 px-lg-4 user-chat-border">
          <div
            style="display: flex; align-items: center; justify-content: space-between;"
          >
            <h5 class="font-size-15 mb-1 text-truncate" v-if="indexSelect > -1">
              {{ clinics[indexSelect].user.firstName }}
            </h5>

            <div>
              <button
                class="btn btn-warning btn-sm mr-3"
                @click="showModalAddCategory = true"
              >
                Tambahkan Kategori
              </button>

              <button
                class="btn btn-primary btn-sm"
                @click="showModalFinish = true"
                v-if="clinics[indexSelect].status !== 'DONE'"
              >
                Selesaikan Percakapan
              </button>
            </div>
          </div>
        </div>

        <div class="px-lg-2 chat-users">
          <div class="chat-conversation p-3">
            <simplebar
              style="max-height: 500px"
              id="containerElement"
              ref="current"
            >
              <ul class="list-unstyled mb-0 pr-3">
                <li class="left">
                  <div class="conversation-list">
                    <div
                      class="chat-avatar"
                      v-if="clinics[indexSelect].user.profilePicture"
                    >
                      <img
                        :src="`${clinics[indexSelect].user.profilePicture}`"
                        alt
                      />
                    </div>

                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ clinics[indexSelect].user.firstName }}
                        {{ clinics[indexSelect].user.lastName }}
                      </div>
                      <div class="ctext-wrap-content">
                        <p
                          class="mb-0"
                          v-html="clinics[indexSelect].question"
                        ></p>
                      </div>

                      <p class="chat-time mb-0">
                        <i class="bx bx-time-five align-middle mr-1"></i>
                        {{
                          clinics[indexSelect].createdAt
                            | moment("Do MMMM YYYY HH:mm")
                        }}
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  v-for="(data, indexMessage) of clinics[indexSelect].messages"
                  :key="data.id"
                  :class="{
                    right: clinics[indexSelect].fasilitator.id === data.user.id,
                  }"
                >
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img
                        :src="`${data.user.profilePicture}`"
                        alt
                        v-if="data.user.profilePicture"
                      />

                      <div class="avatar-xs align-self-center" v-else>
                        <span
                          class="avatar-title rounded-circle bg-light text-body"
                          >{{ data.user.firstName.charAt(0) }}</span
                        >
                      </div>
                    </div>

                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ data.user.firstName }} {{ data.user.lastName }}
                      </div>
                      <div class="ctext-wrap-content">
                        <p
                          class="mb-0 text-left"
                          v-html="data.message.replace(/\n/g, '<br/>')"
                        ></p>
                      </div>

                      <p class="chat-time mb-0">
                        <i class="bx bx-time-five align-middle mr-1"></i>
                        {{ data.createdAt | moment("Do MMMM YYYY HH:mm") }} -
                        <a href="#" @click="editMessage(indexMessage)"
                          >Edit Pesan</a
                        >
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </simplebar>
          </div>
          <div class="px-lg-3">
            <div class="p-3 chat-input-section">
              <form @submit.prevent="formSubmit" class="row">
                <div class="col">
                  <div class="position-relative">
                    <textarea
                      type="text"
                      v-model="form.message"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                      :class="{
                        'is-invalid': submitted && $v.form.message.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.message.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.message.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="submit"
                    class="btn btn-primary chat-send w-md waves-effect waves-light"
                  >
                    <span class="d-none d-sm-inline-block mr-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
  </Layout>
</template>

<style>
.select2-container {
  width: 100% !important;
}
</style>
