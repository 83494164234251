import api from "./index";

const baseURL = "/forums";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}/private`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const sendResponse = (id, payload) =>
  api.post(`${baseURL}/${id}/response`, payload);

const deleteResponse = ({ forumId, responseId }) =>
  api.delete(`${baseURL}/${forumId}/responses/${responseId}`);

const deleteResponseReply = ({ forumId, responseId, commentId }) =>
  api.delete(
    `${baseURL}/${forumId}/responses/${responseId}/comments/${commentId}`
  );

const destroy = (id) => api.delete(`${baseURL}/${id}`);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  sendResponse,
  deleteResponse,
  deleteResponseReply,
};
