<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Tambah Forum",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Forum" : "Tambah Forum",
      id: this.$route.params.id,
      items: [
        {
          text: "Forum",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        title: "",
        content: "",
        slug: "",
        medias: [],
        isAnonymous: false,
        regionId: "",
        forumGroupId: "",
      },
      topics: [],
      typeMedia: "image",
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
      regions: [],
      groups: [],
      comment: "",
    };
  },
  methods: {
    ...mapActions({
      create: "forum/create",
      detail: "forum/detail",
      update: "forum/update",

      fetchCategories: "category/fetchCategories",
      fecthRegions: "forumRegion/fetch",
      fecthGroups: "forumGroup/fetch",

      deleteResponse: "forum/deleteResponse",
      deleteResponseReply: "forum/deleteResponseReply",
    }),

    async deleteResponse(responseId) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.$store.dispatch("forum/deleteResponse", {
            forumId: this.$route.params.id,
            responseId,
          });

          await this.getData();
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    async deleteResponseReply(responseId, commentId) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.$store.dispatch("forum/deleteResponseReply", {
            forumId: this.$route.params.id,
            responseId,
            commentId,
          });

          await this.getData();
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    async sendComment() {
      const payload = { message: this.comment };

      await this.$store.dispatch("forum/sendResponse", {
        id: this.$route.params.id,
        payload,
      });

      this.comment = "";

      await this.getData();
    },

    async getData() {
      this.form = Object.assign({}, await this.detail(this.$route.params.id));
      this.form.topicIds = this.form.categories.map((category) => category.id);
      this.form.regionId = this.form.region.id;
      this.form.forumGroupId = this.form.forumGroup.id;
    },
  },

  async mounted() {
    const categories = await this.fetchCategories();
    this.topics = categories.map((category) => {
      return { id: category.id, text: category.name };
    });

    if (this.$route.params.id) {
      await this.getData();
    }

    this.regions = await this.fecthRegions();
    this.groups = await this.fecthGroups();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div>
              <div class="form-group">
                <p>
                  {{ form.author.firstName }} {{ form.author.lastName }} pada
                  {{ $moment(form.createdAt).format("D MMMM YYYY - HH:mm:ss") }}
                </p>
              </div>

              <div class="form-group">
                <label for="">Konten</label>
                <p>{{ form.content }}</p>
              </div>

              <div class="d-flex">
                <div v-for="media in form.medias" :key="media.id" class="mr-3">
                  <div
                    style="width: 200px; height: 200px"
                    v-if="media.type === 'IMAGE'"
                  >
                    <img
                      v-img
                      :src="media.url"
                      alt=""
                      style="height: 100%; width: 100%; object-fit: cover;"
                    />
                  </div>

                  <div style="width: 400px; height: 300px" v-else>
                    <video
                      :src="media.url"
                      style="height: 100%; width: 100%; object-fit: cover;"
                      controls
                    ></video>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group">
                <div class="form-group">
                  <label for="">Komentar {{ form.totalCommented }}</label>

                  <div
                    class="card"
                    v-for="response in form.responses"
                    :key="response.id"
                  >
                    <div class="container py-3">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p class="mb-0 font-bold">
                          <b
                            >{{ response.author.firstName }}
                            {{ response.author.lastName }}</b
                          >
                        </p>

                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteResponse(response.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                      <p class="mb-0">{{ response.message }}</p>

                      <hr />

                      <label v-if="response.comments.length > 0"
                        >Komentar Balasan</label
                      >

                      <div
                        class="card"
                        v-for="comment in response.comments"
                        :key="comment.id"
                      >
                        <div class="container py-3">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <p class="mb-0">
                              <b
                                >{{ comment.user.firstName }}
                                {{ comment.user.lastName }}</b
                              >
                            </p>

                            <button
                              class="btn btn-danger btn-sm"
                              @click="
                                deleteResponseReply(response.id, comment.id)
                              "
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                          <p class="mb-0">{{ comment.message }}</p>
                        </div>
                      </div>

                      <!-- <br />
                      <label for=""
                        >Balas Komentar {{ comment.author.firstName }}
                        {{ comment.author.lastName }}</label
                      >
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="1"
                        class="form-control"
                      ></textarea>
                      <button class="btn btn-primary form-control mt-3">
                        Kirim Komentar
                      </button> -->
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Tambah Komentar</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                    v-model="comment"
                  ></textarea>
                  <button
                    class="btn btn-primary form-control mt-3"
                    @click="sendComment"
                  >
                    Kirim Komentar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
