import api from "./index";

const baseURL = "/whatsapp-number-hosts";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const setStatus = (id, payload) =>
  api.patch(`${baseURL}/${id}/set-status`, payload);

const rejoin = (id) => api.patch(`${baseURL}/${id}/rejoin`);

const fetchActivities = (id, params) =>
  api.get(`${baseURL}/${id}/activities`, { params });

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  setStatus,
  fetchActivities,
  rejoin,
};
