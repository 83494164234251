<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Tambah Batch Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      fasilitators: "user/users",
    }),
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Ubah batch kelas" : "Tambah batch kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "batch kelas",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        zoom: "",
        telegram: "",
        facebook: "",
        whatsapp: "",
        courseId: "",
        fasilitatorId: "",
        startDate: "",
        finishDate: "",
        multipleLink: false,
        youtube: "",
        zoomMeetingId: "",
        zoomMeetingPasscode: "",
        dateTimeWebinar: "",
        fasilitatorIds: [],
        links: [
          {
            name: "",
            youtube: "",
            zoom: "",
            date: "",
          },
        ],
      },
      submitted: false,
      errors: {},
      loading: false,
      fasilitatorOptions: [],
      fasilitatorId: "",
    };
  },

  watch: {
    fasilitators: {
      handler(val) {
        this.fasilitatorOptions = [];

        Object.assign([], val).forEach((user) => {
          this.fasilitatorOptions.push({
            id: user.id,
            text: `${user.name} - ${user.phoneNumber}`,
          });
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      update: "batch/update",
      create: "batch/create",
      detail: "batch/detail",

      fetchCourse: "course/fetch",
      fetchFasilitator: "user/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};
      this.form.fasilitatorId = this.fasilitatorId;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-batch" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },

    changeMember(e) {
      this.fetchFasilitator({ key: "name", value: e });
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);
      this.form.courseId = this.form.course.id;

      try {
        this.form.startDate = this.form.startDate.split("T")[0];
        this.form.finishDate = this.form.finishDate.split("T")[0];
      } catch (error) {
        console.log(error);
      }

      if (this.form.links.length === 0) {
        this.form.links.push({
          name: "",
          youtube: "",
          zoom: "",
        });
      } else {
        this.form.links = this.form.links.map((link) => {
          link.date = this.$options.filters.moment(
            link.date,
            "YYYY-MM-DDTHH:mm"
          );

          return link;
        });
      }

      if (this.form.dateTimeWebinar)
        this.form.dateTimeWebinar = this.$options.filters.moment(
          this.form.dateTimeWebinar,
          "YYYY-MM-DDTHH:mm"
        );

      this.form.fasilitatorIds = this.form.fasilitators.map(
        (fasilitator) => fasilitator.id
      );
    }

    this.fetchCourse();
    this.fetchFasilitator({ key: "roles", value: "FASILITATOR" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="batchId">Kelas</label>
                    <select
                      id="courseId"
                      v-model="form.courseId"
                      type="text"
                      class="form-control"
                      placeholder="batch"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="batchId">Fasilitator</label>
                    <Select2
                      v-model="form.fasilitatorIds"
                      :options="fasilitatorOptions"
                      :settings="{ multiple: true }"
                    />
                    <div
                      v-if="errors['fasilitatorId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["fasilitatorId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dateTimeWebinar"
                      >Tanggal dan waktu webinar</label
                    >
                    <input
                      id="dateTimeWebinar"
                      v-model="form.dateTimeWebinar"
                      type="datetime-local"
                      class="form-control"
                      placeholder="Link dateTimeWebinar"
                      :class="{
                        'is-invalid': errors['dateTimeWebinar'],
                      }"
                    />
                    <div
                      v-if="errors['dateTimeWebinar']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["dateTimeWebinar"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="youtube">Link Youtube</label>
                    <input
                      id="youtube"
                      v-model="form.youtube"
                      type="text"
                      class="form-control"
                      placeholder="Link youtube"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['youtube'],
                      }"
                    />
                    <div v-if="errors['youtube']" class="invalid-feedback">
                      <span>{{ errors["youtube"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="zoom">Link Zoom</label>
                    <input
                      id="zoom"
                      v-model="form.zoom"
                      type="text"
                      class="form-control"
                      placeholder="Link Zoom"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['zoom'],
                      }"
                    />
                    <div v-if="errors['zoom']" class="invalid-feedback">
                      <span>{{ errors["zoom"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="zoomMeetingId">ID Meeting Zoom</label>
                    <input
                      id="zoomMeetingId"
                      v-model="form.zoomMeetingId"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['zoomMeetingId'],
                      }"
                    />
                    <div
                      v-if="errors['zoomMeetingId']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["zoomMeetingId"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="zoomMeetingPasscode"
                      >Passcode Meeting Zoom</label
                    >
                    <input
                      id="zoomMeetingPasscode"
                      v-model="form.zoomMeetingPasscode"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['zoomMeetingPasscode'],
                      }"
                    />
                    <div
                      v-if="errors['zoomMeetingPasscode']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["zoomMeetingPasscode"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="zoom">Tanggal Mulai</label>
                    <input
                      id="startDate"
                      v-model="form.startDate"
                      type="date"
                      class="form-control"
                      placeholder="Tanggal Mulai"
                      :class="{
                        'is-invalid': errors['startDate'],
                      }"
                    />
                    <div v-if="errors['startDate']" class="invalid-feedback">
                      <span>{{ errors["startDate"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="finishDate">Tanggal Selesai</label>
                    <input
                      id="finishDate"
                      v-model="form.finishDate"
                      type="date"
                      class="form-control"
                      placeholder="Tanggal Selesai"
                      :class="{
                        'is-invalid': errors['finishDate'],
                      }"
                    />
                    <div v-if="errors['finishDate']" class="invalid-feedback">
                      <span>{{ errors["finishDate"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style>
.quil-custom .ql-container.ql-snow {
  height: 800px !important;
}
</style>
