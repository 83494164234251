import api from "./index";

const baseURL = "/exams";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const findAnswerExam = ({ id, params }) =>
  api.get(`${baseURL}/${id}/answers`, { params });

const detailAnswerExam = ({ examId, answerId, params }) =>
  api.get(`${baseURL}/${examId}/answers/${answerId}`, { params });

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  findAnswerExam,
  detailAnswerExam,
};
