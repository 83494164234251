<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { bankQnaMethods } from "../../../state/helpers";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Buat Bank QNA",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    fasilitators() {
      return this.$store.getters["user/users"];
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      categories: "category/categories",
      batchs: "batch/batchs",
      modules: "module/modules",
      user: "auth/currentUser",
      mentors: "user/users",
    }),
  },

  watch: {
    "form.courseId"(val) {
      this.$store.dispatch("batch/changeFilter", {
        key: "courseId",
        value: val,
      });

      this.$store.dispatch("module/changeFilter", {
        key: "courseId",
        value: val,
      });

      this.categoriesOptions = [];

      Object.assign(
        [],
        this.courses[this.courses.findIndex((course) => course.id === val)]
          .categories
      ).forEach((category) => {
        this.categoriesOptions.push({
          id: category.id,
          text: category.name,
        });
      });
    },
  },

  components: { Layout, PageHeader, Select2 },

  data() {
    return {
      title: this.$route.params.id ? "Ubah Bank QNA" : "Buat Bank QNA",
      id: this.$route.params.id,
      categoriesOptions: [],
      items: [
        {
          text: "Bank QNA",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        question: "",
        answer: "",
        fasilitatorId: "",
        mentorId: "",
        categoryIds: [],
        courseId: "",
        batchId: "",
        questioner: "",
        moduleId: "",
        isStar: false,
        video: "",
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...bankQnaMethods,

    ...mapActions({
      fetchCategories: "category/fetchCategories",
      fetchMentor: "user/changeFilter",
      fetchCourse: "course/fetch",
    }),

    getCopyText() {
      return this.$options.filters.removeTag(this.form.answer);
    },

    successCopy() {
      alert("Berhasil menyalin jawaban");
    },

    getRole() {
      return this.user.role;
    },

    isRole(role) {
      const roles = Object.assign([], this.user.roles);

      return roles.findIndex((r) => r.name === role) > -1;
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit() {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-bank-qna" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    saveAsDraft() {
      this.form.status = "DRAFT";
      this.formSubmit();
    },

    publish() {
      this.form.status = "ALREADY_ANSWERED";
      this.formSubmit();
    },

    saveAsDelivery() {
      this.form.status = "DELIVERED";
      this.formSubmit();
    },

    uploadVideo(e) {
      const video = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(video);
      reader.onload = (e) => {
        this.form.video = e.target.result;
      };
    },
  },

  async mounted() {
    await this.fetchCategories();
    await this.fetchMentor({ key: "roles", value: "MENTOR" });
    await this.fetchCourse();

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      this.form.categoryIds = this.form.categories.map(
        (category) => category.id
      );
      this.form.courseId = this.form.course.id;
      this.form.fasilitatorId = this.form.fasilitator.id;

      if (this.form.mentor) {
        this.form.mentorId = this.form.mentor.id;
      }

      if (this.form.module) {
        this.form.moduleId = this.form.module.id;
      }

      this.form.batchId = this.form.batch.id;
    } else {
      this.form.fasilitatorId = this.user.id;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12" v-if="isRole('MENTOR')">
                  <div class="form-group">
                    <label for="code">Kode Pertanyaan</label>
                    <p>
                      {{ form.code }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="course">Kelas</label>
                    <select
                      id="course"
                      v-model="form.courseId"
                      type="text"
                      class="form-control"
                      placeholder="Course"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                      v-if="!isRole('MENTOR')"
                    >
                      <option value="">Pilih Kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <p v-else-if="form.course">{{ form.course.title }}</p>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="batch">Batch Kelas</label>
                    <select
                      id="batch"
                      v-model="form.batchId"
                      type="text"
                      class="form-control"
                      placeholder="batch"
                      :class="{
                        'is-invalid': errors['batchId'],
                      }"
                      v-if="!isRole('MENTOR')"
                    >
                      <option value="">Pilih Batch Kelas</option>
                      <option
                        :value="batch.id"
                        v-for="batch in batchs"
                        :key="batch.id"
                        >{{ batch.name }}</option
                      >
                    </select>
                    <p v-else-if="form.batch">{{ form.batch.name }}</p>
                    <div v-if="errors['batchId']" class="invalid-feedback">
                      <span>{{ errors["batchId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="module">Modul Kelas</label>
                    <select
                      id="module"
                      v-model="form.moduleId"
                      type="text"
                      class="form-control"
                      placeholder="module"
                      :class="{
                        'is-invalid': errors['moduleId'],
                      }"
                      v-if="!isRole('MENTOR')"
                    >
                      <option value="">Pilih module Kelas</option>
                      <option
                        :value="module.id"
                        v-for="module in modules"
                        :key="module.id"
                        >{{ module.title }}</option
                      >
                    </select>
                    <p v-else-if="form.module">
                      {{ form.module.title }}
                    </p>

                    <div v-if="errors['moduleId']" class="invalid-feedback">
                      <span>{{ errors["moduleId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="module">Topik</label>
                    <Select2
                      v-model="form.categoryIds"
                      :options="categoriesOptions"
                      :settings="{ multiple: true }"
                      v-if="!isRole('MENTOR')"
                    />
                    <p v-else>
                      <span
                        v-for="category in form.categories"
                        :key="category.id"
                        >{{ category.name }},
                      </span>
                    </p>
                    <div v-if="errors['moduleId']" class="invalid-feedback">
                      <span>{{ errors["moduleId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4" v-if="!isRole('MENTOR')">
                  <div class="form-group">
                    <label for="mentor">Mentor</label>
                    <select
                      id="mentor"
                      v-model="form.mentorId"
                      type="text"
                      class="form-control"
                      placeholder="mentor"
                      :class="{
                        'is-invalid': errors['mentorId'],
                      }"
                    >
                      <option value="">Pilih Mentor</option>
                      <option
                        :value="mentor.id"
                        v-for="mentor in mentors"
                        :key="mentor.id"
                        >{{ mentor.firstName }} {{ mentor.lastName }}</option
                      >
                    </select>
                    <div v-if="errors['mentorId']" class="invalid-feedback">
                      <span>{{ errors["mentorId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4" v-if="isRole('MENTOR')">
                  <div class="form-group">
                    <label for="fasilitator"><b>Fasilitator</b></label>
                    <p v-if="form.fasilitator">
                      {{ form.fasilitator.firstName }}
                      {{ form.fasilitator.lastName }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4" v-if="!isRole('MENTOR')">
                  <div class="form-group">
                    <label for="questioner">Penanya</label>
                    <input
                      id="questioner"
                      v-model="form.questioner"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['questioner'],
                      }"
                    />
                    <div v-if="errors['questioner']" class="invalid-feedback">
                      <span>{{ errors["questioner"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4" v-if="isRole('MENTOR')">
                  <div class="form-group">
                    <label for="fasilitator"><b>Penanya</b></label>
                    <p>
                      {{ form.questioner }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="isStar"><b>Munculkan pada QNA Kelas</b></label>
                    <select
                      name="isStar"
                      id="isStar"
                      v-model="form.isStar"
                      class="form-control"
                      v-if="isRole('ADMIN')"
                    >
                      <option :value="true">Munculkan</option>
                      <option :value="false">Tidak Munculkan</option>
                    </select>
                    <p v-else>{{ form.isStar ? "Munculkan" : "Tidak" }}</p>
                  </div>
                </div>

                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <label for="question"><b>Pertanyaan</b></label>
                    <quill-editor
                      v-model="form.question"
                      style="height: 200px"
                      v-if="!isRole('MENTOR')"
                    />
                    <p v-else v-html="form.question"></p>
                    <div v-if="errors['question']" class="invalid-feedback">
                      <span>{{ errors["question"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <label for="answer"
                      ><b>Jawaban</b>
                      <button
                        class="ml-2 btn btn-sm btn-primary"
                        v-clipboard:copy="getCopyText()"
                        v-clipboard:success="successCopy"
                        type="button"
                      >
                        Copy Jawaban (hanya teks)
                      </button>
                    </label>
                    <quill-editor
                      v-model="form.answer"
                      style="height: 200px"
                      v-if="!isRole('FASILITATOR')"
                      :class="{
                        'is-invalid': errors['answer'],
                      }"
                    />
                    <div class="answered" v-else>
                      <p v-html="form.answer"></p>
                    </div>
                    <div v-if="errors['answer']" class="invalid-feedback">
                      <span>{{ errors["answer"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <label for="video"><b>Jawaban Video (Opsional)</b></label>
                    <input
                      type="file"
                      class="form-control"
                      @change="uploadVideo"
                    />

                    <video width="320" height="240" controls class="mt-3">
                      <source :src="form.video" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary form-control"
                type="submit"
                v-if="!isRole('MENTOR')"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                class="btn btn-primary form-control"
                @click="saveAsDelivery"
                v-if="!isRole('MENTOR') && form.status === 'ALREADY_ANSWERED'"
                :disabled="loading"
              >
                <span v-if="!loading">Sudah Didelivery</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                class="btn btn-warning form-control mb-3"
                type="button"
                v-if="this.isRole('MENTOR')"
                @click="saveAsDraft"
                :disabled="loading"
              >
                <span v-if="!loading">Simpan Draft</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                class="btn btn-primary form-control"
                type="submit"
                v-if="this.isRole('MENTOR')"
                @click="publish"
                :disabled="loading"
              >
                <span v-if="!loading">Publish Jawaban</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.answered {
  width: 100%;
  border: 1px solid #efefef;
  padding: 15px;
}
</style>
