import activationClassApi from "../../api/activation-class.api";
import filterDefault from "../../helpers/filter";

export const state = {
  activationClasses: [],
  activationClass: {},
  total: 0,
  filter: {
    ...filterDefault,
    fields:
      "id,status,course.id,course.title,batch.id,batch.name,batch.startDate,batch.finishDate,user.id,user.firstName,user.lastName,expiredAt,finishedAt,typeEnroll,typePayment,alreadyJoinGroup",
  },
};

export const getters = {
  activationClasses(state) {
    return state.activationClasses;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_ACTIVATION_CLASSES(state, activationClasses) {
    state.activationClasses = activationClasses;
  },

  MERGE_ACTIVATION_CLASSES(state, activationClasses) {
    state.activationClasses = activationClasses.concat(state.activationClasses);
  },

  ADD_ACTIVATION_CLASS(state, activationClass) {
    state.activationClasses.unshift(activationClass);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_ACTIVATION_CLASS(state, id) {
    state.activationClasses.splice(
      state.activationClasses.findIndex(
        (activationClass) => activationClass.id === id
      ),
      1
    );
  },

  SET_ACTIVATION_CLASS(state, activationClass) {
    state.activationClass = activationClass;
  },

  CHANGE_ACTIVATION_CLASS(state, { id, activationClass }) {
    state.activationClasses[
      state.activationClasses.findIndex(
        (activationClass) => activationClass.id === id
      )
    ] = activationClass;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await activationClassApi.fetch(state.filter);

      commit("SET_ACTIVATION_CLASSES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await activationClassApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_ACTIVATION_CLASS", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await activationClassApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_ACTIVATION_CLASS", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await activationClassApi.detail(id);

    commit("SET_ACTIVATION_CLASS", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await activationClassApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_ACTIVATION_CLASS", { id, activationClass: data.data });

    return { status: true };
  },

  async importActivationClass({ dispatch, commit }, payload) {
    const { data } = await activationClassApi.importActivationClass(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("MERGE_ACTIVATION_CLASSES", data.data.data);

    return { status: true, process: data.data.process };
  },
};
