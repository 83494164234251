<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { courseMethods } from "../../../state/helpers";

/**
 * Master Course component
 */
export default {
  page: {
    title: "Master Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Kelas",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kelas",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      loading: false,
      fields: [
        { key: "title", label: "Judul" },
        { key: "link", label: "Link" },
        { key: "totalShowCourse", label: "Total View Kelas" },
        { key: "categories", label: "Kategori" },
        { key: "type", label: "Tipe" },
        { key: "show", label: "Tampilkan" },
        { key: "banner" },
        { key: "actions", label: "Aksi" },
      ],
      loadingCopy: false,

      copyPayload: {
        fromCourseId: "",
        toCourseId: "",
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["course/courses"];
    },

    totals() {
      return this.$store.getters["course/totals"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["course/filter"].search;
      },
      set(val) {
        this.changeFilter({ key: "search", value: val });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["course/filter"].limit;
      },
      set(val) {
        this.changeFilter({ key: "limit", value: val });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["course/filter"].page;
      },
      set(val) {
        this.changeFilter({ key: "page", value: val });
      },
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...courseMethods,

    deleteCourse(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data kamu aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async copy() {
      this.loadingCopy = true;

      await this.$store.dispatch("course/copyCourse", this.copyPayload);

      this.loadingCopy = false;

      this.$bvModal.hide("modal-copy");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <router-link :to="{ name: 'create-course' }">
                <b-button class="mr-3" variant="primary" size="sm"
                  ><i class="mdi mdi-plus mr-2"></i> Tambah Kelas</b-button
                >
              </router-link>

              <b-button variant="warning" size="sm" v-b-modal.modal-copy
                >Salin data kelas</b-button
              >

              <b-modal
                centered
                id="modal-copy"
                title="Salin data kelas ke kelas lain"
                @ok.prevent="copy"
                size="xl"
              >
                <div v-if="!loadingCopy">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>Kelas yang akan disalin</label>
                        <select
                          v-model="copyPayload.fromCourseId"
                          class="form-control"
                        >
                          <option value=""
                            >Pilih kelas yang akan disalin</option
                          >
                          <option
                            :value="course.id"
                            v-for="course in tableData"
                            :key="course.id"
                            >{{
                              course.type === "KULWA"
                                ? "[Kulwa]"
                                : course.isRecommendation
                                ? "[Rekomendasi]"
                                : ""
                            }}
                            {{ course.title }}</option
                          >
                        </select>
                      </div>
                      <div class="col-lg-6">
                        <label>Kelas yang akan menjadi tujuan</label>
                        <select
                          v-model="copyPayload.toCourseId"
                          class="form-control"
                        >
                          <option value=""
                            >Pilih kelas yang akan menjadi tujuan
                            penyalinan</option
                          >
                          <option
                            :value="course.id"
                            v-for="course in tableData"
                            :key="course.id"
                            >{{
                              course.type === "KULWA"
                                ? "[Kulwa]"
                                : course.isRecommendation
                                ? "[Rekomendasi]"
                                : ""
                            }}
                            {{ course.title }}</option
                          ></select
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <div
                    class="spinner-border text-center text-primary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </b-modal>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(categories)="data">
                  <p v-if="data.item.categories">
                    <span
                      v-for="(category, index) in data.item.categories"
                      :key="category.id"
                      >{{ category.name
                      }}<span v-if="index < data.item.categories.length - 1"
                        >,
                      </span>
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(type)="data">
                  <p>
                    {{ data.item.type | textReplace("_", " ") | titleCase }}
                  </p>
                </template>

                <template #cell(title)="data">
                  <p>
                    {{
                      data.item.type === "KULWA"
                        ? "[Kulwa]"
                        : data.item.isRecommendation
                        ? "[Rekomendasi]"
                        : ""
                    }}
                    {{ data.item.title }}
                  </p>
                </template>

                <template #cell(link)="data">
                  <a
                    :href="`https://app.bolu.id/course/${data.item.slug}`"
                    target="_blank"
                  >
                    Link Preview
                  </a>

                  <hr />

                  <a
                    :href="
                      `https://app.bolu.id/course/${data.item.slug}/checkout`
                    "
                    target="_blank"
                  >
                    Link Checkout
                  </a>

                  <hr />

                  <a
                    :href="
                      `https://app.bolu.id/komunitas?access=${data.item.accessKey}`
                    "
                    target="_blank"
                  >
                    Link Landing
                  </a>
                </template>

                <template #cell(banner)="data">
                  <img
                    :src="data.item.banner | media"
                    height="100"
                    width="200"
                    style="object-fit: cover"
                    alt=""
                  />
                </template>

                <template #cell(price)="data">
                  <p>
                    {{ (data.item.price - data.item.discount) | rupiah("Rp.") }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3 mb-2"
                    variant="warning"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'list-course-rating',
                        query: { courseId: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>
                  <b-button
                    class="mr-3 mb-2"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-course',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteCourse(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totals"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
