<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Buat Iklan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      partners: "partnershipPartner/partnershipPartners",
      products: "partnershipPartnerProduct/partnershipPartnerProducts",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Iklan" : "Buat Iklan",
      id: this.$route.params.id,
      items: [
        {
          text: "Iklan",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        name: "",
        image: "",
        position: "",
        startDateTime: "",
        finishDateTime: "",
        partnerId: "",
        productId: "",
        externalLink: "",
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },

  watch: {
    async "form.partnerId"(val) {
      await this.fetchProduct([
        { key: "limit", value: 9999 },
        { key: "partnerId", value: val },
      ]);
    },
  },

  methods: {
    ...mapActions({
      create: "partnershipAdvertisement/create",
      update: "partnershipAdvertisement/update",
      detail: "partnershipAdvertisement/detail",

      fetchPartner: "partnershipPartner/changeFilters",
      fetchProduct: "partnershipPartnerProduct/changeFilters",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-partnership-advertisement" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadIklan(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },
  },

  async mounted() {
    await this.fetchPartner([
      { key: "limit", value: 99999 },
      { key: "ordering", value: "name" },
    ]);

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      if (this.form.partner) this.form.partnerId = this.form.partner.id;
      if (this.form.product) this.form.productId = this.form.product.id;

      this.form.startDateTime = this.$options.filters.moment(
        this.form.startDateTime,
        "YYYY-MM-DDTHH:mm"
      );

      this.form.finishDateTime = this.$options.filters.moment(
        this.form.finishDateTime,
        "YYYY-MM-DDTHH:mm"
      );
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="partnerId">Partner</label>
                    <select v-model="form.partnerId" class="form-control">
                      <option value="">Pilih Partner</option>
                      <option
                        :value="partner.id"
                        v-for="partner in partners"
                        :key="partner.id"
                        >{{ partner.name }}</option
                      >
                    </select>
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="productId">Produk</label>
                    <select v-model="form.productId" class="form-control">
                      <option value="">Pilih Partner</option>
                      <option
                        :value="product.id"
                        v-for="product in products"
                        :key="product.id"
                        >{{ product.name }}</option
                      >
                    </select>
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="position">External Link (Opsional)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.externalLink"
                    />
                    <div v-if="errors['externalLink']" class="invalid-feedback">
                      <span>{{ errors["externalLink"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="position">Posisi</label>
                    <select v-model="form.position" class="form-control">
                      <option value="">Pilih Posisi</option>
                      <option value="MAIN_BANNER">Banner Utama</option>
                      <option value="BETWEEN_COURSE">Diantara Kelas</option>
                      <option value="BANNER_AFTER_REGISTER"
                        >Banner Setelah Mendaftar Komunitas atau Kelas</option
                      >
                      <option value="BANNER_WHEN_OPEN_MODULE"
                        >Banner Ketika Membuka Module Kelas</option
                      >
                      <option value="BANNER_WHEN_CREATE_QUESTION_CLINIC"
                        >Banner Ketika Membuat Pertanyaan Fitur Klinik</option
                      >
                      <option value="BANNER_BETWEEN_QUESTION_CLINIC"
                        >Banner Diantara Pertanyaan Klinik</option
                      >
                    </select>
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="startDateTime">Waktu mulai</label>
                    <input
                      id="startDateTime"
                      v-model="form.startDateTime"
                      type="datetime-local"
                      class="form-control"
                      placeholder="startDateTime"
                      :class="{
                        'is-invalid': errors['startDateTime'],
                      }"
                    />
                    <div
                      v-if="errors['startDateTime']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["startDateTime"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="finishDateTime">Waktu selesai</label>
                    <input
                      id="finishDateTime"
                      v-model="form.finishDateTime"
                      type="datetime-local"
                      class="form-control"
                      placeholder="finishDateTime"
                      :class="{
                        'is-invalid': errors['finishDateTime'],
                      }"
                    />
                    <div
                      v-if="errors['finishDateTime']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["finishDateTime"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="image">Gambar</label>
                    <input
                      id="image"
                      type="file"
                      class="form-control"
                      placeholder="image"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['image'],
                      }"
                      @change="uploadIklan"
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </div>
                  <div style="width: 300px">
                    <img :src="form.image | media" width="300" alt="" />
                    <button
                      class="btn btn-danger mt-2 form-control"
                      type="button"
                      @click="form.image = ''"
                    >
                      Hapus
                    </button>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary mt-5 form-control"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
