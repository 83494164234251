<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import slugify from "slugify";

export default {
  page: {
    title: "Tambah survey Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      fasilitators: "user/users",
      batchs: "batch/batchs",
      types: "survey/types",
      surveys: "survey/surveys",
    }),
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah survey kelas"
        : "Tambah survey kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "survey kelas",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        courseId: "",
        batchId: "",
        expiredAt: "",
        type: "",
        description: "",
        successDescription: "",
        backgroundImage: "",
        slug: "",
        options: [
          {
            label: "",
            type: "TEXT",
            isRequired: true,
            description: "",
            placeholder: "",
            connectToUser: false,
            items: [
              {
                text: "",
              },
            ],
          },
        ],
      },
      itemOptions: [
        "TEXT",
        "NUMBER",
        "TEXTAREA",
        "CHECKBOX",
        "RADIO",
        "PHONENUMBER",
        "EMAIL",
        "SELECT",
      ],
      loading: false,
      submitted: false,
      errors: {},
      surveyCopy: "",
    };
  },

  watch: {
    "form.courseId": {
      handler(val) {
        this.form.batchId = "";
        this.fetchBatch({ key: "courseId", value: val });
      },
      deep: true,
    },
    "form.name"(val) {
      this.form.slug = slugify(val.toLowerCase());
    },

    async surveyCopy(val) {
      const data = await this.detail(val);

      this.form = Object.assign({}, data);
      if (this.form.course) {
        this.form.courseId = this.form.course.id;
      }

      if (this.form.batch) {
        this.form.batchId = this.form.batch.id;
      }

      if (this.form.expiredAt) {
        this.form.expiredAt = this.$options.filters.moment(
          this.form.expiredAt,
          "YYYY-MM-DD"
        );
      }
    },
  },

  methods: {
    ...mapActions({
      update: "survey/update",
      create: "survey/create",
      detail: "survey/detail",
      fetch: "survey/fetch",

      fetchCourse: "course/fetch",
      fetchBatch: "batch/changeFilter",
      fetchFasilitator: "user/changeFilter",
    }),

    addOption() {
      this.form.options.push({
        label: "",
        type: "TEXT",
        isRequired: true,
        description: "",
        placeholder: "",
        items: [],
        connectToUser: false,
      });
    },

    addOptionText(index) {
      this.form.options[index].items.push({ text: "" });
    },

    haveOption(type) {
      return type === "SELECT" || type === "CHECKBOX" || type === "RADIO";
    },

    uploadBackgroundImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.backgroundImage = e.target.result;
      };
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      let response = {};

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-survey" });
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);
      if (this.form.course) {
        this.form.courseId = this.form.course.id;
      }

      if (this.form.batch) {
        this.form.batchId = this.form.batch.id;
      }

      if (this.form.expiredAt) {
        this.form.expiredAt = this.$options.filters.moment(
          this.form.expiredAt,
          "YYYY-MM-DD"
        );
      }
    }

    await this.fetchCourse();
    await this.fetchFasilitator({ key: "role", valuei: "FASILITATOR" });
    await this.fetch();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="copy-survey">Copy Survey</label>
                    <select
                      name="copy-survey"
                      id="copy-survey"
                      v-model="surveyCopy"
                      class="form-control"
                    >
                      <option value="">Pilih survey yang ingin disalin</option>
                      <option
                        :value="survey.id"
                        v-for="survey in surveys"
                        :key="survey.id"
                        >{{ survey.name }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="type">Tipe</label>
                    <select
                      v-model="form.type"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['type'],
                      }"
                    >
                      <option value="">Pilih Tipe Survey</option>
                      <option
                        :value="type.key"
                        v-for="type in types"
                        :key="type.key"
                        >{{ type.text }}</option
                      >
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="slug">Slug</label>
                    <input
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      class="form-control"
                      placeholder="Slug"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['slug'],
                      }"
                    />
                    <div v-if="errors['slug']" class="invalid-feedback">
                      <span>{{ errors["slug"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-md-4"
                  v-if="form.type !== 'PUBLIC' && form.type !== 'PRIVATE'"
                >
                  <div class="form-group">
                    <label for="surveyId">Kelas</label>
                    <select
                      v-model="form.courseId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['courseId'],
                      }"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                        >{{ course.title }}</option
                      >
                    </select>
                    <div v-if="errors['courseId']" class="invalid-feedback">
                      <span>{{ errors["courseId"] }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-4"
                  v-if="form.type !== 'PUBLIC' && form.type !== 'PRIVATE'"
                >
                  <div class="form-group">
                    <label for="batchId">Batch Kelas</label>
                    <select
                      v-model="form.batchId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['batchId'],
                      }"
                    >
                      <option value="">Pilih batch kelas</option>
                      <option
                        :value="batch.id"
                        v-for="batch in batchs"
                        :key="batch.id"
                        >{{ batch.name }} -
                        {{ batch.startDate | moment("DD MMMM YYYY") }} s/d
                        {{ batch.startDate | moment("DD MMMM YYYY") }}</option
                      >
                    </select>
                    <div v-if="errors['batchId']" class="invalid-feedback">
                      <span>{{ errors["batchId"] }}</span>
                    </div>
                  </div>
                </div>

                <div
                  :class="
                    form.type !== 'PUBLIC' && form.type !== 'PRIVATE'
                      ? 'col-md-4'
                      : 'col-md-12'
                  "
                >
                  <div class="form-group">
                    <label for="expiredAt">Kadaluarsa Pada</label>
                    <input
                      id="expiredAt"
                      v-model="form.expiredAt"
                      type="date"
                      class="form-control"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['expiredAt'],
                      }"
                    />
                    <div v-if="errors['expiredAt']" class="invalid-feedback">
                      <span>{{ errors["expiredAt"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deksripsi</label>
                    <quill-editor v-model="form.description" height="100" />
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="successDescription"
                      >Teks Ketika Telah Mengirim Survey</label
                    >
                    <quill-editor
                      v-model="form.successDescription"
                      height="100"
                    />
                    <div
                      v-if="errors['successDescription']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["successDescription"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="backgroundImage">Gambar Background</label>
                    <input
                      id="backgroundImage"
                      type="file"
                      class="form-control"
                      placeholder="backgroundImage"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['backgroundImage'],
                      }"
                      @change="uploadBackgroundImage"
                    />
                  </div>
                  <img
                    class="mb-3"
                    :src="form.backgroundImage | media"
                    width="300"
                    alt=""
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="backgroundImage">Form Option</label>
                    <draggable v-model="form.options" draggable=".item">
                      <div
                        v-for="(option, index) in form.options"
                        :key="index"
                        class="item"
                      >
                        <div class="row">
                          <div class="col-lg-11">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="label">Label</label>
                                  <input
                                    v-model="option.label"
                                    type="text"
                                    class="form-control"
                                    placeholder="Contoh: Nama, Email"
                                    :class="{
                                      'is-invalid': errors['label'],
                                    }"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="type">Tipe</label>
                                  <select
                                    v-model="option.type"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors['type'],
                                    }"
                                  >
                                    <option
                                      :value="item"
                                      v-for="item in itemOptions"
                                      :key="`${item}-${index}`"
                                      >{{ item }}</option
                                    ></select
                                  >
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="isRequired"
                                    >Apakah Wajib Diisi</label
                                  >
                                  <select
                                    v-model="option.isRequired"
                                    class="form-control"
                                  >
                                    <option :value="true">Iya</option>
                                    <option :value="false">Tidak</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="placeholder">Placeholder</label>
                                  <input
                                    v-model="option.placeholder"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors['placeholder'],
                                    }"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="description">Deksripsi</label>
                                  <input
                                    v-model="option.description"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors['description'],
                                    }"
                                  />
                                </div>
                              </div>

                              <div
                                class="col-md-12"
                                v-if="
                                  option.type === 'EMAIL' ||
                                    option.type === 'PHONENUMBER'
                                "
                              >
                                <b-form-group
                                  label="Apakah berelasi dengan data member?"
                                >
                                  <b-form-radio-group
                                    :id="`radio-group-${index}`"
                                    v-model="option.connectToUser"
                                    :options="[
                                      {
                                        text: 'Iya',
                                        value: true,
                                      },
                                      { text: 'Tidak', value: false },
                                    ]"
                                    :name="`radio-options-${index}`"
                                  ></b-form-radio-group>
                                </b-form-group>
                              </div>

                              <div
                                class="col-lg-12"
                                v-if="haveOption(option.type)"
                              >
                                <label>Options</label>
                                <draggable
                                  v-model="option.items"
                                  draggable=".item"
                                >
                                  <div
                                    v-for="(item, indexItem) in option.items"
                                    :key="`${indexItem}-${index}`"
                                    class="item"
                                  >
                                    <div class="row">
                                      <div class="col-lg-11">
                                        <input
                                          v-model="item.text"
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>

                                      <div class="col-lg-1">
                                        <button
                                          class="btn btn-danger btn-sm"
                                          @click="
                                            option.items.splice(indexItem, 1)
                                          "
                                          type="button"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    type="button"
                                    class="btn btn-primary form-control"
                                    slot="footer"
                                    @click="addOptionText(index)"
                                  >
                                    Tambah Option Teks
                                  </button>
                                </draggable>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-1">
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click="form.options.splice(index, 1)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary form-control"
                        slot="footer"
                        @click="addOption"
                        type="button"
                      >
                        Tambah Option
                      </button>
                    </draggable>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary mt-4 form-control"
                :disabled="loading"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.item {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
