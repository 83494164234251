<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Pemenang Giveaway",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  components: { Layout, PageHeader },

  data() {
    return {
      title: this.$route.params.id ? "Pemenang Giveaway" : "Pemenang Giveaway",
      id: this.$route.params.id,
      categoriesOptions: [],
      items: [
        {
          text: "Giveaway",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Pemenang" : "",
          active: true,
        },
      ],
      form: {},
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "giveaway/detail",
    }),
  },

  async mounted() {
    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);
      this.form = Object.assign({}, data);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation">
              <div class="row">
                <div class="col-md-12 mb-5 pb-4">
                  <div class="form-group">
                    <label for="descriptionFinish"><b>Hadiah</b></label>
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>Nama</th>
                        <th>Sertifikat</th>
                        <th>Whatsapp</th>
                        <th>Hadiah</th>
                      </tr>
                      <tbody v-for="gift in form.gifts" :key="gift.id">
                        <tr v-for="winner in gift.winners" :key="winner.id">
                          <th>
                            {{ winner.user.firstName }}
                            {{ winner.user.lastName }}
                          </th>
                          <th>{{ winner.certificate.number }}</th>
                          <th>
                            <a
                              :href="`https://wa.me/${winner.user.phoneNumber}`"
                              target="_blank"
                              >{{ winner.user.phoneNumber }}</a
                            >
                          </th>
                          <th>
                            {{ gift.name }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.answered {
  width: 100%;
  border: 1px solid #efefef;
  padding: 15px;
}
</style>
