import api from "./index";

const baseURL = "/whatsapp-groups";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const resume = (params) => api.get(`${baseURL}/resume`, { params });

const syncMember = (id) => api.patch(`${baseURL}/${id}/sync-member`);

const syncChat = (id, payload) =>
  api.patch(`${baseURL}/${id}/sync-chat`, payload);

const invite = (payload) => api.post(`${baseURL}/invite`, payload);

const syncGroupToSoby = (id) =>
  api.patch(`${baseURL}/${id}/sync-group-to-soby`);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  syncMember,
  resume,
  invite,
  syncChat,
  syncGroupToSoby,
};
