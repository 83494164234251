import whatsappGroupWordSearchSummaryApi from "../../api/whatsapp-group-word-search-summary.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupWordSearchSummaries: [],
  total: 0,
  filter: {
    ...filterDefault,
    wordIds: "",
    categoryIds: "",
    groupIds: "",
    startAt: "",
    endAt: "",
  },
};

export const getters = {
  whatsappGroupWordSearchSummaries(state) {
    return state.whatsappGroupWordSearchSummaries;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARIES(
    state,
    whatsappGroupWordSearchSummaries
  ) {
    state.whatsappGroupWordSearchSummaries = whatsappGroupWordSearchSummaries;
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async changeFilters({ dispatch, commit }, filters) {
    filters.forEach((filter) => {
      const { key, value } = filter;

      commit("SET_FILTER", { key, value });
    });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupWordSearchSummaryApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_WORD_SEARCH_SUMMARIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },
};
