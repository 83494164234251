import moduleApi from "../../api/module.api";
import filterDefault from "../../helpers/filter";

export const state = {
  modules: [],
  module: {},
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  modules(state) {
    return state.modules;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_MODULES(state, modules) {
    state.modules = modules;
  },

  ADD_MODULE(state, module) {
    state.modules.unshift(module);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_MODULE(state, id) {
    state.modules.splice(
      state.modules.findIndex((module) => module.id === id),
      1
    );
  },

  SET_MODULE(state, module) {
    state.module = module;
  },

  CHANGE_MODULE(state, { id, module }) {
    state.modules[
      state.modules.findIndex((module) => module.id === id)
    ] = module;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await moduleApi.fetch(state.filter);

      commit("SET_MODULES", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await moduleApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_MODULE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true, data: data.data };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await moduleApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_MODULE", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await moduleApi.detail(id);

    commit("SET_MODULE", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await moduleApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_MODULE", { id, module: data.data });

    return { status: true };
  },
};
