import whatsappGroupKulwaTemplateApi from "../../api/whatsapp-group-kulwa-template.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupKulwaTemplates: [],
  whatsappGroupKulwaTemplate: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupKulwaTemplates(state) {
    return state.whatsappGroupKulwaTemplates;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_KULWA_TEMPLATES(state, whatsappGroupKulwaTemplates) {
    state.whatsappGroupKulwaTemplates = whatsappGroupKulwaTemplates;
  },

  ADD_WHATSAPP_GROUP_KULWA_TEMPLATES(state, whatsappGroupKulwaTemplate) {
    state.whatsappGroupKulwaTemplates.unshift(whatsappGroupKulwaTemplate);
  },

  MERGE_WHATSAPP_GROUP_KULWA_TEMPLATESS(state, whatsappGroupKulwaTemplates) {
    state.whatsappGroupKulwaTemplates = whatsappGroupKulwaTemplates.concat(
      state.whatsappGroupKulwaTemplates
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_KULWA_TEMPLATES(state, id) {
    state.whatsappGroupKulwaTemplates.splice(
      state.whatsappGroupKulwaTemplates.findIndex(
        (whatsappGroupKulwaTemplate) => whatsappGroupKulwaTemplate.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_KULWA_TEMPLATE(state, whatsappGroupKulwaTemplate) {
    state.whatsappGroupKulwaTemplate = whatsappGroupKulwaTemplate;
  },

  CHANGE_WHATSAPP_GROUP_KULWA_TEMPLATES(
    state,
    { id, whatsappGroupKulwaTemplate }
  ) {
    state.whatsappGroupKulwaTemplates[
      state.whatsappGroupKulwaTemplates.findIndex(
        (whatsappGroupKulwaTemplate) => whatsappGroupKulwaTemplate.id === id
      )
    ] = whatsappGroupKulwaTemplate;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupKulwaTemplateApi.fetch(state.filter);

      commit("SET_WHATSAPP_GROUP_KULWA_TEMPLATES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupKulwaTemplateApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_KULWA_TEMPLATES", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupKulwaTemplateApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_KULWA_TEMPLATES", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupKulwaTemplateApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupKulwaTemplateApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_KULWA_TEMPLATE", {
      id,
      whatsappGroupKulwaTemplate: data.data,
    });

    return { status: true };
  },
};
