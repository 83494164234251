import whatsappGroupImportApi from "../../api/whatsapp-group-import.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupImports: [],
  whatsappGroupImport: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
  filterPending: {
    ...filterDefault,
  },
  filterContact: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupImports(state) {
    return state.whatsappGroupImports;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_IMPORTS(state, whatsappGroupImports) {
    state.whatsappGroupImports = whatsappGroupImports;
  },

  ADD_WHATSAPP_GROUP_IMPORT(state, whatsappGroupImport) {
    state.whatsappGroupImports.unshift(whatsappGroupImport);
  },

  MERGE_WHATSAPP_GROUP_IMPORTS(state, whatsappGroupImports) {
    state.whatsappGroupImports = whatsappGroupImports.concat(
      state.whatsappGroupImports
    );
  },

  SET_FILTER(state, { key, value, type }) {
    state[type][key] = value;
  },

  REMOVE_WHATSAPP_GROUP_IMPORT(state, id) {
    state.whatsappGroupImports.splice(
      state.whatsappGroupImports.findIndex(
        (whatsappGroupImport) => whatsappGroupImport.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_IMPORT(state, whatsappGroupImport) {
    state.whatsappGroupImport = whatsappGroupImport;
  },

  CHANGE_WHATSAPP_GROUP_IMPORT(state, { id, whatsappGroupImport }) {
    state.whatsappGroupImports[
      state.whatsappGroupImports.findIndex(
        (whatsappGroupImport) => whatsappGroupImport.id === id
      )
    ] = whatsappGroupImport;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value, type }) {
    commit("SET_FILTER", { key, value, type: type ? "filter" : type });

    switch (type) {
      case "filter":
        dispatch("fetch");
        break;

      case "filterPending":
        dispatch("fetchPending");
        break;

      case "filterContact":
        dispatch("fetchContact");
        break;

      default:
        dispatch("fetch");
        break;
    }
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupImportApi.fetch(state.filter);

      commit("SET_WHATSAPP_GROUP_IMPORTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async fetchPending({ commit, state }) {
    try {
      const { data } = await whatsappGroupImportApi.fetchPending(
        state.filterPending
      );

      commit("SET_WHATSAPP_GROUP_IMPORTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async fetchContact({ commit, state }) {
    try {
      const { data } = await whatsappGroupImportApi.fetchContact(
        state.filterContact
      );

      commit("SET_WHATSAPP_GROUP_IMPORTS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupImportApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_IMPORT", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async fetchNullableContacts() {
    const { data } = await whatsappGroupImportApi.fetchContact({
      isNullableContact: true,
    });

    return data.data.data;
  },

  async createContacts({ dispatch }, payload) {
    const { data } = await whatsappGroupImportApi.createContact(payload);

    dispatch("notification/success", data.message, { root: true });

    return data.data;
  },
};
