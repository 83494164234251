import settingApi from "../../api/setting.api";
import filterDefault from "../../helpers/filter";

export const state = {
  settings: [],
  setting: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  settings(state) {
    return state.settings;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ state }) {
    try {
      const { data } = await settingApi.fetch(state.filter);

      return data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async update({ dispatch }, payload) {
    const { data } = await settingApi.update(payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },
};
