<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Buat Produk",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      partners: "partnershipPartner/partnershipPartners",
      categories:
        "partnershipPartnerProductCategory/partnershipPartnerProductCategories",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Produk" : "Buat Produk",
      id: this.$route.params.id,
      items: [
        {
          text: "Produk",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        name: "",
        link: "",
        categoryId: "",
        partnerId: "",
        shortDescription: "",
        description: "",
        thumbnail: "",
        images: [],
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      create: "partnershipPartnerProduct/create",
      update: "partnershipPartnerProduct/update",
      detail: "partnershipPartnerProduct/detail",

      fetchCategory: "partnershipPartnerProductCategory/changeFilter",
      fetchPartner: "partnershipPartner/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({
          name: "list-partnership-partner-product",
        });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e, type) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        if (type === "thumbnail") {
          this.form.thumbnail = e.target.result;
        }

        if (type === "images") {
          this.form.images.push(e.target.result);
        }
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      if (this.form.category) this.form.categoryId = this.form.category.id;
      if (this.form.partner) this.form.partnerId = this.form.partner.id;

      this.form.images = this.form.images.map((image) => image.image);
    }

    this.fetchCategory({ key: "limit", value: 99999 });
    this.fetchPartner({ key: "limit", value: 99999 });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="link">Link</label>
                    <input
                      id="link"
                      v-model="form.link"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['link'],
                      }"
                    />
                    <div v-if="errors['link']" class="invalid-feedback">
                      <span>{{ errors["link"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="categoryId">Kategori</label>
                    <select
                      name="categoryId"
                      id="categoryId"
                      v-model="form.categoryId"
                      class="form-control"
                    >
                      <option value="">Pilih Kategori</option>
                      <option
                        :value="category.id"
                        v-for="category in categories"
                        :key="category.id"
                        >{{ category.name }}</option
                      >
                    </select>
                    <div v-if="errors['categoryId']" class="invalid-feedback">
                      <span>{{ errors["categoryId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="partnerId">Partner</label>
                    <select
                      name="partnerId"
                      id="partnerId"
                      v-model="form.partnerId"
                      class="form-control"
                    >
                      <option value="">Pilih Partner</option>
                      <option
                        :value="partner.id"
                        v-for="partner in partners"
                        :key="partner.id"
                        >{{ partner.name }}</option
                      >
                    </select>
                    <div v-if="errors['partnerId']" class="invalid-feedback">
                      <span>{{ errors["partnerId"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="shortDescription">Deskripsi Singkat</label>
                    <ckeditor
                      id="shortDescription"
                      v-model="form.shortDescription"
                      :class="{
                        'is-invalid': errors['shortDescription'],
                      }"
                    ></ckeditor>
                    <div
                      v-if="errors['shortDescription']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["shortDescription"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <ckeditor
                      id="description"
                      v-model="form.description"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></ckeditor>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Thumbnail</label>
                    <input
                      type="file"
                      @change="uploadImage($event, 'thumbnail')"
                      class="form-control"
                    />
                    <div style="width: 300px" v-if="form.thumbnail">
                      <img
                        class="mt-3"
                        :src="form.thumbnail | media"
                        width="300"
                        alt=""
                        v-img
                      />
                      <button
                        class="form-control btn btn-danger btn-sm mt-3"
                        type="button"
                        @click="form.thumbnail = ''"
                      >
                        Hapus
                      </button>
                    </div>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <br />
                  <div class="form-group">
                    <label for="images"
                      >Gambar Lainnya (Bisa lebih dari 1)</label
                    >
                    <input
                      type="file"
                      @change="uploadImage($event, 'images')"
                      class="form-control"
                    />
                    <div
                      style="width: 300px"
                      v-for="image in form.images"
                      :key="image"
                    >
                      <img
                        class="mt-3"
                        :src="image | media"
                        width="300"
                        alt=""
                        v-img
                      />
                      <button
                        class="form-control btn btn-danger btn-sm mt-3"
                        type="button"
                        @click="image = ''"
                      >
                        Hapus
                      </button>
                    </div>
                    <div v-if="errors['images']" class="invalid-feedback">
                      <span>{{ errors["images"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary form-control mt-4"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
