import api from "./index";

const baseURL = "/bank-qnas";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const importBankQna = (payload) => api.post(`${baseURL}/import`, payload);

const exportBankQna = (params) => api.get(`${baseURL}/export`, { params });

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  importBankQna,
  exportBankQna,
};
