import api from "./index";

const baseURL = "/whatsapp-group-imports";

const fetch = (params) => api.get(`${baseURL}`, { params });

const fetchPending = (params) => api.get(`${baseURL}/pendings`, { params });

const fetchContact = (params) => api.get(`${baseURL}/contacts`, { params });

const createContact = (payload) =>
  api.post(`${baseURL}/contacts`, { contacts: payload });

const create = (payload) => api.post(`${baseURL}`, payload);

export default { fetch, create, fetchPending, fetchContact, createContact };
