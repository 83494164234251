import partnershipPartnerTypeApi from "../../api/partnership-partner-type.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnershipPartnerTypes: [],
  partnershipPartnerType: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnershipPartnerTypes(state) {
    return state.partnershipPartnerTypes;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIP_PARTNER_TYPES(state, partnershipPartnerTypes) {
    state.partnershipPartnerTypes = partnershipPartnerTypes;
  },

  ADD_PARTNERSHIP_PARTNER_TYPE(state, partnershipPartnerType) {
    state.partnershipPartnerTypes.unshift(partnershipPartnerType);
  },

  MERGE_PARTNERSHIP_PARTNER_TYPES(state, partnershipPartnerTypes) {
    state.partnershipPartnerTypes = partnershipPartnerTypes.concat(
      state.partnershipPartnerTypes
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP_PARTNER_TYPE(state, id) {
    state.partnershipPartnerTypes.splice(
      state.partnershipPartnerTypes.findIndex(
        (partnershipPartnerType) => partnershipPartnerType.id === id
      ),
      1
    );
  },

  SET_PARTNERSHIP_PARTNER_TYPE(state, partnershipPartnerType) {
    state.partnershipPartnerType = partnershipPartnerType;
  },

  CHANGE_PARTNERSHIP_PARTNER_TYPE(state, { id, partnershipPartnerType }) {
    state.partnershipPartnerTypes[
      state.partnershipPartnerTypes.findIndex(
        (partnershipPartnerType) => partnershipPartnerType.id === id
      )
    ] = partnershipPartnerType;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnershipPartnerTypes.findIndex(
      (number) => number.id === id
    );

    state.partnershipPartnerTypes[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipPartnerTypeApi.fetch(state.filter);

      commit("SET_PARTNERSHIP_PARTNER_TYPES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipPartnerTypeApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP_PARTNER_TYPE", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipPartnerTypeApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP_PARTNER_TYPE", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipPartnerTypeApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipPartnerTypeApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP_PARTNER_TYPE", {
      id,
      partnershipPartnerType: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipPartnerTypeApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await partnershipPartnerTypeApi.getAudiencesByQuery(query);

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipPartnerTypeApi.getQueue(id);

    return data.data;
  },
};
