<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import { ModelSelect } from "vue-search-select";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Tambah Whatsapp Blaster",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ModelSelect, Select2 },
  data() {
    return {
      title: "Tambah Whatsapp Blaster",
      items: [
        {
          text: "Whatsapp Blaster",
          href: "/whatsapp-blaster",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
      errors: {},
      form: {
        name: "",
        status: "DRAFT",
        text: "",
        image: "",
        csv: "",
        templateIds: [],
        endAt: "",
        startAt: "",
      },

      formTemplate: {
        name: "",
        options: [],
      },

      loading: false,
      submitted: false,
      eventName: "",
      search: "",
      showModal: false,
      totalAffectedUser: 0,
      selectedTemplates: [],
      audiences: [],
      loadingAudience: false,

      courseId: "",
      coursesOptions: [],
      courseSelected: [],

      utmIds: "",
      utmsOptions: [],
      utmSelected: [],

      userCategoryIds: [],
      userCategoriesOptions: [],
      userCategorySelected: [],

      categoryIds: [],
      categoriesOptions: [],
      categorySelected: [],

      filterAudiences: [],
      loadingFilterAudience: false,
      loadingFilter: false,

      typeSelling: [],
      averageProfitPerMonth: [],
      gender: [],
      paidMember: [],
      alreadyJoinCommunity: "",
      isPaidMember: "",

      typeSelectAudience: "filter",
    };
  },

  watch: {
    courses: {
      handler(val) {
        this.coursesOptions = [];

        Object.assign([], val).forEach((course) => {
          this.coursesOptions.push({
            value: course.id,
            text: `${course.title}`,
          });
        });
      },
      deep: true,
    },

    courseId(val) {
      const index = this.courses.findIndex((course) => course.id === val);

      if (index > -1) {
        const exists = this.courseSelected.findIndex(
          (course) => course.id === val
        );

        if (exists === -1) {
          this.courseSelected.push(this.courses[index]);

          const indexKey = this.formTemplate.options.findIndex(
            (option) => option.key === "COURSE"
          );

          if (indexKey > -1) {
            this.formTemplate.options[
              index
            ].value += `,${this.courses[index].id}`;
          } else {
            this.formTemplate.options.push({
              key: "COURSE",
              value: this.courses[index].id,
            });
          }
        }
      }
    },

    categoryIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "CATEGORY"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "CATEGORY",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    userCategoryIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "PRODUCT_CATEGORY"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "PRODUCT_CATEGORY",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    typeSelling(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "TYPE_SELLING"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "TYPE_SELLING",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    gender(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "GENDER"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "GENDER",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    averageProfitPerMonth(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "AVERAGE_PROFIT_PER_MONTH"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "AVERAGE_PROFIT_PER_MONTH",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    alreadyJoinCommunity(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "ALREADY_JOIN_COMMUNITY_GROUP"
      );

      if (val) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val;
        } else {
          this.formTemplate.options.push({
            key: "ALREADY_JOIN_COMMUNITY_GROUP",
            value: val,
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    isPaidMember(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "IS_PAID_MEMBER"
      );

      if (val) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val;
        } else {
          this.formTemplate.options.push({
            key: "IS_PAID_MEMBER",
            value: val,
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    utmIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "UTM"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "UTM",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    "formTemplate.options": {
      handler() {
        if (this.formTemplate.options.length > 0) this.updateFilterAudience();
      },
      deep: true,
    },

    search: {
      async handler(val) {
        if (val) {
          const index = this.selectedTemplates.findIndex(
            (template) => template.id === val
          );

          if (index === -1) {
            const temmplateData = this.filterTemplates.filter(
              (template) => template.id === val
            )[0];

            this.selectedTemplates.push(temmplateData);
            this.form.templateIds.push(temmplateData.id);
          }

          this.search = "";
        }
      },
      deep: true,
    },

    selectedTemplates: {
      async handler() {
        if (this.selectedTemplates.length > 0) {
          this.loadingAudience = true;
          this.audiences = await this.getAudiences(
            this.selectedTemplates.map((template) => template.id).join(",")
          );
          this.loadingAudience = false;
        }
      },
      deep: true,
    },

    utms: {
      handler() {
        this.utmsOptions = [];
        this.utms.forEach((utm) => {
          this.utmsOptions.push({ id: utm.id, text: utm.name });
        });
      },
      deep: true,
    },

    categories: {
      handler() {
        this.categoriesOptions = [];
        this.categories.forEach((category) => {
          this.categoriesOptions.push({ id: category.id, text: category.name });
        });
      },
      deep: true,
    },

    userCategories: {
      handler() {
        this.userCategoriesOptions = [];
        this.userCategories.forEach((category) => {
          this.userCategoriesOptions.push({
            id: category.id,
            text: category.name,
          });
        });
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      filterTemplates: "whatsappBlasterFilterTemplate/filterTemplates",
      courses: "course/courses",
      utms: "utm/utms",
      categories: "category/categories",
      userCategories: "userCategory/userCategorys",
    }),
  },

  methods: {
    ...mapActions({
      fetchFilterTemplate: "whatsappBlasterFilterTemplate/fetch",
      getAudiences: "whatsappBlaster/getAudiences",

      create: "whatsappBlaster/create",
      detail: "whatsappBlaster/detail",
      update: "whatsappBlaster/update",

      fetchCourse: "course/changeFilter",
      fetchUtm: "utm/changeFilter",
      fetchCategory: "category/changeFilter",
      fetchUserCategory: "userCategory/changeFilter",
    }),

    async submitFilter() {
      this.loadingFilter = true;
      const data = await this.$store.dispatch(
        "whatsappBlasterFilterTemplate/create",
        this.formTemplate
      );
      this.search = data.id;
      this.showModal = false;

      this.loadingFilter = false;
    },

    async submit(status) {
      this.form.status = status;

      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-whatsapp-blaster" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },

    uploadCsv(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.csv = e.target.result;
        console.log(e.target.result);
      };
    },

    changeCourse(e) {
      const index = this.coursesOptions.findIndex((member) => {
        return member.value === this.userId;
      });
      if (index === -1) {
        this.fetchCourse({ key: "search", value: e });
      }
    },

    deleteKey(optionKey, id) {
      switch (optionKey) {
        case "COURSE": {
          const indexSelected = this.courseSelected.findIndex(
            (course) => course.id === id
          );
          this.courseSelected.splice(indexSelected, 1);

          const indexKey = this.formTemplate.options.findIndex(
            (option) => option.key === optionKey
          );
          const values = this.formTemplate.options[indexKey].value.split(",");
          values.splice(values.indexOf(id), 1);

          if (values.length > 0) {
            this.formTemplate.options[indexKey].value = values.join(",");
          } else {
            this.formTemplate.options.splice(indexKey, 1);
          }
          break;
        }

        default:
          break;
      }
    },

    async updateFilterAudience() {
      if (this.formTemplate.options.length === 0) return;

      this.loadingFilterAudience = true;
      const query = {};

      this.formTemplate.options.forEach((option) => {
        query[option.key] = option.value;
      });

      this.filterAudiences = await this.$store.dispatch(
        "whatsappBlaster/getAudiencesByQuery",
        query
      );
      this.loadingFilterAudience = false;
    },
  },

  async mounted() {
    await this.fetchFilterTemplate();
    await this.changeCourse();
    await this.fetchCourse({ key: "search", value: "" });
    await this.fetchUtm({ key: "ordering", value: "name" });
    await this.fetchCategory({ key: "ordering", value: "name" });
    await this.fetchUserCategory({ key: "ordering", value: "name" });

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
      this.form.templateIds = [];

      this.form.templates.forEach((template) => {
        const temmplateData = this.filterTemplates.filter(
          (templateD) => templateD.id === template.id
        )[0];

        this.selectedTemplates.push(temmplateData);
        this.form.templateIds.push(temmplateData.id);
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-modal
              centered
              id="modal-new-filter-template"
              title="Buat Filter Template Persona Baru"
              size="xl"
              hide-footer
              v-model="showModal"
            >
              <div class="row">
                <div class="col-lg-12">
                  <form class="needs-validation" @submit.prevent="submitFilter">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="name">Nama Template Persona</label>
                              <input
                                id="name"
                                v-model="formTemplate.name"
                                type="text"
                                placeholder="Masukkan nama template persona..."
                                class="form-control"
                              />
                            </div>
                          </div>

                          <br />

                          <div class="row">
                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kelas</label>
                                <model-select
                                  placeholder="Pilih Kelas"
                                  :options="coursesOptions"
                                  v-model="courseId"
                                  @searchchange="changeCourse"
                                >
                                </model-select>

                                <ul class="mt-2">
                                  <li
                                    v-for="course in courseSelected"
                                    :key="course.id"
                                    class="mb-2"
                                  >
                                    {{ course.title }}
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="deleteKey('COURSE', course.id)"
                                    >
                                      Hapus
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">UTM</label>

                                <Select2
                                  v-model="utmIds"
                                  :options="utmsOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Rata rata profit perbulan</label>

                                <Select2
                                  v-model="averageProfitPerMonth"
                                  :options="[
                                    {
                                      id: 'Kurang dari 1 Juta',
                                      text: 'Kurang dari 1 Juta',
                                    },
                                    {
                                      id: 'Rp1 Juta - Rp5 Juta',
                                      text: 'Rp1 Juta - Rp5 Juta',
                                    },
                                    {
                                      id: 'Rp5 Juta - Rp10 Juta',
                                      text: 'Rp5 Juta - Rp10 Juta',
                                    },
                                    {
                                      id: 'Rp10 Juta - Rp20 Juta',
                                      text: 'Rp10 Juta - Rp20 Juta',
                                    },
                                    {
                                      id: 'Rp20 Juta - Rp50 Juta',
                                      text: 'Rp20 Juta - Rp50 Juta',
                                    },
                                    {
                                      id: 'Lebih dari Rp50 Juta',
                                      text: 'Lebih dari Rp50 Juta',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kategori yang disukai</label>

                                <Select2
                                  v-model="categoryIds"
                                  :options="categoriesOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Tipe penjualan</label>
                                <Select2
                                  v-model="typeSelling"
                                  :options="[
                                    {
                                      id: 'Produsen',
                                      text: 'Produsen',
                                    },
                                    {
                                      id: 'Reseller',
                                      text: 'Reseller',
                                    },
                                    {
                                      id: 'Dropshipper',
                                      text: 'Dropshipper',
                                    },
                                    {
                                      id: 'Jasa',
                                      text: 'Jasa',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kategori produk</label>

                                <Select2
                                  v-model="userCategoryIds"
                                  :options="userCategoriesOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <!-- <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Provinsi</label>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kota</label>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Desa</label>
                              </div>
                            </div> -->

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Jenis Kelamin</label>
                                <Select2
                                  v-model="gender"
                                  :options="[
                                    {
                                      id: 'MALE',
                                      text: 'Laki - Laki',
                                    },
                                    {
                                      id: 'FEMALE',
                                      text: 'Perempuan',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for=""
                                  >Telah bergabung kedalam grup komunitas</label
                                >
                                <Select2
                                  v-model="alreadyJoinCommunity"
                                  :options="[
                                    {
                                      id: 'true',
                                      text: 'Sudah',
                                    },
                                    {
                                      id: 'false',
                                      text: 'Belum',
                                    },
                                  ]"
                                  :settings="{ multiple: false }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Member Berbayar</label>
                                <Select2
                                  v-model="isPaidMember"
                                  :options="[
                                    {
                                      id: 'true',
                                      text: 'Iya',
                                    },
                                    {
                                      id: 'false',
                                      text: 'Bukan',
                                    },
                                  ]"
                                  :settings="{ multiple: false }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" v-if="formTemplate.options.length > 0">
                          <div class="col-md-12">
                            <div class="alert alert-info text-center">
                              <div
                                class="spinner-border text-primary"
                                role="status"
                                v-if="loadingFilterAudience"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div v-else>
                                <h5 class="text-center m-0">
                                  Estimasi Member yang akan dikirim
                                  <b>{{ filterAudiences.total || 0 }}</b>
                                </h5>

                                <!-- <b-button
                                  variant="danger"
                                  class="mt-3"
                                  v-if="filterAudiences.total > 0"
                                  v-b-modal.modal-estimate-audience-filter
                                  >Klik untuk melihat detailnya</b-button
                                > -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          :disabled="loadingFilter || !formTemplate.name"
                          class="btn btn-primary mt-4 form-control"
                          type="submit"
                        >
                          <span v-if="!loadingFilter">Simpan</span>

                          <div
                            class="spinner-border text-light spinner-border-sm"
                            role="status"
                            v-else
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </b-modal>

            <b-modal
              centered
              id="modal-estimate-audience"
              title="Estimasi member yang akan dikirim"
              size="xl"
            >
              <div v-if="audiences.total > 0">
                <table class="table table-hover table-bordered">
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Whatsaapp</th>
                    <th>E-mail</th>
                  </tr>
                  <tr
                    v-for="(audience, index) in audiences.data"
                    :key="audience.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ audience.first_name }} {{ audience.last_name }}</td>
                    <td>{{ audience.phone_number }}</td>
                    <td>{{ audience.email }}</td>
                  </tr>
                </table>
              </div>
            </b-modal>

            <b-modal
              centered
              id="modal-estimate-audience-filter"
              title="Estimasi member yang akan dikirim"
              size="xl"
            >
              <div v-if="filterAudiences.total > 0">
                <table class="table table-hover table-bordered">
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Whatsaapp</th>
                    <th>E-mail</th>
                  </tr>
                  <tr
                    v-for="(audience, index) in filterAudiences.data"
                    :key="audience.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ audience.first_name }} {{ audience.last_name }}</td>
                    <td>{{ audience.phone_number }}</td>
                    <td>{{ audience.email }}</td>
                  </tr>
                </table>
              </div>
            </b-modal>

            <div class="row">
              <div class="col-md-12">
                <label for="name">Nama Blaster</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  placeholder="Masukkan nama event..."
                  class="form-control"
                />
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-6">
                <label for="name">Tanggal Mulai Blaster (Optional)</label>
                <input
                  id="name"
                  v-model="form.startAt"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <label for="name">Tanggal Berakhir Blaster (Optional)</label>
                <input
                  id="name"
                  v-model="form.endAt"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-info">
                  <li>
                    Gunakan tag <b>{name}</b> untuk menggunakan nama lengkap
                    member
                  </li>
                  <li>
                    Gunakan tag <b>{firstName}</b> untuk menggunakan nama depan
                    member
                  </li>
                  <li>
                    Gunakan tag <b>{lastName}</b> untuk menggunakan nama
                    belakang member
                  </li>
                  <li>
                    Gunakan tag <b>{whatsapp}</b> untuk menggunakan nomer
                    whatsapp
                  </li>
                </div>

                <label for="text">Teks</label>
                <textarea
                  id="text"
                  v-model="form.text"
                  type="text"
                  placeholder="Masukkan nama event..."
                  class="form-control"
                />
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-12">
                <div>
                  <label for="image">Gambar</label>
                  <input
                    id="image"
                    type="file"
                    class="form-control"
                    placeholder="image"
                    value="Mark"
                    :class="{
                      'is-invalid': errors['image'],
                    }"
                    @change="uploadImage"
                  />
                </div>
                <div style="width: 300px" v-if="form.image">
                  <img
                    class="mt-3"
                    :src="form.image | media"
                    width="300"
                    alt=""
                    v-img
                  />
                  <button
                    class="form-control btn btn-danger btn-sm mt-3"
                    @click="form.image = ''"
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>

            <br />

            <div class="row" v-if="form.status !== 'PUBLISHED'">
              <div class="col-md-12">
                <div class="col-12">
                  <div class="row">
                    <label for="">Metode Pemilihinan Audience</label>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="filter"
                    v-model="typeSelectAudience"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Menggunakan Fitur Filter Database</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="csv"
                    v-model="typeSelectAudience"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Menggunakan File CSV</label
                  >
                </div>
              </div>
            </div>

            <br />

            <div
              v-if="typeSelectAudience === 'csv' && form.status !== 'PUBLISHED'"
            >
              <div class="row">
                <div class="col-md-12">
                  <label for="">Pilih Template</label>

                  <input type="file" class="form-control" @change="uploadCsv" />
                </div>
              </div>
            </div>

            <div
              v-if="
                typeSelectAudience === 'filter' && form.status !== 'PUBLISHED'
              "
            >
              <div class="row">
                <div class="col-md-12">
                  <label for="">Pilih Template</label>
                  <select name="search" v-model="search" class="form-control">
                    <option value="">Pilih Template</option>
                    <option
                      v-for="template in filterTemplates"
                      :key="template.id"
                      :value="template.id"
                    >
                      {{ template.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <b-button
                    class="mt-3 text-decoration-none"
                    variant="primary"
                    v-b-modal.modal-new-filter-template
                    size="sm"
                  >
                    <template>
                      <p style="margin: 0">Buat template</p>
                    </template>
                  </b-button>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <b-card
                    v-for="(template, index) in selectedTemplates"
                    :key="template.id"
                  >
                    <template>
                      <div class="d-flex justify-content-between">
                        <p style="font-size: 18px; font-weight: bold">
                          {{ template.name }}
                        </p>
                        <b-button
                          class="mb-3 text-decoration-none"
                          style="color: #000; padding: 0"
                          variant="link"
                          @click="
                            selectedTemplates.splice(index, 1);
                            form.templateIds.splice(index, 1);
                          "
                        >
                          <template>
                            <i class="ri-close-fill"></i>
                          </template>
                        </b-button>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="col-12">
                          <div class="row">
                            <div
                              class="col-4 mb-2"
                              v-for="option in template.options"
                              :key="`head-${option.id}`"
                            >
                              <label for="">{{
                                option.key | filterTemplateType
                              }}</label>
                              <p>{{ option.text }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>
              </div>
            </div>

            <hr />

            <br />

            <div class="row" v-if="selectedTemplates.length > 0">
              <div class="col-md-12">
                <div class="alert alert-info text-center">
                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                    v-if="loadingAudience"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>
                    <h5 class="text-center m-0">
                      Estimasi Member yang akan dikirim
                      <b>{{ audiences.total || 0 }}</b>
                    </h5>

                    <!-- <b-button
                      variant="danger"
                      class="mt-3"
                      v-if="audiences.total > 0"
                      v-b-modal.modal-estimate-audience
                      >Klik untuk melihat detailnya</b-button
                    > -->
                  </div>
                </div>
              </div>
            </div>

            <div v-if="form.status !== 'PUBLISHED'">
              <button
                :disabled="loading"
                class="btn btn-warning mt-4 form-control"
                type="button"
                @click="submit('DRAFT')"
              >
                <span v-if="!loading">Draft Blaster</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="button"
                @click="submit('PUBLISHED')"
              >
                <span v-if="!loading">Publish Blaster</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="button"
                @click="submit(form.status)"
              >
                <span v-if="!loading">Update Blaster</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.select2-container {
  width: 100% !important;
}
</style>
