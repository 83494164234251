import api from "./index";

const baseURL = "/dashboards";

// API for get all users with params
const fetchAdmin = (params) => api.get(`${baseURL}/admin`, { params });

// API for get total users
const getTotalUsers = (params) => api.get(`${baseURL}/total-users`, { params });

// API for get total users already login
const getTotalUsersAlreadyLogin = (params) =>
  api.get(`${baseURL}/total-users-already-login`, { params });

// API for get total users already read module
const getTotalUsersAlreadyReadModule = (params) =>
  api.get(`${baseURL}/total-users-already-read-module`, { params });

// API for get total users have certificate
const getTotalUsersHaveCertificate = (params) =>
  api.get(`${baseURL}/total-users-have-certificate`, { params });

// API for get total new users
const getTotalNewUsers = (params) =>
  api.get(`${baseURL}/total-new-users`, { params });

// API for get total users having course
const getTotalUsersHavingCourse = (params) =>
  api.get(`${baseURL}/total-users-having-course`, { params });

// API for get total users login
const getTotalUsersLogin = (params) =>
  api.get(`${baseURL}/total-users-login`, { params });

// API for get total users used forum
const getTotalUsersUsedForum = (params) =>
  api.get(`${baseURL}/total-users-used-forum`, { params });

// API for get total users take quiz
const getTotalUsersTakeQuiz = (params) =>
  api.get(`${baseURL}/total-users-take-quiz`, { params });

// API for get total revenue
const getTotalRevenue = (params) =>
  api.get(`${baseURL}/total-order-revenue`, { params });

// API for get total order paid
const getTotalOrdersPaid = (params) =>
  api.get(`${baseURL}/total-orders-paid`, { params });

// API for get order paid ratio
const getPaidRatio = (params) => api.get(`${baseURL}/paid-ratio`, { params });

// API for get total members order
const getTotalMembersOrder = (params) =>
  api.get(`${baseURL}/total-members-order`, { params });

// API for get total members paid
const getTotalMembersPaid = (params) =>
  api.get(`${baseURL}/total-members-paid`, { params });

// API for get total new enrollment
const getTotalNewEnrollment = (params) =>
  api.get(`${baseURL}/total-new-enrollment`, { params });

// API for get total new paid enrollment
const getTotalNewPaidEnrollment = (params) =>
  api.get(`${baseURL}/total-new-paid-enrollment`, { params });

// API for get total new free enrollment
const getTotalNewFreeEnrollment = (params) =>
  api.get(`${baseURL}/total-new-free-enrollment`, { params });

// API for get total member new enrollment
const getTotalMemberNewEnrollment = (params) =>
  api.get(`${baseURL}/total-member-new-enrollment`, { params });

// API for get total member new paid enrollment
const getTotalMemberNewPaidEnrollment = (params) =>
  api.get(`${baseURL}/total-member-new-paid-enrollment`, { params });

// API for get total member new free enrollment
const getTotalMemberNewFreeEnrollment = (params) =>
  api.get(`${baseURL}/total-member-new-free-enrollment`, { params });

export default {
  fetchAdmin,

  // Overal member
  getTotalUsers,
  getTotalUsersAlreadyLogin,
  getTotalUsersAlreadyReadModule,
  getTotalUsersHaveCertificate,
  getTotalNewUsers,
  getTotalUsersHavingCourse,
  getTotalUsersLogin,
  getTotalUsersTakeQuiz,
  getTotalUsersUsedForum,

  // Transaction Order
  getTotalRevenue,
  getTotalOrdersPaid,
  getPaidRatio,
  getTotalMembersOrder,
  getTotalMembersPaid,

  // Enrollment
  getTotalNewEnrollment,
  getTotalNewPaidEnrollment,
  getTotalNewFreeEnrollment,
  getTotalMemberNewEnrollment,
  getTotalMemberNewPaidEnrollment,
  getTotalMemberNewFreeEnrollment,
};
