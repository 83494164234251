<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import { ModelSelect } from "vue-search-select";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Filter Template Whatsapp Blaster",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ModelSelect, Select2 },
  data() {
    return {
      title: "Filter Template Whatsapp Blaster",
      items: [
        {
          text: "Whatsapp Blaster",
          href: "/whatsapp-blaster",
        },
        {
          text: "Filter Template",
          active: true,
        },
      ],
      errors: {},
      form: {
        gender: "",
        finishedClass: "",
        utm: "",
        rangeProfit: "",
      },
      loading: false,
      submitted: false,
      eventName: "",
      search: "",
      showModal: false,
      selectedFilterTemplate: "",
      totalAffectedUser: 0,

      formTemplate: {
        name: "",
        options: [],
      },

      courseId: "",
      coursesOptions: [],
      courseSelected: [],

      utmIds: "",
      utmsOptions: [],
      utmSelected: [],

      userCategoryIds: [],
      userCategoriesOptions: [],
      userCategorySelected: [],

      categoryIds: [],
      categoriesOptions: [],
      categorySelected: [],

      filterAudiences: [],
      loadingFilterAudience: false,
      loadingFilter: false,

      typeSelling: [],
      averageProfitPerMonth: [],
      gender: [],
      paidMember: [],
      alreadyJoinCommunity: "",
      isPaidMember: "",

      typeSelectAudience: "filter",

      indexSelected: "-1",
    };
  },

  computed: mapGetters({
    filterTemplates: "whatsappBlasterFilterTemplate/filterTemplates",
    courses: "course/courses",
    utms: "utm/utms",
    categories: "category/categories",
    userCategories: "userCategory/userCategorys",
  }),

  watch: {
    courses: {
      handler(val) {
        this.coursesOptions = [];

        Object.assign([], val).forEach((course) => {
          this.coursesOptions.push({
            value: course.id,
            text: `${course.title}`,
          });
        });
      },
      deep: true,
    },

    courseId(val) {
      const index = this.courses.findIndex((course) => course.id === val);

      if (index > -1) {
        const exists = this.courseSelected.findIndex(
          (course) => course.id === val
        );

        if (exists === -1) {
          this.courseSelected.push(this.courses[index]);

          const indexKey = this.formTemplate.options.findIndex(
            (option) => option.key === "COURSE"
          );

          if (indexKey > -1) {
            this.formTemplate.options[
              index
            ].value += `,${this.courses[index].id}`;
          } else {
            this.formTemplate.options.push({
              key: "COURSE",
              value: this.courses[index].id,
            });
          }
        }
      }
    },

    categoryIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "CATEGORY"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "CATEGORY",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    userCategoryIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "PRODUCT_CATEGORY"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "PRODUCT_CATEGORY",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    typeSelling(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "TYPE_SELLING"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "TYPE_SELLING",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    gender(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "GENDER"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "GENDER",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    averageProfitPerMonth(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "AVERAGE_PROFIT_PER_MONTH"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "AVERAGE_PROFIT_PER_MONTH",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    alreadyJoinCommunity(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "ALREADY_JOIN_COMMUNITY_GROUP"
      );

      if (val) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val;
        } else {
          this.formTemplate.options.push({
            key: "ALREADY_JOIN_COMMUNITY_GROUP",
            value: val,
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    isPaidMember(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "IS_PAID_MEMBER"
      );

      if (val) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val;
        } else {
          this.formTemplate.options.push({
            key: "IS_PAID_MEMBER",
            value: val,
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    utmIds(val) {
      const indexKey = this.formTemplate.options.findIndex(
        (option) => option.key === "UTM"
      );

      if (val.length > 0) {
        if (indexKey > -1) {
          this.formTemplate.options[indexKey].value = val.join(",");
        } else {
          this.formTemplate.options.push({
            key: "UTM",
            value: val.join(","),
          });
        }
      } else {
        this.formTemplate.options.splice(indexKey, 1);
      }
    },

    "formTemplate.options": {
      handler() {
        if (this.formTemplate.options.length > 0) this.updateFilterAudience();
      },
      deep: true,
    },

    search: {
      async handler(val) {
        if (val) {
          const index = this.selectedTemplates.findIndex(
            (template) => template.id === val
          );

          if (index === -1) {
            const temmplateData = this.filterTemplates.filter(
              (template) => template.id === val
            )[0];

            this.selectedTemplates.push(temmplateData);
            this.form.templateIds.push(temmplateData.id);
          }

          this.search = "";
        }
      },
      deep: true,
    },

    selectedTemplates: {
      async handler() {
        if (this.selectedTemplates.length > 0) {
          this.loadingAudience = true;
          this.audiences = await this.getAudiences(
            this.selectedTemplates.map((template) => template.id).join(",")
          );
          this.loadingAudience = false;
        }
      },
      deep: true,
    },

    utms: {
      handler() {
        this.utmsOptions = [];
        this.utms.forEach((utm) => {
          this.utmsOptions.push({ id: utm.id, text: utm.name });
        });
      },
      deep: true,
    },

    categories: {
      handler() {
        this.categoriesOptions = [];
        this.categories.forEach((category) => {
          this.categoriesOptions.push({ id: category.id, text: category.name });
        });
      },
      deep: true,
    },

    userCategories: {
      handler() {
        this.userCategoriesOptions = [];
        this.userCategories.forEach((category) => {
          this.userCategoriesOptions.push({
            id: category.id,
            text: category.name,
          });
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      destroy: "whatsappBlasterFilterTemplate/delete",
      fetch: "whatsappBlasterFilterTemplate/fetch",

      fetchCourse: "course/changeFilter",
      fetchUtm: "utm/changeFilter",
      fetchCategory: "category/changeFilter",
      fetchUserCategory: "userCategory/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.loading = true;

      this.showModal = false;

      this.loading = false;
      this.submitted = false;
    },

    resetForm() {
      this.form = {
        gender: "",
        finishedClass: "",
        utm: "",
        rangeProfit: "",
      };
    },

    changeCourse(e) {
      const index = this.coursesOptions.findIndex((member) => {
        return member.value === this.userId;
      });
      if (index === -1) {
        this.fetchCourse({ key: "search", value: e });
      }
    },

    clickNewTemplate() {
      this.resetForm();
      this.showModal = true;
    },

    deleteFilterTemplate(id) {
      this.$swal({
        text: "Apakah kamu yakin ingin menghapus data ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, Hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.destroy(id);
        }

        this.editTotalReadId = "";
        this.totalViewEdit = "";
      });
    },

    editFilterTemplate(index) {
      this.indexSelected = index;
      this.showModal = true;

      this.formTemplate = this.filterTemplates[this.indexSelected];
      this.formTemplate.options.forEach((option) => {
        switch (option.key) {
          case "GENDER":
            this.gender = option.value;
            break;

          // case "GENDER":
          //   this.gender = option.value;
          //   break;

          case "COURSE":
            // option.value.split(",").forEach((val) => {
            // const index = this.coursesOptions
            // });
            // courseSelectedß
            break;

          default:
            break;
        }
      });

      console.log(this.formTemplate.options);
    },

    async submitFilter() {
      this.loadingFilter = true;
      const data = await this.$store.dispatch(
        "whatsappBlasterFilterTemplate/create",
        this.formTemplate
      );
      this.search = data.id;
      this.showModal = false;

      this.loadingFilter = false;
    },

    getValueOption(options, key) {
      const index = options.findIndex((option) => option.key === key);

      if (index > -1) {
        return options[index].text;
      } else {
        return "-";
      }
    },

    async updateFilterAudience() {
      if (this.formTemplate.options.length === 0) return;

      this.loadingFilterAudience = true;
      const query = {};

      this.formTemplate.options.forEach((option) => {
        query[option.key] = option.value;
      });

      this.filterAudiences = await this.$store.dispatch(
        "whatsappBlaster/getAudiencesByQuery",
        query
      );
      this.loadingFilterAudience = false;
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetch();
    await this.changeCourse();
    await this.fetchCourse({ key: "search", value: "" });
    await this.fetchUtm({ key: "ordering", value: "name" });
    await this.fetchCategory({ key: "ordering", value: "name" });
    await this.fetchUserCategory({ key: "ordering", value: "name" });
    this.loading = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-modal
              centered
              id="modal-new-filter-template"
              :title="
                `${
                  indexSelected > -1
                    ? 'Ubah Filter Template Persona'
                    : 'Buat Filter Template Persona Baru'
                }`
              "
              size="xl"
              hide-footer
              v-model="showModal"
            >
              <div class="row">
                <div class="col-lg-12">
                  <form class="needs-validation" @submit.prevent="submitFilter">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="name">Nama Template Persona</label>
                              <input
                                id="name"
                                v-model="formTemplate.name"
                                type="text"
                                placeholder="Masukkan nama template persona..."
                                class="form-control"
                              />
                            </div>
                          </div>

                          <br />

                          <div class="row">
                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kelas</label>
                                <model-select
                                  placeholder="Pilih Kelas"
                                  :options="coursesOptions"
                                  v-model="courseId"
                                  @searchchange="changeCourse"
                                >
                                </model-select>

                                <ul class="mt-2">
                                  <li
                                    v-for="course in courseSelected"
                                    :key="course.id"
                                    class="mb-2"
                                  >
                                    {{ course.title }}
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="deleteKey('COURSE', course.id)"
                                    >
                                      Hapus
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">UTM</label>

                                <Select2
                                  v-model="utmIds"
                                  :options="utmsOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Rata rata profit perbulan</label>

                                <Select2
                                  v-model="averageProfitPerMonth"
                                  :options="[
                                    {
                                      id: 'Kurang dari 1 Juta',
                                      text: 'Kurang dari 1 Juta',
                                    },
                                    {
                                      id: 'Rp1 Juta - Rp5 Juta',
                                      text: 'Rp1 Juta - Rp5 Juta',
                                    },
                                    {
                                      id: 'Rp5 Juta - Rp10 Juta',
                                      text: 'Rp5 Juta - Rp10 Juta',
                                    },
                                    {
                                      id: 'Rp10 Juta - Rp20 Juta',
                                      text: 'Rp10 Juta - Rp20 Juta',
                                    },
                                    {
                                      id: 'Rp20 Juta - Rp50 Juta',
                                      text: 'Rp20 Juta - Rp50 Juta',
                                    },
                                    {
                                      id: 'Lebih dari Rp50 Juta',
                                      text: 'Lebih dari Rp50 Juta',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kategori yang disukai</label>

                                <Select2
                                  v-model="categoryIds"
                                  :options="categoriesOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Tipe penjualan</label>
                                <Select2
                                  v-model="typeSelling"
                                  :options="[
                                    {
                                      id: 'Produsen',
                                      text: 'Produsen',
                                    },
                                    {
                                      id: 'Reseller',
                                      text: 'Reseller',
                                    },
                                    {
                                      id: 'Dropshipper',
                                      text: 'Dropshipper',
                                    },
                                    {
                                      id: 'Jasa',
                                      text: 'Jasa',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kategori produk</label>

                                <Select2
                                  v-model="userCategoryIds"
                                  :options="userCategoriesOptions"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <!-- <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Provinsi</label>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Kota</label>
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Desa</label>
                              </div>
                            </div> -->

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Jenis Kelamin</label>
                                <Select2
                                  v-model="gender"
                                  :options="[
                                    {
                                      id: 'MALE',
                                      text: 'Laki - Laki',
                                    },
                                    {
                                      id: 'FEMALE',
                                      text: 'Perempuan',
                                    },
                                  ]"
                                  :settings="{ multiple: true }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for=""
                                  >Telah bergabung kedalam grup komunitas</label
                                >
                                <Select2
                                  v-model="alreadyJoinCommunity"
                                  :options="[
                                    {
                                      id: 'true',
                                      text: 'Sudah',
                                    },
                                    {
                                      id: 'false',
                                      text: 'Belum',
                                    },
                                  ]"
                                  :settings="{ multiple: false }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>

                            <div class="col-4 mb-2">
                              <div class="form-group">
                                <label for="">Member Berbayar</label>
                                <Select2
                                  v-model="isPaidMember"
                                  :options="[
                                    {
                                      id: 'true',
                                      text: 'Iya',
                                    },
                                    {
                                      id: 'false',
                                      text: 'Bukan',
                                    },
                                  ]"
                                  :settings="{ multiple: false }"
                                  style="width: 100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" v-if="formTemplate.options.length > 0">
                          <div class="col-md-12">
                            <div class="alert alert-info text-center">
                              <div
                                class="spinner-border text-primary"
                                role="status"
                                v-if="loadingFilterAudience"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div v-else>
                                <h5 class="text-center m-0">
                                  Estimasi Member yang akan dikirim
                                  <b>{{ filterAudiences.total || 0 }}</b>
                                </h5>

                                <!-- <b-button
                                  variant="danger"
                                  class="mt-3"
                                  v-if="filterAudiences.total > 0"
                                  v-b-modal.modal-estimate-audience-filter
                                  >Klik untuk melihat detailnya</b-button
                                > -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          :disabled="loadingFilter || !formTemplate.name"
                          class="btn btn-primary mt-4 form-control"
                          type="submit"
                        >
                          <span v-if="!loadingFilter">Simpan</span>

                          <div
                            class="spinner-border text-light spinner-border-sm"
                            role="status"
                            v-else
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </b-modal>

            <div class="row">
              <div class="col-md-12">
                <b-button
                  class="text-decoration-none form-control"
                  variant="primary"
                  @click="clickNewTemplate"
                >
                  <template>
                    <p style="margin: 0">Buat Template</p>
                  </template>
                </b-button>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <b-card
                  v-for="(filterTemplate, index) in filterTemplates"
                  :key="index"
                >
                  <template>
                    <div class="d-flex justify-content-between">
                      <p style="font-size: 18px; font-weight: bold">
                        {{ filterTemplate.name }}
                      </p>
                      <div>
                        <b-button
                          class="mb-3 text-decoration-none mr-2"
                          style="color: #000; padding: 0"
                          variant="link"
                          @click="editFilterTemplate(index)"
                        >
                          <template>
                            <i class="ri-pencil-line"></i>
                          </template>
                        </b-button>

                        <b-button
                          class="mb-3 text-decoration-none"
                          style="color: #000; padding: 0"
                          variant="link"
                          @click="deleteFilterTemplate(filterTemplate.id)"
                        >
                          <template>
                            <i class="ri-close-fill"></i>
                          </template>
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Kelas</strong>
                        <br />
                        <span>{{
                          getValueOption(filterTemplate.options, "COURSE")
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>UTM</strong>
                        <br />
                        <span>{{
                          getValueOption(filterTemplate.options, "UTM")
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Rata rata profit perbulan</strong>
                        <br />
                        <span>{{
                          getValueOption(
                            filterTemplate.options,
                            "AVERAGE_PROFIT_PER_MONTH"
                          )
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Kategori yang disukai</strong>
                        <br />
                        <span>{{
                          getValueOption(filterTemplate.options, "CATEGORY")
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Tipe penjualan</strong>
                        <br />
                        <span>{{
                          getValueOption(filterTemplate.options, "TYPE_SELLING")
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Kategori produk</strong>
                        <br />
                        <span>{{
                          getValueOption(
                            filterTemplate.options,
                            "PRODUCT_CATEGORY"
                          )
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Jenis Kelamin</strong>
                        <br />
                        <span>{{
                          getValueOption(filterTemplate.options, "GENDER")
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Telah bergabung kedalam grup komunitas</strong>
                        <br />
                        <span>{{
                          getValueOption(
                            filterTemplate.options,
                            "ALREADY_JOIN_COMMUNITY_GROUP"
                          )
                        }}</span>
                      </b-card-text>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-card-text>
                        <strong>Member Berbayar</strong>
                        <br />
                        <span>{{
                          getValueOption(
                            filterTemplate.options,
                            "IS_PAID_MEMBER"
                          )
                        }}</span>
                      </b-card-text>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.select2-container {
  width: 100% !important;
}
</style>
