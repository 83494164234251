<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Nomer Master Whatsapp",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      partners: "partnershipPartner/partnershipPartners",
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Nomer Master Whatsapp"
        : "Tambah Nomer Master Whatsapp",
      id: this.$route.params.id,
      items: [
        {
          text: "Nomer Master Whatsapp",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        link: "",
        token: "",
        type: "GROUP",
        isActive: true,
        isBanned: false,
        device: "",
        location: "",
        expiredAt: "",
        ipHost: "",
        ipPortHost: "",
        partnerId: "",
      },
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "whatsappNumberHost/create",
      detail: "whatsappNumberHost/detail",
      update: "whatsappNumberHost/update",

      fetchPartner: "partnershipPartner/changeFilters",
    }),

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-whatsapp-number-host" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },
  },

  async mounted() {
    if (!this.isPartner()) {
      await this.fetchPartner([
        { key: "limit", value: 9999 },
        { key: "ordering", value: "name" },
      ]);
    }

    if (this.$route.params.id) {
      const data = await this.detail(this.$route.params.id);

      this.form = data;
      if (this.form.expiredAt)
        this.form.expiredAt = this.$moment(this.form.expiredAt).format(
          "yyyy-MM-DD"
        );

      if (this.form.partner) this.form.partnerId = this.form.partner.id;

      if (this.isPartner()) this.form.partnerId = this.user.partner.id;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="number">Nomer *)</label>
                    <input
                      id="number"
                      v-model="form.number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['number'],
                      }"
                    />
                    <div v-if="errors['number']" class="invalid-feedback">
                      <span>{{ errors["number"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="token">Token *)</label>
                    <input
                      id="token"
                      v-model="form.token"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['token'],
                      }"
                    />
                    <div v-if="errors['token']" class="invalid-feedback">
                      <span>{{ errors["token"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="type">Tipe *)</label>
                    <select
                      id="type"
                      v-model="form.type"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['type'],
                      }"
                    >
                      <option value="MANAGEMENT_GROUP">Management Grup</option>
                      <option value="BLASTER">Blaster</option>
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="host">Host *)</label>
                    <input
                      id="host"
                      v-model="form.host"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['host'],
                      }"
                    />
                    <div v-if="errors['host']" class="invalid-feedback">
                      <span>{{ errors["host"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="isBanned">Apakah bot ini terbanned? *)</label>
                    <select
                      id="isBanned"
                      v-model="form.isBanned"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['isBanned'],
                      }"
                    >
                      <option :value="true">Iya</option>
                      <option :value="false">TIdak</option>
                    </select>
                    <div v-if="errors['isBanned']" class="invalid-feedback">
                      <span>{{ errors["isBanned"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="isActive">Apakah bot ini aktif? *)</label>
                    <select
                      id="isActive"
                      v-model="form.isActive"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['isActive'],
                      }"
                    >
                      <option :value="true">Iya Aktif</option>
                      <option :value="false">TIdak Aktif</option>
                    </select>
                    <div v-if="errors['isActive']" class="invalid-feedback">
                      <span>{{ errors["isActive"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="device">Device</label>
                    <input
                      id="device"
                      v-model="form.device"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['device'],
                      }"
                    />
                    <div v-if="errors['device']" class="invalid-feedback">
                      <span>{{ errors["device"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="location">Lokasi</label>
                    <input
                      id="location"
                      v-model="form.location"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['location'],
                      }"
                    />
                    <div v-if="errors['location']" class="invalid-feedback">
                      <span>{{ errors["location"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="expiredAt">Expired Kartu</label>
                    <input
                      id="expiredAt"
                      v-model="form.expiredAt"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['expiredAt'],
                      }"
                    />
                    <div v-if="errors['expiredAt']" class="invalid-feedback">
                      <span>{{ errors["expiredAt"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
