import filterTemplateApi from "../../api/whatsapp-blaster-filter-template.api";

import filterDefault from "../../helpers/filter";

export const state = {
  filterTemplates: [],
  filterTemplate: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  filterTemplates(state) {
    return state.filterTemplates;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_FILTER_TEMPLATES(state, filterTemplates) {
    state.filterTemplates = filterTemplates;
  },

  SET_FILTER_TEMPLATE(state, filterTemplate) {
    state.filterTemplate = filterTemplate;
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  ADD_FILTER_TEMPLATE(state, filterTemplate) {
    state.filterTemplates.unshift(filterTemplate);
  },

  CHANGE_FILTER_TEMPLATE(state, { id, filterTemplate }) {
    state.filterTemplates[
      state.filterTemplates.findIndex((template) => template.id === id)
    ] = filterTemplate;
  },

  REMOVE_FILTER_TEMPLATE(state, id) {
    state.filterTemplates.splice(
      state.filterTemplates.findIndex(
        (filterTemplate) => filterTemplate.id === id
      ),
      1
    );
  },
};

export const actions = {
  async fetch({ commit, state }) {
    try {
      const { data } = await filterTemplateApi.fetch(state.filter);

      commit("SET_FILTER_TEMPLATES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async detail(context, id) {
    const { data } = await filterTemplateApi.detail(id);

    return data.data;
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await filterTemplateApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_FILTER_TEMPLATE", data.data);
    dispatch("notifications/success", data.message, { root: true });

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await filterTemplateApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notifications/success", data.message, { root: true });
    commit("CHANGE_FILTER_TEMPLATE", {
      id,
      filterTemplate: data.data,
    });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await filterTemplateApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_FILTER_TEMPLATE", id);

    return { status: true };
  },
};
