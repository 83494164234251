import api from "./index";

const baseURL = "/roles";

// API for get all users with params
const fetch = (params) => api.get(`${baseURL}`, { params });

export default {
  fetch,
};
