import whatsappGroupGreetingHistoryApi from "../../api/whatsapp-group-greeting-history.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupGreetingHistories: [],
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupGreetingHistories(state) {
    return state.whatsappGroupGreetingHistories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_GREETING_HISTORIES(state, whatsappGroupGreetingHistories) {
    state.whatsappGroupGreetingHistories = whatsappGroupGreetingHistories;
  },

  MERGE_WHATSAPP_GROUP_GREETING_HISTORIES(
    state,
    whatsappGroupGreetingHistories
  ) {
    state.whatsappGroupGreetingHistories = whatsappGroupGreetingHistories.concat(
      state.whatsappGroupGreetingHistories
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },

  UPDATE_TOTAL_VIEWS(state, { id, totalViews }) {
    const index = state.whatsappGroupGreetingHistories.findIndex(
      (history) => history.id === id
    );

    if (index > -1)
      state.whatsappGroupGreetingHistories[index].totalViews = totalViews;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupGreetingHistoryApi.fetch(
        state.filter
      );

      commit("SET_WHATSAPP_GROUP_GREETING_HISTORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async resend(context, id) {
    const { data } = await whatsappGroupGreetingHistoryApi.resend(id);

    return data.data;
  },

  async syncTotalRead(context, id) {
    const { data } = await whatsappGroupGreetingHistoryApi.syncTotalRead(id);

    return data.data;
  },

  async updateTotalRead({ commit }, { id, payload }) {
    const { data } = await whatsappGroupGreetingHistoryApi.updateTotalRead(id);
    const { totalViews } = payload;

    commit("UPDATE_TOTAL_VIEWS", { id, totalViews });

    return data.data;
  },
};
