<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { courseMethods } from "../../../state/helpers";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

export default {
  page: {
    title: "Tambah Kelas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...mapGetters({
      userCategories: "userCategory/userCategorys",
    }),

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    categories() {
      return this.$store ? this.$store.state.category.categories : [];
    },

    mentors() {
      return this.$store.getters["user/users"];
    },
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Kelas" : "Tambah Kelas",
      id: this.$route.params.id,
      items: [
        {
          text: "Kelas",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        title: "",
        description: "",
        banner: "",
        isFree: false,
        price: 0,
        discount: 0,
        categoryId: "",
        categoryIds: [],
        mentorIds: [],
        type: "SELF_STUDY", //  SELF_STUDY or WEBINAR
        linkEnroll: "",
        isSequence: false,
        show: true,
        disableEnroll: false,
        code: "",
        accessPeriode: 60,
        showForum: false,
        whatsappGroupLink: "",
        typeAudience: "RETAIL",
        filterAverageProfit: [],
        filterTypeOfBusiness: [],
        isRecommendation: false,
        customNameWhatsappGroup: "",
        isKulwa: false,
        startKulwaAt: "",
        endKulwaAt: "",
        haveConfirmation: false,
        textConfirmation: "",
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      userCategoryOptions: [],
      averageProfitOptions: [
        {
          id: "Kurang dari 1 Juta",
          text: "Kurang dari 1 Juta",
        },
        {
          id: "Rp1 Juta - Rp5 Juta",
          text: "Rp1 Juta - Rp5 Juta",
        },
        {
          id: "Rp5 Juta - Rp10 Juta",
          text: "Rp5 Juta - Rp10 Juta",
        },
        {
          id: "Rp10 Juta - Rp20 Juta",
          text: "Rp10 Juta - Rp20 Juta",
        },
        {
          id: "Rp20 Juta - Rp50 Juta",
          text: "Rp20 Juta - Rp50 Juta",
        },
        {
          id: "Lebih dari Rp50 Juta",
          text: "Lebih dari Rp50 Juta",
        },
      ],
      loading: false,
    };
  },
  watch: {
    categories: {
      handler(val) {
        this.categoriesOptions = [];

        Object.assign([], val).forEach((category) => {
          this.categoriesOptions.push({
            id: category.id,
            text: category.name,
          });
        });
      },
      deep: true,
    },

    "form.type"(val) {
      if (val === "KULWA") this.form.isKulwa = true;
    },

    mentors: {
      handler(val) {
        this.mentorsOptions = [];

        Object.assign([], val).forEach((mentor) => {
          this.mentorsOptions.push({
            id: mentor.id,
            text: mentor.name,
          });
        });
      },
      deep: true,
    },

    userCategories: {
      handler(val) {
        this.userCategoryOptions = [];

        Object.assign([], val).forEach((category) => {
          this.userCategoryOptions.push({
            id: category.id,
            text: category.name,
          });
        });
      },
      deep: true,
    },
  },
  methods: {
    ...courseMethods,

    ...mapActions({
      fetchCategories: "category/fetchCategories",
      fetchUsers: "user/changeFilter",
      fetchUserCategory: "userCategory/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      console.log(this.form);

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-course" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadBanner(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.banner = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = Object.assign({}, await this.detail(this.$route.params.id));

      this.form.categoryIds = this.form.categories.map(
        (category) => category.id
      );

      if (this.form.mentors)
        this.form.mentorIds = this.form.mentors.map((mentor) => mentor.id);

      if (this.form.filterAverageProfit)
        this.form.filterAverageProfit = this.form.filterAverageProfit.split(
          ","
        );

      if (this.form.filterTypeOfBusiness)
        this.form.filterTypeOfBusiness = this.form.filterTypeOfBusiness.map(
          (type) => type.id
        );

      if (this.form.startKulwaAt)
        this.form.startKulwaAt = this.$options.filters.moment(
          this.form.startKulwaAt,
          "YYYY-MM-DDTHH:mm"
        );

      if (this.form.endKulwaAt)
        this.form.endKulwaAt = this.$options.filters.moment(
          this.form.endKulwaAt,
          "YYYY-MM-DDTHH:mm"
        );
    }

    this.fetchCategories();
    this.fetchUsers({ key: "roles", value: "MENTOR" });
    this.fetchUserCategory({ key: "ordering", value: "name" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Judul</label>
                    <input
                      id="title"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      placeholder="Ketik judul..."
                      value="Mark"
                      :class="{
                        'is-invalid': errors['title'],
                      }"
                    />
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="code">Periode Akses (Hari)</label>
                    <input
                      id="accessPeriode"
                      v-model="form.accessPeriode"
                      type="number"
                      class="form-control"
                      placeholder="Ketik kode..."
                      value="Mark"
                      :class="{
                        'is-invalid': errors['accessPeriode'],
                      }"
                    />
                    <div
                      v-if="errors['accessPeriode']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["accessPeriode"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="code">Custom Nama Group (Optional)</label>
                    <input
                      id="customNameWhatsappGroup"
                      v-model="form.customNameWhatsappGroup"
                      type="text"
                      class="form-control"
                      placeholder="Custom Group - {number}"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['customNameWhatsappGroup'],
                      }"
                    />
                    <div
                      v-if="errors['customNameWhatsappGroup']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["customNameWhatsappGroup"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Tipe Audiens</label>
                    <select v-model="form.typeAudience" class="form-control">
                      <option value="RETAIL">Retail</option>
                      <option value="PARTNERSHIP">Partnership</option>
                      <option value="BUSINESS_DEVELOPMENT"
                        >Bisnis Development</option
                      >
                    </select>
                    <div v-if="errors['title']" class="invalid-feedback">
                      <span>{{ errors["title"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="category">Filter Rata Rata Profit</label>
                    <Select2
                      v-model="form.filterAverageProfit"
                      :options="averageProfitOptions"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="category">Filter Jenis Bisnis</label>
                    <Select2
                      v-model="form.filterTypeOfBusiness"
                      :options="userCategoryOptions"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="mentor">Mentor</label>
                    <Select2
                      v-model="form.mentorIds"
                      :options="mentorsOptions"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['mentorIds']" class="invalid-feedback">
                      <span>{{ errors["mentorIds"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="category">Topik</label>
                    <Select2
                      v-model="form.categoryIds"
                      :options="categoriesOptions"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="type">Tipe</label>
                    <select
                      name="type"
                      id="Type"
                      class="form-control"
                      v-model="form.type"
                    >
                      <option value="">Pilih Tipe</option>
                      <option value="SELF_STUDY">Belajar Mandiri</option>
                      <option value="REGULER">Kelas Reguler</option>
                      <option value="WEBINAR">Webinar</option>
                      <option value="KULWA">Kulwa</option>
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="checkbox-disable-enroll"
                      >Apakah Pendaftaran Di Kelas Ini Ditutup?</label
                    >
                    <b-form-checkbox
                      id="checkbox-disable-enroll"
                      v-model="form.disableEnroll"
                      name="checkbox-disable-enroll"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="checkbox-show-forum"
                      >Apakah Kelas Ini Tersedia Fitur Tanya Jawab?</label
                    >
                    <b-form-checkbox
                      id="checkbox-show-forum"
                      v-model="form.showForum"
                      name="checkbox-show-forum"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="checkbox-show-forum"
                      >Apakah Kelas Ini Direkomendasikan?</label
                    >
                    <b-form-checkbox
                      id="checkbox-is-recommendation"
                      v-model="form.isRecommendation"
                      name="checkbox-is-recommendation"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="checkbox-free">Apakah Kelas Ini Gratis?</label>
                    <b-form-checkbox
                      id="checkbox-free"
                      v-model="form.isFree"
                      name="checkbox-free"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="checkbox-show"
                      >Apakah Kelas Ini Akan Ditampilkan?</label
                    >
                    <b-form-checkbox
                      id="checkbox-show"
                      v-model="form.show"
                      name="checkbox-show"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="type"
                      >Apakah Module Pada Kelas Ini Berurutan?</label
                    >
                    <b-form-checkbox
                      id="checkbox-sequence"
                      v-model="form.isSequence"
                      name="checkbox-sequence"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="have-confirmation"
                      >Apakah Kelas ini memiliki terms & condition?</label
                    >
                    <b-form-checkbox
                      id="have-confirmation"
                      v-model="form.haveConfirmation"
                      name="have-confirmation"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="col-md-12" v-if="form.haveConfirmation">
                  <div class="form-group">
                    <label for="type">Teks terms & condition?</label>
                    <ckeditor
                      id="description"
                      v-model="form.textConfirmation"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></ckeditor>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6" v-if="form.type === 'KULWA'">
                  <div class="form-group">
                    <label for="type">Tanggal Mulai Kulwa</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      v-model="form.startKulwaAt"
                    />
                  </div>
                </div>
                <div class="col-md-6" v-if="form.type === 'KULWA'">
                  <div class="form-group">
                    <label for="type">Tanggal Selesai Kulwa</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      v-model="form.endKulwaAt"
                    />
                  </div>
                </div>
              </div>

              <br v-if="!form.isFree" />

              <div class="row" v-if="!form.isFree">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="price">Harga</label>
                    <input
                      id="price"
                      v-model="form.price"
                      type="text"
                      class="form-control"
                      placeholder="price"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['price'],
                      }"
                    />
                    <div v-if="errors['price']" class="invalid-feedback">
                      <span>{{ errors["price"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="discount">Diskon</label>
                    <input
                      id="discount"
                      v-model="form.discount"
                      type="text"
                      class="form-control"
                      placeholder="discount"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['discount'],
                      }"
                    />
                    <div v-if="errors['discount']" class="invalid-feedback">
                      <span>{{ errors["discount"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="banner">Banner</label>
                    <input
                      id="banner"
                      type="file"
                      class="form-control"
                      placeholder="banner"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['banner'],
                      }"
                      @change="uploadBanner"
                    />
                  </div>
                  <img
                    class="mb-3"
                    :src="form.banner | media"
                    width="300"
                    alt=""
                  />
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <ckeditor
                      id="description"
                      v-model="form.description"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></ckeditor>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
