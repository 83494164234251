import notificationApi from "../../api/notification.api";
import filterDefault from "../../helpers/filter";

export const state = {
  notifications: [],
  notification: {},
  filter: {
    ...filterDefault,
  },
  type: null,
  message: null,
};

export const getters = {
  notifications(state) {
    return state.notifications;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },

  ADD_NOTIFICATION(state, notification) {
    state.notifications.unshift(notification);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_NOTIFICATION(state, id) {
    state.notifications.splice(
      state.notifications.findIndex((notification) => notification.id === id),
      1
    );
  },

  SET_NOTIFICATION(state, notification) {
    state.notification = notification;
  },

  CHANGE_NOTIFICATION(state, { id, notification }) {
    state.notifications[
      state.notifications.findIndex((notification) => notification.id === id)
    ] = notification;
  },

  success(state, message) {
    state.type = "success";
    state.message = message;
  },
  error(state, message) {
    state.type = "danger";
    state.message = message;
  },
  info(state, message) {
    state.type = "info";
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await notificationApi.fetch(state.filter);

      commit("SET_NOTIFICATIONS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await notificationApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_NOTIFICATION", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await notificationApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_NOTIFICATION", id);

    return { status: true };
  },

  async detail({ dispatch }, id) {
    const { data } = await notificationApi.detail(id);

    dispatch("SET_NOTIFICATION", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await notificationApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_NOTIFICATION", { id, notification: data.data });

    return { status: true };
  },

  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  info({ commit }, message) {
    commit("info", message);
  },
  clear({ commit }) {
    commit("clear");
  },

  async setTokenNotification(context, payload) {
    const { data } = await notificationApi.setToken(payload);

    return data.data;
  },
};
