var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"courseId"}},[_vm._v("Kelas")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.courseId),expression:"form.courseId"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['courseId'],
                    },attrs:{"id":"courseId","type":"text"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "courseId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Kelas")]),_vm._l((_vm.courses),function(course){return _c('option',{key:course.id,domProps:{"value":course.id}},[_vm._v(_vm._s(course.title))])})],2),(_vm.errors['courseId'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["courseId"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Judul")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['title'],
                    },attrs:{"id":"title","type":"text","placeholder":"Judul"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.errors['title'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["title"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"point"}},[_vm._v("Point")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.point),expression:"form.point"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['point'],
                    },attrs:{"id":"point","type":"number","placeholder":"point"},domProps:{"value":(_vm.form.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "point", $event.target.value)}}}),(_vm.errors['point'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["point"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sequence"}},[_vm._v("Urutan")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sequence),expression:"form.sequence"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['sequence'],
                    },attrs:{"id":"sequence","type":"text","placeholder":"sequence","value":"Mark"},domProps:{"value":(_vm.form.sequence)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sequence", $event.target.value)}}}),(_vm.errors['sequence'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["sequence"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Deskripsi")]),_c('ckeditor',{class:{
                      'is-invalid': _vm.errors['description'],
                    },attrs:{"id":"description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Apakah aktif?")]),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}},[_vm._v(" Centang jika modul ini aktif ")])],1)])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"type":"submit","disabled":_vm.loading}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }