<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions } from "vuex";

export default {
  page: {
    title: "Jawaban Survey Grup",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Jawaban Survey Grup",
      id: this.$route.params.id,
      items: [
        {
          text: "Pertanyaan Onboarding",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {},
      loading: false,
      submitted: false,
      errors: {},
      options: [],
    };
  },

  methods: {
    ...mapActions({
      detail: "whatsappGroupLeaveSurvey/detail",
    }),
  },

  async mounted() {
    this.$store.dispatch("notification/clear");
    this.form = await this.detail(this.$route.params.id);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <table class="table table-bordered table-hover">
              <tr>
                <th>No</th>
                <th>Pertanyaan</th>
                <th>Jawaban</th>
              </tr>
              <tr v-for="(answer, index) in form.answers" :key="answer.id">
                <th>{{ index + 1 }}</th>
                <th>{{ answer.question.question }}</th>
                <th>{{ answer.text }}</th>
              </tr>
            </table>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.item {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
