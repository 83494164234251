import forumApi from "../../api/forum.api";
import filterDefault from "../../helpers/filter";

export const state = {
  forums: [],
  forum: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  forums(state) {
    return state.forums;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_FORUMS(state, forums) {
    state.forums = forums;
  },

  ADD_FORUM(state, forum) {
    state.forums.unshift(forum);
  },

  MERGE_FORUMS(state, forums) {
    state.forums = forums.concat(state.forums);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_FORUM(state, id) {
    state.forums.splice(
      state.forums.findIndex((forum) => forum.id === id),
      1
    );
  },

  SET_FORUM(state, forum) {
    state.forum = forum;
  },

  CHANGE_FORUM(state, { id, forum }) {
    state.forums[state.forums.findIndex((forum) => forum.id === id)] = forum;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await forumApi.fetch(state.filter);

      commit("SET_FORUMS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await forumApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_FORUM", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await forumApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_FORUM", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await forumApi.detail(id);

    commit("SET_FORUM", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await forumApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_FORUM", { id, forum: data.data });

    return { status: true };
  },

  async sendResponse(context, { id, payload }) {
    const { data } = await forumApi.sendResponse(id, payload);

    return data.data;
  },

  async deleteResponse(context, { forumId, responseId }) {
    const { data } = await forumApi.deleteResponse({ forumId, responseId });

    return data.data;
  },

  async deleteResponseReply(context, { forumId, responseId, commentId }) {
    const { data } = await forumApi.deleteResponseReply({
      forumId,
      responseId,
      commentId,
    });

    return data.data;
  },
};
