import api from "./index";

const baseURL = "/authentications";

const login = (payload) => api.post(`${baseURL}/login`, payload);

const verificationOtp = (payload) =>
  api.post(`${baseURL}/verification-otp`, payload);

const resendOtp = (payload) => api.post(`${baseURL}/resend-otp`, payload);

export default { login, verificationOtp, resendOtp };
