import api from "./index";

const baseURL = "/whatsapp-blasters";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const getAudiences = (params) =>
  api.get(`${baseURL}/get-audiences`, {
    params: { ...params, countOnly: true },
  });

const getAudiencesByQuery = (params) =>
  api.get(`${baseURL}/get-audiences-by-query`, { params });

const getQueue = ({ id, params }) =>
  api.get(`${baseURL}/${id}/queue`, { params });

const updatePause = (id, payload) =>
  api.patch(`${baseURL}/${id}/update-pause`, payload);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  getAudiences,
  getQueue,
  getAudiencesByQuery,
  updatePause,
};
