var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mb-3",attrs:{"variant":_vm.notification.type,"dismissible":"","show":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nama *)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['name'],
                    },attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"code"}},[_vm._v("Kode *)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.code),expression:"form.code"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['code'],
                    },attrs:{"id":"code","type":"text"},domProps:{"value":(_vm.form.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "code", $event.target.value)}}}),(_vm.errors['code'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["code"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Jenis Voucher *)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['type'],
                    },attrs:{"id":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Tipe Voucher")]),_vm._l((_vm.types),function(type){return _c('option',{key:type.value,domProps:{"value":type.value}},[_vm._v(_vm._s(type.text))])})],2),(_vm.errors['type'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["type"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"amountPercentage"}},[_vm._v("Apakah berupa persen?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.amountPercentage),expression:"form.amountPercentage"}],staticClass:"form-control",attrs:{"id":"amountPercentage"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "amountPercentage", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":true}},[_vm._v("Iya")]),_c('option',{domProps:{"value":false}},[_vm._v("Tidak")])]),(_vm.errors['amountPercentage'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["amountPercentage"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"totalAmount"}},[_vm._v("Total Diskon / Cashback")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.totalAmount),expression:"form.totalAmount"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['totalAmount'],
                    },attrs:{"id":"totalAmount","type":"number"},domProps:{"value":(_vm.form.totalAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "totalAmount", $event.target.value)}}}),(_vm.errors['totalAmount'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["totalAmount"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"maxAmount"}},[_vm._v("Maksimal Diskon / Cashback")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.maxAmount),expression:"form.maxAmount"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['maxAmount'],
                    },attrs:{"id":"maxAmount","type":"number"},domProps:{"value":(_vm.form.maxAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "maxAmount", $event.target.value)}}}),(_vm.errors['maxAmount'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["maxAmount"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"minimumTotalSpending"}},[_vm._v("Minimum Total Belanja")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.minimumTotalSpending),expression:"form.minimumTotalSpending"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['minimumTotalSpending'],
                    },attrs:{"id":"minimumTotalSpending","type":"number"},domProps:{"value":(_vm.form.minimumTotalSpending)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "minimumTotalSpending", $event.target.value)}}}),(_vm.errors['minimumTotalSpending'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["minimumTotalSpending"]))])]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"expiredAt"}},[_vm._v("Tanggal Kadaluarsa (Kosongkan jika tidak ada)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.expiredAt),expression:"form.expiredAt"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['expiredAt'],
                    },attrs:{"id":"expiredAt","type":"date"},domProps:{"value":(_vm.form.expiredAt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "expiredAt", $event.target.value)}}}),(_vm.errors['expiredAt'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["expiredAt"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"limit"}},[_vm._v("Maksimal Digunakan (isi 0 jika unlimited)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.limit),expression:"form.limit"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['limit'],
                    },attrs:{"id":"limit","type":"number"},domProps:{"value":(_vm.form.limit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "limit", $event.target.value)}}}),(_vm.errors['limit'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["limit"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"isPublic"}},[_vm._v("Apakah voucher ini bisa digunakan semua orang?")]),_c('b-form-checkbox',{attrs:{"id":"isPublic","name":"isPublic"},model:{value:(_vm.form.isPublic),callback:function ($$v) {_vm.$set(_vm.form, "isPublic", $$v)},expression:"form.isPublic"}},[_vm._v(" Centang jika benar ")]),(_vm.errors['isPublic'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["isPublic"]))])]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Deskripsi")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.errors['description'],
                    },attrs:{"id":"description"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Berlaku hanya pada kelas (Kosongkan jika berlaku pada semua kelas)")]),_c('Select2',{attrs:{"options":_vm.courses,"settings":{ multiple: true }},model:{value:(_vm.form.courseIds),callback:function ($$v) {_vm.$set(_vm.form, "courseIds", $$v)},expression:"form.courseIds"}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Voucher otomatis diberikan apabila masuk ke kelas :")]),_c('Select2',{attrs:{"options":_vm.courses,"settings":{ multiple: true }},model:{value:(_vm.form.courseGiveIds),callback:function ($$v) {_vm.$set(_vm.form, "courseGiveIds", $$v)},expression:"form.courseGiveIds"}}),(_vm.errors['description'])?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.errors["description"]))])]):_vm._e()],1)])]),_c('button',{staticClass:"btn btn-primary mt-4 form-control",attrs:{"disabled":_vm.loading,"type":"submit"}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"spinner-border text-light spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }