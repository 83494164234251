import Vue from "vue";

Vue.filter("media", function(value) {
  try {
    return value.split("base64").length > 1 || value.split("http").length > 1
      ? value
      : `${process.env.VUE_APP_MEDIA_URL}/${value}`;
  } catch (error) {
    return value;
  }
});

Vue.filter("removeTag", function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("limitText", function(value, length) {
  const trimmedString =
    value.length > length ? value.substring(0, length - 3) + "..." : value;

  return trimmedString;
});

Vue.filter("textReplace", function(value, from, to) {
  return value.replace(new RegExp(from, "g"), to);
});

Vue.filter("filterBankQnaState", function(value) {
  switch (value.toLowerCase()) {
    case "not yet answered":
      return "Belum Dijawab";

    case "delivered":
      return "Sudah Dikirim";

    case "already answered":
      return "Sudah Dijawab";

    default:
      return value;
  }
});

Vue.filter("filterSurveyState", function(value) {
  switch (value) {
    case "PUBLIC":
      return "Publik";

    case "PRIVATE":
      return "Privasi";

    case "HAVE_TO_FINISH_CLASS":
      return "Sudah Menyelesaikan Kelas";

    case "JOIN_CLASS":
      return "Sudah Masuk Kelas";

    default:
      return value;
  }
});

Vue.filter("filterBankQnaState", function(value) {
  switch (value.toLowerCase()) {
    case "not yet answered":
      return "Belum Dijawab";

    case "delivered":
      return "Sudah Dikirim";

    case "already answered":
      return "Sudah Dijawab";

    default:
      return value;
  }
});

Vue.filter("statusOrderProofPayment", function(value) {
  switch (value) {
    case "WAITING":
      return "Menunggu";

    case "PROCESS":
      return "Sedang Diproses";

    case "ACCEPT":
      return "Telah Disetujui";

    case "REJECT":
      return "DItolak";

    default:
      return value;
  }
});

Vue.filter("statusOrder", function(value) {
  switch (value) {
    case "WAITING_PAYMENT":
      return "Menunggu Pembayaran";

    case "WAITING_VERIFICATION":
      return "Menunggu Verifikasi Otomatis";

    case "FAILED_AUTO_CHECK_PAYMENT":
      return "Gagal Verifikasi Otomatis";

    case "WAITING_MANUAL_CONFIRMATION":
      return "Menunggu Verifikasi Manual";

    case "FAILED_MANUAL_CONFIRMATION":
      return "Gagal Verifikasi Manual";

    case "DONE":
      return "Selesai";

    case "CANCEL":
      return "Gagal";

    case "REFUND":
      return "Refund";

    default:
      return value;
  }
});

Vue.filter("filterExamState", function(value) {
  switch (value) {
    case "COURSE":
      return "Kelas";

    case "MODULE":
      return "Modul";

    case "SUBMODULE":
      return "Submodul";

    default:
      return value;
  }
});

Vue.filter("hostType", function(value) {
  switch (value) {
    case "GROUP":
      return "Membuat Grup";

    case "BROADCAST":
      return "Broadcast";

    case "BROADCAST_PROMO":
      return "Blaster";

    case "ACTIVITY_GROUP":
      return "Pengambilan Data Grup";

    default:
      return value;
  }
});

Vue.filter("filterTemplateType", function(value) {
  switch (value) {
    case "COURSE":
      return "Kelas";

    case "AVERAGE_PROFIT_PER_MONTH":
      return "Rata rata profit perbulan";

    case "CATEGORY":
      return "Topik yang diminati";

    case "TYPE_SELLING":
      return "Jenis penjualan";

    case "PRODUCT_CATEGORY":
      return "Kategori produk yang dijual";

    case "PROVINCE":
      return "Provinsi";

    case "CITY":
      return "Kota";

    case "SUBDISTRICT":
      return "Kecamatan";

    case "GENDER":
      return "Jenis Kelamin";

    case "ALREADY_JOIN_COMMUNITY_GROUP":
      return "Sudah masuk grup komunitas";

    case "IS_PAID_MEMBER":
      return "Member berbayar";

    default:
      return value;
  }
});

Vue.filter("titleCase", function(value) {
  const splitStr = value.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
});

Vue.filter("convertBoolean", function(value, defaultTrue, defaultFalse) {
  if (value) {
    return defaultTrue || "Iya";
  } else {
    return defaultFalse || "Tidak";
  }
});

Vue.filter("rupiah", (angka, prefix) => {
  try {
    let number_string = angka
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  } catch (error) {
    return angka;
  }
});
