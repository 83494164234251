<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

/**
 * Master survey component
 */
export default {
  page: {
    title: "Master Survey",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Survey",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Survey",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nama" },
        { key: "slug", label: "Link" },
        { key: "isActive", label: "Aktif" },
        { key: "type", label: "Tipe" },
        { key: "course", label: "Kelas" },
        { key: "batch", label: "Batch" },
        { key: "partnershipPartner", label: "Partner" },
        { key: "answers", label: "Total Jawaban" },
        { key: "expiredAt", label: "Kadaluarsa" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      batchs: "batch/batchs",
      courses: "course/courses",
    }),

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["survey/surveys"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courseId: {
      get() {
        return this.$store.getters["survey/filter"].courseId;
      },
      set(val) {
        this.fetchBatch({ key: "courseId", value: val });
        this.changeFilter({ key: "courseId", value: val });
      },
    },

    batchId: {
      get() {
        return this.$store.getters["survey/filter"].batchId;
      },
      set(val) {
        this.changeFilter({ key: "batchId", value: val });
      },
    },

    search: {
      get() {
        return this.$store.getters["survey/filter"].search;
      },
      set(val) {
        this.changeFilter({ key: "search", value: val });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["survey/filter"].limit;
      },
      set(val) {
        this.changeFilter({ key: "limit", value: val });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["survey/filter"].page;
      },
      set(val) {
        this.changeFilter({ key: "page", value: val });
      },
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;

    await this.fetchCourse();
  },
  methods: {
    ...mapActions({
      fetch: "survey/fetch",
      changeFilter: "survey/changeFilter",
      delete: "survey/delete",

      fetchBatch: "batch/changeFilter",
      fetchCourse: "course/fetch",
    }),

    deletesurvey(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data kamu aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <router-link :to="{ name: 'create-survey' }">
                <b-button class="mr-3" variant="primary" size="sm"
                  ><i class="mdi mdi-plus mr-2"></i> Tambah survey
                  Kelas</b-button
                >
              </router-link>
            </h4>

            <hr />

            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4">
                  <div class="row">
                    <label for="courseId">Kelas</label>
                    <select
                      name="courseId"
                      id="courseId"
                      class="form-control"
                      v-model="courseId"
                    >
                      <option value="">Semua Kelas</option>
                      <option
                        :value="course.id"
                        :key="course.id"
                        v-for="course in courses"
                        >{{ course.title }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="col-lg-4 ml-4">
                  <div class="row">
                    <label for="batchId">Batch Kelas</label>
                    <select
                      name="batchId"
                      id="batchId"
                      class="form-control"
                      v-model="batchId"
                    >
                      <option value="">Semua Batch Kelas</option>
                      <option
                        :value="batch.id"
                        :key="batch.id"
                        v-for="batch in batchs"
                        >{{ batch.name }} -
                        {{ batch.startDate | moment("DD MMMM YYYY") }}
                        s/d
                        {{ batch.finishDate | moment("DD MMMM YYYY") }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(partnershipPartner)="data">
                  <p v-if="data.item.partnershipPartner">
                    {{ data.item.partnershipPartner.name }}
                  </p>
                </template>

                <template #cell(batch)="data">
                  <p v-if="data.item.batch">
                    {{ data.item.batch.name }} -
                    {{ data.item.batch.startDate | moment("DD MMMM YYYY") }} s/d
                    {{ data.item.batch.finishDate | moment("DD MMMM YYYY") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(slug)="data">
                  <a
                    v-if="data.item.slug"
                    :href="`https://kelas.bolu.id/survey/${data.item.slug}`"
                  >
                    https://kelas.bolu.id/survey/{{ data.item.slug }}
                  </a>
                  <p v-else>-</p>
                </template>

                <template #cell(expiredAt)="data">
                  <p v-if="data.item.expiredAt">
                    {{ data.item.expiredAt | moment("Do MMMM YYYY") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(type)="data">
                  {{ data.item.type | filterSurveyState }}
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-survey',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mr-3"
                    @click="deletesurvey(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                  <b-button
                    variant="warning"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'answer-survey',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-chat-1-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
