import roleApi from "../../api/role.api";
import filterDefault from "../../helpers/filter";

export const state = {
  roles: [],
  role: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  roles(state) {
    return state.roles;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      commit("SET_ROLES", []);
      const { data } = await roleApi.fetch(state.filter);

      commit("SET_ROLES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },
};
