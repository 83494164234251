import partnershipPartnerCategoryApi from "../../api/partnership-partner-category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  partnershipPartnerCategories: [],
  partnershipPartnerCategory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  partnershipPartnerCategories(state) {
    return state.partnershipPartnerCategories;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_PARTNERSHIP_PARTNER_CATEGORIES(state, partnershipPartnerCategories) {
    state.partnershipPartnerCategories = partnershipPartnerCategories;
  },

  ADD_PARTNERSHIP_PARTNER_CATEGORY(state, partnershipPartnerCategory) {
    state.partnershipPartnerCategories.unshift(partnershipPartnerCategory);
  },

  MERGE_PARTNERSHIP_PARTNER_CATEGORIES(state, partnershipPartnerCategories) {
    state.partnershipPartnerCategories = partnershipPartnerCategories.concat(
      state.partnershipPartnerCategories
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_PARTNERSHIP_PARTNER_CATEGORY(state, id) {
    state.partnershipPartnerCategories.splice(
      state.partnershipPartnerCategories.findIndex(
        (partnershipPartnerCategory) => partnershipPartnerCategory.id === id
      ),
      1
    );
  },

  SET_PARTNERSHIP_PARTNER_CATEGORY(state, partnershipPartnerCategory) {
    state.partnershipPartnerCategory = partnershipPartnerCategory;
  },

  CHANGE_PARTNERSHIP_PARTNER_CATEGORY(
    state,
    { id, partnershipPartnerCategory }
  ) {
    state.partnershipPartnerCategories[
      state.partnershipPartnerCategories.findIndex(
        (partnershipPartnerCategory) => partnershipPartnerCategory.id === id
      )
    ] = partnershipPartnerCategory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.partnershipPartnerCategories.findIndex(
      (number) => number.id === id
    );

    state.partnershipPartnerCategories[index].state = status;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await partnershipPartnerCategoryApi.fetch(state.filter);

      commit("SET_PARTNERSHIP_PARTNER_CATEGORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await partnershipPartnerCategoryApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_PARTNERSHIP_PARTNER_CATEGORY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await partnershipPartnerCategoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_PARTNERSHIP_PARTNER_CATEGORY", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await partnershipPartnerCategoryApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await partnershipPartnerCategoryApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_PARTNERSHIP_PARTNER_CATEGORY", {
      id,
      partnershipPartnerCategory: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await partnershipPartnerCategoryApi.getAudiences({
      templateIds,
    });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await partnershipPartnerCategoryApi.getAudiencesByQuery(
      query
    );

    return data.data;
  },

  async getQueue(context, id) {
    const { data } = await partnershipPartnerCategoryApi.getQueue(id);

    return data.data;
  },
};
