<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah Voucher",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      types: "voucher/types",
      courses: "course/courses",
    }),
  },
  components: { Layout, PageHeader, Select2 },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Voucher" : "Tambah Voucher",
      id: this.$route.params.id,
      items: [
        {
          text: "Voucher",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        description: "",
        type: "",
        code: "",
        maxAmount: 0,
        totalAmount: 0,
        amountPercentage: true,
        minimumTotalSpending: 0,
        courseIds: [],
        courseGiveIds: [],
        limit: 0,
        isPublic: false,
        expiredAt: null,
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "voucher/create",
      detail: "voucher/detail",
      update: "voucher/update",

      fetchCourse: "course/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      this.form.maxAmount = parseInt(this.form.maxAmount);
      this.form.totalAmount = parseInt(this.form.totalAmount);
      this.form.minimumTotalSpending = parseInt(this.form.minimumTotalSpending);

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-voucher" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      this.form.courseIds = this.form.voucherCourses.map(
        (voucher) => voucher.course.id
      );
      this.form.courseGiveIds = this.form.voucherAutoGives.map(
        (voucher) => voucher.course.id
      );

      if (this.form.expiredAt) {
        this.form.expiredAt = this.$options.filters.moment(
          this.form.expiredAt,
          "YYYY-MM-DD"
        );
      }
    }

    await this.fetchCourse();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name">Nama *)</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="code">Kode *)</label>
                    <input
                      id="code"
                      v-model="form.code"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['code'],
                      }"
                    />
                    <div v-if="errors['code']" class="invalid-feedback">
                      <span>{{ errors["code"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="type">Jenis Voucher *)</label>
                    <select
                      id="type"
                      v-model="form.type"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['type'],
                      }"
                    >
                      <option value="">Pilih Tipe Voucher</option>
                      <option
                        :value="type.value"
                        v-for="type in types"
                        :key="type.value"
                        >{{ type.text }}</option
                      >
                    </select>
                    <div v-if="errors['type']" class="invalid-feedback">
                      <span>{{ errors["type"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="amountPercentage">Apakah berupa persen?</label>
                    <select
                      id="amountPercentage"
                      v-model="form.amountPercentage"
                      class="form-control"
                    >
                      <option :value="true">Iya</option>
                      <option :value="false">Tidak</option>
                    </select>
                    <div
                      v-if="errors['amountPercentage']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["amountPercentage"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="totalAmount">Total Diskon / Cashback</label>
                    <input
                      id="totalAmount"
                      v-model="form.totalAmount"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['totalAmount'],
                      }"
                    />
                    <div v-if="errors['totalAmount']" class="invalid-feedback">
                      <span>{{ errors["totalAmount"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="maxAmount">Maksimal Diskon / Cashback</label>
                    <input
                      id="maxAmount"
                      v-model="form.maxAmount"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['maxAmount'],
                      }"
                    />
                    <div v-if="errors['maxAmount']" class="invalid-feedback">
                      <span>{{ errors["maxAmount"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="minimumTotalSpending"
                      >Minimum Total Belanja</label
                    >
                    <input
                      id="minimumTotalSpending"
                      v-model="form.minimumTotalSpending"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['minimumTotalSpending'],
                      }"
                    />
                    <div
                      v-if="errors['minimumTotalSpending']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["minimumTotalSpending"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="expiredAt"
                      >Tanggal Kadaluarsa (Kosongkan jika tidak ada)</label
                    >
                    <input
                      id="expiredAt"
                      v-model="form.expiredAt"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['expiredAt'],
                      }"
                    />
                    <div v-if="errors['expiredAt']" class="invalid-feedback">
                      <span>{{ errors["expiredAt"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="limit"
                      >Maksimal Digunakan (isi 0 jika unlimited)</label
                    >
                    <input
                      id="limit"
                      v-model="form.limit"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['limit'],
                      }"
                    />
                    <div v-if="errors['limit']" class="invalid-feedback">
                      <span>{{ errors["limit"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="isPublic"
                      >Apakah voucher ini bisa digunakan semua orang?</label
                    >
                    <b-form-checkbox
                      id="isPublic"
                      v-model="form.isPublic"
                      name="isPublic"
                    >
                      Centang jika benar
                    </b-form-checkbox>
                    <div v-if="errors['isPublic']" class="invalid-feedback">
                      <span>{{ errors["isPublic"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <textarea
                      id="description"
                      v-model="form.description"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                      class="form-control"
                    ></textarea>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"
                      >Berlaku hanya pada kelas (Kosongkan jika berlaku pada
                      semua kelas)</label
                    >
                    <Select2
                      v-model="form.courseIds"
                      :options="courses"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description"
                      >Voucher otomatis diberikan apabila masuk ke kelas
                      :</label
                    >
                    <Select2
                      v-model="form.courseGiveIds"
                      :options="courses"
                      :settings="{ multiple: true }"
                    />
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
