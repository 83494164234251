import api from "./index";

const baseURL = "/whatsapp-broadcasts";

const fetch = (params) => api.get(`${baseURL}`, { params });

const detail = (id) => api.get(`${baseURL}/${id}`);

const create = (payload) => api.post(`${baseURL}`, payload);

const update = (id, payload) => api.patch(`${baseURL}/${id}`, payload);

const destroy = (id) => api.delete(`${baseURL}/${id}`);

const resend = ({ id, groupId, type }) =>
  api.patch(`${baseURL}/${id}/groups/${groupId}/resend?type=${type}`);

const syncRead = ({ id, groupId }) =>
  api.patch(`${baseURL}/${id}/groups/${groupId}/sync-read`);

const updateTotalRead = ({ id, groupId, payload }) =>
  api.patch(`${baseURL}/${id}/groups/${groupId}/update-total-read`, payload);

export default {
  fetch,
  detail,
  create,
  update,
  destroy,
  resend,
  syncRead,
  updateTotalRead,
};
