import whatsappGroupKulwaApi from "../../api/whatsapp-group-kulwa.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupKulwas: [],
  whatsappGroupKulwa: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappGroupKulwas(state) {
    return state.whatsappGroupKulwas;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_KULWAS(state, whatsappGroupKulwas) {
    state.whatsappGroupKulwas = whatsappGroupKulwas;
  },

  ADD_WHATSAPP_GROUP_KULWAS(state, whatsappGroupKulwa) {
    state.whatsappGroupKulwas.unshift(whatsappGroupKulwa);
  },

  MERGE_WHATSAPP_GROUP_KULWASS(state, whatsappGroupKulwas) {
    state.whatsappGroupKulwas = whatsappGroupKulwas.concat(
      state.whatsappGroupKulwas
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_GROUP_KULWAS(state, id) {
    state.whatsappGroupKulwas.splice(
      state.whatsappGroupKulwas.findIndex(
        (whatsappGroupKulwa) => whatsappGroupKulwa.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_GROUP_KULWA(state, whatsappGroupKulwa) {
    state.whatsappGroupKulwa = whatsappGroupKulwa;
  },

  CHANGE_WHATSAPP_GROUP_KULWAS(state, { id, whatsappGroupKulwa }) {
    state.whatsappGroupKulwas[
      state.whatsappGroupKulwas.findIndex(
        (whatsappGroupKulwa) => whatsappGroupKulwa.id === id
      )
    ] = whatsappGroupKulwa;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupKulwaApi.fetch(state.filter);

      commit("SET_WHATSAPP_GROUP_KULWAS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappGroupKulwaApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_GROUP_KULWAS", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappGroupKulwaApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_GROUP_KULWAS", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappGroupKulwaApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappGroupKulwaApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_GROUP_KULWA", {
      id,
      whatsappGroupKulwa: data.data,
    });

    return { status: true };
  },
};
