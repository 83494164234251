<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Sertifikat Event component
 */
export default {
  page: {
    title: "Master Sertifikat Event",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Sertifikat Event",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Sertifikat Event",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nama" },
        { key: "event", label: "Event" },
        { key: "number", label: "Nomer" },
        { key: "image", label: "Sertifikat" },
        { key: "createdAt", label: "Dibuat Pada" },
      ],
      loading: false,
      loadingRefresh: false,
      loadingImport: false,
      processImport: [],
      importForm: {
        file: "",
        event: "",
        finishedAt: "",
        code: "",
        certificateMasterId: "",
      },
      errors: {},
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["certificationEvent/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["certificationEvent/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["certificationEvent/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      total: "certificationEvent/total",
      tableData: "certificationEvent/certificationEvents",

      certificationMasters: "certificationMaster/certificationMasters",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    await this.fetchCertificationMaster();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "certificationEvent/fetch",
      fetchCertificationMaster: "certificationMaster/fetch",
    }),

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async refresh(id) {
      this.loadingRefresh = true;
      await this.$store.dispatch("certificationEvent/refresh", id);
      this.$refs.userTable.refresh();
      this.loadingRefresh = false;
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    async importData() {
      if (this.processImport.length > 0) {
        this.processImport = [];

        this.$nextTick(() => {
          this.$bvModal.hide("modal-import");
        });
      } else {
        this.loadingImport = true;
        this.errors = {};
        const response = await this.$store.dispatch(
          "certificationEvent/importData",
          this.importForm
        );
        this.loadingImport = false;

        if (response.status) {
          this.processImport = response.process.data;
        } else {
          this.errors = response.errors;

          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat import Jawaban Survey!",
          });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="row mb-3">
              <div class="col-lg-4">
                <b-button variant="warning" size="sm" v-b-modal.modal-import
                  >Import Data</b-button
                >

                <b-modal
                  centered
                  id="modal-import"
                  title="Import Data"
                  @ok.prevent="importData"
                  size="xl"
                >
                  <div v-if="processImport.length > 0">
                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>
                            No.
                          </th>
                          <th>
                            Nama
                          </th>
                          <th>
                            Nomer
                          </th>
                          <th>
                            Status
                          </th>
                          <th>
                            Pesan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(process, index) in processImport"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ process.name }}</td>
                          <td>{{ process.number }}</td>
                          <td>{{ process.status }}</td>
                          <td>{{ process.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <div class="text-center" v-if="loadingImport">
                      <div
                        class="spinner-border text-center text-primary"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="batchId">Nama Event</label>

                          <input
                            type="text"
                            v-model="importForm.event"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['event'],
                            }"
                          />

                          <div v-if="errors['event']" class="invalid-feedback">
                            <span>{{ errors["event"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="batchId">Kode</label>

                          <input
                            type="text"
                            v-model="importForm.code"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['code'],
                            }"
                          />

                          <div v-if="errors['code']" class="invalid-feedback">
                            <span>{{ errors["code"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="finishedAt">Tanggal Selesai</label>

                          <input
                            type="date"
                            v-model="importForm.finishedAt"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['finishedAt'],
                            }"
                          />

                          <div
                            v-if="errors['finishedAt']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["finishedAt"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="batchId">Template Sertifikat</label>

                          <select
                            name=""
                            v-model="importForm.certificateMasterId"
                            id=""
                            class="form-control"
                          >
                            <option
                              :value="certificationMaster.id"
                              v-for="certificationMaster in certificationMasters"
                              :key="certificationMaster.id"
                            >
                              {{ certificationMaster.name }}
                            </option>
                          </select>

                          <div
                            v-if="errors['certificateMasterId']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["certificateMasterId"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="batchId">File</label>

                          <input
                            @change="uploadFileImport"
                            type="file"
                            name=""
                            id=""
                            accept=".csv"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['file'],
                            }"
                          />

                          <div v-if="errors['file']" class="invalid-feedback">
                            <span>{{ errors["file"] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                ref="userTable"
              >
                <template #cell(image)="data">
                  <a
                    v-if="data.item.image"
                    :href="data.item.image"
                    target="_blank"
                  >
                    Download Sertifikat
                  </a>
                  <p v-else>-</p>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
