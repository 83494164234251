<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Pesanan component
 */
export default {
  page: {
    title: "Pesanan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pesanan",
      items: [
        {
          text: "Pesanan",
          href: "/",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "user", label: "Pengguna" },
        { key: "phoneNumber", label: "Nomor Telpon" },
        { key: "email", label: "E-mail" },
        { key: "orderNumber", label: "Nomer Pesanan" },
        { key: "items", label: "Kelas Dibeli" },
        { key: "totalPrice", label: "Total Harga" },
        { key: "status", label: "Status" },
        { key: "alreadyPaid", label: "Status Pembayaran" },
        { key: "paidAt", label: "Tanggal Dibayar" },
        { key: "voucher", label: "Voucher" },
        { key: "utms", label: "UTM" },
        { key: "followups", label: "Total Followup" },
        { key: "createdAt", label: "Dibuat" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
      host: window.location.hostname,
      selectOrderIndex: "",

      followupPayload: {
        templateId: "",
        text: "",
      },
      loadingFollowup: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["order/orders"];
    },

    statuses() {
      return this.$store.getters["order/statuses"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    status: {
      get() {
        return this.$store.getters["order/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    search: {
      get() {
        return this.$store.getters["order/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["order/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["order/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    orderId: {
      get() {
        return this.$store.getters["order/filter"].orderId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "orderId", value: val });
        this.loading = false;
      },
    },

    mentorId: {
      get() {
        return this.$store.getters["order/filter"].mentorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "mentorId", value: val });
        this.loading = false;
      },
    },

    fasilitatorId: {
      get() {
        return this.$store.getters["order/filter"].fasilitatorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "fasilitatorId", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      orders: "order/orders",
      users: "user/users",
      statuses: "order/statuses",
      modules: "module/modules",
      total: "order/total",

      templates: "notificationTemplate/notificationTemplates",
      orderTemplateHelper: "notificationTemplate/orderTemplateHelper",
    }),
  },

  watch: {
    "followupPayload.templateId"(val) {
      const index = this.templates.findIndex((template) => template.id === val);

      this.followupPayload.text = this.templates[index].templateWhatsapp;
    },
  },

  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    const status = this.$route.query.status;
    if (status) await this.changeFilter({ key: "status", value: status });

    this.loading = true;
    await this.fetch();
    await this.fetchTemplate();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "order/fetch",
      changeFilter: "order/changeFilter",
      fetchTemplate: "notificationTemplate/fetch",
      followup: "order/followup",
    }),

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async sendFollowup() {
      this.loadingFollowup = true;

      const data = await this.followup({
        orderId: this.orders[this.selectOrderIndex].id,
        ...this.followupPayload,
      });

      this.$store.commit("order/ADD_DATA_FOLLOWUP", {
        index: this.selectOrderIndex,
        data,
      });

      this.loadingFollowup = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <b-modal
              centered
              id="modal-followup"
              :title="
                `Followup Pesanan ${
                  orders[selectOrderIndex]
                    ? '#' + orders[selectOrderIndex].orderNumber
                    : ''
                }`
              "
              @ok.prevent="sendFollowup"
              size="xl"
              hide-footer
            >
              <b-alert
                :variant="notification.type"
                class="mb-3"
                dismissible
                @dismissed="$store.dispatch('notification/clear')"
                v-if="notification.message"
                show
                >{{ notification.message }}</b-alert
              >
              <div v-if="selectOrderIndex !== ''">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Teks</th>
                      <th>Pengirim Followup</th>
                      <th>Dibuat Pada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(followup, index) in orders[selectOrderIndex]
                        .followups"
                      :key="followup.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>Followup ke {{ index + 1 }}</td>
                      <td
                        v-html="
                          $options.filters.textReplace(
                            followup.text,
                            '\n',
                            '<br/>'
                          )
                        "
                      ></td>
                      <td>
                        <p v-if="followup.createdBy">
                          {{ followup.createdBy.firstName }}
                          {{ followup.createdBy.lastName }}
                        </p>
                        <p v-else>Otomatis Sistem</p>
                      </td>
                      <td>
                        {{ followup.createdAt | moment("D MMMM YYYY, HH:mm") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kode</th>
                        <th>Deskripsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(helper, index) in orderTemplateHelper"
                        :key="helper.code"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ helper.code }}</td>
                        <td>{{ helper.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="">Pilih Template</label>
                    <select
                      v-model="followupPayload.templateId"
                      class="form-control"
                    >
                      <option value="">Pilih Template</option>
                      <option
                        :value="template.id"
                        v-for="template in templates"
                        :key="template.id"
                        >{{ template.name }}</option
                      >
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="">Teks</label>
                    <textarea
                      v-model="followupPayload.text"
                      cols="30"
                      rows="10"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button
                :disabled="loadingFollowup"
                class="btn btn-primary mt-4 form-control"
                type="submit"
                @click="sendFollowup"
              >
                <span v-if="!loadingFollowup">Kirim Followup</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </b-modal>

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="form-group">
                        <label for="">Status</label>
                        <select v-model="status" class="form-control">
                          <option value="">Semua Pesanan</option>
                          <option
                            :value="state.value"
                            v-for="state in statuses"
                            :key="state.value"
                            >{{ state.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(user)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(phoneNumber)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.phoneNumber }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(email)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.email }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(totalPrice)="data">
                  <p>
                    {{ data.item.totalPrice | rupiah("Rp.") }}
                  </p>
                </template>

                <template #cell(status)="data">
                  <p>
                    {{ data.item.status | statusOrder }}
                  </p>
                </template>

                <template #cell(alreadyPaid)="data">
                  <p>
                    {{
                      data.item.alreadyPaid
                        | convertBoolean("Sudah dibayar", "Belum dibayar")
                    }}
                  </p>
                </template>

                <template #cell(paidAt)="data">
                  <p v-if="data.item.paidAt">
                    {{ data.item.paidAt | moment("DD MMMM YYYY - HH:mm:SS") }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(voucher)="data">
                  <p v-if="data.item.voucher">
                    {{ data.item.voucher.name }} - {{ data.item.voucher.code }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(items)="data">
                  <p>
                    <span
                      v-for="(item, index) in data.item.items"
                      :key="item.id"
                    >
                      {{ item.course.title }}

                      <span v-if="index < data.item.items.length - 1">, </span>
                    </span>
                  </p>
                </template>

                <template #cell(utms)="data">
                  <p>
                    <span
                      v-for="(item, index) in data.item.utms"
                      :key="item.id"
                    >
                      <a :href="`/utm/${item.id}`">{{ item.name }}</a>

                      <span v-if="index < data.item.utms.length - 1">, </span>
                    </span>
                  </p>
                </template>

                <template #cell(followups)="data">
                  <p>
                    {{ data.item.followups.length }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'detail-order',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-eye-line"></i
                  ></b-button>

                  <b-button
                    class="mr-3"
                    variant="warning"
                    size="sm"
                    v-b-modal.modal-followup
                    @click="selectOrderIndex = data.index"
                    ><i class="ri-whatsapp-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
