import certificateMasterApi from "../../api/certificate-master.api";
import filterDefault from "../../helpers/filter";

export const state = {
  certificationMasters: [],
  certificationMaster: {},
  filter: {
    ...filterDefault,
    fields: "id,image,name",
  },
};

export const getters = {
  certificationMasters(state) {
    return state.certificationMasters;
  },

  filter(state) {
    return state.filter;
  },
};

export const mutations = {
  SET_CERTIFICATIONS(state, certificationMasters) {
    state.certificationMasters = certificationMasters;
  },

  ADD_CERTIFICATION(state, certificationMaster) {
    state.certificationMasters.unshift(certificationMaster);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_CERTIFICATION(state, id) {
    state.certificationMasters.splice(
      state.certificationMasters.findIndex(
        (certificationMaster) => certificationMaster.id === id
      ),
      1
    );
  },

  SET_CERTIFICATION(state, certificationMaster) {
    state.certificationMaster = certificationMaster;
  },

  CHANGE_CERTIFICATION(state, { id, certificationMaster }) {
    state.certificationMasters[
      state.certificationMasters.findIndex(
        (certificationMaster) => certificationMaster.id === id
      )
    ] = certificationMaster;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await certificateMasterApi.fetch(state.filter);

      commit("SET_CERTIFICATIONS", data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await certificateMasterApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_CERTIFICATION", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true, data: data.data };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await certificateMasterApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_CERTIFICATION", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await certificateMasterApi.detail(id);

    // commit("SET_CERTIFICATION", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await certificateMasterApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_CERTIFICATION", { id, certificationMaster: data.data });

    return { status: true };
  },
};
