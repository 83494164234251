import whatsappBlasterApi from "../../api/whatsapp-blaster.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappBlasters: [],
  whatsappBlaster: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  whatsappBlasters(state) {
    return state.whatsappBlasters;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_BLASTERS(state, whatsappBlasters) {
    state.whatsappBlasters = whatsappBlasters;
  },

  ADD_WHATSAPP_BLASTER(state, whatsappBlaster) {
    state.whatsappBlasters.unshift(whatsappBlaster);
  },

  MERGE_WHATSAPP_BLASTERS(state, whatsappBlasters) {
    state.whatsappBlasters = whatsappBlasters.concat(state.whatsappBlasters);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_WHATSAPP_BLASTER(state, id) {
    state.whatsappBlasters.splice(
      state.whatsappBlasters.findIndex(
        (whatsappBlaster) => whatsappBlaster.id === id
      ),
      1
    );
  },

  SET_WHATSAPP_BLASTER(state, whatsappBlaster) {
    state.whatsappBlaster = whatsappBlaster;
  },

  CHANGE_WHATSAPP_BLASTER(state, { id, whatsappBlaster }) {
    state.whatsappBlasters[
      state.whatsappBlasters.findIndex(
        (whatsappBlaster) => whatsappBlaster.id === id
      )
    ] = whatsappBlaster;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_STATUS(state, { id, status }) {
    const index = state.whatsappBlasters.findIndex(
      (number) => number.id === id
    );

    state.whatsappBlasters[index].state = status;
  },

  UPDATE_PAUSED(state, { index, isPaused }) {
    state.whatsappBlasters[index].isPaused = isPaused;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappBlasterApi.fetch(state.filter);

      commit("SET_WHATSAPP_BLASTERS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await whatsappBlasterApi.create(payload);
    console.log(data);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_WHATSAPP_BLASTER", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await whatsappBlasterApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_WHATSAPP_BLASTER", id);

    return { status: true };
  },

  async detail(context, id) {
    const { data } = await whatsappBlasterApi.detail(id);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await whatsappBlasterApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_WHATSAPP_BLASTER", {
      id,
      whatsappBlaster: data.data,
    });

    return { status: true };
  },

  async getAudiences(context, templateIds) {
    const { data } = await whatsappBlasterApi.getAudiences({ templateIds });

    return data.data;
  },

  async getAudiencesByQuery(context, query) {
    const { data } = await whatsappBlasterApi.getAudiencesByQuery(query);

    return data.data;
  },

  async getQueue(context, { id, params }) {
    const { data } = await whatsappBlasterApi.getQueue({ id, params });

    return data.data;
  },

  async updatePause(context, { id, isPaused }) {
    const data = await whatsappBlasterApi.updatePause(id, { isPaused });

    return data;
  },
};
