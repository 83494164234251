import surveyAnswerApi from "../../api/survey.api";
import filterDefault from "../../helpers/filter";

export const state = {
  surveyAnswers: [],
  surveyAnswer: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    fasilitatorId: "",
    mentorId: "",
    status: "",
  },
  surveyId: "",
};

export const getters = {
  surveyAnswers(state) {
    return state.surveyAnswers;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_SURVEY_ANSWERS(state, surveyAnswers) {
    state.surveyAnswers = surveyAnswers;
  },

  ADD_SURVEY_ANSWER(state, surveyAnswer) {
    state.surveyAnswers.unshift(surveyAnswer);
  },

  MERGE_SURVEY_ANSWERS(state, surveyAnswers) {
    state.surveyAnswers = surveyAnswers.concat(state.surveyAnswers);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_SURVEY_ANSWER(state, id) {
    state.surveyAnswers.splice(
      state.surveyAnswers.findIndex((surveyAnswer) => surveyAnswer.id === id),
      1
    );
  },

  SET_SURVEY_ANSWER(state, surveyAnswer) {
    state.surveyAnswer = surveyAnswer;
  },

  CHANGE_SURVEY_ANSWER(state, { id, surveyAnswer }) {
    state.surveyAnswers[
      state.surveyAnswers.findIndex((surveyAnswer) => surveyAnswer.id === id)
    ] = surveyAnswer;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },

  SET_SURVEY_ID(state, surveyId) {
    state.surveyId = surveyId;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }, answerId) {
    try {
      if (answerId) commit("SET_SURVEY_ID", answerId);

      const { data } = await surveyAnswerApi.fetchAnswer(
        answerId || state.surveyId,
        state.filter
      );

      commit("SET_SURVEY_ANSWERS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async detail({ commit }, { surveyId, answerId }) {
    const { data } = await surveyAnswerApi.detailAnswer(surveyId, answerId);

    commit("SET_SURVEY_ANSWER", data.data);

    return data.data;
  },

  async delete({ dispatch, commit }, { surveyId, answerId }) {
    const { data } = await surveyAnswerApi.deleteAnswer(surveyId, answerId);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_SURVEY_ANSWER", answerId);

    return { status: true };
  },

  async update({ dispatch, commit }, { surveyId, answerId, payload }) {
    const { data } = await surveyAnswerApi.updateAnswer(
      surveyId,
      answerId,
      payload
    );

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_SURVEY_ANSWER", { answerId, survey: data.data });

    return { status: true };
  },

  async download({ dispatch }, surveyId) {
    const { data } = await surveyAnswerApi.downloadAnswer(surveyId);

    if (!data.success) {
      return { success: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    console.log(data.data.url);

    return { status: true, url: data.data };
  },

  async importAnswer({ dispatch, commit }, { surveyId, payload }) {
    const { data } = await surveyAnswerApi.importAnswer(surveyId, payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("MERGE_SURVEY_ANSWERS", data.data.data);

    return { status: true, process: data.data.process };
  },
};
