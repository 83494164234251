<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions } from "vuex";

/**
 * Master Klinik component
 */
export default {
  page: {
    title: "Master Klinik",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Klinik",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Klinik",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "question", label: "Pertanyaan" },
        { key: "status", label: "Status" },
        { key: "user", label: "Member" },
        { key: "fasilitator", label: "Fasilitator" },
        { key: "createdAt", label: "Dibuat Pada" },
        { key: "actions", label: "Aksi" },
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    tableData() {
      return this.$store.getters["clinic/clinics"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["clinic/filter"].search;
      },
      set(val) {
        this.changeFilter({ key: "search", value: val });
      },
    },

    perPage: {
      get() {
        return this.$store.getters["clinic/filter"].limit;
      },
      set(val) {
        this.changeFilter({ key: "limit", value: val });
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["clinic/filter"].page;
      },
      set(val) {
        this.changeFilter({ key: "page", value: val });
      },
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.loading = true;

    await this.fetch();

    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "clinic/fetch",
      takeQuestion: "clinic/takeQuestion",
      rejectQuestion: "clinic/rejectQuestion",
      pinQuestion: "clinic/pinQuestion",
      setPublicQuestion: "clinic/setPublicQuestion",
    }),

    async takeQuestionClinic(index) {
      this.$swal({
        text: "Apakah kamu yakin ingin menjawab pertanyaan ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Iya",
        cancelButtonText: "Tidak",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.takeQuestion(this.tableData[index].id);
          this.$router.push({
            name: "detail-clinic",
            params: { id: this.tableData[index].id },
          });
        }
      });
    },

    async rejectQuestionClinic(index) {
      this.$swal({
        text: "Apakah kamu yakin ingin menolak pertanyaan ini?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Iya",
        cancelButtonText: "Tidak",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.rejectQuestion(this.tableData[index].id);
        }
      });
    },

    pinQuestionClinic(index) {
      this.$swal({
        text: `Apakah kamu yakin ingin ${
          this.tableData[index].isPinned ? "unpin" : "pin"
        } pertanyaan ini?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Iya",
        cancelButtonText: "Tidak",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.pinQuestion(this.tableData[index].id);
        }
      });
    },

    setPublicQuestionClinic(index) {
      this.$swal({
        text: `Apakah kamu yakin ingin ${
          this.tableData[index].isPublicAccess
            ? "menjadikan tidak bisa diakses publik"
            : "menjadikan bisa diakses publik"
        } pertanyaan ini?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Iya",
        cancelButtonText: "Tidak",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.setPublicQuestion(this.tableData[index].id);
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Cari..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(question)="data">
                  <p v-if="data.item.question">
                    {{ data.item.isPinned ? "[PIN]" : "" }}
                    {{ data.item.isPublicAccess ? "[PUBLIK]" : "" }}
                    {{ data.item.question }}
                  </p>
                </template>

                <template #cell(user)="data">
                  <p v-if="data.item.user">
                    {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                  </p>
                </template>

                <template #cell(fasilitator)="data">
                  <p v-if="data.item.fasilitator">
                    {{ data.item.fasilitator.firstName }}
                    {{ data.item.fasilitator.lastName }}
                  </p>
                </template>

                <template #cell(createdAt)="data">
                  <p>
                    {{ data.item.createdAt | moment("Do MMMM YYYY HH:mm") }}
                  </p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3 mb-1"
                    variant="primary"
                    size="sm"
                    @click="takeQuestionClinic(data.index)"
                    v-if="!data.item.fasilitator"
                    ><i class="ri-user-shared-2-line"></i
                  ></b-button>
                  <b-button
                    class="mr-3 mb-1"
                    variant="danger"
                    size="sm"
                    @click="rejectQuestionClinic(data.index)"
                    v-if="data.item.status !== 'REJECT'"
                    ><i class="ri-close-line"></i
                  ></b-button>
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-3 mb-1"
                    @click="pinQuestionClinic(data.index)"
                    >{{ data.item.isPinned ? "Unpin" : "Pin" }}</b-button
                  >
                  <b-button
                    variant="info"
                    size="sm"
                    @click="setPublicQuestionClinic(data.index)"
                    >{{
                      data.item.isPublicAccess
                        ? "Jadikan Non Publik"
                        : "Jadikan Publik"
                    }}</b-button
                  >
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
