<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { bankQnaMethods } from "../../../state/helpers";
import { mapActions, mapGetters } from "vuex";
import Select2 from "v-select2-component";

/**
 * Master Bank QNA component
 */
export default {
  page: {
    title: "Master Bank QNA",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Select2, Layout, PageHeader },
  data() {
    return {
      title: "Master Bank QNA",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Bank QNA",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      mentors: [],
      fasilitators: [],
      fields: [
        { key: "code", label: "Kode", sortable: true },
        { key: "course", label: "Kelas" },
        { key: "batch", label: "Batch" },
        { key: "module", label: "Modul" },
        { key: "categories", label: "Topik" },
        { key: "questioner", label: "Penanya", sortable: true },
        { key: "question", label: "Pertanyaan", sortable: true },
        { key: "status", label: "Status", sortable: true },
        // { key: "isStar", label: "Tampil FE" },
        { key: "actions", label: "Aksi" },
      ],
      loadingImport: false,
      loading: false,
      processImport: [],
      importForm: {
        file: "",
        courseId: "",
        moduleId: "",
        batchId: "",
        categoryIds: "",
      },
      errors: {},
      categoriesOptions: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["bankQna/bankQnas"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["bankQna/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["bankQna/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["bankQna/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    courseId: {
      get() {
        return this.$store.getters["bankQna/filter"].courseId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "courseId", value: val });
        this.loading = false;
      },
    },

    mentorId: {
      get() {
        return this.$store.getters["bankQna/filter"].mentorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "mentorId", value: val });
        this.loading = false;
      },
    },

    fasilitatorId: {
      get() {
        return this.$store.getters["bankQna/filter"].fasilitatorId;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "fasilitatorId", value: val });
        this.loading = false;
      },
    },

    status: {
      get() {
        return this.$store.getters["bankQna/filter"].status;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "status", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      users: "user/users",
      statuses: "bankQna/statuses",
      total: "bankQna/total",
      batchs: "batch/batchs",
      modules: "module/modules",
    }),
  },
  watch: {
    users: {
      handler(val) {
        this.mentors = Object.assign(
          [],
          val.filter(
            (user) =>
              user.roles.findIndex((role) => role.name === "MENTOR") > -1
          )
        );
        this.fasilitators = Object.assign(
          [],
          val.filter((user) => {
            return (
              user.roles.findIndex((role) => {
                return role.name === "FASILITATOR";
              }) > -1
            );
          })
        );
      },
      deep: true,
    },

    "importForm.courseId"(val) {
      this.$store.dispatch("batch/changeFilter", {
        key: "courseId",
        value: val,
      });

      this.$store.dispatch("module/changeFilter", {
        key: "courseId",
        value: val,
      });

      this.categoriesOptions = [];

      Object.assign(
        [],
        this.courses[this.courses.findIndex((course) => course.id === val)]
          .categories
      ).forEach((category) => {
        this.categoriesOptions.push({
          id: category.id,
          text: category.name,
        });
      });
    },

    sortBy() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },

    sortDesc() {
      this.changeFilter({
        key: "ordering",
        value: `${!this.sortDesc ? "" : "-"}${this.sortBy}`,
      });
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    if (this.user.role === "FASILITATOR") {
      await this.changeFilter({ key: "fasilitator_id", value: this.user.id });
    } else {
      await this.fetch();
    }

    await this.fetchCourse();
    await this.fetchUser({ key: "roles", value: "MENTOR,FASILITATOR" });
    this.loading = false;
  },
  methods: {
    ...bankQnaMethods,

    ...mapActions({
      fetchCourse: "course/fetch",
      fetchUser: "user/changeFilter",
      download: "bankQna/exportBankQna",
    }),

    async importBankQna() {
      if (this.processImport.length > 0) {
        this.processImport = [];

        this.$nextTick(() => {
          this.$bvModal.hide("modal-import");
        });
      } else {
        this.loadingImport = true;
        this.errors = {};
        const response = await this.$store.dispatch(
          "bankQna/importBankQna",
          this.importForm
        );
        this.loadingImport = false;

        if (response.status) {
          this.processImport = response.process;
        } else {
          this.errors = response.errors;

          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan saat import bank qna!",
          });
        }
      }
    },

    uploadFileImport(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.importForm.file = e.target.result;
      };
    },

    deleteBankQna(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah dihapus, Anda tidak akan dapat memulihkan data ini!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Tidak, batalkan !",
        closeOnConfirm: false,
        closeOnCancel: false,
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          await this.delete(id);
        } else {
          this.$swal("Data Anda aman!");
        }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async exportBankQna() {
      this.loading = true;
      const response = await this.download();

      window.open(response.url);

      this.loading = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <div class="col-lg-12">
              <div class="row">
                <router-link :to="{ name: 'create-bank-qna' }">
                  <b-button class="mr-3" variant="primary" size="sm"
                    ><i class="mdi mdi-plus mr-2"></i> Tambah Bank QNA</b-button
                  >
                </router-link>

                <b-button
                  class="mr-3"
                  variant="warning"
                  size="sm"
                  v-b-modal.modal-import
                  >Import bank qna</b-button
                >

                <b-button variant="warning" size="sm" @click="exportBankQna"
                  >Export bank qna</b-button
                >

                <b-modal
                  centered
                  id="modal-import"
                  title="Import Bank QNA"
                  @ok.prevent="importBankQna"
                  size="xl"
                >
                  <div v-if="processImport.length > 0">
                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>
                            No.
                          </th>
                          <th>
                            Penanya
                          </th>
                          <th width="40%">
                            Pertanyaan
                          </th>
                          <th>
                            Fasilitator
                          </th>
                          <th>
                            Status
                          </th>
                          <th>
                            Pesan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(process, index) in processImport"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ process.questioner }}</td>
                          <td>{{ process.question }}</td>
                          <td>{{ process.fasilitator }}</td>
                          <td>{{ process.status }}</td>
                          <td>{{ process.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <div class="text-center" v-if="loadingImport">
                      <div
                        class="spinner-border text-center text-primary"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="batchId">Kelas</label>

                          <select
                            v-model="importForm.courseId"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['courseId'],
                            }"
                          >
                            <option value="">Pilih Kelas</option>
                            <option
                              :value="course.id"
                              v-for="course in courses"
                              :key="course.id"
                              >{{ course.title }}</option
                            >
                          </select>
                          <div
                            v-if="errors['courseId']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["courseId"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="batchId">Modul Kelas</label>

                          <select
                            v-model="importForm.moduleId"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['moduleId'],
                            }"
                          >
                            <option value="">Pilih Modul Kelas</option>
                            <option
                              :value="module.id"
                              v-for="module in modules"
                              :key="module.id"
                              >{{ module.title }}</option
                            >
                          </select>
                          <div
                            v-if="errors['moduleId']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["moduleId"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="categoryIds">Kategori Kelas</label>

                          <Select2
                            v-model="importForm.categoryIds"
                            :options="categoriesOptions"
                            :settings="{ multiple: true }"
                          />
                          <div
                            v-if="errors['categoryIds']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["categoryIds"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="batchId">Batch Kelas</label>

                          <select
                            v-model="importForm.batchId"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['batchId'],
                            }"
                          >
                            <option value="">Pilih Batch Kelas</option>
                            <option
                              :value="batch.id"
                              v-for="batch in batchs"
                              :key="batch.id"
                            >
                              {{ batch.name }} ({{
                                batch.startDate | moment("MMMM Do YYYY")
                              }}
                              -
                              {{ batch.finishDate | moment("MMMM Do YYYY") }})
                            </option>
                          </select>
                          <div
                            v-if="errors['batchId']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["batchId"] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="batchId">File</label>

                          <input
                            @change="uploadFileImport"
                            type="file"
                            name=""
                            id=""
                            accept=".csv"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['file'],
                            }"
                          />

                          <div v-if="errors['file']" class="invalid-feedback">
                            <span>{{ errors["file"] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-12 mt-3">
              <div class="row">
                <div class="col-lg-2">
                  <div class="row">
                    <select
                      v-model="courseId"
                      name=""
                      id=""
                      class="form-control"
                    >
                      <option value="">Pilih kelas</option>
                      <option
                        :value="course.id"
                        v-for="course in courses"
                        :key="course.id"
                      >
                        {{ course.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <select v-model="mentorId" name="" id="" class="form-control">
                    <option value="">Pilih mentor</option>
                    <option
                      :value="mentor.id"
                      v-for="mentor in mentors"
                      :key="mentor.id"
                    >
                      {{ mentor.firstName }} {{ mentor.lastName }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2">
                  <select
                    v-model="fasilitatorId"
                    name=""
                    id=""
                    class="form-control"
                  >
                    <option value="">Pilih fasilitator</option>
                    <option
                      :value="fasilitator.id"
                      v-for="fasilitator in fasilitators"
                      :key="fasilitator.id"
                    >
                      {{ fasilitator.firstName }}
                      {{ fasilitator.lastName }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2">
                  <select v-model="status" name="" id="" class="form-control">
                    <option value="">Pilih status</option>
                    <option
                      :value="status.key"
                      v-for="status in statuses"
                      :key="status.key"
                    >
                      {{ status.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(categories)="data">
                  <p v-if="data.item.categories">
                    <span
                      v-for="category in data.item.categories"
                      :key="category.id"
                      >{{ category.name }},
                    </span>
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(course)="data">
                  <p v-if="data.item.course">
                    {{ data.item.course.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(batch)="data">
                  <p v-if="data.item.batch">
                    {{ data.item.batch.name }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(questioner)="data">
                  <p v-if="data.item.questioner">
                    {{ data.item.questioner }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(module)="data">
                  <p v-if="data.item.module">
                    {{ data.item.module.title }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(isStar)="data">
                  <p v-if="data.item.isStar">
                    1
                  </p>
                  <p v-else>0</p>
                </template>

                <template #cell(question)="data">
                  {{ data.item.question | removeTag | limitText(100) }}
                </template>

                <template #cell(status)="data">
                  {{
                    data.item.status
                      | textReplace("_", " ")
                      | titleCase
                      | filterBankQnaState
                  }}
                </template>

                <template #cell(fasilitator)="data">
                  <p v-if="data.item.fasilitator">
                    {{ data.item.fasilitator.firstName }}
                    {{ data.item.fasilitator.lastName }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click="
                      $router.push({
                        name: 'edit-bank-qna',
                        params: { id: data.item.id },
                      })
                    "
                    ><i class="ri-pencil-line"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteBankQna(data.item.id)"
                    ><i class="ri-delete-bin-line"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
