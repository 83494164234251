<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
// import { ModelSelect } from "vue-search-select";

export default {
  page: {
    title: "Invite Ke Group",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      groups: "whatsappGroup/whatsappGroups",
      user: "auth/currentUser",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Invite Ke Group",
      id: this.$route.params.id,
      items: [
        {
          text: "Whatsapp Group",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        phoneNumber: "",
        groupIds: [],
        isAdmin: false,
      },
      loadingFetchGroup: false,
      provinceId: "",
      submitted: false,
      errors: {},
      loading: false,
      userId: "",
      membersOptions: [],
      users: [],
      loadingSync: false,
      groupFilter: {
        type: "",
        search: "",
      },
    };
  },

  watch: {
    groupFilter: {
      handler(val) {
        this.loadingFetchGroup = true;

        this.fetchGroups([
          { key: "type", value: val.type },
          { key: "search", value: val.search },
        ]);

        this.loadingFetchGroup = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      invite: "whatsappGroup/invite",

      fetchGroups: "whatsappGroup/changeFilters",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      this.loading = true;

      await this.invite(this.form);

      this.$swal("Berhasil invite kedalam group!");

      this.loading = false;
    },

    selectGroup(id) {
      const index = this.form.groupIds.indexOf(id);

      if (index > -1) {
        this.form.groupIds.splice(index, 1);
      } else {
        this.form.groupIds.push(id);
      }
    },

    selectAll() {
      this.groups.forEach((group) => {
        const index = this.form.groupIds.indexOf(group.id);

        if (index === -1) this.form.groupIds.push(group.id);
      });
    },

    isPartner() {
      return this.user.roles.findIndex((role) => role.name === "PARTNER") > -1;
    },
  },

  async mounted() {
    const filters = [
      { key: "limit", value: 9999 },
      { key: "ordering", value: "name" },
    ];

    if (this.user.roles.findIndex((role) => role.name === "PARTNER") > -1) {
      filters.push(
        { key: "type", value: "PARTNERSHIP" },
        { key: "partnerId", value: this.user.partner.id }
      );
    }

    this.loadingFetchGroup = true;
    this.fetchGroups(filters);
    this.loadingFetchGroup = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="phoneNumber"
                      >Nomer Whatsapp (Jika lebih dari satu pisahkan dengan
                      koma, contoh:
                      08237xxxxx66,08237xxxxx61,08237xxxxx62)</label
                    >
                    <input
                      id="phoneNumber"
                      v-model="form.phoneNumber"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['phoneNumber'],
                      }"
                    />
                    <div v-if="errors['phoneNumber']" class="invalid-feedback">
                      <span>{{ errors["phoneNumber"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="isAdmin">Apakah Akan Jadikan Admin?</label>
                    <select v-model="form.isAdmin" class="form-control">
                      <option :value="true">Jadikan admin</option>
                      <option :value="false">Tidak jadikan admin</option>
                    </select>
                    <div v-if="errors['isAdmin']" class="invalid-feedback">
                      <span>{{ errors["isAdmin"] }}</span>
                    </div>
                  </div>
                </div>

                <br />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="image">Grup</label>

                    <div class="col-12 mb-4">
                      <div class="row">
                        <div class="col-lg-3 mr-3" v-if="!isPartner()">
                          <div class="row">
                            <select
                              v-model="groupFilter.type"
                              name=""
                              id=""
                              class="form-control"
                            >
                              <option value="">Semua Tipe Grup</option>
                              <option value="COMMUNITY">
                                Komunitas
                              </option>
                              <option value="EVENT">
                                Event
                              </option>
                              <option value="PARTNERSHIP">
                                Partnership
                              </option>
                              <option value="TESTER">
                                Tester
                              </option>
                              <option value="OTHER">
                                Lainnya
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-3 mr-3">
                          <div class="row">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Cari group..."
                              v-model="groupFilter.search"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th @click="selectAll">Pilih Semua</th>
                          <th>Nama</th>
                          <th>Tipe</th>
                          <th>Aktif</th>
                          <th>Total Member</th>
                          <th v-if="!isPartner()">Kelas</th>
                          <th v-if="!isPartner()">Batch</th>
                        </tr>
                      </thead>

                      <tbody v-if="loadingFetchGroup">
                        <tr>
                          <td
                            :colspan="!isPartner() ? 7 : 5"
                            class="text-center"
                          >
                            Sedang mengambil data...
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="!loadingFetchGroup">
                        <tr
                          v-for="group in groups"
                          :key="group.id"
                          @click="selectGroup(group.id)"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :value="group.id"
                              v-model="form.groupIds"
                              :id="`checkbox-group`"
                            />
                          </td>
                          <td>{{ group.name }}</td>
                          <td>{{ group.type }}</td>
                          <td>{{ group.isActive }}</td>
                          <td>{{ group.totalMembers }}</td>
                          <td v-if="!isPartner()">
                            {{ group.course ? group.course.title : "-" }}
                          </td>
                          <td v-if="!isPartner()">
                            {{
                              group.courseBatch ? group.courseBatch.name : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
