<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  page: {
    title: "Tambah Pertanyaan Onboarding",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    courses() {
      return this.$store.getters["course/courses"];
    },

    ...mapGetters({
      whatsappGroupLeaveSurveyQuestions:
        "whatsappGroupLeaveSurveyQuestion/whatsappGroupLeaveSurveyQuestions",
    }),
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: this.$route.params.id
        ? "Ubah Pertanyaan Onboarding"
        : "Tambah Pertanyaan Onboarding",
      id: this.$route.params.id,
      items: [
        {
          text: "Pertanyaan Onboarding",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        questions: [
          {
            question: "",
            isActive: true,
            type: "TEXT",
            options: [{ text: "" }],
          },
        ],
        deleteIds: [],
      },
      loading: false,
      submitted: false,
      errors: {},
      options: [],
      questionTypes: [
        {
          key: "MULTIPLE_CHOICE",
          text: "Multiple Choice",
        },
        {
          key: "SINGLE_CHOICE",
          text: "Single Choice",
        },
        {
          key: "TEXT",
          text: "Teks",
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      create: "whatsappGroupLeaveSurveyQuestion/create",
      fetch: "whatsappGroupLeaveSurveyQuestion/fetch",
      fetchCourse: "course/fetch",
    }),

    changeOnboarding() {
      if (this.form.filterOnboardingId) {
        this.options = this.whatsappGroupLeaveSurveyQuestions.filter(
          (whatsappGroupLeaveSurveyQuestion) =>
            whatsappGroupLeaveSurveyQuestion.id === this.form.filterOnboardingId
        )[0].options;
        this.form.filterOnboardingOptionId = this.options[0].id;
      } else {
        this.options = [];
        this.form.filterOnboardingId = "";
        this.form.filterOnboardingOptionId = "";
      }
    },

    addOption(index) {
      this.form.questions[index].options.push({
        text: "",
      });
    },

    haveOption(type) {
      return type === "SELECT" || type === "CHECKBOX" || type === "RADIO";
    },

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.loading = true;
      this.submitted = true;
      const response = await this.create(this.form);

      if (response.status) {
        await this.fetch();
        window.scrollTo(0, 0);
      } else {
        this.errors = response.errors;
      }
      this.loading = false;
    },

    addQuestion() {
      this.form.questions.push({
        question: "",
        order: 0,
        isActive: true,
        type: "TEXT",
        options: [{ text: "" }],
      });
    },

    deleteOption(indexQuestion, index) {
      this.form.questions[indexQuestion].options.splice(index, 1);
    },
  },

  async mounted() {
    this.$store.dispatch("notification/clear");
    await this.fetch();

    if (this.whatsappGroupLeaveSurveyQuestions.length > 0)
      this.form.questions = Object.assign(
        [],
        this.whatsappGroupLeaveSurveyQuestions
      );
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <draggable v-model="form.questions" draggable=".card">
                <div
                  class="card"
                  v-for="(question, indexQuestion) in form.questions"
                  :key="question.id"
                >
                  <div class="p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="minimalScore"
                            >Pertanyaan #{{ indexQuestion + 1 }}
                            <button
                              class="btn btn-danger btn-sm ml-2"
                              type="button"
                              @click="
                                form.questions.splice(indexQuestion, 1);
                                deleteIds.push(question.id);
                              "
                            >
                              Hapus Pertanyaan
                            </button></label
                          >
                          <textarea
                            name=""
                            v-model="question.question"
                            id=""
                            cols="30"
                            rows="5"
                            class="form-control mt-2"
                          ></textarea>
                          <div
                            v-if="errors['question']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["question"] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="name">Apakah Aktif?</label>
                          <select
                            name=""
                            v-model="question.isActive"
                            id=""
                            class="form-control"
                          >
                            <option :value="true">Iya</option>
                            <option :value="false">Tidak</option>
                          </select>
                          <div
                            v-if="errors['isActive']"
                            class="invalid-feedback"
                          >
                            <span>{{ errors["isActive"] }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="name">Tipe</label>
                          <select
                            name="type"
                            id="type"
                            v-model="question.type"
                            class="form-control"
                          >
                            <option value="">Pilih tipe pertanyaan</option>
                            <option
                              :value="type.key"
                              v-for="type in questionTypes"
                              :key="`${indexQuestion}-${type.key}`"
                              >{{ type.text }}</option
                            >
                          </select>
                          <div v-if="errors['type']" class="invalid-feedback">
                            <span>{{ errors["type"] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="question.type !== 'TEXT'">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="backgroundImage">Opsi Jawaban</label>
                          <draggable
                            v-model="question.options"
                            draggable=".item"
                          >
                            <div
                              v-for="(question, index) in question.options"
                              :key="`${indexQuestion}-${index}`"
                              class="item"
                            >
                              <div class="row">
                                <div class="col-lg-11">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for="label">Jawaban</label>
                                        <input
                                          name=""
                                          v-model="question.text"
                                          id=""
                                          cols="30"
                                          rows="5"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="deleteOption(indexQuestion, index)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <button
                              class="btn btn-info form-control"
                              slot="footer"
                              @click="addOption(indexQuestion)"
                              type="button"
                            >
                              Tambah Opsi Jawaban
                            </button>
                          </draggable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>

              <button
                class="btn btn-info mt-2 mb-4 form-control"
                @click="addQuestion"
                type="button"
              >
                Tambah Pertanyaan
              </button>

              <hr />

              <button
                class="btn btn-primary mt-4 form-control"
                :disabled="loading"
                type="submit"
              >
                <span v-if="!loading">Simpan Pertanyaan Survey</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.item {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
</style>
