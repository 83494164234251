<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Buat Program",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      partners: "partnershipPartner/partnershipPartners",
      partnershipItems: "partnershipItem/partnershipItems",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Program" : "Buat Program",
      id: this.$route.params.id,
      items: [
        {
          text: "Program",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        name: "",
        startDate: "",
        finishDate: "",
        items: [
          {
            partnerId: "",
            partnerItemId: "",
            revenue: 0,
          },
        ],
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "partnership/create",
      update: "partnership/update",
      detail: "partnership/detail",

      fetchPartner: "partnershipPartner/changeFilter",
      fetchItem: "partnershipItem/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-partnership" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    addItem() {
      this.form.items.push({
        partnerId: "",
        partnerItemId: "",
        revenue: 0,
      });
    },
  },

  async mounted() {
    this.fetchItem({ key: "ordering", value: "name" });
    this.fetchPartner({ key: "ordering", value: "name" });

    if (this.$route.params.id) {
      const detail = Object.assign(
        {},
        await this.detail(this.$route.params.id)
      );
      this.form.name = detail.name;
      this.form.items = detail.events;

      this.form.items.forEach((item) => {
        if (item.partner) item.partnerId = item.partner.id;
        if (item.partnershipItem)
          item.partnershipItemId = item.partnershipItem.id;
      });

      this.form.startDate = this.$options.filters.moment(
        detail.startDate,
        "YYYY-MM-DD"
      );

      this.form.finishDate = this.$options.filters.moment(
        detail.finishDate,
        "YYYY-MM-DD"
      );
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom01">Nama</label>
                    <input
                      id="validationCustom01"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Nama"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="startDate">Tanggal Mulai</label>
                    <input
                      id="startDate"
                      v-model="form.startDate"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['startDate'],
                      }"
                    />
                    <div v-if="errors['startDate']" class="invalid-feedback">
                      <span>{{ errors["startDate"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="finishDate">Tanggal Selesai</label>
                    <input
                      id="finishDate"
                      v-model="form.finishDate"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['finishDate'],
                      }"
                    />
                    <div v-if="errors['finishDate']" class="invalid-feedback">
                      <span>{{ errors["finishDate"] }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <table class="table table-bordered table-hover">
                    <tr>
                      <th>No</th>
                      <th>Partner</th>
                      <th>Event</th>
                      <th>Revenue</th>
                      <th>Action</th>
                    </tr>
                    <tr
                      v-for="(item, index) of form.items"
                      :key="`event-${index}`"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>
                        <select class="form-control" v-model="item.partnerId">
                          <option value="">Pilih Partner</option>
                          <option
                            v-for="partner of partners"
                            :key="partner.id"
                            :value="partner.id"
                            >{{ partner.name }}</option
                          >
                        </select>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          v-model="item.partnershipItemId"
                        >
                          <option value="">Pilih Event</option>
                          <option
                            v-for="partnershipItem of partnershipItems"
                            :key="partnershipItem.id"
                            :value="partnershipItem.id"
                            >{{ partnershipItem.name }}</option
                          >
                        </select>
                      </td>
                      <td>
                        <input
                          id="revenue"
                          v-model="item.revenue"
                          type="number"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['revenue'],
                          }"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-danger btn-sm"
                          type="button"
                          @click="form.items.splice(index, 1)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <button
                          class="btn btn-info form-control"
                          type="button"
                          @click="addItem"
                        >
                          Tambah Event
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary form-control mt-4"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
