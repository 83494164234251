import userCategoryApi from "../../api/user-category.api";
import filterDefault from "../../helpers/filter";

export const state = {
  userCategorys: [],
  userCategory: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
};

export const getters = {
  userCategorys(state) {
    return state.userCategorys;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_USER_CATEGORIES(state, userCategorys) {
    state.userCategorys = userCategorys;
  },

  ADD_USER_CATEGORY(state, userCategory) {
    state.userCategorys.unshift(userCategory);
  },

  MERGE_USER_CATEGORIES(state, userCategorys) {
    state.userCategorys = userCategorys.concat(state.userCategorys);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_USER_CATEGORY(state, id) {
    state.userCategorys.splice(
      state.userCategorys.findIndex((userCategory) => userCategory.id === id),
      1
    );
  },

  SET_USER_CATEGORY(state, userCategory) {
    state.userCategory = userCategory;
  },

  CHANGE_USER_CATEGORY(state, { id, userCategory }) {
    state.userCategorys[
      state.userCategorys.findIndex((userCategory) => userCategory.id === id)
    ] = userCategory;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await userCategoryApi.fetch(state.filter);

      commit("SET_USER_CATEGORIES", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },

  async create({ commit, dispatch }, payload) {
    const { data } = await userCategoryApi.create(payload);

    if (!data.success) {
      return { status: data.success, errors: data.errors };
    }

    commit("ADD_USER_CATEGORY", data.data);
    dispatch("notification/success", data.message, { root: true });

    return { status: true };
  },

  async delete({ commit, dispatch }, id) {
    const { data } = await userCategoryApi.destroy(id);

    if (!data.success) {
      return { status: false };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("REMOVE_USER_CATEGORY", id);

    return { status: true };
  },

  async detail({ commit }, id) {
    const { data } = await userCategoryApi.detail(id);

    commit("SET_USER_CATEGORY", data.data);

    return data.data;
  },

  async update({ dispatch, commit }, { id, payload }) {
    const { data } = await userCategoryApi.update(id, payload);

    if (!data.success) {
      return { status: false, errors: data.errors };
    }

    dispatch("notification/success", data.message, { root: true });
    commit("CHANGE_USER_CATEGORY", { id, userCategory: data.data });

    return { status: true };
  },
};
