import logApi from "../../api/log.api";
import filterDefault from "../../helpers/filter";

export const state = {
  logs: [],
  log: {},
  total: 0,
  filter: {
    ...filterDefault,
    courseId: "",
    courseModuleId: "",
    courseModuleItemId: "",
    logId: "",
    surveyId: "",
    types: "",
  },
  types: [
    "AUTHENTICATION",
    "COURSE",
    "PROFILE",
    "NOTIFICATION",
    "LOG",
    "SURVEY",
  ],
};

export const getters = {
  logs(state) {
    return state.logs;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_LOGS(state, logs) {
    state.logs = logs;
  },

  ADD_LOG(state, log) {
    state.logs.unshift(log);
  },

  MERGE_LOGS(state, logs) {
    state.logs = logs.concat(state.logs);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_LOG(state, id) {
    state.logs.splice(
      state.logs.findIndex((log) => log.id === id),
      1
    );
  },

  SET_LOG(state, log) {
    state.log = log;
  },

  CHANGE_LOG(state, { id, log }) {
    state.logs[state.logs.findIndex((log) => log.id === id)] = log;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await logApi.fetch(state.filter);

      commit("SET_LOGS", data.data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error.response);
    }
  },
};
