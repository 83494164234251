import whatsappGroupLeaveSurveyApi from "../../api/whatsapp-group-leave-survey.api";
import filterDefault from "../../helpers/filter";

export const state = {
  whatsappGroupLeaveSurveys: [],
  total: 0,
  filter: {
    ...filterDefault,
    groupId: "",
    alreadyAnswered: "",
  },
};

export const getters = {
  whatsappGroupLeaveSurveys(state) {
    return state.whatsappGroupLeaveSurveys;
  },

  filter(state) {
    return state.filter;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_WHATSAPP_GROUP_LEAVE_SURVEYS(state, whatsappGroupLeaveSurveys) {
    state.whatsappGroupLeaveSurveys = whatsappGroupLeaveSurveys;
  },

  ADD_WHATSAPP_GROUP_LEAVE_SURVEYS(state, whatsappGroupLeaveSurvey) {
    state.whatsappGroupLeaveSurveys.unshift(whatsappGroupLeaveSurvey);
  },

  MERGE_WHATSAPP_GROUP_LEAVE_SURVEYSS(state, whatsappGroupLeaveSurveys) {
    state.whatsappGroupLeaveSurveys = whatsappGroupLeaveSurveys.concat(
      state.whatsappGroupLeaveSurveys
    );
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await whatsappGroupLeaveSurveyApi.fetch(state.filter);

      commit("SET_WHATSAPP_GROUP_LEAVE_SURVEYS", data.data.data);
      commit("SET_TOTAL", data.data.total);

      return data.data.data;
    } catch (error) {
      console.log(error.response);
    }
  },

  async detail(context, id) {
    const { data } = await whatsappGroupLeaveSurveyApi.detail(id);

    return data.data;
  },
};
