<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Buat Partner",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      categories: "partnershipPartnerCategory/partnershipPartnerCategories",
      types: "partnershipPartnerType/partnershipPartnerTypes",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah Partner" : "Buat Partner",
      id: this.$route.params.id,
      items: [
        {
          text: "Partner",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Buat",
          active: true,
        },
      ],
      form: {
        name: "",
        phoneNumber: "",
        email: "",
        typeId: "",
        categoryId: "",
        website: "",
        instagram: "",
        tiktok: "",
        additionalNotes: "",
        pics: [
          {
            name: "",
            phoneNumber: "",
            email: "",
            position: "",
          },
        ],
      },
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      create: "partnershipPartner/create",
      update: "partnershipPartner/update",
      detail: "partnershipPartner/detail",

      fetchCategory: "partnershipPartnerCategory/changeFilter",
      fetchType: "partnershipPartnerType/changeFilter",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-partnership-partner" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },
  },

  async mounted() {
    this.fetchType({ key: "ordering", value: "name" });
    this.fetchCategory({ key: "ordering", value: "name" });

    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);
      if (this.form.category) this.form.categoryId = this.form.category.id;
      if (this.form.type) this.form.typeId = this.form.type.id;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom01">Nama</label>
                    <input
                      id="validationCustom01"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Nama"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="phoneNumber">Nomer Telpon</label>
                    <input
                      id="phoneNumber"
                      v-model="form.phoneNumber"
                      type="text"
                      class="form-control"
                      placeholder="Nomer Telpon"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['phoneNumber'],
                      }"
                    />
                    <div v-if="errors['phoneNumber']" class="invalid-feedback">
                      <span>{{ errors["phoneNumber"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="email">E-mail</label>
                    <input
                      id="email"
                      v-model="form.email"
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['email'],
                      }"
                    />
                    <div v-if="errors['email']" class="invalid-feedback">
                      <span>{{ errors["email"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <br />
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="typeId">Tipe</label>
                    <select
                      id="typeId"
                      v-model="form.typeId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['typeId'],
                      }"
                    >
                      <option value="">Pilih Tipe Partner</option>
                      <option
                        :value="type.id"
                        v-for="type of types"
                        :key="type.id"
                        >{{ type.name }}</option
                      >
                    </select>
                    <div v-if="errors['typeId']" class="invalid-feedback">
                      <span>{{ errors["typeId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="categoryId">Kategori</label>
                    <select
                      id="categoryId"
                      v-model="form.categoryId"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['categoryId'],
                      }"
                    >
                      <option value="">Pilih Kategori Partner</option>
                      <option
                        :value="category.id"
                        v-for="category of categories"
                        :key="category.id"
                        >{{ category.name }}</option
                      >
                    </select>
                    <div v-if="errors['categoryId']" class="invalid-feedback">
                      <span>{{ errors["categoryId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <br />
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pic">PIC</label>
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>Nama</th>
                        <th>Nomer Telpon</th>
                        <th>E-mail</th>
                        <th>Posisi</th>
                        <th>Aksi</th>
                      </tr>
                      <tr
                        v-for="(pic, index) in form.pics"
                        :key="`index-pic-${index}`"
                      >
                        <td>
                          <input
                            id="name"
                            v-model="pic.name"
                            type="text"
                            class="form-control"
                            placeholder="Nama PIC"
                            value="Mark"
                            :class="{
                              'is-invalid': errors['name'],
                            }"
                          />
                        </td>
                        <td>
                          <input
                            id="phoneNumber"
                            v-model="pic.phoneNumber"
                            type="text"
                            class="form-control"
                            placeholder="Nomer Telpon PIC"
                            value="Mark"
                            :class="{
                              'is-invalid': errors['phoneNumber'],
                            }"
                          />
                        </td>
                        <td>
                          <input
                            id="email"
                            v-model="pic.email"
                            type="text"
                            class="form-control"
                            placeholder="E-mail PIC"
                            value="Mark"
                            :class="{
                              'is-invalid': errors['email'],
                            }"
                          />
                        </td>
                        <td>
                          <input
                            id="position"
                            v-model="pic.position"
                            type="text"
                            class="form-control"
                            placeholder="Posisi PIC"
                            value="Mark"
                            :class="{
                              'is-invalid': errors['position'],
                            }"
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="form.pics.splice(index, 1)"
                            type="button"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <button
                            class="btn btn-warning form-control btn-sm"
                            type="button"
                            @click="
                              form.pics.push({
                                name: '',
                                phoneNumber: '',
                                email: '',
                                position: '',
                              })
                            "
                          >
                            Tambah PIC
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <br />
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="website">Website</label>
                    <input
                      id="website"
                      v-model="form.website"
                      type="text"
                      class="form-control"
                      placeholder="Website"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['website'],
                      }"
                    />
                    <div v-if="errors['website']" class="invalid-feedback">
                      <span>{{ errors["website"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <input
                      id="instagram"
                      v-model="form.instagram"
                      type="text"
                      class="form-control"
                      placeholder="Instagram"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['instagram'],
                      }"
                    />
                    <div v-if="errors['instagram']" class="invalid-feedback">
                      <span>{{ errors["instagram"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="tiktok">Tiktok</label>
                    <input
                      id="tiktok"
                      v-model="form.tiktok"
                      type="text"
                      class="form-control"
                      placeholder="Tiktok"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['tiktok'],
                      }"
                    />
                    <div v-if="errors['tiktok']" class="invalid-feedback">
                      <span>{{ errors["tiktok"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <br />
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="additionalNotes">Catatan Tambahan</label>
                    <textarea
                      id="additionalNotes"
                      v-model="form.additionalNotes"
                      type="text"
                      class="form-control"
                      placeholder="Catatan Tambahan"
                      value="Mark"
                      :class="{
                        'is-invalid': errors['additionalNotes'],
                      }"
                    />
                    <div
                      v-if="errors['additionalNotes']"
                      class="invalid-feedback"
                    >
                      <span>{{ errors["additionalNotes"] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                :disabled="loading"
                class="btn btn-primary form-control mt-4"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
