<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { mapActions, mapGetters } from "vuex";

export default {
  page: {
    title: "Tambah UTM",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    ...mapGetters({
      utms: "utm/utms",
    }),
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$route.params.id ? "Ubah UTM" : "Tambah UTM",
      id: this.$route.params.id,
      items: [
        {
          text: "UTM",
          href: "/",
        },
        {
          text: this.$route.params.id ? "Ubah" : "Tambah",
          active: true,
        },
      ],
      form: {
        name: "",
        description: "",
        parentId: "",
      },
      submitted: false,
      errors: {},
      categoriesOptions: [],
      mentorsOptions: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      detail: "utm/detail",
      update: "utm/update",
      create: "utm/create",

      fetch: "utm/fetch",
    }),

    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      let response = {};
      this.loading = true;

      if (this.$route.params.id) {
        response = await this.update({
          id: this.$route.params.id,
          payload: this.form,
        });
      } else {
        response = await this.create(this.form);
      }

      if (response.status) {
        this.$router.push({ name: "list-utm" });
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.form = await this.detail(this.$route.params.id);

      if (this.form.parent) this.form.parentId = this.form.parent.id;
    }

    await this.fetch();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Nama</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['name'],
                      }"
                    />
                    <div v-if="errors['name']" class="invalid-feedback">
                      <span>{{ errors["name"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Parent UTM</label>
                    <select v-model="form.parentId" class="form-control">
                      <option value="">Pilih Parent UTM</option>
                      <option
                        :value="utm.id"
                        v-for="utm in utms"
                        :key="utm.id"
                        >{{ utm.name }}</option
                      >
                    </select>
                    <div v-if="errors['parentId']" class="invalid-feedback">
                      <span>{{ errors["parentId"] }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Deskripsi</label>
                    <textarea
                      id="description"
                      v-model="form.description"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': errors['description'],
                      }"
                    ></textarea>
                    <div v-if="errors['description']" class="invalid-feedback">
                      <span>{{ errors["description"] }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="form.childrens">
                  <div class="col-md-12" v-if="form.childrens.length > 0">
                    <div class="form-group">
                      <label for="name">Child UTM</label>
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(children, index) in form.childrens"
                            :key="children.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ children.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary mt-4 form-control"
                type="submit"
              >
                <span v-if="!loading">{{ title }}</span>

                <div
                  class="spinner-border text-light spinner-border-sm"
                  role="status"
                  v-else
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
